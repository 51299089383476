import UserService from '@/services/user-service';

export class ACL {
  static can(permission: string) {
    const user = UserService.instance.user;

    if (!user.role) {
      UserService.instance.logout();
    }

    return user.role?.permissions?.find((p: { name: string }) => p.name === permission) !== undefined;
  }

  static canGroup(group: string) {
    const user = UserService.instance.user;

    if (!user.role) {
      UserService.instance.logout();
    }

    return user.role?.permissions?.find((p: { name: string }) => p.name.indexOf(`${group}.`) !== -1) !== undefined;
  }
}
