export default [
  {
    path: '/report/road-facilities',
    name: 'road-facilities-report',
    component: () => import(/* webpackChunkName: "main-report" */ '@/views/report/main-report.vue'),
    children: [
      {
        path: 'form1',
        name: 'road-facilities-report.form1',
        component: () => import(/* webpackChunkName: "road-facilities-report.form1" */ '@/views/report/road-facilities/form1.vue'),
      },
      {
        path: 'form2',
        name: 'road-facilities-report.form2',
        component: () => import(/* webpackChunkName: "road-facilities-report.form2" */ '@/views/report/road-facilities/form2.vue'),
      },
      {
        path: 'form3',
        name: 'road-facilities-report.form3',
        component: () => import(/* webpackChunkName: "road-facilities-report.form3" */ '@/views/report/road-facilities/form3.vue'),
      },
    ],
  },
];
