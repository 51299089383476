

































































import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api';
import { IChatGroup, IChatUser } from '../../../../types';
import ItemOpenCloseIcon from '../../../shared/item-open-close-icon.vue';
import { isMobile } from 'mobile-device-detect';
import { ACL } from '../../../../modules/acl/acl-service';

export default defineComponent({
  components: { ItemOpenCloseIcon },
  emits: ['open', 'start-mass-send', 'cancel-mass-send', 'mass-send-users'],
  props: ['groups', 'isMassSendMode', 'isMassSendConfirmMode'],
  setup(props, { emit }) {
    const getMessageCount = (group: IChatGroup) => {
      return group.users.reduce((acc, user) => {
        acc += user.count_unread_messages;

        return acc;
      }, 0);
    };
    watch(() => props.groups, () => {
      props.groups.forEach((group: IChatGroup) => {
        if (getMessageCount(group) > 0) {
          group.open = true;
        }
      });
    });
    const massSendBufferGroupValues: {
      [key: number]: boolean,
    } = {};
    const massSendBufferValues: {
      [key: number]: boolean,
    } = {};
    props.groups.forEach((group: IChatGroup) => {
      massSendBufferGroupValues[group.id as number] = false;
      group.users.forEach(user => {
        massSendBufferValues[user.id] = false;
      });
    });

    const massSendBuffer: {
      [key: number]: boolean,
    } = reactive(massSendBufferValues);
    const massSendBufferGroup: {
      [key: number]: boolean,
    } = reactive(massSendBufferGroupValues);

    const isMassSendDisabled = ref(!ACL.can('messenger.message-mass-create'));
    const isMobileMode = ref(isMobile);

    const users = computed(() => {
      return Object
        .keys(massSendBuffer)
        .map(id => Number(id))
        .filter(id => massSendBuffer[id])
        .map(id => {
          for (let index = 0; index < props.groups.length; index++) {
            const group: IChatGroup = props.groups[index];
            const user = group.users.find(user => user.id === id);

            if (user) {
              return user;
            }
          }
        });
    });

    const updateGroupMassSendStatus = () => {
      props.groups.forEach((group: IChatGroup) => {
        let groupValue = true;
        group.users.forEach(user => {
          groupValue = massSendBuffer[user.id] && groupValue;
        });

        massSendBufferGroup[group.id as number] = groupValue;
      });
    };

    watch(users, () => {
      emit('mass-send-users', users.value);
    });

    return {
      massSendBuffer,
      massSendBufferGroup,
      isMassSendDisabled,
      isMobileMode,
      users,
      openContact(user: IChatUser) {
        if (!props.isMassSendMode) {
          emit('open', user);
        } else {
          massSendBuffer[user.id] = !massSendBuffer[user.id];
          updateGroupMassSendStatus();
        }
      },
      getMessageCount,
      toggleSendMode() {
        if (!props.isMassSendMode) {
          emit('start-mass-send');
        } else {
          emit('cancel-mass-send');
          Object.keys(massSendBuffer).forEach(key => {
            massSendBuffer[Number(key)] = false;
          });
          updateGroupMassSendStatus();
        }
      },
      toggleMassSendGroup(event: Event, group: IChatGroup) {
        const value = (event.target as HTMLInputElement).checked;
        massSendBufferGroup[group.id as number] = value;
        group.users.forEach(user => {
          massSendBuffer[user.id] = value;
        });

        updateGroupMassSendStatus();
      },
      toggleMassSendUser(event: Event, user: IChatUser) {
        const value = (event.target as HTMLInputElement).checked;
        massSendBuffer[user.id] = value;

        updateGroupMassSendStatus();
      },
    };
  },
});
