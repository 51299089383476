import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import simpleVueValidator from 'simple-vue-validator';
import Notifications from './modules/notifications/module';
import acl from './modules/acl/module';
import Vue2TouchEvents from 'vue2-touch-events';

import App from '@/app.vue';
import router from './router';
import store from './store';
import { InitializeService } from '@/services/initialize-service';

import '@fortawesome/fontawesome-free/js/all';

Vue.use(Notifications);
Vue.use(acl);
Vue.use(simpleVueValidator as any);
Vue.use(VueCompositionAPI);
Vue.use(Vue2TouchEvents);
Vue.config.productionTip = false;

let app: Vue;

InitializeService.instance
  .setStore(store)
  .initialize()
  .finally(() => {
    app = new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app');
  });

export { app, store };
