<template>
  <div class="app-base" :class="{ mobile }">
    <slot name="header" v-if="isAuth">
      <app-header :open-menu="openMenu" @toggle-menu="toggleMenu"></app-header>
      <app-content :open-menu="openMenu"></app-content>
    </slot>
    <slot v-else>
      <app-content :open-menu="isAuth && openMenu"></app-content>
    </slot>
    <popups></popups>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import isAuthMixin from '@/mixins/isAuth';
import AppHeader from '@/components/app/app-header';
import AppContent from '@/components/app/app-content';
import Popups from '@/components/app/popups';
import { isMobile } from 'mobile-device-detect';

export default {
  name: 'app-base',
  components: { Popups, AppContent, AppHeader },
  mixins: [isAuthMixin],
  computed: {
    ...mapGetters({
      openMenu: 'openMenu',
    }),
    mobile: () => isMobile,
  },
  methods: {
    ...mapMutations({
      toggleMenu: 'toggleMenu',
    }),
    ...mapActions({
      closeMenu: 'closeMenu',
    }),
    initVh() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
  },
  mounted() {
    if (this.mobile) {
      this.closeMenu();
      document.body.style.overflowY = 'hidden';

      this.initVh();
      window.addEventListener('resize', () => {
        this.initVh();
      });
    }
  },
};
</script>

<style lang="scss">
#map {
  .leaflet-bar a:hover {
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 22px;
  }
}
.mobile {
  .menu-sidebars.closed-menu {
    left: 0;
  }

  .sidebar-button.opened {
    left: calc(100vw - 34px);
    transition: .3s;
  }

  .left-menu.left-menu-closed {
    display: none;
  }

  .content {
    margin-left: 0;
  }

  .left-menu, .left-sidebar-menu .sidebar-content {
    width: 100vw;
  }

  .header-email, .header-icon, .control-panel {
    display: none;
  }

  .logout-wrapper .header-icon, .chat-icon .header-icon {
    display: block;
  }

  .mo-selector {
    width: 170px;

    .icon-mo {
      display: none;
    }

    .ui.search.dropdown .text {
      width: 100px;
    }

    @media only screen and (max-width: 767px) {
      .ui.search.dropdown .menu {
        max-height: 300px;
      }
    }
  }

  .filter-wrapper {
    flex-wrap: wrap;

    .filter-item .ui {
      width: 250px;
    }
  }

  div.layers {
    top: 13px;
    right: 5px;
  }

  #map {
    .leaflet-top.leaflet-right {
      top: 68px;
      right: 5px;
    }
  }

  .chat-menu {
    width: 100%;
    max-width: 100%;
  }

  .header .right-block {
    padding-left: 0;

    .logout-wrapper {
      margin: 0;
    }
  }
}
</style>
