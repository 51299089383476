/* eslint-disable camelcase */
import axios from './axios';
import { PubSub } from '@/services/pub-sub';

const key = Symbol('key');
const keyEnforcer = Symbol('keyEnforcer');
const tokenStorageKey = 'user-token';
const userStorageKey = 'user-data';

class UserService extends PubSub {
  _token = null;
  _user = null;

  constructor(enforcer) {
    super();
    if (enforcer !== keyEnforcer) {
      throw new Error('Instantiation failed: use UserService.instance instead of new.');
    }

    this._token = localStorage.getItem(tokenStorageKey) || null;

    const storedUserData = localStorage.getItem(userStorageKey);

    if (storedUserData !== null) {
      this._user = JSON.parse(storedUserData);
    } else {
      this._user = {};
    }
  }

  static get instance() {
    if (!this[key]) {
      this[key] = new UserService(keyEnforcer);
    }

    return this[key];
  }

  static set instance(v) {
    throw new Error("Can't change constant property!");
  }

  get token() {
    return this._token;
  }

  set token(token) {
    if (token && token.indexOf('Bearer ') !== 0) {
      token = `Bearer ${token}`;
    }

    this._token = token;
    localStorage.setItem(tokenStorageKey, token);
  }

  get isAuth() {
    return this._token !== null;
  }

  get user() {
    return this._user;
  }

  set user(user) {
    this._user = Object.freeze(user);
    localStorage.setItem(userStorageKey, JSON.stringify(user));
  }

  logout() {
    this._token = null;
    localStorage.clear();
    this.publish('logout');
  }

  /**
   * Отправляет запрос на регистрацию нового пользователя
   *
   * @param string login
   * @param string password
   * @param string password_confirmation
   * @returns {Promise<any>}
   */
  registration({ login, password, password_confirmation }) {
    return new Promise((resolve, reject) => {
      axios.post('/auth/register', {
        login, password, password_confirmation,
      })
        .then(resolve)
        .catch(reject);
    });
  }

  /**
   * Отправляет запрос на авторизацию в системе
   *
   * @param string login
   * @param string password
   * @returns {Promise<any>}
   */
  auth({ email, password }) {
    return new Promise((resolve, reject) => {
      axios.post('/auth/login', {
        email, password,
      })
        .then(({ data }) => {
          this.token = data.access_token;
          this.user = data.user;
          this.publish('login');
          resolve(data);
        })
        .catch(reject);
    });
  }

  /**
   * Возвращает информацию о текущем пользователе
   *
   * @returns {Promise<any>}
   */
  me() {
    return new Promise((resolve, reject) => {
      axios.get('/auth/me')
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  }

  refreshToken() {
    return new Promise((resolve, reject) => {
      axios.get('/auth/refresh', {
        refreshRequest: true,
      })
        .then(response => {
          if (response.headers.authorization) {
            this.token = response.headers.authorization;
          }
          resolve(response);
        })
        .catch(e => reject(e));
    });
  }
}

export default UserService;
