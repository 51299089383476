import axios from '@/services/axios';
import { SitCenterRouteResponse, SitCenterTransportResponse, SitCenterOutfitsResponse } from '@/types';

export class SitCenter {
  static getRoutes(municipalityId: number, details?: string, background: boolean = true) {
    return axios.get<SitCenterRouteResponse>('/dashboard/routes', {
      params: {
        municipality_id: municipalityId !== 0 ? municipalityId : '',
        with_detail: details,
      },
      isBackgroundLoading: background,
    } as any);
  }

  static getTransports(municipalityId: number, details?: string, background: boolean = true) {
    return axios.get<SitCenterTransportResponse>('/dashboard/transports', {
      params: {
        municipality_id: municipalityId !== 0 ? municipalityId : '',
        with_detail: details,
      },
      isBackgroundLoading: background,
    } as any);
  }

  static getOutfits(municipalityId: number, background: boolean = true) {
    return axios.get<SitCenterOutfitsResponse>('dashboard/outfits', {
      params: {
        municipality_id: municipalityId !== 0 ? municipalityId : '',
      },
      isBackgroundLoading: background,
    } as any);
  }
}
