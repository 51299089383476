import axios from '@/services/axios';

export class Trip {
  static getList(routeGroup: number, scheduleDate: string) {
    return axios.get('/trip', {
      params: {
        'filter[route_group]': routeGroup,
        'filter[schedule_date]': scheduleDate,
        'all': true,
        'include': 'points',
      },
    });
  }
}
