<template>
  <div class="notifications">
    <div v-for="(notification, index) in notifications" :key="index">
      <div :class="notificationClass(notification)">
        <button class="delete" @click="close(index)"></button>
        {{ notification.text }}
      </div>
    </div>
  </div>
</template>

<script>
import { NotificationsService } from '@/modules/notifications/notifications-service';

export default {
  data: () => ({
    notifications: [],
  }),
  methods: {
    notificationClass(notification) {
      let classes = {
        alert: true,
      };

      if (notification.type !== '') {
        classes['alert-' + notification.type] = true;
      }

      return classes;
    },
    close(index) {
      NotificationsService.instance.remove(index);
    },
  },
  mounted() {
    setInterval(() => {
      this.notifications.forEach((notification, index) => {
        if ((new Date(notification.ttl)) - (new Date()) < 0) {
          NotificationsService.instance.remove(index);
        }
      });
    }, 1000);

    this.notifications = NotificationsService.instance.notifications;
    NotificationsService.instance.subscribe('update', () => {
      this.notifications = NotificationsService.instance.notifications;
    });
  },
};
</script>

<style lang="scss" scoped>
  .notifications {
    position: fixed;
    top: 50px;
    right: 50px;
    width: 300px;
    z-index: 99999999;

    .alert {
      margin-bottom: 10px;
      padding: 12px 25px;
    }

    @media (max-width: 500px) {
      left: 20px;
      right: 20px;
      width: auto;
    }

    .delete {
      -moz-appearance: none;
      -webkit-appearance: none;
      background-color: rgba(10, 10, 10, .2);
      border: none;
      border-radius: 290486px;
      cursor: pointer;
      display: inline-block;
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      font-size: 0;
      height: 20px;
      max-height: 20px;
      max-width: 20px;
      min-height: 20px;
      min-width: 20px;
      outline: 0;
      position: relative;
      vertical-align: top;
      width: 20px;
    }

    .delete {
      position: absolute;
      right: .5rem;
      top: .5rem;

      &::before {
        height: 2px;
        width: 50%;
      }

      &::after,
      &::before {
        background-color: #fff;
        content: "";
        display: block;
        left: 50%;
        position: absolute;
        top: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        -webkit-transform-origin: center center;
        transform-origin: center center;
      }

      &::after {
        height: 50%;
        width: 2px;
      }
    }
  }
</style>
