import axios from '../axios';

export class VideoWatch {
  static list({ all, municipalityId, id }) {
    const params = {
      include: 'type',
    };

    if (all) {
      params.all = 1;
    }

    if (municipalityId) {
      params['filter[municipality_id]'] = municipalityId;
    }

    if (id) {
      params['filter[id]'] = id;
    }

    return axios.get('/videocam', { params })
      .then(({ data }) => data)
      .catch(error => error);
  }
}
