














































import { MapService } from '@/services/map-service';
import moment from 'moment';
import '@trevoreyre/autocomplete-vue/dist/style.css';
import { LayersService } from '@/services/layers-service';
import { ACL } from '@/modules/acl/acl-service';
import {
  computed,
  ComputedRef,
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive, Ref,
  ref,
  watch,
} from '@vue/composition-api';
import { ITransport } from '@/types';
import DatePicker from 'vue2-datepicker';
import { API_QUERY_DATETIME_TZ_FORMAT } from '@/constants/Global';
import { HistoryTransportLayer } from '@/services/layers/history-transport-layer';

export default defineComponent({
  name: 'history-transport',
  components: {
    DatePicker,
  },
  setup(props, { root }) {
    const layerService = LayersService.instance._historyTransportLayers;
    const filter : {
      date: string,
      number: string,
      transportId: number | null,
    } = reactive({
      date: moment().format('YYYY-MM-DD'),
      number: '',
      transportId: null,
    });
    let _storeSubscription: { (): void; (): void; } | null = null;
    const selectedTransports: Ref<Array<ITransport>> = ref([]);
    const transportName = ref('');
    const cacheKey = ref(1);
    const layers = computed(() => {
      if (cacheKey.value > 0) {
        return layerService._layers;
      }
      return {};
    });
    const transportsFromLayer: ComputedRef<{ [key: string]: ITransport }> = computed(() => {
      if (cacheKey.value > 0) {
        return LayersService.instance?._transportLayers?._ws?.activeTransport?.getValue();
      }
      return {};
    });
    const canViewDetailHistory = computed(() => {
      return ACL.can('history.index');
    });
    const filterTransports: ComputedRef<Array <ITransport>> = computed(() => {
      if (transportName.value) {
        return Object.values(transportsFromLayer.value).filter(transport => transport.number.toUpperCase().includes(transportName.value.toUpperCase()));
      }
      return Object.values(transportsFromLayer.value);
    });
    const showTransport = computed(() => {
      return Object.values(transportsFromLayer.value).length > 0;
    });

    const createRoute = (transport: ITransport | null = null) => {
      let transportList = selectedTransports.value;
      layerService.setTransports(transportList);
      const queryTransport = transport ? [transport] : transportList;
      layerService.drawLine(
        queryTransport,
        moment(new Date(filter.date)).startOf('day').format(API_QUERY_DATETIME_TZ_FORMAT),
        moment(new Date(filter.date)).endOf('day').format(API_QUERY_DATETIME_TZ_FORMAT),
      );
      if (!layerService.isRealtimeLoadLines()) {
        layerService.startRealtimeLoadLines();
      }
    };
    const checkAll = () => {
      selectedTransports.value = [];
      filterTransports.value.forEach((transport) => {
        selectedTransports.value.push(transport);
      });
    };
    const resetLayers = () => {
      layerService.resetLayers();
      layerService.stopRealtimeLoadLines();
      selectedTransports.value = [];
    };
    const setSelectedTransportFromLayerTransport = () => {
      selectedTransports.value = [];
      Object.values(transportsFromLayer.value).forEach((ts) => {
        selectedTransports.value.push(ts);
      });
    };
    const searchTransports = () => {
      layerService.resetLayers();
      createRoute();
    };

    watch(() => filter.date, (newDate: string) => {
      const dateFrom = moment(new Date(newDate)).startOf('day').format(API_QUERY_DATETIME_TZ_FORMAT);
      const dateTo = moment(new Date(newDate)).endOf('day').format(API_QUERY_DATETIME_TZ_FORMAT);
      layerService
        .setDateFrom(dateFrom);
      layerService
        .setDateTo(dateTo);
      layerService.deleteLayers();
      if (!moment().isSame(moment(newDate), 'day')) {
        layerService.stopRealtimeLoadLines();
      } else {
        layerService.startRealtimeLoadLines();
      }
      searchTransports();
    });

    watch(transportName, () => {
      nextTick(() => {
        resetLayers();
        checkAll();
        createRoute();
      });
    });
    onUnmounted(() => {
      if (_storeSubscription) {
        _storeSubscription();
      }
    });
    onMounted(() => {
      _storeSubscription = root.$store.subscribeAction(action => {
        if (action.type === 'transports/brokenLoaded') {
          cacheKey.value += 1;
          selectedTransports.value = [];
          Object.values(transportsFromLayer.value).forEach((ts) => {
            selectedTransports.value.push(ts);
          });
        }
      });

      Object.values(transportsFromLayer.value).forEach((ts) => {
        selectedTransports.value.push(ts);
      });
      root.$on('route-removed', (transportId: number) => {
        selectedTransports.value = selectedTransports.value.filter((ts) => ts.id !== transportId);
      });
      root.$on('filter-changed', () => {
        cacheKey.value += 1;
        setSelectedTransportFromLayerTransport();
      });
    });
    return {
      filter,
      selectedTransports,
      filterTransports,
      canViewDetailHistory,
      transportName,
      showTransport,
      layers,
      checkAll,
      createRoute,
      resetLayers,
      disabledBeforeAfter(date: string) {
        return !HistoryTransportLayer.isRangeThreeMonths(date);
      },
      showRoute(transport: ITransport) {
        const layers = layerService._layers;
        const lines = layers.filter((layer: { transportId: number; }) => layer.transportId === transport.id).map((l: { line: any; }) => l.line);
        const isSelectedTransport = selectedTransports.value.find((ts) => ts.id === transport.id);

        if (!isSelectedTransport) {
          selectedTransports.value.push(transport);
        }

        if (!lines.length) {
          createRoute(transport);
        }

        if (lines.length) {
          const line = lines.slice().pop();
          if (line.getBounds().isValid()) {
            MapService.instance.map.fitBounds(line.getBounds());
          }
          line.togglePopup();
          layerService.highlightRoute(lines);
        }
      },
      removeRoute(id : number, e: any) {
        layerService.removeRoute(id);
        selectedTransports.value = selectedTransports.value.filter((ts) => ts.id !== id);
      },
      activeLayer(id : number) {
        return layerService.activeLayer(id) ?? false;
      },
    };
  },
});
