/* eslint-disable camelcase */
import { ZoneSelectionState, RootState } from '@/types';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

const state: ZoneSelectionState = {
  rectangle: [],
};
const getters: GetterTree<ZoneSelectionState, RootState> = {
  enabled: state => state.rectangle.length > 0,
  rectangle: state => state.rectangle,
};
const mutations: MutationTree<ZoneSelectionState> = {
  setRectangle: (state, payload: any) => {
    state.rectangle = payload;
  },
};
const actions: ActionTree<ZoneSelectionState, RootState> = {
  setRectangle({ commit }, payload: any) {
    commit('setRectangle', payload);
  },
  clear() { },
  start() { },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
