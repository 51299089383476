import { IAnalysisAverageFilter, IAnalysisAverageForm1ResponseItem, IAnalysisAverageForm1ResponseSegmentItem, IAnalysisAverageForm2ReponseItemRow, IAnalysisAverageRoadTrafficSegment, IRoute } from '@/types';
import {} from 'leaflet-polylinedecorator';
import { Store } from 'vuex';
import { BaseLayer } from './base-layer';
import MTSTypes from '@/constants/MTSTypes';
export class AnalysisAverageLayer extends BaseLayer {
  layersForm1: L.LayerGroup = L.layerGroup();
  layersForm1Arrows: L.LayerGroup = L.layerGroup();
  layersForm2: L.LayerGroup = L.layerGroup();
  layersForm2Arrows: L.LayerGroup = L.layerGroup();

  pointStart: null | L.Marker = null;
  pointFinish: null | L.Marker = null;

  selectedIndexForm1 = -1;

  _zoomEndHandler: null | Function = null;

  constructor({ store, map, type }: {
    store: Store<any>,
    map: L.Map,
    type: string,
  }) {
    super({ store, map, type });

    this.clearFilter();

    this.layersForm1.addTo(map);
    this.layersForm2.addTo(map);
  }

  clearFilter() {
    this.filter = {
      ...this.filter,
      average: {
        dates: [],
        days: [],
        hours: [],
        pointStart: null,
        pointFinish: null,
        type: [],
      },
      general: {
        dates: [],
        days: [],
        hours: [],
        hourGroup: {
          id: 1,
          text: '1',
        },
        route: {
          id: 0,
          text: 'Маршруты',
        },
        routeGroup: {
          id: 0,
          text: 'Направления',
        },
      },
    };
  }

  applyFilter(filter: IAnalysisAverageFilter) {
    this.filter = {
      ...this.filter,
      ...filter,
    };
  }

  async reRender() {
    if (!this._zoomEndHandler) {
      this._zoomEndHandler = this._zoomEnd.bind(this);
    }

    (this._map as any).on('zoomend', this._zoomEndHandler);

    this._zoomEnd();
  }

  visualizeForm1() {
    this.destroyForm1();
    this.destroyForm2();

    const row: IAnalysisAverageForm1ResponseItem = this._store.getters['layers/analysisAverage/selectedForm1'];
    const data: Array<IAnalysisAverageForm1ResponseSegmentItem> = Object.values(this._store.getters['layers/analysisAverage/segmentsForm1']);

    Object.entries(row.route_types).forEach(([id, rt]) => {
      rt.avgs.forEach(item => {
        const color = this.getColor(item.speed);
        const typeColor: string = (MTSTypes as any)[id]?.color ?? color;
        const segment = data.find(s => s.id === item.segment_id);

        if (segment) {
          const popupContent = `
            <p class="mts"><strong>Тип:</strong> ${rt.route_type_name}</p>
            <p class="mts"><strong>Скорость:</strong> ${item.speed} км/ч</p>
            <p class="mts"><strong>Кол-во ТС:</strong> ${item.count_ts}</p>
          `;
          const line = L.polyline(L.GeoJSON.coordsToLatLngs(segment.line.coordinates), {
            color,
            fill: true,
            fillColor: typeColor,
            weight: 10,
            lineCap: 'butt',
            fillOpacity: 1,
          }).bindPopup(popupContent);

          const decorator = L.polylineDecorator(line, {
            patterns: [
              {
                offset: '104%',
                repeat: 0,
                symbol: L.Symbol.arrowHead({
                  pixelSize: 7,
                  polygon: false,
                  pathOptions: {
                    stroke: true,
                    color: typeColor,
                    fillColor: typeColor,
                    weight: 3,
                  },
                }),
              },
            ],
          }).bindPopup(popupContent);

          this.layersForm1.addLayer(line);
          this.layersForm1Arrows.addLayer(decorator);
        }
      });
    });
  }

  getColor(speed: number) {
    return speed >= 25 ? 'green'
      : speed >= 10 ? 'yellow'
        : 'red';
  }

  visualizeForm2() {
    this.destroyForm1();
    this.destroyForm2();

    const routes: Array<IRoute> = this._store.getters['layers/analysisAverage/routes'];
    const selectedId = this.filter.general.route.id;
    const selectedRoute = routes.find(r => r.id === selectedId);
    const row: Array<IAnalysisAverageForm2ReponseItemRow> = this._store.getters['layers/analysisAverage/selectedForm2'];
    const data: Array<IAnalysisAverageRoadTrafficSegment> = Object.values(this._store.getters['layers/analysisAverage/segmentsForm2']);
    const typeColor: string = (selectedRoute ? (MTSTypes as any)[selectedRoute.route_type_id]?.color : '#000') ?? '#000';

    row.forEach(r => {
      r.avgs.forEach(item => {
        const color = this.getColor(item.speed);
        const segment = data.find(s => s.id === item.segment_id);

        if (segment) {
          const popupContent = `
            <p class="mts"><strong>Направление:</strong> ${r.name}</p>
            <p class="mts"><strong>Скорость:</strong> ${item.speed} км/ч</p>
            <p class="mts"><strong>Кол-во ТС:</strong> ${item.count_ts}</p>
          `;
          const line = L.polyline(L.GeoJSON.coordsToLatLngs(segment.line.coordinates), {
            color,
            fill: true,
            fillColor: typeColor,
            weight: 10,
            lineCap: 'butt',
            fillOpacity: 1,
          }).bindPopup(popupContent);

          const decorator = L.polylineDecorator(line, {
            patterns: [
              {
                offset: '104%',
                repeat: 0,
                symbol: L.Symbol.arrowHead({
                  pixelSize: 7,
                  polygon: false,
                  pathOptions: {
                    stroke: true,
                    color: typeColor,
                    fillColor: typeColor,
                    weight: 3,
                  },
                }),
              },
            ],
          }).bindPopup(popupContent);

          this.layersForm2.addLayer(line);
          this.layersForm2Arrows.addLayer(decorator);
        }
      });
    });
  }

  destroyForm1() {
    this.layersForm1.clearLayers();
    this.layersForm1Arrows.clearLayers();
  }

  destroyForm2() {
    this.layersForm2.clearLayers();
    this.layersForm2Arrows.clearLayers();
  }

  destroy() {
    this.destroyForm1();
    this.destroyForm2();

    if (this.pointStart) {
      this.pointStart.remove();
      this.pointStart = null;
    }
    if (this.pointFinish) {
      this.pointFinish.remove();
      this.pointFinish = null;
    }

    if (this._zoomEndHandler) {
      (this._map as any).off('zoomend', this._zoomEndHandler);
    }

    this._store.dispatch('layers/analysisAverage/clearForm1');
    this._store.dispatch('layers/analysisAverage/clearSelectForm1');
    this._store.dispatch('layers/analysisAverage/clearForm2');
    this._store.dispatch('layers/analysisAverage/clearSelectForm2');
    this.clearFilter();
  }

  _zoomEnd() {
    const currentZoom = this._map.getZoom();
    const breakPoint = 15;

    if (currentZoom >= breakPoint) {
      this.layersForm1Arrows.addTo(this._map);
      this.layersForm2Arrows.addTo(this._map);
    } else {
      this._map.removeLayer(this.layersForm1Arrows);
      this._map.removeLayer(this.layersForm2Arrows);
    }
  }
}
