/* eslint-disable camelcase */
import { IRouteSubtype } from '@/types';
import { AxiosResponse } from 'axios';
import axios from '../axios';

export class RouteSubtype {
  static getAll(): Promise<Array<IRouteSubtype>> {
    return axios.get('/route-subtype?all')
      .then((
        result: AxiosResponse<{
          route_subtypes: Array<IRouteSubtype>
        }>,
      ) => result.data.route_subtypes);
  }
}
