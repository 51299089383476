






















import { defineComponent, computed, ref, watch } from '@vue/composition-api';
import { MapService } from '../../../services/map-service';
import { tileSettings } from '../../../constants/Global';
import TilesetIcon from '@/components/shared/tileset-icon.vue';

export default defineComponent({
  components: {
    TilesetIcon,
  },
  setup() {
    const service = MapService.instance;
    const tileSets = computed(() => {
      return Object.values(tileSettings)
        .sort((a, b) => {
          if (a.order < b.order) {
            return -1;
          }
          if (a.order > b.order) {
            return 1;
          }

          return 0;
        })
        .map(tile => tile.title);
    });
    const isShow = ref(false);
    const tileRef = ref(service.selectedTileSet);
    const tile = computed({
      get: () => {
        return tileRef.value;
      },
      set: value => {
        service.selectedTileSet = value;

        update();
      },
    });

    const update = () => {
      tileRef.value = service.selectedTileSet;
    };

    service.subscribe('map-initialized', () => update());
    service.subscribe('map-selected-base-layer', () => update());

    return {
      tile,
      tileSets,
      toggleIsShow() {
        isShow.value = !isShow.value;
      },
      isShow,
    };
  },
});
