/* eslint-disable camelcase */
import { IAnalysisAverageForm1Response, IAnalysisAverageForm1VisualizeResponse, IAnalysisAverageForm2Response } from '@/types';
import axios from '../axios';

export class AnalysisAverage {
  public static getForm1(
    point1: {
      lat: number, lng: number,
    },
    point2: {
      lat: number, lng: number,
    },
    timeFrom: string = '',
    timeTo: string = '',
    days: Array<string> = [],
    hours: Array<string> = [],
    routeTypeIds: Array<string> = []): Promise<IAnalysisAverageForm1Response> {
    const params = {
      'point1[lat]': point1.lat,
      'point1[lng]': point1.lng,
      'point2[lat]': point2.lat,
      'point2[lng]': point2.lng,
      time_from: timeFrom,
      time_to: timeTo,
      days: days.join(','),
      hours: hours.join(','),
      route_type_ids: routeTypeIds.join(','),
    };

    return axios.get<IAnalysisAverageForm1Response>('/report-avg-speed/form1', {
      params,
    })
      .then(res => {
        return res.data;
      });
  }

  public static visualizeForm1(
    point1: {
      lat: number, lng: number,
    },
    point2: {
      lat: number, lng: number,
    },
    timeFrom: string,
    timeTo: string,
    routeTypeIds: Array<string>,
  ) {
    const params = {
      'point1[lat]': point1.lat,
      'point1[lng]': point1.lng,
      'point2[lat]': point2.lat,
      'point2[lng]': point2.lng,
      time_from: timeFrom,
      time_to: timeTo,
      route_type_ids: routeTypeIds.join(','),
    };

    return axios.get<IAnalysisAverageForm1VisualizeResponse>('/report-avg-speed/form1-visualize', {
      params,
    })
      .then(res => {
        return res.data.report;
      });
  }

  public static getForm2(
    timeFrom: string = '',
    timeTo: string = '',
    days: Array<string> = [],
    hours: Array<string> = [],
    hourGroup: number = 1,
    routeId?: number,
    routeGroupId?: number,
  ) {
    const params = {
      time_from: timeFrom,
      time_to: timeTo,
      days: days.join(','),
      hours: hours.join(','),
      group_by: hourGroup,
      route_id: routeId,
      route_group_id: routeGroupId,
    };

    return axios.get<IAnalysisAverageForm2Response>('/report-avg-speed/form2', {
      params,
    })
      .then(res => {
        return res.data;
      });
  }
}
