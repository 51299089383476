








































import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'HouseInfoPopup',
  props: {
    house: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const showTrashAreaButton = computed(() => {
      return props.house.trash_areas && props.house.trash_areas.length;
    });

    return {
      showTrashAreaButton,
      showTrashArea() {
        emit('show-trash-area', props.house.id);
      },
    };
  },
});
