











































import { computed, defineComponent, nextTick, onBeforeUnmount, ref } from '@vue/composition-api';
import L from 'leaflet';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { LayersService } from '../../../services/layers-service';
import { MapService } from '../../../services/map-service';
import { IRoute } from '../../../types';
import ColorPicker from '../../shared/color-picker.vue';

const routeHelper = createNamespacedHelpers('route');

export default defineComponent({
  name: 'analysis-double-layer-control',
  components: {
    ColorPicker,
  },
  setup(props, { root }) {
    const { updateConverge } = routeHelper.useActions(['updateConverge']);
    const { converge, duplicationRouteGroupId } = routeHelper.useMutations(['converge', 'duplicationRouteGroupId']);

    const map = MapService.instance.map;
    const fromMarker = ref(LayersService.instance._routeLayers.fromMarker);
    const isFromDrawing = ref(false);
    const toMarker = ref(LayersService.instance._routeLayers.toMarker);
    const isToDrawing = ref(false);
    const analysisRoutes = computed(() => {
      return LayersService.instance._routeLayers._originAnalysisRoutes;
    });

    const fromMarkerOptions = {
      icon: L.icon({
        iconUrl: '/assets/icons/marker-from-icon.png',
        iconSize: L.point(25, 41),
        iconAnchor: L.point(12.5, 40),
        className: 'analysis-marker-icon',
      }),
      draggable: true,
    };
    const toMarkerOptions = {
      icon: L.icon({
        iconUrl: '/assets/icons/marker-to-icon.png',
        iconSize: L.point(25, 41),
        iconAnchor: L.point(12.5, 40),
        className: 'analysis-marker-icon',
      }),
      draggable: true,
    };

    const onDrawingEnd = () => {
      const layer = LayersService.instance._layersParams.find((l: any) => l.type === 'route');
      let types;
      let pathTypes;
      if (layer) {
        const groups = layer.params.groups ?? [];

        const selectedGroups = groups.filter((group: any) => group.types.filter((type: any) => type.checked).length > 0);
        types = selectedGroups.find((group: any) => group.name === 'Тип ТС')?.types.filter((type: any) => type.checked).map((type: any) => type.id).join(',');
        pathTypes = selectedGroups.filter((group: any) => group.name !== 'Тип ТС').map((group: any) => group.types.filter((type: { checked: boolean }) => type.checked)
          .map((type: { id: number }) => type.id).join(',')).join(',');
      }

      if (toMarker.value && fromMarker.value) {
        LayersService.instance._routeLayers.toMarker = toMarker.value;
        LayersService.instance._routeLayers.fromMarker = fromMarker.value;
        LayersService.instance._routeLayers.filter = {
          ...LayersService.instance._routeLayers.filter,
          analysisPoint1: fromMarker.value.getLatLng(),
          analysisPoint2: toMarker.value.getLatLng(),
          analysisTypes: types,
          routePathTypesByGroup: pathTypes,
        };

        LayersService.instance._routeLayers.converge();
      }

      isFromDrawing.value = false;
      isToDrawing.value = false;
    };
    const onDrawingCancel = () => {
      if (toMarker.value && isToDrawing.value) {
        toMarker.value.off('dragend', drawHandler);
        toMarker.value.remove();
        toMarker.value = null;
      }

      if (fromMarker.value && isFromDrawing.value) {
        fromMarker.value.off('dragend', drawHandler);
        fromMarker.value.remove();
        fromMarker.value = null;
      }

      isFromDrawing.value = false;
      isToDrawing.value = false;
    };

    const drawHandler = onDrawingEnd.bind(this);
    const drawCancelHandler = onDrawingCancel.bind(this);

    map.on('editable:drawing:commit', drawHandler);
    map.on('editable:drawing:cancel', drawCancelHandler);

    onBeforeUnmount(() => {
      if (drawHandler) {
        map.off('editable:drawing:commit', drawHandler);
      }
      if (drawCancelHandler) {
        map.off('editable:drawing:cancel', drawCancelHandler);
      }
    });

    const resetMarkers = () => {
      map.editTools.stopDrawing();
      const reset = !!(toMarker.value || fromMarker.value);

      if (toMarker.value) {
        toMarker.value.off('dragend', drawHandler);
        toMarker.value.remove();
        toMarker.value = null;
        LayersService.instance._routeLayers.filter.analysisPoint2 = null;
      }

      if (fromMarker.value) {
        fromMarker.value.off('dragend', drawHandler);
        fromMarker.value.remove();
        fromMarker.value = null;
        LayersService.instance._routeLayers.filter.analysisPoint1 = null;
      }

      if (reset) {
        LayersService.instance._routeLayers._originAnalysisRoutes = [];
        LayersService.instance._routeLayers.analysisRoutes.next([]);
        LayersService.instance._routeLayers.highlightedRoute = null;
        LayersService.instance._routeLayers._filter({
          name: LayersService.instance._routeLayers.filter.name,
          selectedRoutes: LayersService.instance._routeLayers.filter.selectedRoutes,
          clear: false,
        });
        converge([]);
        duplicationRouteGroupId(null);
      }
    };

    return {
      analysisRoutes,
      resetMarkers,
      changeAnalysisRouteColor({ id, color }: {
        id: number,
        color: string,
      }) {
        LayersService.instance._routeLayers.changeAnalysisRouteColor(id, color);
      },
      onAnalysisRouteCheckbox(route: {
        id: number
      }) {
        (LayersService.instance._routeLayers.analysisRoutes as any).next(
          LayersService.instance._routeLayers.analysisRoutes.getValue().map((r: IRoute) => (r.id === route.id ? route : r)),
        );
        updateConverge(route);
      },
      changeFrom() {
        map.editTools.stopDrawing();
        if (fromMarker.value) {
          fromMarker.value.off('dragend', drawHandler);
          fromMarker.value.remove();
        }

        fromMarker.value = map.editTools.startMarker(undefined, fromMarkerOptions);
        fromMarker.value.on('dragend', drawHandler);
        isFromDrawing.value = true;
      },
      changeTo() {
        map.editTools.stopDrawing();
        if (toMarker.value) {
          toMarker.value.off('dragend', drawHandler);
          toMarker.value.remove();
        }

        toMarker.value = map.editTools.startMarker(undefined, toMarkerOptions);
        toMarker.value.on('dragend', drawHandler);
        isToDrawing.value = true;
      },
      exchangeMarkers() {
        map.editTools.stopDrawing();

        nextTick(() => {
          if (toMarker.value && !fromMarker.value) {
            fromMarker.value = L.marker(toMarker.value.getLatLng(), fromMarkerOptions as any).addTo(map);

            toMarker.value.off('dragend', drawHandler);
            toMarker.value.remove();
            toMarker.value = null;
          } else if (fromMarker.value && !toMarker.value) {
            toMarker.value = L.marker(fromMarker.value.getLatLng(), toMarkerOptions as any).addTo(map);

            fromMarker.value.off('dragend', drawHandler);
            fromMarker.value.remove();
            fromMarker.value = null;
          } else if (fromMarker.value && toMarker.value) {
            const [ from, to ] = [
              fromMarker.value.getLatLng(),
              toMarker.value.getLatLng(),
            ];

            toMarker.value.off('dragend', drawHandler);
            fromMarker.value.off('dragend', drawHandler);
            toMarker.value.remove();
            fromMarker.value.remove();

            toMarker.value = L.marker(from, toMarkerOptions as any).addTo(map);
            toMarker.value.on('dragend', drawHandler);
            fromMarker.value = L.marker(to, fromMarkerOptions as any).addTo(map);
            fromMarker.value.on('dragend', drawHandler);

            onDrawingEnd();
          }
        });
      },
      highlightAnalysisRoute(route: {
        id: number,
      }) {
        LayersService.instance._routeLayers.highlightAnalysisRoute(route.id);
      },
    };
  },
});
