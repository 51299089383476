import { ILayerFilterType, ILocality } from '@/types';
import { LayerGroup } from 'leaflet';
import { Store } from 'vuex';
import { Locality } from '../api/locality';
import { LayersService } from '../layers-service';
import { MODES } from './analysis-layer';
import { BaseLayer } from './base-layer';
import Vue from 'vue';
import LocalityAnalysisPopup from '../../components/map/popups/locality-analysis-popup.vue';
import router from '../../router';
import store from '../../store';

const UNSELECTED_COLOR = 'blue';
const SELECTED_COLOR = 'rgb(180, 1, 1)';

export class LocalityLayer extends BaseLayer {
  localities: Array<ILocality> = [];
  filteredLocalities: Array<ILocality> = [];
  municipalityId: number;
  layers: LayerGroup<L.GeoJSON<any>> = L.layerGroup();
  isSelectMode = false;
  selectedLayers: Array<L.GeoJSON<any>> = [];
  isInProcess: number | null = null;
  popups: Array<any> = [];

  keyHandler: null | ((e: KeyboardEvent) => void) = null;

  constructor({ store, map, type }: {
    store: Store<any>,
    map: L.Map,
    type: string,
  }) {
    super({ store, map, type });
    this.filter = {
      ...this.filter,
      types: 0,
      name: '',
      district: 0,
      ids: [],
    };

    this.municipalityId = this._store.getters['municipalities/current'].id;
    this.layers.addTo(map);
  }

  async reRender({ types }: { types: Array<ILayerFilterType> }) {
    if (!this.keyHandler) {
      this.keyHandler = this._keyHandler.bind(this);
      document.addEventListener('keypress', this.keyHandler);
    }

    this.cancelSelect();

    const typesBuffer: Array<number> = types.filter(t => t.checked).map(t => t.id);

    this.filter = {
      ...this.filter,
      types: typesBuffer,
    };

    if ((this.isInProcess === null || this.isInProcess !== this.municipalityId) && (this.localities.length === 0 || this.municipalityId !== this._store.getters['municipalities/current'].id)) {
      this.municipalityId = this._store.getters['municipalities/current'].id;
      this.isInProcess = this.municipalityId;
      const res = await Locality.getLocality(this.municipalityId);
      this.isInProcess = null;
      this.localities = res;
      this.filteredLocalities = res;
    }

    this._render();
  }

  _render() {
    this.filteredLocalities = this.localities
      .filter(locality => {
        if (this.filter.types.length > 0) {
          return this.filter.types.indexOf(locality.type) > -1;
        }

        return true;
      })
      .filter(locality => {
        if (this.filter.district) {
          return locality.district_id === this.filter.district || locality.id === this.filter.district;
        }

        return true;
      })
      .filter(locality => {
        if (this.filter.name) {
          return locality.name.toLowerCase().indexOf(this.filter.name.toLowerCase()) !== -1;
        }

        return true;
      })
      .filter(locality => {
        return this.filter.ids.length === 0 || this.filter.ids.indexOf(locality.id) !== -1;
      })
      .filter(locality => {
        return (this.selectedLayers.length === 0 || this.filter.ids.length > 0) || this.selectedLayers.find(l => (l as any).options.id === locality.id);
      });
    const selectedIds = this.selectedLayers.map((l: any) => l.options.id);
    this._deleteLayers();

    this.filteredLocalities.forEach(locality => {
      const popupContent = `${locality.name} (${locality.population} чел.)`;
      const isSelected = selectedIds.indexOf(locality.id) !== -1;
      const polygon = L.geoJSON(locality.border, {
        weight: 5,
        opacity: 1,
        color: !isSelected ? UNSELECTED_COLOR : SELECTED_COLOR,
        dashArray: '3',
        fillOpacity: 0.3,
        fillColor: !isSelected ? UNSELECTED_COLOR : SELECTED_COLOR,
        id: locality.id,
      } as any)
        .bindTooltip(popupContent, {
          permanent: isSelected,
        })
        .bringToFront()
        .addEventListener('click', (e: any) => {
          if (!e.originalEvent.ctrlKey) {
            return;
          }

          if (e.originalEvent.ctrlKey && !this.isSelectMode) {
            this.startSelect();
          }
          if (this.isSelectMode && this.selectedLayers.indexOf(polygon) === -1) {
            polygon.setStyle({
              color: SELECTED_COLOR,
              fillColor: SELECTED_COLOR,
            })
              .unbindTooltip()
              .bindTooltip(popupContent, {
                permanent: true,
              });
            this.selectedLayers.push(polygon);
          } else if (this.isSelectMode) {
            polygon.setStyle({
              color: UNSELECTED_COLOR,
              fillColor: UNSELECTED_COLOR,
            })
              .unbindTooltip()
              .bindTooltip(popupContent, {
                permanent: false,
              });
            this.selectedLayers = this.selectedLayers.filter((l: any) => l.options.id !== (polygon as any).options.id);
          }

          this.updateAnalysisReport();
        });

      if (isSelected) {
        this.selectedLayers.push(polygon);
        this.isSelectMode = true;
      }

      if (LayersService.instance._analysisLayers.mode !== MODES.EMPTY) {
        const popup = new Vue({
          render: h => h(LocalityAnalysisPopup, {
            props: {
              localityId: locality.id,
              municipalityId: locality.municipality_id,
            },
            on: {
              'change': (c: {
                color: string,
                id: number,
              }) => {
                LayersService.instance._routeLayers.changeRouteGroupsColorFromAnalysis(c.id, c.color);
              },
            },
          }),
          router,
          store,
        }).$mount();
        this.popups.push(popup);

        polygon.bindPopup(popup.$el as HTMLElement, {
          minWidth: 300,
          maxWidth: 300,
        });
      }

      this.layers.addLayer(polygon);
    });

    this.updateAnalysisReport();

    setTimeout(() => {
      this.layers.eachLayer(layer => (layer as L.GeoJSON<any>).bringToFront());
    });
  }

  _filter(filter: {
    name: string,
    district: number,
    type: number,
  }) {
    this.filter = {
      ...this.filter,
      ...filter,
    };
    this._render();
  }

  jump(locality: ILocality) {
    this._map.setView([locality.border.coordinates[0][0][0][1], locality.border.coordinates[0][0][0][0]], 17);
  }

  startSelect() {
    this.isSelectMode = true;
    this.selectedLayers = [];
  }

  stopSelect() {
    this.isSelectMode = false;
    this.filter = {
      ...this.filter,
      ids: this.selectedLayers.map((l: any) => l.options.id),
    };
    this.selectedLayers = [];
    this._render();
  }

  cancelSelect() {
    this.isSelectMode = false;
    this.selectedLayers = [];
  }

  selectLayers(ids: Array<number>) {
    ids.forEach(id => {
      const locality = this.filteredLocalities.find(l => Number(l.id) === Number(id));
      let polygon: any = null;
      this.layers.eachLayer((l: any) => {
        if (Number(l.options.id) === Number(id)) {
          polygon = l;
        }
      });

      if (locality && polygon) {
        const popupContent = `${locality.name} (${locality.population} чел.)`;
        polygon.setStyle({
          color: SELECTED_COLOR,
          fillColor: SELECTED_COLOR,
        })
          .unbindTooltip()
          .bindTooltip(popupContent, {
            permanent: true,
          });
        this.selectedLayers.push(polygon);
      }
    });

    this._render();
  }

  filterLayers(ids: Array<number>) {
    this.filter = {
      ...this.filter,
      ids: [...ids],
    };
    this._render();
  }

  _keyHandler(e: KeyboardEvent) {
    if (e.ctrlKey && e.code === 'Enter' && this.isSelectMode) {
      this.stopSelect();
    }
  }

  _deleteLayers() {
    this.layers.eachLayer(l => l.remove());
    this.layers.clearLayers();
    this.selectedLayers = [];

    this.popups.forEach(p => p.$destroy());
    this.popups = [];
  }

  updateAnalysisReport() {
    this._store.commit('layers/analysis/updateReport');
  }

  destroy() {
    this._deleteLayers();

    if (this.keyHandler) {
      document.removeEventListener('keypress', this.keyHandler);
      this.keyHandler = null;
    }
  }
}
