export default {
  1: {
    id: 1,
    name: 'Муниципальная',
  },
  2: {
    id: 2,
    name: 'Региональная',
  },
  3: {
    id: 3,
    name: 'Федеральная',
  },
  4: {
    id: 4,
    name: 'Межмуниципальная',
  },
};

export const ROAD_TYPES_COLORS = {
  1: '#00008B',
  2: '#483D8B',
  3: '#8B0000',
  4: '#6A5ACD',
};
