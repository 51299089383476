<template>
  <div class="detectors">
    <div class="detector" v-for="(parking, index) in parkings" :key="index" @click="showParking(parking)">
      <div class="detector-address">
        {{ parking.address }}
      </div>
    </div>
  </div>
</template>

<script>
import { LayersService } from '@/services/layers-service';

export default {
  name: 'parking-layer-control',
  data: () => ({
    parkings: [],
  }),
  methods: {
    showParking(parking) {
      LayersService.instance
        ._parkingLayers
        .showMarker(parking);
    },
  },
  created() {
    LayersService.instance
      ._parkingLayers
      .parkingList
      .subscribe(parkings => { this.parkings = parkings; });
  },
};
</script>

<style lang="scss" scoped>
  .detectors {
    .detector {
      cursor: pointer;
      margin-bottom: 16px;

      &-address {
        font-size: 16px;
      }
    }
  }
</style>
