import axios from 'axios';
import { DailyMtsReport2 } from '@/services/api/report/daily-mts-report2';

export default {
  namespaced: true,
  state: {
    data: {},
    routes: [],
  },
  getters: {
    data: state => state.data,
    routes: state => state.routes,
  },
  mutations: {
    resetData: (state, payload) => { state.data = {}; },
    setData: (state, payload) => { payload ? state.data = payload.data : state.data = {}; },
    setRoutes: (state, payload) => {
      state.routes = [];
      state.routes = payload
        .filter(route => route.dislocation_type === 2)
        .sort((a, b) => a.name - b.name);
    },
  },
  actions: {
    loadData(state, { mtsId, routeId, direction = 1, date }) {
      state.data = {};
      return new Promise((resolve, reject) => {
        DailyMtsReport2.loadData({ mtsId, routeId, direction, date })
          .then((response) => {
            state.commit('setData', response);
            resolve(response);
          })
          .catch(e => reject(e));
      });
    },

    loadRoutes(state, { date }) {
      axios.manualLoading = true;
      return new Promise((resolve, reject) => {
        DailyMtsReport2.loadRoutes({ date })
          .then((data) => {
            axios.manualLoading = false;
            state.commit('setRoutes', data.routes);
            resolve(data);
          })
          .catch(e => reject(e));
      });
    },
  },
};
