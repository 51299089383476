import { DailyRouteReport } from '@/services/api/report/daily-route-report';

export default {
  namespaced: true,
  state: {
    data: {},
  },
  getters: {
    data: state => state.data,
  },
  mutations: {
    setData: (state, payload) => { payload ? state.data = payload.data : state.data = {}; },
  },
  actions: {
    loadData(state, { routeId, date, direction = 1 }) {
      return new Promise((resolve, reject) => {
        DailyRouteReport.loadData({ routeId, date, direction })
          .then((response) => (response))
          .catch(e => reject(e));
      });
    },

    loadDataNew(state, { routeId, direction = 1, date }) {
      return new Promise((resolve, reject) => {
        DailyRouteReport.loadDataNew({ routeId, direction, date })
          .then(data => {
            let lines = [];
            data.mts.forEach(mts => {
              mts.lines.forEach(line => {
                line.mts = mts.gos_number;
                lines.push(line);
              });
            });

            lines = lines
              .sort((lineA, lineB) => {
                if (lineA.schedule.line < lineB.schedule.line) {
                  return -1;
                } else if (lineA.schedule.line > lineB.schedule.line) {
                  return 1;
                } else {
                  return 0;
                }
              })
              .map(line => {
                line.points = line.points.reduce((a, c) => {
                  a[c.route_point_id] = c;
                  return a;
                }, {});

                if (line.diff_in_minutes !== undefined) {
                  line.diff_in_minutes = Number((line.diff_in_minutes / data.points.length).toFixed(2));
                }
                let successPoint = 0;
                for (const key in line.points) {
                  if (line.points[key].hasOwnProperty('real_time')) {
                    if (Math.abs(line.points[key].diff_in_minutes) <= 5) {
                      successPoint++;
                    } else {
                      successPoint += 0;
                    }
                  }
                }

                line.regular = successPoint / data.points.length;

                line.success = !!(line.points[data.points[0].id] &&
                  line.points[data.points[0].id].real_time &&
                  line.points[data.points[data.points.length - 1].id] &&
                  line.points[data.points[data.points.length - 1].id].real_time);

                return line;
              })
              .filter(line => {
                let foundedPoints = 0;
                data.points.forEach(point => {
                  if (line.points[point.id]) {
                    foundedPoints++;
                  }
                });

                return foundedPoints > 0;
              });

            state.commit('setData', {
              data: {
                lines,
                points: data.points,
              },
            });

            resolve({
              data: {
                lines,
                points: data.points,
              },
            });
          })
          .catch(e => reject(e));
      });
    },
  },
};
