import axios from '../../axios';

export class DailyMtsReport2 {
  static loadData({ date, transportId, routeGroupId }) {
    return axios.get(`/report/form4?date=${date}&transport_id=${transportId}&route_group_id=${routeGroupId}`)
      .then(({ data }) => {
        return {
          outfits: data.outfits,
          points: data.points,
        };
      });
  }
  static exportReport({ date, transportId, routeGroupId, municipalityId }) {
    return axios({
      url: `/report/export/form4?date=${date}&transport_id=${transportId}&route_group_id=${routeGroupId}&municipality_id=${municipalityId}`,
      method: 'GET',
      responseType: 'blob',
    })
      .then(({ data }) => ({
        file: data,
      }));
  }
}
