import axios from '../axios';

export class Weather {
  static list({ isBackgroundLoading = false, municipalityId }) {
    let url = '/service/weather/asm';
    const params = new URLSearchParams();

    if (municipalityId) {
      params.set('municipality_id', municipalityId);
    }

    return axios.get(url, {
      params,
      isBackgroundLoading,
    })
      .then(({ data }) => {
        if (!Array.isArray(data.WEATHER_DATA)) {
          data.WEATHER_DATA = [{ ...data.WEATHER_DATA }];
        }

        return data;
      })
      .catch(e => e);
  }

  static kazanList({ isBackgroundLoading = false, municipalityId }) {
    let url = '/service/weather/stations';
    const params = new URLSearchParams();

    if (municipalityId) {
      params.set('municipality_id', municipalityId);
    }

    return axios.get(url, {
      params,
      isBackgroundLoading,
    })
      .then(({ data }) => {
        data.map((el, i) => {
          el.latitude = +el.latitude + 0.003 * (i + 1);
          el.longitude = +el.longitude + 0.003 * (i + 1);
          return el;
        });
        return data;
      })
      .catch(() => {});
  }

  static samaraList({ isBackgroundLoading = false, municipalityId }) {
    let url = '/service/weather/factic';
    const params = new URLSearchParams();

    if (municipalityId) {
      params.set('municipality_id', municipalityId);
    }

    return axios.get(url, {
      params,
      isBackgroundLoading,
    })
      .then(({ data }) => {
        return data[1];
      })
      .catch(() => {});
  }

  static alertList({
    isBackgroundLoading = false,
  }) {
    let url = '/service/weather/alerts';
    const params = new URLSearchParams();

    return axios.get(url, {
      params,
      isBackgroundLoading,
    })
      .then(({ data }) => {
        return data;
      })
      .catch(() => {});
  }

  static yandexWeatherList({ isBackgroundLoading = false, municipalityId }) {
    let url = '/service/weather/yandex';
    const params = new URLSearchParams();

    if (municipalityId) {
      params.set('municipality_id', municipalityId);
    }

    return axios.get(url, {
      params,
      isBackgroundLoading,
    })
      .then(({ data }) => {
        return data;
      })
      .catch(e => { return e; });
  }

  static meteoM5List({ isBackgroundLoading = false, municipalityId }) {
    let url = '/service/weather/m5';
    const params = new URLSearchParams();

    if (municipalityId) {
      params.set('municipality_id', municipalityId);
    }

    return axios.get(url, {
      params,
      isBackgroundLoading,
    })
      .then(({ data }) => {
        return data;
      })
      .catch(e => { return e; });
  }
}
