import axios from '../axios';

export class RouteType {
  static loadRouteTypes({ all, municipality, include }) {
    let url = '/route-type';
    const params = {};

    if (all) {
      params.all = 1;
    }

    if (municipality) {
      params.municipality_id = municipality;
    }

    if (include) {
      params.include = include.join(',');
    }

    return new Promise((resolve, reject) => {
      axios.get(url, {
        params,
      })
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  }
}
