/* eslint-disable camelcase */
import { ICustomLayer, ICustomLayerObject } from '@/types';
import { AxiosResponse } from 'axios';
import axios from '../axios';

export class Layer {
  static getLayers(): Promise<Array<ICustomLayer>> {
    return axios.get('/layer?all')
      .then((res: AxiosResponse<{
        layers: Array<ICustomLayer>,
      }>) => res.data.layers);
  }

  static getLayerObject(layerId: string): Promise<Array<any>> {
    const params: {
      [key: string]: number | string
    } = {};

    params['filter[layer_id]'] = layerId;
    params.all = 1;
    params.include = 'objectPropertyValues,objectType,objectType.properties';
    return axios.get('/layer-object', { params })
      .then((res: AxiosResponse<{
        layer_objects: Array<ICustomLayerObject>,
      }>) => res.data.layer_objects);
  }
}
