




























































































































import { MapService } from '../../../services/map-service';
import { computed, defineComponent, Ref, ref, nextTick, watch, WritableComputedRef, onBeforeUnmount } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import L from 'leaflet';
import leafletImage from 'leaflet-image';
import { NotificationsService } from '../../../modules/notifications/notifications-service';
import store from '../../../store';
import { isMobile } from 'mobile-device-detect';
import { MODES } from '../../../services/layers/analysis-layer';
import { LayersService } from '../../../services/layers-service';
import ColorPicker from '../../shared/color-picker.vue';
import SelectZoneControl from './select-zone-control.vue';
import TileSetControl from './tileset-control.vue';

const mapHelper = createNamespacedHelpers('map');

export default defineComponent({
  name: 'MapMainControl',
  components: {
    ColorPicker,
    SelectZoneControl,
    TileSetControl,
  },
  setup() {
    const show = ref(false);
    const showOutline = ref(false);
    const { isGroupControlDisabled, layers }: {
      isGroupControlDisabled: Ref<boolean>,
      layers: Ref<Array<string>>
    } = mapHelper.useGetters(['isGroupControlDisabled', 'layers']) as any;
    const isDisabled = computed(() => !!isGroupControlDisabled.value);
    const { setGroupControlDisabled }: {
      setGroupControlDisabled: (val: boolean) => void,
    } = mapHelper.useActions(['setGroupControlDisabled']) as any;
    const ruler: Ref<null | any> = ref(null);
    const distance = computed(() => {
      if (!ruler.value) {
        return false;
      }

      let prevPoint: null | L.LatLng = null;
      return (ruler.value._latlngs.reduce((acc: number, latLng: L.LatLng) => {
        if (prevPoint) {
          acc += prevPoint.distanceTo(latLng);
        }

        prevPoint = latLng;
        return acc;
      }, 0) / 1000).toFixed(2);
    });
    const showSave = computed(() => {
      const disabledLayers = ['transport', 'busStop', 'accident', 'detector', 'video', 'trafficLight', 'weather', 'custom']; // Слои, которые используют div-маркеры

      return layers.value.filter(l => disabledLayers.indexOf(l) !== -1).length === 0;
    });
    const isMobileMode = computed(() => isMobile);
    const marker: Ref<null | any> = ref(null);
    const analysisMode: WritableComputedRef<MODES> = computed({
      get: () => {
        return LayersService.instance._analysisLayers ? LayersService.instance._analysisLayers.mode : MODES.EMPTY;
      },
      set: (v: MODES) => {
        LayersService.instance._analysisLayers.mode = v;
      },
    });
    const removeMode: WritableComputedRef<boolean> = computed({
      get: () => {
        return LayersService.instance._analysisLayers ? LayersService.instance._analysisLayers.removeMode : false;
      },
      set: (v: boolean) => {
        LayersService.instance._analysisLayers.removeMode = v;
      },
    });
    const color: WritableComputedRef<string> = computed({
      get: () => {
        return LayersService.instance._analysisLayers.color;
      },
      set: (v: string) => {
        LayersService.instance._analysisLayers.color = v;
      },
    });
    const type: WritableComputedRef<string> = computed({
      get: () => {
        return LayersService.instance._analysisLayers.type;
      },
      set: (v: '0' | '1') => {
        LayersService.instance._analysisLayers.type = v;
      },
    });
    const isAnalysisModeNotEmpty = computed(() => analysisMode.value !== MODES.EMPTY);
    const isDrawing = computed(() => LayersService.instance._analysisLayers ? LayersService.instance._analysisLayers.isDrawing : false);
    const drawingType = computed(() => LayersService.instance._analysisLayers ? LayersService.instance._analysisLayers.drawingType : null);
    const isRemoveMode = computed(() => removeMode.value === true);
    const isFullType = computed(() => type.value.toString() === '0');
    const isNoneType = computed(() => type.value.toString() === '1');

    watch(distance, () => {
      if (marker.value) {
        marker.value.remove();
        marker.value = null;
      }

      if (ruler.value && ruler.value._latlngs.length > 0) {
        const last = ruler.value._latlngs[ruler.value._latlngs.length - 1];
        marker.value = (L as any).marker(last, {
          opacity: 0,
          icon: L.icon({
            iconUrl: '/assets/icons/marker-from-icon.png',
          }),
        });
        marker.value.addTo(map);
        marker.value.bindTooltip(`${distance.value} км.`, { permanent: true });
        marker.value.openPopup();
      }
    });

    const map: any = MapService.instance.map;

    const clearRuler = () => {
      map.editTools.stopDrawing();
      if (ruler.value) {
        ruler.value.remove();
        ruler.value = null;
      }
    };

    const dataURItoBlob = (dataURI: string) => {
      const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const binary = atob(dataURI.split(',')[1]);
      const array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], { type: mime });
    };

    const errorHandler = (e: ErrorEvent) => {
      // Ошибка в библиотеки создания изображения с canvas'а карты
      const errorMessages = [
        'TypeError: url is undefined',
        `Uncaught TypeError: Cannot read property 'match' of undefined`,
        `Uncaught TypeError: Cannot read property 'src' of null`,
        `TypeError: marker._icon is null`,
      ];

      if (errorMessages.indexOf(e.message) !== -1) {
        store.dispatch('stopLoading');
        LayersService.instance._analysisLayers.toggleEditable(true);
        NotificationsService.instance.error({
          message: 'Не удалось сделать скриншот',
        });
      }
    };

    window.addEventListener('error', errorHandler as any);
    onBeforeUnmount(() => {
      window.removeEventListener('error', errorHandler as any);
    });

    return {
      show,
      showOutline,
      isDisabled,
      distance,
      clearRuler,
      showSave,
      isMobileMode,
      isAnalysisModeNotEmpty,
      isDrawing,
      drawingType,
      isRemoveMode,
      isFullType,
      isNoneType,
      color,
      toggle() {
        show.value = !show.value;
      },
      toggleOutline() {
        showOutline.value = !showOutline.value;
      },
      toggleGrouped() {
        setGroupControlDisabled(!isGroupControlDisabled.value);
      },
      print() {
        show.value = false;
        const openSidebar = !!store.getters.openSidebar;
        store.dispatch('setSidebar', false);
        const mapEl = document.getElementById('map');
        const header: HTMLElement | null = document.querySelector('.app-base .header');
        const sidebar: HTMLElement | null = document.querySelector('.left-sidebar-menu');
        const content: HTMLElement | null = document.querySelector('.app-base div.content.open-menu');

        if (!mapEl || !header || !sidebar || !content) {
          return;
        }

        mapEl.style.height = '100vh';
        header.style.display = 'none';
        sidebar.style.display = 'none';
        content.style.marginLeft = '0';

        nextTick()
          .then(() => {
            map.invalidateSize();
            return nextTick();
          })
          .then(() => {
            window.setTimeout(() => {
              window.print();

              mapEl.style.height = '';
              header.style.display = '';
              sidebar.style.display = '';
              content.style.marginLeft = '';
              store.dispatch('setSidebar', openSidebar);
            });
          });
      },
      ruler() {
        clearRuler();
        ruler.value = map.editTools.startPolyline();
      },
      async save() {
        show.value = false;
        store.dispatch('startLoading');
        const isToggleRouteNumber = LayersService.instance._routeLayers.showRouteNumbers;

        LayersService.instance._analysisLayers.toggleEditable(false);
        LayersService.instance._routeLayers.toggleRouteNumber(false, true);
        await nextTick();

        leafletImage(map, (err: any, canvas: HTMLCanvasElement) => {
          store.dispatch('stopLoading');
          LayersService.instance._analysisLayers.toggleEditable(true);
          LayersService.instance._routeLayers.toggleRouteNumber(isToggleRouteNumber, true);
          if (err) {
            NotificationsService.instance.error(err);
          }

          const image = canvas.toDataURL('image/png');
          const url = window.URL.createObjectURL(dataURItoBlob(image));
          window.open(url, '_blank');
        });
      },
      drawLine() {
        LayersService.instance._analysisLayers.drawLine();
      },
      drawCircle() {
        LayersService.instance._analysisLayers.drawCircle();
      },
      clear() {
        removeMode.value = true;
      },
      stopRemoveMode() {
        removeMode.value = false;
      },
      stopDrawing() {
        LayersService.instance._analysisLayers.stopDrawing();
      },
      changeDrawingColor(c: {
        color: string,
        id: number,
      }) {
        color.value = c.color;
      },
      setOutline(t: '0' | '1') {
        type.value = t;
        showOutline.value = false;
      },
    };
  },
});
