












































import { computed, defineComponent, reactive, Ref, ref, watch } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import Popup from '../../shared/popup.vue';
import ColorPicker from '../../shared/color-picker.vue';
import { BasicSelect } from 'vue-search-select';
import { IMunicipality } from '../../../types';
import UserService from '../../../services/user-service';

const analysisHelper = createNamespacedHelpers('layers/analysis');
const municipalitiesHelper = createNamespacedHelpers('municipalities');

export default defineComponent({
  name: 'CrossShowPopup',
  components: {
    Popup,
    ColorPicker,
    BasicSelect,
  },
  setup() {
    const { showCrossShowPopup, crossShow } = analysisHelper.useGetters(['showCrossShowPopup', 'crossShow']);
    const { setCrossShowPopup, setCrossShow } = analysisHelper.useActions(['setCrossShowPopup', 'setCrossShow']);
    const { menuMunicipalities, current } = municipalitiesHelper.useGetters(['current', 'menuMunicipalities']);
    const color = ref('#000');
    const currentComp: Ref<{
      value: number,
      text: string,
      }> = ref({
        value: 0,
        text: '',
      });
    const options: Ref<Array<{
      value: number,
      text: string,
    }>> = computed(() => {
      const municipalities = UserService.instance.user.municipalities;
      return (menuMunicipalities.value as Array<IMunicipality>)
        .filter(municipality =>
          (municipalities.length === 0 || municipalities.find((m: IMunicipality) => m.id === municipality.id)) &&
          data.items.map(i => i.id).indexOf(municipality.id) === -1 && municipality.id !== 0 && municipality.id !== current.value.id)
        .map((municipality: IMunicipality) => ({
          value: municipality.id,
          text: municipality.title,
        }));
    });
    const data: {
      items: Array<{
        color: string,
        id: number,
        text: string,
      }>
    } = reactive({
      items: [],
    });

    watch(showCrossShowPopup, () => {
      currentComp.value = {
        value: 0,
        text: '',
      };

      color.value = '#000';

      if (showCrossShowPopup.value) {
        data.items = [...crossShow.value];
      }
    });

    return {
      color,
      showCrossShowPopup,
      current: currentComp,
      options,
      data,
      closeCrossShowPopup() {
        data.items = [];
        setCrossShowPopup(false);
      },
      saveCrossShowPopup() {
        setCrossShow(data.items);
        setCrossShowPopup(false);
      },
      changeColor(c: {
        color: string,
        id: number,
      }) {
        const item = data.items.find(i => i.id === c.id);
        if (item) {
          item.color = c.color;
        } else {
          color.value = c.color;
        }
      },
      select(item: {
        value: number,
        text: string,
      }) {
        data.items.push({
          color: color.value,
          id: item.value,
          text: item.text,
        });
        color.value = '#000';
      },
      removeCrossShow(id: number) {
        data.items = data.items.filter(i => i.id !== id);
      },
      moveUp(index: number) {
        if (data.items[index - 1]) {
          const buffer = { ...data.items[index - 1] };
          data.items[index - 1] = { ...data.items[index] };
          data.items[index] = buffer;

          data.items = [...data.items];
        }
      },
      moveDown(index: number) {
        if (data.items[index + 1]) {
          const buffer = { ...data.items[index + 1] };
          data.items[index + 1] = { ...data.items[index] };
          data.items[index] = buffer;

          data.items = [...data.items];
        }
      },
    };
  },
});
