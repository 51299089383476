<template>
  <div class="detectors">
    <div class="detector" v-for="(detector, index) in trafficJams" :key="index" @click="showDetector(detector)">
      <div class="detector-address">
        {{ detector.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { LayersService } from '@/services/layers-service';

export default {
  name: 'traffic-jam-layer-control',
  data: () => ({
    trafficJams: [],
  }),
  methods: {
    showDetector(trafficJam) {
      LayersService.instance
        ._trafficJamLayers
        .showMarker(trafficJam);
    },
  },
  created() {
    LayersService.instance
      ._trafficJamLayers
      .defaultJams
      .subscribe(trafficJams => { this.trafficJams = trafficJams; });
  },
};
</script>

<style lang="scss" scoped>
  .detectors {
    .detector {
      cursor: pointer;
      margin-bottom: 16px;

      &-address {
        font-size: 16px;
      }
    }
  }
</style>
