<template>
  <div class="control">
    <div class="tab-content">
      <div class="filters">
        <div class="filter">
          <input v-model="name" placeholder="Введите название маршрута">
        </div>
      </div>
      <div class="items">
        <div class="detectors" v-for="type in transportRouteTypes" :key="type.id">
          <span class="detector-address">{{ type.name }}</span>
          <ul class="route-list">
            <template v-for="(route, index) in getFilteredRoutesByType(type.id)">
              <li class="route-item" :key="route.id" v-show="!name || route.name.toLowerCase().includes(name.toLowerCase())">
                <input type="checkbox" :value="route.id" v-model="filter.selectedRoutes" @change="applyFilter">
                <span @click="highlightRoute(route)">{{ index + 1 }}. {{ route.name }}</span>
                <color-picker
                  :id="route.id"
                  :color="route.color"
                  @change="changeColor"
                ></color-picker>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { LayersService } from '@/services/layers-service';
import { MapService } from '@/services/map-service';
import ColorPicker from '@/components/shared/color-picker';
import { PASSENGER_TYPE_GROUP } from '@/constants/RouteTypeGroups';

export default {
  name: 'route-layer-control',
  components: { ColorPicker },
  data: () => ({
    name: '',
    filter: {
      selectedRoutes: [],
    },
    itemRouteTypes: ['Городские', 'Муниципальные', 'Межмуниципальные', 'Межсубьектные'],
  }),
  methods: {
    ...mapActions({
      updateConverge: 'route/updateConverge',
      openLayers: 'layer/openLayers',
      disableLayers: 'layer/disableLayers',
      openLocality: 'layer/openLocality',
    }),
    showRoute(route) {
      LayersService.instance
        ._routeLayers
        .goToMarker(route);
    },
    applyFilter() {
      LayersService.instance
        ._routeLayers
        ._filter({
          name: this.name,
          selectedRoutes: this.filter.selectedRoutes,
          routeTypes: this.routeTypesA,
        });
    },
    changeColor({ id, color }) {
      LayersService.instance._routeLayers.changeRouteGroupsColor(id, color);
    },
    highlightRoute(route) {
      const layer = LayersService.instance._routeLayers._layers.find(l => l.route.id === route.id);

      if (layer) {
        layer.line?.bringToFront();
        layer.lineCut?.bringToFront();
      }
    },
    getFilteredRoutesByType(typeId) {
      return this.filteredRoutes.filter(r => r.route_type_id === typeId);
    },
  },
  computed: {
    ...mapGetters({
      routeTypes: 'route-type/routeTypes',
      routes: 'route/routes',
      currentMunicipality: 'municipalities/current',
      layers: 'map/layers',
    }),
    filteredRoutes() {
      let routes = LayersService.instance._routeLayers._originRoutes;
      const buffer = LayersService.instance._routeLayers._colorBuffer;

      if (this.routeTypesA && this.routeTypesA.length > 0) {
        routes = routes.filter(route => this.routeTypesA.indexOf(route.route_path_type_id) > -1);
      }

      routes = routes.filter(route => route.route_groups.filter(routeGroup => routeGroup.route_line !== null).length > 0);
      routes = routes.map(route => {
        let color = '#000';

        route.route_groups.forEach(group => {
          if (buffer[route.id]) {
            color = buffer[route.id];
          } else if (group.color) {
            color = group.color;
          }
        });

        return {
          ...route,
          color,
        };
      });

      return routes;
    },
    routeTypesA() {
      return LayersService.instance._routeLayers.filter.routePathTypes
        ? LayersService.instance._routeLayers.filter.routePathTypes
        : this.itemRouteTypes;
    },
    transportRouteTypes() {
      return this.routeTypes.filter(r => r.type_group === PASSENGER_TYPE_GROUP);
    },
  },
  created() {
    this.name = LayersService.instance._routeLayers.filter.name;
    this.map = MapService.instance.map;
    this.filter.selectedRoutes = [...LayersService.instance._routeLayers.filter.selectedRoutes];
  },
  watch: {
    name: function(name) {
      LayersService.instance._routeLayers.filter = {
        ...LayersService.instance._routeLayers.filter,
        name,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .control {
    display: flex;
    flex-flow: column nowrap;

    .filter {
      input {
        padding: 3px;
        font-size: 15px;
        width: 100%;
        margin: 16px 0;
        display: block;
        border: 1px solid var(--border-color);
        border-radius: 3px;
      }
    }

    .detector {
      cursor: pointer;
      margin-bottom: 16px;

      &-address {
        margin-bottom: 10px;
        font-size: 16px;
      }
    }
  }

  .route {

    &-list {
      padding-left: 0;
      display: flex;
      flex-flow: row wrap;
    }

    &-item {
      margin-bottom: 15px;
      list-style: none;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      cursor: pointer;

      input {
        cursor: pointer;
      }

      label, span {
        margin: 0;
        margin-left: 5px;
        margin-right: 5px;
        line-height: 1;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: calc(var(--sidebar-width) - 90px);
      }
    }
  }

  .tab {
    &-headers {
      display: flex;
      justify-content: space-between;
    }

    &-headers {
      margin-left: -16px;
      margin-top: -1px;
      margin-right: -16px;
    }

    &-header {
      width: 50%;
      text-align: center;

      padding-top: 10px;
      padding-bottom: 10px;

      cursor: pointer;

      &:hover {
        color: #fff;
        background-color: var(--main-color);
      }

      &-active, &-active:hover {
        color: #fff;
        background-color: var(--menu-main-color);
      }
    }
  }

  h5 {
    margin-top: 10px;
  }

  .analysis {
    &-wrapper {
      display: flex;
      align-items: center;
    }

    &-inputs {
      width: 100%;
      margin-right: 10px;
    }

    &-item {
      width: 100%;

      button {
        width: 100%;
        text-align: left;
        background-color: rgb(224, 224, 224);

        &:hover {
          background-color: rgb(197, 197, 197);
        }

        &:focus, &:active {
          border: none;
          outline: none;
          box-shadow: none;
        }
      }

      &-from svg {
        color: red;
      }
    }

    &-exchange {
      font-size: 20px;
      transform: rotate(90deg);
      color: gray;
      cursor: pointer;

      &:hover {
        color: var(--main-color);
      }
    }
  }

  .btn-reset {
    background-color: var(--main-color);
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
</style>

<style lang="scss">
.analysis-marker-icon {
  margin-top: -36px;
  margin-left: -10px;
}
</style>
