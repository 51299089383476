import axios from '../axios';

export class TrafficLight {
  static getTrafficLights({ all, municipalityId }: {
    all: boolean,
    municipalityId?: number,
  }) {
    const params: {
      [key: string]: string,
    } = {};

    if (all) {
      params.all = '';
    }

    if (municipalityId) {
      params['filter[municipalities]'] = municipalityId.toString();
    }

    return axios.get('/traffic-light', {
      params,
    })
      .then(({ data }) => data)
      .catch(e => e);
  }
}
