




















































































/* eslint-disable camelcase */

import { defineComponent, computed, reactive, onMounted, PropType } from '@vue/composition-api';
import { TrashContainerType, TrashAreaItem, IFile } from '@/types';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import moment from 'moment';
import { API_QUERY_DATE_FORMAT, DATE_ONLY_FORMAT, TIME_ONLY_FORMAT_WITHOUT_SECONDS } from '@/constants/Global';

const trashAreaHelper = createNamespacedHelpers('trash-area');

export default defineComponent({
  name: 'TrashAreaPopup',
  props: {
    trashArea: {
      type: Object as PropType<TrashAreaItem>,
      required: true,
      default: () => ({} as TrashAreaItem),
    },
    showHousesButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props, { root, emit }) {
    const imageUrl = `${process.env.VUE_APP_API_BASE}/trash-area-file/`;

    const status : {
      mounted: boolean,
    } = reactive({
      mounted: false,
    });

    const { showImagesPopup }: {
      showImagesPopup: ({ files, title } : {files: Array<IFile>, title: string}) => Promise<void>,
    } = trashAreaHelper.useActions(['showImagesPopup']) as any;

    const showHousesFlag = computed(() => {
      return props.showHousesButton && props.trashArea.houses && props.trashArea.houses.length > 0;
    });

    const count = computed(() => {
      let count = 0;

      props.trashArea.trash_container_types?.forEach((type: TrashContainerType) => {
        count += type.pivot?.count ?? 0;
      });

      return count;
    });

    const trashAreaTripsFormatted = computed(() => {
      if (!props.trashArea.last_trips || !props.trashArea.last_trips.length) {
        return [];
      }

      return props.trashArea.last_trips.map((trip) => {
        return {
          ...trip,
          date: moment(trip.datetime).format(DATE_ONLY_FORMAT),
          time: moment(trip.datetime).format(TIME_ONLY_FORMAT_WITHOUT_SECONDS),
        };
      });
    });
    const size = computed(() => {
      let size = 0;

      props.trashArea.trash_container_types?.forEach((type: TrashContainerType) => {
        if (type.pivot?.count) {
          size += type.pivot.count * type.value;
        }
      });

      return Math.round(size * 10) / 10;
    });

    const containersName = computed(() => {
      const data: Array<{
        name: string;
        count: number;
      }> = [];

      props.trashArea.trash_container_types?.forEach((type: TrashContainerType) => {
        if (type.pivot?.count) {
          let added = false;

          data.forEach((el) => {
            if (el.name === type.name) {
              el.count += type.pivot?.count ?? 0;
              added = true;
            }
          });

          if (!added) {
            data.push({
              name: type.name,
              count: type.pivot?.count ?? 0,
            });
          }
        }
      });

      return data;
    });

    onMounted(() => {
      status.mounted = true;
    });

    const getElementByWeekdayAndId = (weekDayToCompare: number, trashAreaId: number) => {
      return function(item: {isodow: number, trash_area_id: number}) {
        return item.isodow === weekDayToCompare && item.trash_area_id === trashAreaId;
      };
    };

    return {
      count,
      size,
      containersName,
      imageUrl,
      status,
      showHousesFlag,
      trashAreaTripsFormatted,
      showHouses() {
        emit('show-houses', props.trashArea.id);
      },
      showPopup(trashArea: TrashAreaItem) {
        showImagesPopup({
          files: trashArea?.files ?? [],
          title: trashArea.address,
        });
      },
      query: computed(() => {
        const indexOfLastElement = trashAreaTripsFormatted.value.length - 1;
        const date_from = moment(trashAreaTripsFormatted.value[indexOfLastElement].datetime).format(API_QUERY_DATE_FORMAT);
        const date_to = moment(trashAreaTripsFormatted.value[0].datetime).format(API_QUERY_DATE_FORMAT);

        return {
          date_from,
          date_to,
          trash_area_id: props.trashArea.id.toString(),
        };
      }),
    };
  },
});
