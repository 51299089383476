






















import { computed, defineComponent, Ref, ref } from '@vue/composition-api';
import { isMobile } from 'mobile-device-detect';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { PASSENGER_TYPE_GROUP, ROAD_FACILITIES_TYPE_GROUP } from '../../../constants/RouteTypeGroups';
import { ACL } from '../../../modules/acl/acl-service';
import router from '../../../router';

const routeTypeHelper = createNamespacedHelpers('route-type');
const layerHelper = createNamespacedHelpers('layer');
const sitCenterHelper = createNamespacedHelpers('layers/sitCenter');

export default defineComponent({
  name: 'sitCenterButton',
  setup() {
    const show = ref(!isMobile);

    const { openLayers, disableLayers }: {
      openLayers: (payload: Array<string>) => void,
      disableLayers: (payload: { layers: Array<string>, toggle: boolean }) => void,
    } = layerHelper.useActions(['disableLayers', 'openLayers']) as any;
    const { active }: {
      active: Ref<boolean>,
    } = sitCenterHelper.useGetters(['active']) as any;

    const { groups }: {
      groups: Ref<{
        [key: number]: Array<number>,
      }>
    } = routeTypeHelper.useGetters(['groups']) as any;

    const acl = computed(() => {
      return ACL.canGroup('dashboard');
    });

    return {
      show,
      active,
      acl,
      goTo() {
        const transportTypes = [
          ...groups.value[PASSENGER_TYPE_GROUP],
          ...groups.value[ROAD_FACILITIES_TYPE_GROUP],
        ].join(',');

        router.push({
          name: 'main-map',
        }).catch(() => {})
          .then(() => {
            return router.push({
              name: 'main-map-typed',
              query: {
                transport: transportTypes,
              },
              params: {
                type: transportTypes,
              },
            });
          })
          .then(() => {
            openLayers(['sitCenter', 'transport', 'repairRoad', 'weather']);
          });
      },
      disable() {
        router.push({
          name: 'main-map',
        })
          .catch(() => {})
          .then(() => {
            disableLayers({ layers: ['sitCenter'], toggle: true });
          });
      },
    };
  },
});
