import L from 'leaflet';
import { Transport } from '@/services/layers/transport';
import { Transport as TransportApi } from '@/services/api/transport';
import { BaseLayer } from './base-layer';
import { LayersService } from '@/services/layers-service';
import UserService from '@/services/user-service';
import { TransportStatusEnum, MessageTypeEnum } from '@/types';

export class TransportLayer extends BaseLayer {
  _ws = null;
  _municipalityId = null;
  _layers = null;

  constructor({ store, map, type }) {
    super({ store, map, type });

    this._store.subscribe((mutation) => {
      if (mutation.type === 'municipalities/current' && !!this._ws) {
        const municipality = this._store.getters['municipalities/municipalities'].find(m => m.id === mutation.payload);

        if (!municipality) {
          return;
        }

        // TODO: исправиль на использование атрибута is_under
        if (municipality.id === 0 || municipality.super) {
          this._ws.changeMunicipality(null);
          this._municipalityId = null;
        } else {
          this._ws.changeMunicipality(mutation.payload);
          this._municipalityId = municipality.id;
        }

        if (!this._ws.isSocketActive) {
          this._ws._municipality = this._municipalityId;
        }

        this.getBrokenTransports();

        this._store.dispatch('transports/clearRoute');
        this._clearRoutes();
      }

      if (mutation.type === 'transports/setRouteFilter') {
        const route = mutation.payload;
        const currentFilter = LayersService.instance
          ._transportLayers
          ._ws
          .filter
          .getValue();

        currentFilter.routeId = route?.id;

        LayersService.instance
          ._transportLayers
          ._ws
          .filter
          .next({
            ...currentFilter,
          });
      }
    });
  }

  _clearRoutes() {
    if (this._layers !== null) {
      this._layers.forEach(layer => layer.line.remove());
      this._layers = null;
    }
  }

  _deleteLayer() {
    if (this._ws !== null) {
      this._ws.destroy();
      this._ws = null;
    }

    this._clearRoutes();
  }

  goToMarker({ number }) {
    const latlng = this._ws._markersZoomed[number].getLatLng();
    this._map.setView([latlng.lat, latlng.lng], 18);
    this._ws._generatePopup(this._ws._markersZoomed[number]);
  }

  goToRoute(route, zoom = 15) {
    this._layers.forEach(layer => {
      if (layer.route.id === route.id) {
        this._map.setView([route.route_groups[0].route_line.coordinates[0][1], route.route_groups[0].route_line.coordinates[0][0]], zoom);
        layer.line.openPopup();
      }
    });
  }

  reRender({ types }) {
    if (!this._ws) {
      const municipality = this._store.getters['municipalities/current'];

      if (municipality.position === 0 || municipality.super) {
        this._municipalityId = null;
      } else {
        this._municipalityId = municipality.id;
      }

      this._ws = new Transport({
        map: this._map,
        routeTypes: this._store.getters['route-type/routeTypes'],
        token: UserService.instance.token,
        municipality: this._municipalityId,
        layer: this,
      });

      this.getBrokenTransports();
    }

    if (this._store.getters['map/filters']['transport'] && Object.keys(this._store.getters['map/filters']['transport']).length > 0) {
      if (this._store.getters['map/filters']['transport'].routeId) {
        this._store.dispatch('transports/showRoute', this._store.getters['map/filters']['transport'].routeId)
          .then((route) => {
            this.drawRoute(route);
          });
      }

      this._ws.filter.next(this._store.getters['map/filters']['transport']);
    }

    const currentFilter = this._ws.filter.getValue();
    currentFilter.routeTypes = types.filter(type => type.checked).map(type => type.id);
    this._ws.filter.next(currentFilter);
  }

  getBrokenTransports() {
    TransportApi.getBrokenTransports({ municipalityId: this._municipalityId })
      .then(({ broken, normal, parking }) => {
        this._ws.processMessage({
          type: MessageTypeEnum.TRANSPORT_MESSAGE_TYPE,
          payload: [
            ...broken.map(t => ({ ...t, status: TransportStatusEnum.BROKEN })),
            ...parking.map(t => ({ ...t, status: TransportStatusEnum.PARKING })),
            ...normal.map(t => ({ ...t, status: TransportStatusEnum.ACTIVE })),
          ],
        });
        this._store.dispatch('transports/brokenLoaded');
      })
      .catch(() => {});
  }

  destroy() {
    this._deleteLayer();
  }

  drawRoute(route) {
    this._clearRoutes();
    this._layers = [];

    route.route_groups.forEach(routeGroup => {
      let colorLine = routeGroup.color ? routeGroup.color : 'black';
      const line = L.geoJSON(routeGroup.route_line, {
        weight: 5,
        opacity: 1,
        color: colorLine,
        dashArray: '3',
        fillOpacity: 0.3,
        fillColor: colorLine,
      });

      line.bindPopup(`Маршрута ${route.name} направление ${routeGroup.name}`);
      line.addTo(this._map);

      this._layers.push({
        line,
        route,
        routeGroupId: routeGroup.id,
      });
    });
  }
}
