import axios from 'axios';
import { app, store } from '../main';
import UserService from './user-service';
import { NotificationsService } from '@/modules/notifications/notifications-service';

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE,
});

axiosInstance.interceptors.request.use(function(config) {
  if (!config.headers.authorization) {
    if (UserService.instance.isAuth) {
      config.headers.authorization = UserService.instance.token;
    }
  }

  if (!config?.isBackgroundLoading) {
    store.commit('loading', true);
  }
  return config;
});

axiosInstance.interceptors.response.use(function(response) {
  if (!response?.config?.isBackgroundLoading) {
    store.commit('loading', false);
  }
  return response;
}, function(error) {
  const config = error.response?.config;
  const status = error.response?.status;
  const url = config?.url;
  const method = config?.method;
  const isAuth = UserService.instance.isAuth;

  if (!config?.isBackgroundLoading) {
    store.commit('loading', false);
  }

  if (status === 400 || status === 401 || status === 403 || status === 404 || status >= 500) {
    // Check if normal request failed
    if (status === 401 && !config.refreshRequest && !config.repeatedRequest && isAuth) {
      // Refresh token
      UserService.instance.refreshToken()
        .then(() => {
          // If refresh succeeded and it's not repeated one, repeat request
          const newConfig = { ...config };
          newConfig.repeatedRequest = true;
          newConfig.headers.authorization = UserService.instance.token;

          axiosInstance.request(newConfig);
        });
    } else if (status === 401 && isAuth) {
      // Logout user if refresh request failed with 401
      UserService.instance.logout();
      app.$router.push('/login');
    } else if (status < 500 && error.response && error.response.data && (status !== 401 || (method === 'post' && url === '/auth/login'))) {
      // Show notification otherwise if there any
      if (error.response.data instanceof Blob) {
        error.response.data.text().then(t => NotificationsService.instance.error(JSON.parse(t)));
      } else {
        NotificationsService.instance.error(error.response.data);
      }
    } else if (status >= 500) {
      NotificationsService.instance.add({
        type: 'danger',
        text: 'Сервер временно недоступен. Пожалуйста, попробуйте позже.',
      });
    }
  } else {
    NotificationsService.instance.error(error);
  }

  return Promise.reject(error);
});

export default axiosInstance;
