/* eslint-disable camelcase */
import { RouteAnalysis } from '@/services/api/report/route-analysis';
import { LayersService } from '@/services/layers-service';
import { AnalysisState, IAnalysisReportResponse, IRouteAnalysis, RootState } from '@/types';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

function isCircle(value: L.Circle | L.Polyline): value is L.Circle {
  return (value as L.Circle).getRadius !== undefined;
}

const state: AnalysisState = {
  showCrossShowPopup: false,
  crossShow: [],
  analysisList: [],
  routeColorBuffer: {},
  report: null,
  showReport: false,
  lastReportFilter: '',
  localityFilter: [],
};
const getters: GetterTree<AnalysisState, RootState> = {
  showCrossShowPopup: state => state.showCrossShowPopup,
  crossShow: state => state.crossShow,
  analysisList: state => state.analysisList,
  routeColorBuffer: state => state.routeColorBuffer,
  report: state => ({
    totals: state.report?.totals,
    municipalities: state.report?.municipalities?.map(m => {
      return {
        ...m,
        localities: m.localities.filter(l => state.localityFilter.length === 0 || state.localityFilter.indexOf(l.id) !== -1),
      };
    }),
  }),
  municipalities: state => state.report?.municipalities,
  showReport: state => state.showReport,
  localityFilter: state => state.localityFilter,
};
const mutations: MutationTree<AnalysisState> = {
  setCrossShowPopup: (state, payload: boolean) => {
    state.showCrossShowPopup = payload;
  },
  enableCrossShow: (state, payload: {
    color: string,
    id: number,
    text: string,
  }) => {
    state.crossShow.push({
      color: payload.color,
      id: payload.id,
      text: payload.text,
    });
  },
  setCrossShow: (state, payload: Array<{
    color: string,
    id: number,
    text: string,
  }>) => {
    state.crossShow = [...payload];
  },
  setAnalysisList: (state, payload: Array<IRouteAnalysis>) => {
    state.analysisList = [...payload];
  },
  setRouteColorBuffer: (state, payload: { [key: number]: string }) => {
    state.routeColorBuffer = { ...payload };
  },
  setReport: (state, payload: IAnalysisReportResponse) => {
    state.report = { ...payload };
  },
  setShowReport: (state, payload: boolean) => {
    state.showReport = payload;
  },
  setLastReport: (state, payload: string) => {
    state.lastReportFilter = payload;
  },
  setLocalityFilter: (state, payload: Array<number>) => {
    state.localityFilter = [...payload];
  },
  updateReport: () => { },
};
const actions: ActionTree<AnalysisState, RootState> = {
  enableCrossShow({ commit }, payload: {
    color: string,
    id: number,
    text: string,
  } | null) {
    commit('enableCrossShow', payload);
  },
  setCrossShow({ commit }, payload: Array<{
    color: string,
    id: number,
    text: string,
  }>) {
    commit('setCrossShow', payload);
  },
  setCrossShowPopup({ commit }, payload: boolean) {
    return commit('setCrossShowPopup', payload);
  },
  setRouteColorBuffer({ commit }, payload: { [key: number]: string }) {
    return commit('setRouteColorBuffer', payload);
  },
  async saveAnalysis(context, payload: {
    name: string,
    id?: number,
  }) {
    const data: Array<{
      type: string,
      [key: string]: any,
    }> = [];
    LayersService.instance._analysisLayers.layers.eachLayer((l: L.Circle | L.Polyline) => {
      if (isCircle(l)) {
        const { lat, lng } = l.getLatLng();
        data.push({
          type: 'circle',
          color: l.options.color as string,
          dashArray: l.options.dashArray as string,
          latLng: L.latLng(lat, lng),
          radius: l.getRadius(),
        });
      } else {
        data.push({
          type: 'polyline',
          color: (l.options as any).color,
          dashArray: (l.options as any).dashArray,
          latLngs: (l.getLatLngs() as L.LatLng[]).map(({ lat, lng }) => L.latLng(lat, lng)),
        });
      }
    });

    data.push(...LayersService.instance._analysisLayers.getSavingData());

    let analysis: IRouteAnalysis;

    if (!payload.id) {
      analysis = await RouteAnalysis.saveAnalysis({
        name: payload.name,
        data: JSON.stringify(data),
      });

      context.commit('setAnalysisList', [
        ...context.getters.analysisList,
        analysis,
      ]);
    } else {
      analysis = await RouteAnalysis.updateAnalysis({
        name: payload.name,
        data: JSON.stringify(data),
        id: payload.id,
      });

      context.commit('setAnalysisList', context.getters.analysisList.map((a: IRouteAnalysis) => {
        if (a.id === analysis.id) {
          return analysis;
        }

        return a;
      }));
    }

    return analysis;
  },
  removeAnalysis(context, payload: {
    id: number,
  }) {
    return RouteAnalysis.deleteAnalysis(payload);
  },
  exportAnalysis(context, payload: {
    municipality_id: Array<number>,
    locality_id: Array<number>,
  }) {
    return RouteAnalysis.getReport(payload);
  },
  exportAnalysis2(context, payload: {
    municipality_id: Array<number>,
    locality_id: Array<number>,
  }) {
    return RouteAnalysis.getReport2(payload);
  },
  async getList({ commit }) {
    const list = await RouteAnalysis.getList();

    commit('setAnalysisList', list);
  },
  async loadReport({ commit, getters }, payload: {
    municipality_id: Array<number>,
    locality_id: Array<number>,
    isBackgroundLoading: boolean,
  }) {
    commit('setLocalityFilter', payload.locality_id);
    const filter = JSON.stringify({ municipality_id: payload.municipality_id });
    if (state.lastReportFilter === filter) {
      return getters.report;
    }

    commit('setLastReport', filter);
    const data = await RouteAnalysis.getData({
      isBackgroundLoading: payload.isBackgroundLoading,
      municipality_id: payload.municipality_id,
    });

    commit('setReport', data);
    return data;
  },
  setShowReport({ commit }, payload: boolean) {
    commit('setShowReport', payload);
  },
  clearReport({ commit }) {
    commit('setLastReport', '');
    commit('setReport', null);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
