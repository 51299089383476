import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { TrashArea } from '@/services/api/trash-area';
import { RootState, TrashAreaState, ImagesPreviewPopup, IFile } from '@/types';

const state: TrashAreaState = {
  coatingTypes: [],
  highlight: false,
  imagesPopup: {
    open: false,
    files: [],
    title: '',
  },
};

const getters: GetterTree<TrashAreaState, RootState> = {
  coatingTypes: state => state.coatingTypes,
  imagesPopup: state => state.imagesPopup,
  highlight: state => state.highlight,
};

const mutations: MutationTree<TrashAreaState> = {
  setCoatingTypes: (state, payload: Array<string>) => {
    state.coatingTypes = [...payload];
  },
  setImagesPopup: (state, payload: ImagesPreviewPopup) => {
    state.imagesPopup = payload;
  },
  setHighlight: (state, payload: boolean) => {
    state.highlight = payload;
  },
};

const actions: ActionTree<TrashAreaState, RootState> = {
  async loadCoatingTypes({ commit }) {
    const coatingTypes = await TrashArea.getCoatingTypes();
    commit('setCoatingTypes', coatingTypes);
  },
  showImagesPopup({ commit }, { files, title } : {files: Array<IFile>, title: string}) {
    commit('setImagesPopup', {
      open: true,
      files,
      title,
    });
  },
  hideImagesPopup({ commit }) {
    commit('setImagesPopup', {
      open: false,
      files: [],
      title: '',
    });
  },
  showHighlight({ commit }) {
    commit('setHighlight', true);
  },
  hideHighlight({ commit }) {
    commit('setHighlight', false);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
