


























































import { defineComponent, onBeforeUnmount, onMounted, Ref, ref, watch } from '@vue/composition-api';
import FilePreview from './file-preview.vue';
import { IFile } from '../../../../types';
import { Chat } from '../../../../services/api/chat';
import VueContext from 'vue-context';
import { ACL } from '../../../../modules/acl/acl-service';

export default defineComponent({
  props: {
    body: {
      type: String,
      default: '',
    },
    input: {
      type: Boolean,
      default: false,
    },
    output: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      required: true,
    },
    read: {
      type: Boolean,
      default: false,
    },
    readedAt: {
      type: String,
      default: '',
    },
    editedAt: {
      type: String,
      default: '',
    },
    id: {
      required: true,
    },
    scrollPosition: {
      type: Number,
      default: 0,
    },
    files: {
      type: Array,
      default: () => [],
    },
    error: {
      type: Boolean,
      default: false,
    },
    disableContext: {
      type: Boolean,
      default: false,
    },
  },
  components: { FilePreview, VueContext },
  emits: ['mark-read', 'remove', 'resend', 'delete'],
  setup(props, { emit }) {
    const element: Ref<null | HTMLElement> = ref(null);
    const menu: Ref<null | HTMLElement> = ref(null);
    const isElementInViewport = ref(false);

    const calculateIsElementInViewport = () => {
      if (!element.value) {
        return false;
      }

      const { top, bottom, height } = element.value.getBoundingClientRect();
      const parent = element.value?.parentElement?.parentElement?.getBoundingClientRect();

      if (!parent) {
        return false;
      }

      return (
        top >= parent.top &&
        bottom <= parent.bottom + height
      ) || (
        top >= parent.top - height &&
        bottom <= parent.bottom
      );
    };

    const init = () => {
      if (props.read || props.output) {
        return;
      }

      isElementInViewport.value = calculateIsElementInViewport();

      if (isElementInViewport.value && !document.hidden) {
        emit('mark-read', props.id);
      }
    };

    const markReadOnVisibilityChange = () => {
      init();

      document.removeEventListener('visibilitychange', markReadOnVisibilityChange);
    };

    const openContextMenu = (e: MouseEvent) => {
      if (props.output && menu.value && !props.disableContext && (ACL.can('messenger.message-edit') || ACL.can('messenger.messages-delete'))) {
        e.preventDefault();
        (menu.value as any).open(e);
      }
    };

    watch(() => props.scrollPosition, () => {
      init();
    });

    onMounted(() => {
      init();

      if (props.read || props.output) {
        return;
      }
      document.addEventListener('visibilitychange', markReadOnVisibilityChange);
    });

    onBeforeUnmount(() => {
      if (props.read || props.output) {
        return;
      }

      document.removeEventListener('visibilitychange', markReadOnVisibilityChange);
    });

    return {
      element,
      menu,
      openContextMenu,
      isElementInViewport,
      prepareBody(text: string) {
        return String(text)
          // Санитайзим строку
          .replace(/&/g, '&amp;')
          .replace(/</g, '&lt;')
          .replace(/>/g, '&gt;')
          .replace(/"/g, '&quot;')
          // Меняем переносы на <br>
          .replace(/([^>])\n/g, '$1<br>');
      },
      downloadFile(id: number) {
        const file = (props.files as Array<IFile>).find(f => f.id === id);

        if (file) {
          Chat.downloadFile(file)
            .catch(() => {});
        }
      },
      remove() {
        emit('remove', props.id);
      },
      resend() {
        emit('resend', props.id);
      },
      deleteMessage() {
        if (!props.error) {
          const agree = window.confirm('Вы действительно хотите удалить сообщение?');

          if (agree) {
            emit('delete-message', props.id);
          }
        } else {
          emit('remove', props.id);
        }
      },
      editMessage() {
        emit('edit-message', props.id);
      },
    };
  },
});
