import { Transporter } from '@/services/api/transporter';

export default {
  state: {
    transporters: [],
    transportersRoutes: [],
  },
  getters: {
    transporters: state => state.transporters,
    transportersRoutes: state => state.transportersRoutes,
  },
  mutations: {
    transporters: (state, payload) => { state.transporters = payload; },
    transportersRoutes: (state, payload) => { state.transportersRoutes = payload; },
  },
  actions: {
    loadTransporters(state, { all, filter, include, append, sort }) {
      return new Promise((resolve, reject) => {
        Transporter.getTransporters({ all, filter, include, append, sort })
          .then((response) => {
            state.commit('transporters', response.transporters);
            resolve(response);
          })
          .catch(reject);
      });
    },
    loadTransportersRoutes(state, { municipality }) {
      return new Promise((resolve, reject) => {
        Transporter.getTransportersRoutes({ municipality })
          .then((response) => {
            state.commit('transportersRoutes', response.transporters);
            resolve(response);
          })
          .catch(reject);
      });
    },
  },
};
