


















import { BasicSelect } from 'vue-search-select';
import { LayersService } from '../../../services/layers-service';
import { mapGetters, mapMutations } from 'vuex';
import { ICurrentMunicipality, IMunicipality } from '../../../types';
import UserService from '../../../services/user-service';

export default {
  name: 'mo-selector',
  components: { BasicSelect },
  computed: {
    ...mapGetters({
      municipalities: 'municipalities/menuMunicipalities',
      currentMunicipality: 'municipalities/current',
    }),
    options(): Array<{
      value: number,
      text: string,
    }> {
      const municipalities = UserService.instance.user.municipalities;
      return (this.municipalities as unknown as Array<IMunicipality>)
        .filter(municipality => municipalities.length === 0 || municipality.id === 0 || municipalities.find((m: IMunicipality) => m.id === municipality.id))
        .map((municipality: IMunicipality) => ({
          value: municipality.id,
          text: municipality.title,
        }));
    },
    current(): {
      value: number,
      text: string,
      } {
      const currentMunicipality: ICurrentMunicipality = this.currentMunicipality as unknown as ICurrentMunicipality;
      if (currentMunicipality && currentMunicipality.id === 0) {
        return {
          value: currentMunicipality.id,
          text: 'Выбрать Организатора деятельности',
        };
      } else if (currentMunicipality) {
        return {
          value: currentMunicipality.id,
          text: currentMunicipality.name,
        };
      } else {
        return {
          value: 0,
          text: '',
        };
      }
    },
  },
  methods: {
    ...mapMutations({
      setCurrentMunicipality: 'municipalities/current',
    }),
    select(data: {
      value: number,
      text: string,
    }) {
      localStorage.setItem('currentMunicipality', data.value.toString());
      this.setCurrentMunicipality(data.value);
      LayersService.instance.render(LayersService.instance._layersParams);
    },
  },
};
