import Municipality from '@/services/api/municipality';

export default {
  namespaced: true,
  state: {
    current: parseInt(localStorage.getItem('currentMunicipality') || '0'),
    municipalities: [],
  },
  getters: {
    municipalities: state => state.municipalities,
    menuMunicipalities: state => state.municipalities.map(elm => ({
      id: elm.id,
      title: elm.name,
    })),
    current: state => {
      if (state.current === 0) {
        return state.municipalities[state.current];
      } else if (state.current === -1) {
        return undefined;
      } else {
        return state.municipalities.find((elm) => {
          return elm.id === state.current;
        });
      }
    },
    currentIndex: state => state.current,
    sortedMunicipalities: state => {
      const regions = state.municipalities
        .filter(region => region.position < 10)
        .sort((a, b) => a.position - b.position);

      const cities = state.municipalities
        .filter(city => city.name.toLowerCase().indexOf('город') > -1)
        .sort((a, b) => a.position - b.position);

      const areas = state.municipalities.filter(area => regions.indexOf(area) === -1 && cities.indexOf(area) === -1)
        .sort((a, b) => (b.name < a.name) - (a.name < b.name));

      return [].concat(regions, cities, areas);
    },
    isCurrentGeneral: state => state.current === 0,
  },
  mutations: {
    municipalities: (state, payload) => {
      state.municipalities = payload;
    },
    current: (state, payload) => { state.current = payload; },
  },
  actions: {
    loadMunicipalities(state, { all = false, sort = ['sort'] }) {
      return new Promise((resolve, reject) => {
        Municipality.getMunicipalities({ all, sort })
          .then(municipalities => {
            const generatedMunicipalities = [
              {
                border: {},
                center: null,
                type: 'Point',
                color: 'red',
                id: 0,
                image_url: '',
                name: 'Не выбран',
                position: 0,
                super: true,
                updated_at: '',
              },
              ...municipalities,
            ];
            state.commit('municipalities', generatedMunicipalities);
            resolve(generatedMunicipalities);
          })
          .catch(e => reject(e));
      });
    },
    setCurrentMunicipality({ commit }, id) {
      commit('current', id);
    },
  },
};
