



















import Vue from 'vue';
import { computed, defineComponent, onMounted, Ref, ref, watch } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';

export default defineComponent({
  props: {
    name: {
      type: String,
      default: 'Файл',
    },
    mimeType: {
      type: String,
      default: '',
    },
    id: {
      type: Number,
      required: true,
    },
    downloadMode: {
      type: Boolean,
      default: false,
    },
    hideRemove: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['remove', 'download'],
  setup(props, { emit }) {
    const iconClasses: {
          [key: string]: string,
        } = {
          image: 'fa-file-image',
          audio: 'fa-file-audio',
          video: 'fa-file-video',
          'application/pdf': 'fa-file-pdf',
          'application/msword': 'fa-file-word',
          'application/vnd.ms-word': 'fa-file-word',
          'application/vnd.oasis.opendocument.text': 'fa-file-word',
          'application/vnd.openxmlformats-officedocument.wordprocessingml': 'fa-file-word',
          'application/vnd.ms-excel': 'fa-file-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml': 'fa-file-excel',
          'application/vnd.oasis.opendocument.spreadsheet': 'fa-file-excel',
          'application/vnd.ms-powerpoint': 'fa-file-powerpoint',
          'application/vnd.openxmlformats-officedocument.presentationml': 'fa-file-powerpoint',
          'application/vnd.oasis.opendocument.presentation': 'fa-file-powerpoint',
          'text/plain': 'fa-file-text',
          'text/html': 'fa-file-code',
          'application/json': 'fa-file-code',
          'application/gzip': 'fa-file-archive',
          'application/zip': 'fa-file-archive',
          'application/x-zip-compressed': 'fa-file-archive',
        };
    const iconClass = computed(() => {
      return iconClasses[props.mimeType.split('/')[0]] || iconClasses[props.mimeType] || 'fa-file';
    });
    return {
      remove() {
        emit('remove', props.id);
      },
      download() {
        if (props.downloadMode) {
          emit('download', props.id);
        }
      },
      iconClass,
    };
  },
});
