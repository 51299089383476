import { IRoutePathType } from '@/types';
import { AxiosResponse } from 'axios';
import axios from '../axios';

export class RoutePathType {
  static loadRoutePathTypes({ all = true, filter = {} } : {
    all? : boolean | number,
    filter?: {
        [key: string]: string | number
    },
  }) : Promise<Array<IRoutePathType>> {
    const params: {
      [key: string]: string | number
    } = {};
    if (all) {
      params.all = '';
    }
    if (filter.typeGroup) {
      params['filter[group_code]'] = filter.typeGroup;
    }
    return axios.get('/route-path-type', { params })
      .then((res: AxiosResponse<{
        // eslint-disable-next-line camelcase
        route_path_types: Array<IRoutePathType>,
      }>) => res.data.route_path_types);
  }
}
