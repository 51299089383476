import { History } from '@/services/api/history';

export default {
  namespaced: true,
  state: {
    transports: [],
  },
  getters: {
    transports: state => state.transports,
  },
  mutations: {
    transports: (state, payload) => {
      state.transports = payload;
    },
  },
  actions: {
    clearTransports({ commit }) {
      commit('transports', {});
    },
    getTransports(state, { transporterId, dateFrom, dateTo, transportId, routeTypeGroup }) {
      return new Promise((resolve, reject) => {
        History.getTransports({ transporterId, dateFrom, dateTo, transportId, routeTypeGroup })
          .then(({ transports }) => {
            state.commit('transports', transports);
            resolve(transports);
          })
          .catch(e => reject(e));
      });
    },
    getTransportsLine(state, { transportId, dateFrom, dateTo, routeGroupId, cancelToken }) {
      return new Promise((resolve, reject) => {
        History.trackTransports({ transportId, dateFrom, dateTo, routeGroupId, cancelToken })
          .then((response) => {
            resolve(response);
          })
          .catch(e => reject(e));
      });
    },
    getHistory(state, { transportId, dateFrom, dateTo, outfitId, routeGroupId }) {
      return new Promise((resolve, reject) => {
        History.getHistory({ transportId, dateFrom, dateTo, outfitId, routeGroupId })
          .then((response) => {
            resolve(response);
          })
          .catch(e => reject(e));
      });
    },
  },
};
