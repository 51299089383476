<template>
  <div class="control">
    <div class="detectors">
      <div class="detector" v-for="(parking, index) in parkings" :key="index" @click="showParking(parking)">
        <div class="detector-address">
          {{ parking.company_name }} (Свободно {{ parking.free_impound_today ? parking.free_impound_today.count : 0 }} из {{ parking.capacity || 0 }})
          <br>
          Адрес: {{ parking.address }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LayersService } from '@/services/layers-service';

export default {
  name: 'parking-fine-layer-control',
  data: () => ({
    parkings: [],
  }),
  methods: {
    showParking(parking) {
      LayersService.instance
        ._parkingFineLayers
        .showMarker(parking);
    },
  },
  created() {
    LayersService.instance
      ._parkingFineLayers
      .parkingList
      .subscribe(parkings => { this.parkings = parkings; });
  },
};
</script>

<style lang="scss" scoped>
  .control {

    .detector {
      cursor: pointer;
      margin-bottom: 16px;

      &-address {
        font-size: 16px;
      }
    }
  }
</style>
