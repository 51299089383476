import axios from '../../axios';

export class ReportByRoute {
  static loadRoutes({ dateFrom, dateTo }) {
    return new Promise((resolve, reject) => {
      axios.get(`https://rnis.dorogi73.ru/api/logs/common-report-for-each-route-form-2/?datetime_from=${dateFrom}&datetime_to=${dateTo}`, {
        headers: {
          Authorization: `Bearer exzIk5c3oCvzuG4UwhvxGffCVKm6OxYH`,
        },
      })
        .then(({ data }) => resolve(data))
        .catch(error => reject(error));
    });
  }

  static loadRoutesNew({ date }) {
    return new Promise((resolve, reject) => {
      axios.get(`https://rnis.dorogi73.ru/api/custom/report/form2/?date=${date}`, {
        headers: {
          Authorization: `Bearer exzIk5c3oCvzuG4UwhvxGffCVKm6OxYH`,
        },
      })
        .then(({ data }) => resolve(data))
        .catch(error => reject(error));
    });
  }

  static loadPlannedData() {
    return new Promise((resolve, reject) => {
      axios.get(`https://rnis.dorogi73.ru/api/custom/report/count-planed-mts`, {
        headers: {
          Authorization: `Bearer exzIk5c3oCvzuG4UwhvxGffCVKm6OxYH`,
        },
      })
        .then(({ data }) => resolve(data))
        .catch(error => reject(error));
    });
  }
}
