import Vue from 'vue';
import { LayerGroup } from 'leaflet';
import { BaseLayer } from './base-layer';
import { House } from '../api/house';
import { Store } from 'vuex';
import { HouseFilters, HouseItem, TrashAreaItem } from '@/types';
import HouseInfoPopup from '@/components/map/popups/house-info-popup.vue';
import router from '../../router';
import store from '../../store';
import TrashAreaPopup from '@/components/map/popups/trash-area-popup.vue';
import { TrashArea } from '@/services/api/trash-area';

const UNSELECTED_COLOR = 'black';

export class HouseLayer extends BaseLayer {
  municipalityId: number;
  layers: LayerGroup<L.GeoJSON<any>> = L.layerGroup();
  trashAreaLayers: LayerGroup<L.GeoJSON<any>> = L.layerGroup();
  houses: Array<HouseItem> = [];
  isInProcess: number | null = null;
  popups: Array<any> = [];
  filters: Partial<HouseFilters>= {};
  filtersUpdated: boolean = true;
  currentHouse: number = 0;

  trashAreas: Array<TrashAreaItem> = [];

  constructor({ store, map, type }: {
    store: Store<any>,
    map: L.Map,
    type: string,
  }) {
    super({ store, map, type });

    this.municipalityId = this._store.getters['municipalities/current'].id;
    this.layers.addTo(map);
    this.trashAreaLayers.addTo(map);

    this._store.subscribe(mutation => {
      if (mutation.type === 'house/setHighlight') {
        if (this.currentHouse) {
          this.showTrashArea(this.currentHouse);
        }
      }
    });
  }

  async reRender() {
    if (this._store.getters['municipalities/current'].id !== this.municipalityId) {
      this.filtersUpdated = true;
    }

    if (this.filtersUpdated) {
      this.municipalityId = this._store.getters['municipalities/current'].id;
      this.filters.municipalityId = this.municipalityId;
      this.isInProcess = this.municipalityId;

      const response = await House.getHouses(this.filters, false);
      this.houses = response.data?.houses;

      this.isInProcess = null;
      this.filtersUpdated = false;
    }

    this._render();
  }

  _filter(filters: HouseFilters) {
    this.filters = filters;
    this.filtersUpdated = true;
    this.reRender();
  }

  showTrashArea(houseId: number) {
    this.currentHouse = houseId;
    this._deleteTrashAreaLayers();

    const highlight = this._store.getters['house/highlight'];
    if (!highlight) {
      return;
    }

    const house = [...this.houses.filter(value => value.id === houseId)].pop();

    if (!house) {
      return;
    }

    const trashArea: TrashAreaItem = house.trash_areas[0];
    const layerOptions = {
      weight: 2,
      opacity: 1,
      color: 'red',
      fillColor: 'red',
    };
    const polygon = L.geoJSON(trashArea.border, {
      ...layerOptions,
    } as any);

    polygon.on('click', () => {
      this.trashAreaPopup(trashArea.id, polygon);
    });

    this.trashAreaLayers.addLayer(polygon);
  }

  showEmptyTrashAreaPopup(layer: any, house: HouseItem, popup: any) {
    layer.unbindPopup();
    layer.bindPopup('Нет связанных площадок ТКО');
    layer.openPopup();

    layer.bindPopup(popup.$el as HTMLElement, { });
  }

  gotoTrashArea(house: HouseItem) {
    if (house.trash_areas && house.trash_areas.length) {
      this._map.setView(L.GeoJSON.coordsToLatLng(house.trash_areas[0].point.coordinates), 18);
    }
  }

  showTrashAreaOnHouseClick(layer: any, house: HouseItem, popup: any) {
    layer.on('click', (e: L.LeafletMouseEvent) => {
      if (e.originalEvent.ctrlKey) {
        if (!house.trash_areas.length) {
          this.showEmptyTrashAreaPopup(layer, house, popup);
          return;
        }

        this._store.dispatch('house/showHighlight');
        this._store.dispatch('trash-area/hideHighlight');

        layer.closePopup();

        this.showTrashArea(house.id);
        this.gotoTrashArea(house);
      }
    });
  }

  async trashAreaPopup(id: number, polygon: any) {
    const found = this.trashAreas.filter(value => value.id === id);
    let trashArea: TrashAreaItem | null;

    if (found && found.length) {
      trashArea = found[0];
    } else {
      trashArea = await TrashArea.getSingleTrashArea(id);

      if (trashArea) {
        this.trashAreas.push(trashArea);
      }
    }

    if (trashArea) {
      const popup = new Vue({
        render: h => h(TrashAreaPopup, {
          props: {
            trashArea: trashArea,
            showHousesButton: false,
          },
        }),
        router,
        store,
      }).$mount();

      this.popups.push(popup);
      polygon.bindPopup(popup.$el as HTMLElement, { maxWidth: 500 });
      polygon.openPopup();
      polygon.off('click', () => { });
    }
  }

  _render() {
    this._deleteLayers();

    this.houses.forEach((house) => {
      let layer = null;
      const layerOptions = {
        weight: 2,
        opacity: 1,
        color: UNSELECTED_COLOR,
        dashArray: '3',
        fillOpacity: 0.3,
        fillColor: UNSELECTED_COLOR,
      };

      if (house.border) {
        layer = L.geoJSON(house.border, {
          ...layerOptions,
          id: house.id,
        } as any);
      } else {
        layer = L.geoJSON(house.point, {
          id: house.id,
          pointToLayer(feature:any, latlng:any) {
            return L.circle(latlng, {
              ...layerOptions,
            });
          },
        } as any);
      }

      layer.bindTooltip(house.address, {
        permanent: false,
      });

      const popup = new Vue({
        render: h => h(HouseInfoPopup, {
          props: {
            house: house,
          },
          on: {
            'show-trash-area': (id: number) => {
              this.showTrashArea(id);
              this.gotoTrashArea(house);
            },
          },
        }),
        router,
        store,
      }).$mount();

      this.popups.push(popup);
      layer.bindPopup(popup.$el as HTMLElement, { });

      this.showTrashAreaOnHouseClick(layer, house, popup);

      this.layers.addLayer(layer);
    });

    if (this.currentHouse) {
      this.showTrashArea(this.currentHouse);
    }

    setTimeout(() => {
      this.layers.eachLayer(layer => (layer as L.GeoJSON<any>).bringToFront());
    });
  }

  _deleteLayers() {
    this.layers.eachLayer(l => l.remove());
    this.layers.clearLayers();
    this._deleteTrashAreaLayers();

    this.popups.forEach(p => p.$destroy());
    this.popups = [];
  }

  _deleteTrashAreaLayers() {
    this.trashAreaLayers.eachLayer(l => l.remove());
    this.trashAreaLayers.clearLayers();
  }

  destroy() {
    this._deleteLayers();
  }
}
