import L from 'leaflet';
import { Road } from '../api/road';
import { BehaviorSubject } from 'rxjs';
import { BaseLayer } from './base-layer';
import roadTypes, { ROAD_TYPES_COLORS } from '@/constants/roadTypes';
import { IIdNameIndexed, IRoad, ILayerFilterType, ILayerFilterGroup, IMultiLineString, ITransportRoad } from '@/types';
import { Store } from 'vuex';
import { TransportRoad } from '../api/transport-road';
import moment from 'moment';
import '../offset';
import Vue from 'vue';
import TransportRoadPopup from '../../components/map/popups/transport-road-popup.vue';
import router from '../../router';

export class RoadLayer extends BaseLayer {
  _layers: Array<any> = [];
  _historyLayers: Array<any> = [];
  _legend: L.Control | null = null;

  _originRoads: any = [];
  _municipalityId: number | null = null;

  isSubLayerEnabled = false;
  lastFilter = '';

  roads: BehaviorSubject<any> = new BehaviorSubject([]);
  roadTypes: IIdNameIndexed = roadTypes;
  constructor({ store, map, type }: {store: Store<any>, map: L.Map, type: string }) {
    super({ store, map, type });

    this.filter = {
      date: [new Date(), new Date()],
      transporter: [],
      routeSubtype: [],
      ...this.filter,
    };
    this.roads.subscribe(() => this._render());
  }

  _deleteHistoryLayer() {
    this._historyLayers.forEach((layer: any) => layer.line.remove());
    this._historyLayers = [];

    if (this._legend) {
      this._legend.remove();
      this._legend = null;
    }
  }

  _deleteLayer() {
    if (this._layers !== []) {
      this._layers.forEach((layer: any) => layer.line.remove());
      this._layers = [];
    }
  }

  showMarker(road: IRoad) {
    this._layers.forEach((layer: any) => {
      if (layer.road.id === road.id) {
        layer.line.openPopup();
        this._map.fitBounds(layer.line.getBounds());
      }
    });
  }

  async reRender({ groups }: { groups: Array<ILayerFilterGroup> }) {
    const selectedGroups = groups.filter((group: ILayerFilterGroup) => group.types.filter((type: ILayerFilterType) => type.checked).length > 0);
    const types = selectedGroups
      .find((group: ILayerFilterGroup) => group.name === 'По типу')
      ?.types.filter((type: ILayerFilterType) => type.checked)
      .map((type: ILayerFilterType) => type.id).join(',');

    this.filter = {
      ...this.filter,
      types,
    };
    this.isSubLayerEnabled = !!selectedGroups
      .find((group: ILayerFilterGroup) => group.name === 'Подслой')
      ?.types.filter((type: ILayerFilterType) => type.checked && type.id === 0);

    const filter = JSON.stringify({
      municipalityId: this._store.getters['municipalities/current'].id,
      type: types,
    });

    if (!filter || this.lastFilter !== filter) {
      this._municipalityId = this._store.getters['municipalities/current'].id;

      this.lastFilter = filter;
      const response = await Road.getRoads(
        {
          all: true,
          filter: {
            municipalityId: this._municipalityId,
            type: this.filter.types,
          },
          include: 'roadOwner.municipality',
        },
      );
      this._originRoads = response.roads;
    }

    if (this.isSubLayerEnabled) {
      this.renderHistory();
    } else {
      this._deleteHistoryLayer();
    }

    this.roads.next(this._originRoads);
  }

  createLegend() {
    const legend = new L.Control({ position: 'bottomright' });
    legend.onAdd = function() {
      const div = L.DomUtil.create('div', 'road-layer-legend-wrapper');
      const categories = [
        {
          color: 'black',
          label: 'Дорожная сеть',
        },
        {
          color: 'green',
          label: 'Скорость до 30 км/ч',
        },
        {
          color: 'yellow',
          label: 'Скорость до 50 км/ч',
        },
        {
          color: 'orange',
          label: 'Скорость до 65 км/ч',
        },
        {
          color: 'red',
          label: 'Скорость более 65 км/ч',
        },
      ];

      div.innerHTML = categories.map(category => {
        return `<div class="road-layer-legend-item"><div class="road-layer-legend-line" style="background-color: ${category.color}"></div> ${category.label}</div>`;
      }).join('');

      return div;
    };

    this._legend = legend.addTo(this._map);
  }

  renderHistory() {
    this._deleteHistoryLayer();
    this.createLegend();

    TransportRoad.getAll({
      date: moment(this.filter.date[1]).format('YYYY-MM-DD'),
      date_from: moment(this.filter.date[0]).format('YYYY-MM-DD'),
      municipality_id: this._store.getters['municipalities/current'].id,
      route_subtype_id: this.filter.routeSubtype ? this.filter.routeSubtype.map((r: any) => r.value).join(',') : undefined,
      transporter_id: this.filter.transporter ? this.filter.transporter.map((t: any) => t.value).join(',') : undefined,
      road_type: this.filter.types,
    }).then((roads: Array<ITransportRoad>) => {
      roads.forEach(road => {
        if (road.line) {
          const color = road.avg_speed > 65 ? 'red'
            : road.avg_speed > 50 ? 'orange'
              : road.avg_speed > 30 ? 'yellow'
                : 'green';
          const offset = road.avg_speed > 65 ? -40
            : road.avg_speed > 50 ? -20
              : road.avg_speed > 30 ? 20
                : 0;
          const line = L.polyline(
            L.GeoJSON.coordsToLatLngs(road.line.coordinates, 0),
          {
            weight: 5,
            opacity: 1,
            color,
            dashArray: '3',
            fillOpacity: 0.3,
            fillColor: color,
            offset,
          } as any,
          );

          const popup = new Vue({
            render: h => h(TransportRoadPopup, {
              props: {
                road,
              },
            }),
            router,
          }).$mount();

          line.bindPopup(() => popup.$el as HTMLElement, {
            minWidth: 400,
          });
          line.addTo(this._map);

          this._historyLayers.push({
            line,
            road,
          });
        }
      });
    });
  }

  _render() {
    this._deleteLayer();
    this._layers = [];

    this.roads.getValue().forEach((road: IRoad) => {
      const type = Number(road.type) as 1 | 2 | 3 | 4;
      const line = L.geoJSON(<IMultiLineString>road.line, <L.GeoJSONOptions>{
        weight: 5,
        opacity: 1,
        color: !this.isSubLayerEnabled ? ROAD_TYPES_COLORS[type] : '#000',
        dashArray: '3',
        fillOpacity: 0.3,
        fillColor: !this.isSubLayerEnabled ? ROAD_TYPES_COLORS[type] : '#000',
      });

      line.bindPopup(`<p><strong>Название дороги:</strong> ${road.name}</p>` +
        `<p><strong>Тип:</strong> ${this.roadTypes[road.type].name}</p>` +
        `<p><strong>Владелец:</strong> ${road.road_owner ? road.road_owner.name : ''}</p>` +
        `<p><strong>Организатор деятельности:</strong> ${road.road_owner && road.road_owner.municipality ? road.road_owner.municipality.name : ''}</p>` +
        `<p><strong>Длина:</strong> ${road.length} м</p>`,
      );

      line.addTo(this._map);

      this._layers.push({
        line,
        road,
      });
    });
  }

  destroy() {
    this._deleteLayer();
    this._originRoads = [];
    this.lastFilter = '';
    this._deleteHistoryLayer();
  }
}
