import L from 'leaflet';
import { BehaviorSubject } from 'rxjs';
import { BaseLayer } from '@/services/layers/base-layer';
import { LayersService } from '@/services/layers-service';
import { TrafficLight } from '@/services/api/traffic-light';

export class TrafficLightLayer extends BaseLayer {
  _layers = null;

  _originTrafficLights = [];
  trafficLights = new BehaviorSubject([]);

  filter = {
    municipalityId: null,
  };

  markers = new BehaviorSubject([]);

  markersLoaded = [];
  markersHard = [];

  markersHardGroup = null;
  markersGroup = null;

  _clusterBreakPoint = 16;

  constructor({ store, map, type }) {
    super({ store, map, type });
    this.createGroups();

    this.trafficLights.subscribe(() => this._render());

    document.addEventListener('click', this.trafficLightPopupHandler.bind(this));

    this._store.subscribe(mutation => {
      if (mutation.type === 'map/setGroupControlDisabled') {
        this.markersGroup?.clearLayers();
        this.markersGroup?.remove();
        this.markersGroup = null;
        this.markersHardGroup?.clearLayers();
        this.markersHardGroup?.remove();
        this.markersHardGroup = null;

        this.createGroups();
      }
    });
  }

  createGroups() {
    this.markersGroup = new L.MarkerClusterGroup({
      disableClusteringAtZoom: !this._store.getters['map/isGroupControlDisabled'] ? this._clusterBreakPoint : 0,
      iconCreateFunction: function(cluster) {
        const markers = cluster.getAllChildMarkers();
        return L.divIcon({
          html: `
          <div class="marker-icon text-center"
            style="
            height: 40px;
            width: 40px;
            position: relative;
            padding: 20px 10px;
            background-repeat: no-repeat;
            background-size: contain;
            border-color: red;
            background-image: url(assets/icons/traffic-light.svg);">
              <div style="
              width: 28px;
              height: 28px;
              position: absolute;
              top: 6px;
              left: 4px;
              line-height: 28px;
              text-align: center;
              font-size: 12px;
              color: #ffffff;
              border-radius: 50%;
              background: rgba(0, 74, 42, 0.7)">${markers.length}</div>
          </div>`,
          className: '',
          iconSize: L.point(40, 40),
        });
      },
      spiderfyOnMaxZoom: false,
    });
    this.markersHardGroup = new L.MarkerClusterGroup({
      disableClusteringAtZoom: !this._store.getters['map/isGroupControlDisabled'] ? this._clusterBreakPoint : 0,
      iconCreateFunction: function(cluster) {
        const markers = cluster.getAllChildMarkers();
        return L.divIcon({
          html: `
          <div class="marker-icon text-center"
            style="
            height: 40px;
            width: 40px;
            position: relative;
            padding: 20px 10px;
            background-repeat: no-repeat;
            background-size: contain;
            border-color: blue;
            background-image: url(assets/icons/traffic-light.svg);">
              <div style="
              width: 28px;
              height: 28px;
              position: absolute;
              top: 6px;
              left: 4px;
              line-height: 28px;
              text-align: center;
              font-size: 12px;
              color: #ffffff;
              border-radius: 50%;
              background: rgba(0, 74, 42, 0.7)">${markers.length}</div>
          </div>`,
          className: '',
          iconSize: L.point(40, 40),
        });
      },
      spiderfyOnMaxZoom: false,
    });

    this.markersGroup.addLayers(this.markersLoaded);
    this.markersHardGroup.addLayers(this.markersHard);

    this._map.addLayer(this.markersGroup);
    this._map.addLayer(this.markersHardGroup);
  }

  trafficLightPopupHandler(e) {
    const target = e.target;
    // Show route
    if (target.classList.contains('popup-bus-route-name')) {
      e.preventDefault();
      e.stopPropagation();
      const routeId = Number(target.getAttribute('data-id'));

      if (!routeId) {
        return;
      }

      this._store.dispatch('transports/openTransportLayer', routeId)
        .then(() => {
          return this._store.dispatch('transports/showRoute', routeId);
        })
        .then((route) => {
          LayersService.instance._transportLayers.drawRoute(route);
        });
    }
  }

  _deleteLayer() {
    this.markersGroup?.clearLayers();
    this.markersHardGroup?.clearLayers();
    if (this._layers !== null) {
      this._layers.forEach(layer => layer.clearLayers());
      this._layers = null;
    }
  }

  showMarker(trafficLight) {
    this._layers.forEach(layer => {
      if (layer.options.id === trafficLight.id) {
        layer.openPopup();
        const latlng = layer.getLatLng();
        this._map.setView([latlng.lat, latlng.lng], 18);
      }
    });
  }

  async reRender() {
    if (this._originTrafficLights.length === 0 || this.filter._municipalityId !== this._store.getters['municipalities/current'].id) {
      if (this._store.getters['municipalities/current'].id === 0 || this._store.getters['municipalities/current'].super) {
        this.filter = {
          ...this.filter,
          municipalityId: null,
        };
      } else {
        this.filter = {
          ...this.filter,
          municipalityId: this._store.getters['municipalities/current'].id,
        };
      }
    }

    this.markers.next([
      { id: 1, name: 'Димитровградское шоссе', latitude: '54.336297', longitude: '48.532336', speed: '38' },
      { id: 2, name: 'Димитровградское шоссе', latitude: '54.336813', longitude: '48.536480', speed: '36' },
      { id: 3, name: 'Димитровградское шоссе', latitude: '54.337116', longitude: '48.541808', speed: '40' },
      { id: 4, name: 'Димитровградское шоссе', latitude: '54.337763', longitude: '48.549677', speed: '42' },
      { id: 5, name: 'Димитровградское шоссе', latitude: '54.337895', longitude: '48.558113', speed: '41' },
    ]);

    const response = await TrafficLight.getTrafficLights({
      all: true,
      municipalityId: this.filter.municipalityId,
    });
    this._originTrafficLights = response.traffic_lights.slice(0, 6000);

    this.applyFilter(this._filter);
  }

  applyFilter() {
    let filtered = this._originTrafficLights;

    this.trafficLights.next(filtered);
  }

  _render() {
    this._deleteLayer();
    let myRenderer = L.canvas({ padding: 0.5 });
    this._layers = [];
    this.markersHard = [];
    this.markersLoaded = [];
    this.trafficLights.getValue().forEach(trafficLight => {
      const marker = L.marker(trafficLight.point.coordinates.reverse(), {
        icon: L.divIcon({
          html: `<span class="marker-icon text-center" style="border-color: red"><img class="invert" src="/assets/icons/traffic-light.svg" style="height: 100%"></span>`,
          iconSize: [25, 25],
          className: '',
        }),
        renderer: myRenderer,
        id: trafficLight.id,
      });
      this.markersGroup.addLayer(marker);
      this.markersLoaded.push(marker);
    });
    this.markers.getValue().forEach(elm => {
      const marker = L.marker([elm.latitude, elm.longitude], {
        icon: L.divIcon({
          html: '<span class="marker-icon text-center" style="border-color: blue"><img class="invert" src="/assets/icons/traffic-light.svg" style="height: 100%"></span>',
          iconSize: [25, 25],
          className: '',
        }),
        renderer: myRenderer,
        id: elm.id,
      });

      marker.bindPopup(
        '<p class=\'traffic-light\'><strong>Состояние: </strong><span class=\'green-circle\'></span> Активен</p>' +
        '<p class=\'traffic-light\'><strong>Средняя скорость: </strong><i class=\'fas fa-tachometer-alt\'></i> ' + elm.speed + 'км/ч</p>',
      );

      this.markersHardGroup.addLayer(marker);
      this.markersHard.push(marker);
    });

    this._layers.push(this.markersGroup);
    this._layers.push(this.markersHardGroup);
  }

  destroy() {
    this._deleteLayer();
    this.markersHard = [];
    this.markersLoaded = [];
  }
}
