import Vue from 'vue';
import VueRouter from 'vue-router';
import UserService from '@/services/user-service';
import { LayersService } from '@/services/layers-service';
import KommunTransportation from './routes/kommun-transportation';
import RoadFacilitiesReports from './routes/road-facilities-reports';
import TrashAreaReports from './routes/trash-area-reports';

import { isMobile } from 'mobile-device-detect';
import store from '@/store';
import { PROJECT_TITLE } from '@/constants/Global';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'main-map' },
  },
  {
    path: '/transporters/:id',
    name: 'report.transporters',
    component: () => import(/* webpackChunkName: "report.transporters" */ '@/views/report/transporters'),
    props: (route) => ({ routeGroupId: route.params.id }),
  },
  {
    path: '/map',
    name: 'main-map',
    component: () => import(/* webpackChunkName: "main-map" */ '@/views/map/main-map'),
    props: (route) => ({ analysis: undefined }),
  },
  {
    path: '/map/:type',
    name: 'main-map-typed',
    component: () => import(/* webpackChunkName: "main-map-typed" */ '@/views/map/main-map'),
    props: (route) => ({ typeGroup: route.params.type }),
  },
  {
    path: '/map/analysis/:type',
    name: 'main-map-analysis-typed',
    component: () => import(/* webpackChunkName: "main-map-typed" */ '@/views/map/main-map'),
    props: (route) => ({ analysis: route.params.type }),
  },
  {
    path: '/map/video/:id',
    name: 'video-watcher',
    component: () => import(/* webpackChunkName: "video-watcher" */ '@/views/map/video-watcher'),
    props: (route) => ({
      cameras: route.params.cameras,
      id: route.params.id,
      channelId: route.params.channelId,
      layout: route.params.layout,
    }),
  },
  {
    path: '/map/video-list/:id',
    name: 'video-list',
    component: () => import(/* webpackChunkName: "video-list" */ '@/views/map/video-list'),
    props: (route) => ({
      cameras: route.params.cameras,
      id: route.params.id,
      layoutProp: route.params.layout,
    }),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login.vue'),
  },
  {
    path: '/rnis-admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin.vue'),
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import(/* webpackChunkName: "chat" */ '@/components/app/header/chat/blank.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes.concat(KommunTransportation, RoadFacilitiesReports, TrashAreaReports),
});
router.beforeEach((to, from, next) => {
  if (from.name === 'main-map-analysis-typed' && to.name !== 'main-map-analysis-typed' && !to.query['from-analysis']) {
    const unsaved = LayersService.instance._analysisLayers ? LayersService.instance._analysisLayers.unsaved : false;
    const query = { 'from-analysis': true };
    const toWithQuery = {
      name: to.name,
      query: {
        ...to.query,
        ...query,
      },
    };
    if (unsaved && !window.confirm('У вас имеются несохранённые изменения, вы уверены, что хотите продолжить?')) {
      next(false);
    } else {
      next(toWithQuery);
    }
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  document.title = to.meta && to.meta.title ? to.meta.title : PROJECT_TITLE;

  if (UserService.instance.isAuth) {
    next();
  } else {
    if (to.name !== 'login') {
      return next({ name: 'login' });
    } else {
      next();
    }
  }
});

router.afterEach(() => {
  if (isMobile) {
    store.dispatch('closeMenu');
  }
});

export default router;
