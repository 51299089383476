import { ChatState, RootState } from '@/types';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

const state: ChatState = {
  open: false,
  unreadCounter: 0,
};
const getters: GetterTree<ChatState, RootState> = {
  open: state => state.open,
  unreadCounter: state => state.unreadCounter,
};
const mutations: MutationTree<ChatState> = {
  toggleOpen: (state, payload: boolean) => {
    state.open = payload;
  },
  setUnreadCounter: (state, payload: number) => {
    state.unreadCounter = payload <= 0 ? 0 : payload;
  },
};
const actions: ActionTree<ChatState, RootState> = {
  openChat({ commit }) {
    return commit('toggleOpen', true);
  },
  closeChat({ commit }) {
    return commit('toggleOpen', false);
  },
  toggleChat({ dispatch, getters }) {
    return getters.open ? dispatch('closeChat') : dispatch('openChat');
  },
  setUnreadCounter({ commit }, payload: number) {
    commit('setUnreadCounter', payload);
  },
  incrementUnreadCounter({ commit, getters }, payload: number) {
    commit('setUnreadCounter', getters.unreadCounter + payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
