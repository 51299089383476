



























import { computed, defineComponent, Ref } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { LayersService } from '../../../services/layers-service';
import { IAnalysisMunicipality, IAnalysisRoute } from '../../../types';
import ColorPicker from '../../shared/color-picker.vue';

const analysisHelper = createNamespacedHelpers('layers/analysis');

export default defineComponent({
  name: 'LocalityAnalysisPopup',
  components: {
    ColorPicker,
  },
  props: {
    localityId: {
      type: Number,
      required: true,
    },
    municipalityId: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { municipalities }: {
      municipalities: Ref<Array<IAnalysisMunicipality> | null>,
    } = analysisHelper.useGetters(['municipalities']) as any;

    const colors = computed(() => {
      const colors: {
        [key: string]: string,
      } = {
        ...LayersService.instance._routeLayers._colorBuffer,
      };
      LayersService.instance._routeLayers.crossShowRoutes
        .forEach(({ routes, municipalityId }: { routes: Array<any>, municipalityId: number }) => {
          routes.forEach((route: any) => {
            if (!colors[route.id]) {
              colors[route.id] = (LayersService.instance._routeLayers.crossShowColors as any)[municipalityId] as string;
            }
          });
        });
      LayersService.instance._routeLayers.routes.getValue()
        .forEach((route: any) => {
          route.route_groups.forEach((routeGroup: any) => {
            if (!colors[route.id]) {
              colors[route.id] = routeGroup.color ? routeGroup.color : '#000000';
            }
          });
        });

      return colors;
    });

    const routes = computed(() => {
      const answer: Array<IAnalysisRoute & { color: string }> = [];

      if (municipalities.value) {
        const m = municipalities.value.find(m => m.id === Number(props.municipalityId));
        if (m) {
          const l = m.localities.find(l => l.id === Number(props.localityId));
          if (l) {
            l.routes.forEach(r => {
              answer.push({
                ...r,
                color: colors.value[r.id] ?? '#000',
              });
            });
          }
        }
      }

      return answer;
    });

    return {
      routes,
      changeColor(c: {
        color: string,
        id: number,
      }) {
        emit('change', c);
      },
    };
  },
});
