<template>
  <div class="detectors">
    <div class="detector" v-for="(repairRoad, index) in repairRoads" :key="index" @click="showRepairRoad(repairRoad)">
      <div class="detector-address">
        {{ repairRoad.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { LayersService } from '@/services/layers-service';

export default {
  name: 'repair-road-layer-control',
  data: () => ({
    repairRoads: [],
  }),
  methods: {
    showRepairRoad(repairRoad) {
      LayersService.instance
        ._repairRoadLayers
        .showMarker(repairRoad);
    },
  },
  created() {
    LayersService.instance
      ._repairRoadLayers
      .repairRoads
      .subscribe(repairRoads => { this.repairRoads = repairRoads; });
  },
};
</script>

<style lang="scss" scoped>
  .detectors {
    .detector {
      cursor: pointer;
      margin-bottom: 16px;

      &-address {
        font-size: 16px;
      }
    }
  }
</style>
