































































































































































































import { computed, defineComponent, nextTick, onBeforeUnmount, onMounted, reactive, Ref, ref, watch } from '@vue/composition-api';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import { MultiListSelect, BasicSelect } from 'vue-search-select';
import { WEEKDAYS } from '../../../constants/Global';
import { IAnalysisAverageFilter, IAnalysisAverageForm1ResponseItem, IAnalysisAverageForm2ResponseItem, IAnalysisAverageRouteType, IRoute, IRouteGroup, IRouteType, ITransportType } from '../../../types';
import { LayersService } from '../../../services/layers-service';
import { MapService } from '../../../services/map-service';
import L from 'leaflet';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { PASSENGER_TYPE_GROUP } from '../../../constants/RouteTypeGroups';

const layerHelper = createNamespacedHelpers('layer');
const analysisHelper = createNamespacedHelpers('layers/analysisAverage');
const routeTypeHelper = createNamespacedHelpers('route-type');
const municipalitiesHelpers = createNamespacedHelpers('municipalities');

export default defineComponent({
  name: 'analysis-average-layer-control',
  components: {
    DatePicker,
    MultiListSelect,
    BasicSelect,
  },
  setup(props, { root }) {
    const averageDays: Ref<{
      $el: HTMLElement,
    } | null> = ref(null);
    const averageHours: Ref<{
      $el: HTMLElement,
    } | null> = ref(null);
    const generalDays: Ref<{
      $el: HTMLElement,
    } | null> = ref(null);
    const generalHours: Ref<{
      $el: HTMLElement,
    } | null> = ref(null);
    const { current } = municipalitiesHelpers.useGetters(['current']);
    const { routeTypes } = routeTypeHelper.useGetters(['routeTypes']);
    const PPRouteTypesArray: Ref<Array<IRouteType>> = computed(() => {
      return routeTypes.value
        .filter((r: any) => r.type_group === PASSENGER_TYPE_GROUP);
    });
    const PPRouteTypes: Ref<Array<string>> = computed(() => {
      return PPRouteTypesArray.value
        .map((r: any) => r.id);
    });

    const {
      loadRoutes,
      loadRouteGroups,
      loadForm1,
      selectForm1,
      clearSelectForm1,
      clearSelectForm2,
      clearForm1,
      clearForm2,
      loadForm2,
      selectForm2,
    }: {
      loadRoutes: ({ municipalityId, routeTypes }: { municipalityId: number, routeTypes: Array<string>}) => void,
      loadRouteGroups: (payload: number) => void,
      loadForm1: (payload: {
        point1: {
          lat: number, lng: number,
        },
        point2: {
          lat: number, lng: number,
          },
        timeFrom: string,
        timeTo: string,
        days: Array<string>,
        hours: Array<string>,
        routeTypeIds: Array<string>,
      }) => Promise<void>,
      selectForm1: (payload: IAnalysisAverageForm1ResponseItem) => void,
      selectForm2: (payload: null | 0 | string) => void,
      clearSelectForm1: () => void,
      clearSelectForm2: () => void,
      clearForm1: () => void,
      clearForm2: () => void,
      loadForm2: (payload: {
        timeFrom: string,
        timeTo: string,
        days: Array<string>,
        hours: Array<string>,
        hourGroup: number,
        routeId?: number,
        routeGroupId?: number,
      }) => Promise<void>,
    } = analysisHelper.useActions(['loadRoutes', 'loadRouteGroups', 'loadForm1', 'selectForm1', 'clearSelectForm1', 'clearForm1', 'loadForm2', 'selectForm2', 'clearForm2', 'clearSelectForm2']) as any;
    const {
      routes,
      routeGroups,
      reportsForm1,
      selectedForm1,
      groupedForm1,
      reportsForm2,
      selectedForm2Hour,
    } : {
      transportTypes: Ref<Array<ITransportType>>,
      routes: Ref<Array<IRoute>>,
      routeGroups: Ref<Array<IRouteGroup>>,
      reportsForm1: Ref<Array<IAnalysisAverageForm1ResponseItem>>,
      selectedForm1: Ref<IAnalysisAverageForm1ResponseItem | null>,
      groupedForm1: Ref<{ [key: number]: IAnalysisAverageRouteType }>,
      reportsForm2: Ref<Array<IAnalysisAverageForm2ResponseItem>>,
      selectedForm2Hour: Ref<string | 0 | null>,
    } = analysisHelper.useGetters(['transportTypes', 'routes', 'routeGroups', 'reportsForm1', 'selectedForm1', 'groupedForm1', 'reportsForm2', 'selectedForm2Hour']) as any;
    const { disableLayers, openLayers }: {
      disableLayers: (payload: { layers: Array<string>, toggle: boolean }) => void,
      openLayers: (payload: Array<string>) => void,
    } = layerHelper.useActions(['disableLayers', 'openLayers']) as any;
    const tabs = reactive({
      general: true,
      route: false,
    });
    const filter: Ref<IAnalysisAverageFilter> = ref({
      average: {
        dates: [],
        days: [],
        hours: [],
        pointStart: null,
        pointFinish: null,
        type: [],
      },
      general: {
        dates: [],
        days: [],
        hours: [],
        hourGroup: {
          id: 1,
          text: '1',
        },
        route: {
          id: 0,
          text: 'Маршруты',
        },
        routeGroup: {
          id: 0,
          text: 'Направления',
        },
      },
    });
    const weekdays = computed(() => {
      return Object.entries(WEEKDAYS).map(([id, name]) => {
        return {
          id,
          name,
        };
      });
    });

    const hourGroups = computed(() => {
      return [
        {
          id: 0,
          text: 'Группировка по количеству часов',
        },
        ...[...Array(12).keys()].map(hour => ({
          id: hour + 1,
          text: hour + 1,
        })),
      ];
    });

    const hours = computed(() => {
      return [...Array(24).keys()].map(hour => ({
        id: hour,
        name: `${hour < 10 ? '0' : ''}${hour}:00`,
      }));
    });

    const transportTypesList = computed(() => {
      return PPRouteTypesArray.value.map(({ id, name }) => ({
        id,
        name,
      }));
    });

    const routesList = computed(() => {
      return [
        {
          id: 0,
          text: 'Маршруты',
        },
        ...routes.value.map(({ name, id }) => ({
          id,
          text: name,
        })),
      ];
    });

    const routeGroupsList = computed(() => {
      return [
        {
          id: 0,
          text: 'Направления',
        },
        ...routeGroups.value.map(({ name, id }) => ({
          id,
          text: name,
        })),
      ];
    });

    const isRouteGroupsDisabled = computed(() => {
      return filter.value.general.route.id === 0;
    });

    const form1Rows = computed(() => {
      const f = LayersService.instance._analysisAverageLayers.filter;
      const rows = reportsForm1.value.map(f => moment(f.hour).locale('ru').format('DD MMMM HH:00'));

      if (!isGroupedForm1.value) {
        return rows;
      }
      const groupedRow = `${moment(f.average.dates[0]).locale('ru').format('DD MMMM HH:00')} — ${moment(f.average.dates[1]).locale('ru').format('DD MMMM HH:00')}`;

      return [
        groupedRow,
        ...rows,
      ];
    });

    const isGroupedForm1 = computed(() => {
      return canApplyFilterForm1.value && reportsForm1.value.length >= 2 && groupedForm1.value;
    });

    const canApplyFilterForm1 = computed(() => {
      return filter.value.average.dates.length === 2;
    });

    const canApplyFilterForm2 = computed(() => {
      return filter.value.general.dates.length === 2 &&
        (filter.value.general.route.id !== 0 || filter.value.general.routeGroup.id !== 0) &&
        filter.value.general.hourGroup.id !== 0;
    });

    const selectedIndexForm1 = computed({
      get: () => {
        return LayersService.instance._analysisAverageLayers.selectedIndexForm1;
      },
      set: (val: number) => {
        LayersService.instance._analysisAverageLayers.selectedIndexForm1 = val;
      },
    });

    const form2Rows = computed(() => {
      if (reportsForm2.value.length === 0) {
        return {};
      }

      const f = LayersService.instance._analysisAverageLayers.filter;

      const rows = reportsForm2.value.reduce((acc, cur) => {
        cur.rows.forEach(r => {
          if (r.hour) {
            if (!acc[r.hour]) {
              const hour = moment(r.hour).locale('ru').format('DD MMMM HH:00') as string;
              acc[r.hour] = hour;
            }
          }
        });

        return acc;
      }, {} as { [key: string]: string });

      const groupedRow = `${moment(f.general.dates[0]).locale('ru').format('DD MMMM HH:00')} — ${moment(f.general.dates[1]).locale('ru').format('DD MMMM HH:00')}`;

      if (Object.keys(rows).length > 1) {
        return {
          0: groupedRow,
          ...rows,
        };
      } else {
        return rows;
      }
    });

    onMounted(() => {
      loadRoutes({
        municipalityId: current.value.id,
        routeTypes: PPRouteTypes.value,
      });

      filter.value = {
        average: {
          ...filter.value.average,
          ...LayersService.instance._analysisAverageLayers.filter.average,
        },
        general: {
          ...filter.value.general,
          ...LayersService.instance._analysisAverageLayers.filter.general,
        },
      };

      if (filter.value.average.dates.length > 0) {
        filter.value.average.dates = [
          new Date(filter.value.average.dates[0]),
          new Date(filter.value.average.dates[1]),
        ];
      }

      if (filter.value.general.dates.length > 0) {
        filter.value.general.dates = [
          new Date(filter.value.general.dates[0]),
          new Date(filter.value.general.dates[1]),
        ];
      }

      loadRouteGroups(filter.value.general.route.id);
    });

    watch(current, () => {
      loadRoutes({
        municipalityId: current.value.id,
        routeTypes: PPRouteTypes.value,
      });

      filter.value.general.route = routesList.value[0];
      filter.value.general.routeGroup = routeGroupsList.value[0];
      loadRouteGroups(0);
    });

    const map = MapService.instance.map;
    const fromMarker: Ref<L.Marker<any> | null> = ref(LayersService.instance._analysisAverageLayers.pointStart) as Ref<L.Marker<any>>;
    const isFromDrawing = ref(false);
    const toMarker: Ref<L.Marker<any> | null> = ref(LayersService.instance._analysisAverageLayers.pointFinish) as Ref<L.Marker<any>>;
    const isToDrawing = ref(false);
    const fromMarkerOptions = {
      icon: L.icon({
        iconUrl: '/assets/icons/marker-from-icon.png',
        iconSize: L.point(25, 41),
        iconAnchor: L.point(12.5, 40),
        className: 'analysis-marker-icon',
      }),
      draggable: true,
    };
    const toMarkerOptions = {
      icon: L.icon({
        iconUrl: '/assets/icons/marker-to-icon.png',
        iconSize: L.point(25, 41),
        iconAnchor: L.point(12.5, 40),
        className: 'analysis-marker-icon',
      }),
      draggable: true,
    };
    const onDrawingEnd = () => {
      if (toMarker.value) {
        LayersService.instance._analysisAverageLayers.pointFinish = toMarker.value as L.Marker<any>;
      }
      if (fromMarker.value) {
        LayersService.instance._analysisAverageLayers.pointStart = fromMarker.value as L.Marker<any>;
      }
      if (toMarker.value && fromMarker.value) {
        applyFilterAverage();
      }

      isFromDrawing.value = false;
      isToDrawing.value = false;
    };
    const onDrawingCancel = () => {
      if (toMarker.value && isToDrawing.value) {
        toMarker.value.off('dragend', drawHandler);
        toMarker.value.remove();
        toMarker.value = null;
      }

      if (fromMarker.value && isFromDrawing.value) {
        fromMarker.value.off('dragend', drawHandler);
        fromMarker.value.remove();
        fromMarker.value = null;
      }

      isFromDrawing.value = false;
      isToDrawing.value = false;
    };

    const resetMarkers = (openRoute = true) => {
      map.editTools.stopDrawing();
      const reset = !!(toMarker.value || fromMarker.value);

      if (toMarker.value) {
        toMarker.value.off('dragend', drawHandler);
        toMarker.value.remove();
        toMarker.value = null;
        LayersService.instance._analysisAverageLayers.filter.average.pointFinish = null;
        LayersService.instance._analysisAverageLayers.pointFinish = null;
      }

      if (fromMarker.value) {
        fromMarker.value.off('dragend', drawHandler);
        fromMarker.value.remove();
        fromMarker.value = null;
        LayersService.instance._analysisAverageLayers.filter.average.pointStart = null;
        LayersService.instance._analysisAverageLayers.pointStart = null;
      }

      if (reset && openRoute) {
        clearForm1();
        clearSelectForm1();
        openLayers(['route']);
      }
    };

    const drawHandler = onDrawingEnd.bind(this);
    const drawCancelHandler = onDrawingCancel.bind(this);

    map.on('editable:drawing:commit', drawHandler);
    map.on('editable:drawing:cancel', drawCancelHandler);

    onBeforeUnmount(() => {
      if (drawHandler) {
        map.off('editable:drawing:commit', drawHandler);
      }
      if (drawCancelHandler) {
        map.off('editable:drawing:cancel', drawCancelHandler);
      }
    });

    const applyFilterGeneral = () => {
      LayersService.instance._analysisAverageLayers.applyFilter(filter.value);
      const f = LayersService.instance._analysisAverageLayers.filter;

      resetMarkers(false);
      clearForm1();
      clearSelectForm1();
      clearForm2();
      clearSelectForm2();

      if (
        (f.general.route.id || f.general.routeGroup.id) &&
        f.general.dates && f.general.dates.length === 2 &&
        f.general.hourGroup.id !== 0
      ) {
        loadForm2({
          timeFrom: moment(f.general.dates[0]).format('YYYY-MM-DD HH:mm:ss'),
          timeTo: moment(f.general.dates[1]).format('YYYY-MM-DD HH:mm:ss'),
          days: f.general.days.map((d: any) => d.id),
          hours: f.general.hours.map((h: any) => h.id),
          hourGroup: f.general.hourGroup.id,
          routeId: f.general.route.id !== 0 ? f.general.route.id : undefined,
          routeGroupId: f.general.routeGroup.id !== 0 ? f.general.routeGroup.id : undefined,
        })
          .then(() => {
            disableLayers({
              layers: ['route'],
              toggle: true,
            });

            selectForm2(Object.keys(form2Rows.value)[0]);
          });
      }
    };

    const applyFilterAverage = () => {
      LayersService.instance._analysisAverageLayers.applyFilter(filter.value);
      const f = LayersService.instance._analysisAverageLayers.filter;

      clearForm1();
      clearSelectForm1();
      clearForm2();
      clearSelectForm2();

      if (
        LayersService.instance._analysisAverageLayers.pointStart &&
        LayersService.instance._analysisAverageLayers.pointFinish &&
        f.average.dates && f.average.dates.length === 2
      ) {
        loadForm1({
          point1: LayersService.instance._analysisAverageLayers.pointStart.getLatLng(),
          point2: LayersService.instance._analysisAverageLayers.pointFinish.getLatLng(),
          timeFrom: moment(f.average.dates[0]).format('YYYY-MM-DD HH:mm:ss'),
          timeTo: moment(f.average.dates[1]).format('YYYY-MM-DD HH:mm:ss'),
          days: f.average.days.map((d: any) => d.id),
          hours: f.average.hours.map((h: any) => h.id),
          routeTypeIds: f.average.type.map((d: any) => d.id),
        })
          .then(() => {
            disableLayers({
              layers: ['route'],
              toggle: true,
            });
            if (selectedIndexForm1.value <= 0 || form1Rows.value.length < selectedIndexForm1.value) {
              selectedIndexForm1.value = isGroupedForm1.value ? -1 : 0;
            }

            selectForm1Wrapper(selectedIndexForm1.value);
          });
      }
    };

    const selectForm1Wrapper = (index: number) => {
      const f = LayersService.instance._analysisAverageLayers.filter;
      const row = index === -1 && isGroupedForm1.value ? {
        hour: `${moment(f.average.dates[0]).locale('ru').format('DD MMMM HH:00')} — ${moment(f.average.dates[1]).locale('ru').format('DD MMMM HH:00')}`,
        route_types: groupedForm1.value,
        isGrouped: true,
      } : reportsForm1.value[index];
      if (!row) {
        return;
      }

      selectedIndexForm1.value = index;

      if (
        LayersService.instance._analysisAverageLayers.pointStart &&
          LayersService.instance._analysisAverageLayers.pointFinish &&
          f.average.dates && f.average.dates.length === 2
      ) {
        selectForm1(row);
      }
    };

    return {
      tabs,
      filter,
      weekdays,
      hours,
      hourGroups,
      transportTypesList,
      routesList,
      routeGroupsList,
      resetMarkers,
      fromMarker,
      toMarker,
      isRouteGroupsDisabled,
      averageDays,
      averageHours,
      generalDays,
      generalHours,
      reportsForm1,
      form1Rows,
      selectedForm1,
      canApplyFilterForm1,
      canApplyFilterForm2,
      selectForm1: selectForm1Wrapper,
      isGroupedForm1,
      reportsForm2,
      form2Rows,
      selectedForm2Hour,
      disabledAfter(date1: Date, dates: Array<Date>) {
        const newDate = moment(date1);
        const maxDate = moment(new Date());
        let answer = false;

        if (dates.length > 1) {
          const date1 = moment(dates[1]).add(7, 'days');
          const date2 = moment(dates[0]).add(-7, 'days');
          answer = answer || !newDate.isSameOrBefore(date1) || !newDate.isSameOrAfter(date2);
        } else if (dates.length === 1) {
          const date1 = moment(dates[0]).add(7, 'days');
          const date2 = moment(dates[0]).add(-7, 'days');
          answer = answer || !newDate.isSameOrBefore(date1) || !newDate.isSameOrAfter(date2);
        }

        return !newDate.isSameOrBefore(maxDate) || answer;
      },
      selectAverageWeekdays(weekdays: Array<{
        id: number,
        text: string,
      }>) {
        if (averageDays.value) {
          averageDays.value.$el.click();
        }
        filter.value.average.days = [...weekdays];
        LayersService.instance._analysisAverageLayers.applyFilter(filter.value);
      },
      selectAverageHours(hours: Array<{
        id: number,
        text: string,
      }>) {
        if (averageHours.value) {
          averageHours.value.$el.click();
        }
        filter.value.average.hours = [...hours];
        LayersService.instance._analysisAverageLayers.applyFilter(filter.value);
      },
      selectGeneralWeekdays(weekdays: Array<{
        id: number,
        text: string,
      }>) {
        if (generalDays.value) {
          generalDays.value.$el.click();
        }
        filter.value.general.days = [...weekdays];
      },
      selectGeneralHours(hours: Array<{
        id: number,
        text: string,
      }>) {
        if (generalHours.value) {
          generalHours.value.$el.click();
        }
        filter.value.general.hours = [...hours];
      },
      selectAverageTransportTypes(type: Array<string>) {
        filter.value.average.type = [...type];
        LayersService.instance._analysisAverageLayers.applyFilter(filter.value);
      },
      selectGeneralRoutes(route: { id: number, text: string }) {
        filter.value.general.route = { ...route };
        filter.value.general.routeGroup = routeGroupsList.value[0];

        loadRouteGroups(route.id);
      },
      selectGeneralRouteGroups(routeGroup: { id: number, text: string }) {
        filter.value.general.routeGroup = { ...routeGroup };
      },
      selectGeneralHourGroup(hourGroup: { id: number, text: string }) {
        filter.value.general.hourGroup = { ...hourGroup };
      },
      applyFilterAverage,
      applyFilterGeneral,
      changeFrom() {
        map.editTools.stopDrawing();
        if (fromMarker.value) {
          fromMarker.value.off('dragend', drawHandler);
          fromMarker.value.remove();
        }

        fromMarker.value = map.editTools.startMarker(undefined, fromMarkerOptions);
        fromMarker.value?.on('dragend', drawHandler);
        isFromDrawing.value = true;
      },
      changeTo() {
        map.editTools.stopDrawing();
        if (toMarker.value) {
          toMarker.value.off('dragend', drawHandler);
          toMarker.value.remove();
        }

        toMarker.value = map.editTools.startMarker(undefined, toMarkerOptions);
        toMarker.value?.on('dragend', drawHandler);
        isToDrawing.value = true;
      },
      exchangeMarkers() {
        map.editTools.stopDrawing();

        nextTick(() => {
          if (toMarker.value && !fromMarker.value) {
            fromMarker.value = L.marker(toMarker.value.getLatLng(), fromMarkerOptions as any).addTo(map);

            toMarker.value.off('dragend', drawHandler);
            toMarker.value.remove();
            toMarker.value = null;
          } else if (fromMarker.value && !toMarker.value) {
            toMarker.value = L.marker(fromMarker.value.getLatLng(), toMarkerOptions as any).addTo(map);

            fromMarker.value.off('dragend', drawHandler);
            fromMarker.value.remove();
            fromMarker.value = null;
          } else if (fromMarker.value && toMarker.value) {
            const [ from, to ] = [
              fromMarker.value.getLatLng(),
              toMarker.value.getLatLng(),
            ];

            toMarker.value.off('dragend', drawHandler);
            fromMarker.value.off('dragend', drawHandler);
            toMarker.value.remove();
            fromMarker.value.remove();

            toMarker.value = L.marker(from, toMarkerOptions as any).addTo(map);
            toMarker.value.on('dragend', drawHandler);
            fromMarker.value = L.marker(to, fromMarkerOptions as any).addTo(map);
            fromMarker.value.on('dragend', drawHandler);

            onDrawingEnd();
          }
        });
      },
      selectForm2(row: string | 0) {
        selectForm2(row);
      },
    };
  },
});
