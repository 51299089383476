import { RouteType } from '@/services/api/route-type';
import { PASSENGER_TYPE_GROUP, ROAD_FACILITIES_TYPE_GROUP, KOMMUN_TYPE_GROUP, ROUTE_TAXI_TYPE_GROUP, FIRST_AID_TYPE_GROUP, TAXI_TYPE_GROUP, MCHS_TYPE_GROUP } from '@/constants/RouteTypeGroups';

export default {
  namespaced: true,
  state: {
    routeTypes: [],
    selectedRouteTypes: [],
  },
  getters: {
    routeTypes: state => state.routeTypes,
    selectedRouteTypes: state => state.selectedRouteTypes,
    menu: state => {
      const defaultValue = {
        [PASSENGER_TYPE_GROUP]: {},
        [ROAD_FACILITIES_TYPE_GROUP]: {},
        [KOMMUN_TYPE_GROUP]: {},
        [ROUTE_TAXI_TYPE_GROUP]: {},
        [FIRST_AID_TYPE_GROUP]: {},
        [TAXI_TYPE_GROUP]: {},
        [MCHS_TYPE_GROUP]: {},
      };

      return state.routeTypes.reduce((acc, current) => {
        if (!acc[current.type_group]) {
          acc[current.type_group] = {
            [current.menu_path]: [current.id],
          };
        } else if (acc[current.type_group] && acc[current.type_group][current.menu_path]) {
          acc[current.type_group][current.menu_path].push(current.id);
        } else if (acc[current.type_group] && !acc[current.type_group][current.menu_path]) {
          acc[current.type_group][current.menu_path] = [current.id];
        }

        return acc;
      }, defaultValue);
    },
    groups: state => {
      const defaultValue = {
        [PASSENGER_TYPE_GROUP]: [],
        [ROAD_FACILITIES_TYPE_GROUP]: [],
        [KOMMUN_TYPE_GROUP]: [],
        [ROUTE_TAXI_TYPE_GROUP]: [],
        [FIRST_AID_TYPE_GROUP]: [],
        [TAXI_TYPE_GROUP]: [],
        [MCHS_TYPE_GROUP]: [],
      };

      return state.routeTypes.reduce((acc, current) => {
        if (!acc[current.type_group]) {
          acc[current.type_group] = [current.id];
        } else {
          acc[current.type_group].push(current.id);
        }

        return acc;
      }, defaultValue);
    },
  },
  mutations: {
    routeType: (state, payload) => { state.routeTypes = payload; },
    selectedRouteTypes: (state, payload) => { state.selectedRouteTypes = payload; },
  },
  actions: {
    loadRouteTypes(state, { all = false, include = [] }) {
      return new Promise((resolve, reject) => {
        RouteType.loadRouteTypes({ all, include })
          .then(response => {
            state.commit('routeType', response.route_types);
            state.commit('selectedRouteTypes', response.route_types);
            resolve(response.route_types);
          })
          .catch(e => reject(e));
      });
    },
  },
};
