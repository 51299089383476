import UserService from '@/services/user-service';

export default {
  data: () => ({
    isAuth: false,
  }),
  methods: {
    isAuthChange() {
      this.isAuth = UserService.instance.isAuth;
    },
    subscribeAuth() {
      UserService.instance
        .subscribe('login', this.isAuthChange.bind(this))
        .subscribe('logout', this.isAuthChange.bind(this));
    },
  },
  beforeMount() {
    this.isAuthChange();
    this.subscribeAuth();
  },
};
