import L from 'leaflet';
import { BehaviorSubject } from 'rxjs';
import { BaseLayer } from './base-layer';
import { Parking } from '../api/parking';

export class ParkingLayer extends BaseLayer {
  _layers = null;

  _parkings = [];
  _municipalityId = null;

  parkingList = new BehaviorSubject([]);

  constructor({ store, map, type }) {
    super({ store, map, type });

    this.parkingList.subscribe(() => this._render());
  }

  showMarker(parking) {
    this._layers.forEach(layer => {
      if (layer.parking.id === parking.id) {
        this._map.setView([parking.geometry.coordinates[0][0][0][0], parking.geometry.coordinates[0][0][0][1]], 17);
        layer.geometry.openPopup();
      }
    });
  }

  async reRender() {
    if (this._parkings.length === 0 || this._municipalityId !== this._store.getters['municipalities/current'].id) {
      if (this._store.getters['municipalities/current'].position >= 10) {
        this.filter = {
          ...this.filter,
          municipalityId: this._store.getters['municipalities/current'].id,
        };
      } else {
        this.filter = {
          ...this.filter,
          municipalityId: null,
        };
      }
      const response = await Parking.getParking({ all: true, municipalityId: this.filter.municipalityId });
      this._parkings = response.parkings;
    }

    this.parkingList.next(this._parkings);
  }

  _render() {
    this._deleteLayer();
    if (this._layers === null) {
      this._layers = [];
    }

    this.parkingList.getValue().forEach(parking => {
      let color;

      if (parking.free_places !== null && +parking.capacity > 0) {
        const percents = (parking.free_places / parking.capacity) * 100;

        if (percents > 75) {
          color = 'green';
        } else if (percents > 50) {
          color = 'yellow';
        } else if (percents > 25) {
          color = 'orange';
        } else {
          color = 'red';
        }
      } else {
        if (parking.occupied_places === parking.capacity) {
          color = 'red';
        } else {
          color = 'green';
        }
      }

      const geometry = L.geoJSON(parking.geometry, {
        weight: 5,
        opacity: 1,
        color,
        dashArray: '3',
        fillOpacity: 0.3,
        fillColor: color,
        parking,
      })
        .bindPopup(
          `<p><strong>Адрес:</strong> ${parking.address}</p>` +
          `<p><strong>Вместимость:</strong> ${parking.capacity || 0}</p>` +
          `<p><strong>Количество свободных мест на сегодня:</strong> ${parking.free_places || 0}</p>`,
        );

      geometry.addTo(this._map);

      this._layers.push({
        geometry,
        parking,
      });
    });
  }

  _deleteLayer() {
    if (this._layers !== null) {
      this._layers.forEach(layer => layer.geometry.remove());
      this._layers = null;
    }
  }

  destroy() {
    this._deleteLayer();
  }
}
