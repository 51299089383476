import { BusStop } from '@/services/api/bus-stop';

export default {
  state: {
    busStops: [],
  },
  getters: {
    busStops: state => state.busStops,
  },
  mutations: {
    busStops: (state, payload) => { state.busStops = payload; },
  },
  actions: {
    loadBusStops(state, { all }) {
      return new Promise((resolve, reject) => {
        BusStop.getBusStops({ all })
          .then((response) => {
            state.commit('busStops', response.bus_stops);
            resolve(response);
          })
          .catch(reject);
      });
    },
  },
};
