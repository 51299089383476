/* eslint-disable camelcase */
import axios from '@/services/axios';
import { IAnalysisReportResponse, IRouteAnalysis } from '@/types';
import { AxiosResponse } from 'axios';

export class RouteAnalysis {
  static deleteAnalysis({ id }: {
    id: number,
  }): Promise<any> {
    return axios.delete(`/route-analysis/${id}`);
  }

  static saveAnalysis({ name, data }: {
    name: string,
    data: any,
  }) {
    const params = {
      name,
      data,
    };

    return axios.post('/route-analysis', params)
      .then((response: AxiosResponse<{
        route_analysis: IRouteAnalysis
      }>) => {
        return response.data.route_analysis;
      });
  }

  static updateAnalysis({ name, data, id }: {
    name: string,
    data: any,
    id: number,
  }) {
    const params = {
      name,
      data,
    };

    return axios.put(`/route-analysis/${id}`, params)
      .then((response: AxiosResponse<{
        route_analysis: IRouteAnalysis
      }>) => {
        return response.data.route_analysis;
      });
  }

  static getList() {
    return axios.get('/route-analysis?all')
      .then((response: AxiosResponse<{
        route_analyzes: Array<IRouteAnalysis>,
      }>) => {
        return response.data.route_analyzes;
      });
  }

  static getReport({
    municipality_id,
    locality_id,
  }: {
      municipality_id?: Array<number>,
      locality_id?: Array<number>,
  }) {
    const params: {
      [key: string]: string,
    } = {};

    if (municipality_id) {
      params.municipality_id = municipality_id.join(',');
    }
    if (locality_id) {
      params.locality_id = locality_id.join(',');
    }

    return axios.get('/route-analysis/form1-export', {
      responseType: 'blob',
      params,
    })
      .then(({ data }) => ({
        file: data,
      }));
  }

  static getReport2({
    municipality_id,
    locality_id,
  }: {
      municipality_id?: Array<number>,
      locality_id?: Array<number>,
  }) {
    const params: {
      [key: string]: string,
    } = {};

    if (municipality_id) {
      params.municipality_id = municipality_id.join(',');
    }
    if (locality_id) {
      params.locality_id = locality_id.join(',');
    }

    return axios.get('/route-analysis/form2-export', {
      responseType: 'blob',
      params,
    })
      .then(({ data }) => ({
        file: data,
      }));
  }

  static getData({
    municipality_id,
    locality_id,
    isBackgroundLoading,
  }: {
      municipality_id?: Array<number>,
      locality_id?: Array<number>,
      isBackgroundLoading: boolean,
  }) {
    const params: {
      [key: string]: string,
    } = {};

    if (Array.isArray(municipality_id) && municipality_id.length > 0) {
      params.municipality_id = municipality_id.join(',');
    }
    if (Array.isArray(locality_id) && locality_id.length > 0) {
      params.locality_id = locality_id.join(',');
    }

    return axios.get('/route-analysis/form1', {
      params,
      isBackgroundLoading,
    } as any)
      .then(({ data }: AxiosResponse<IAnalysisReportResponse>) => {
        return data;
      });
  }
}
