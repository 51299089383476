import { IRouteAnalysis } from '@/types';
import { Store } from 'vuex';
import { LayersService } from '../layers-service';
import { BaseLayer } from './base-layer';

export enum MODES {
  EMPTY,
  NEW,
  LOADED,
};

export class AnalysisLayer extends BaseLayer {
  layers = L.layerGroup();
  last: any = null;
  lastOptions: any = null;
  handler: null | Function = null;
  ctrlHandler: null | Function = null;
  shiftHandler: null | Function = null;
  vertexHandler: null | Function = null;
  mode: MODES = MODES.EMPTY;
  color = '#000';
  type: '0' | '1' = '0';
  lastId: number | null = null;
  currentAnalysis: {
    value: number,
    text: string,
  } = {
    value: 0,
    text: '',
  };
  removeMode = false;
  unsaved = false;
  isLoading = false;

  constructor({ store, map, type }: {
    store: Store<any>,
    map: L.Map,
    type: string,
  }) {
    super({ store, map, type });

    this._store.subscribe((mutation: {
      type: string,
      payload: unknown,
    }) => {
      if (this._store.getters['map/layers'].indexOf('analysis') === -1) {
        return;
      }

      if (!this.isLoading && (mutation.type === 'municipalities/current' || mutation.type === 'layers/analysis/enableCrossShow' || mutation.type === 'layers/analysis/setCrossShow' || mutation.type === 'layers/analysis/updateReport')) {
        this.prepareReport(true);
      }
    });

    this.layers.addTo(this._map);
  }

  async reRender() {
    if (!this.handler) {
      const handler = (e: any) => {
        this.commit();
      };
      this.handler = handler.bind(this);
      (this._map as any).on('editable:drawing:end', this.handler);
    }

    if (!this.ctrlHandler) {
      const handler = (e: any) => {
        e.layer.on('click', L.DomEvent.stop).on('click', () => {
          if (this.removeMode && e.layer.editEnabled && e.layer.editEnabled()) {
            this.layers.removeLayer(e.layer);
            this.unsaved = true;
          }
        });
      };
      this.ctrlHandler = handler.bind(this);
      (this._map as any).on('layeradd', this.ctrlHandler);
    }

    if (!this.shiftHandler) {
      const handler = (e: any) => {
        e.vertex.continue();
        this.unsaved = true;
      };
      this.shiftHandler = handler.bind(this);
      (this._map as any).on('editable:vertex:shiftclick', this.shiftHandler);
    }

    if (!this.vertexHandler) {
      const handler = (e: any) => {
        this.unsaved = true;
      };
      this.vertexHandler = handler.bind(this);
      (this._map as any).on('editable:vertex:drag', this.vertexHandler);
    }
  }

  prepareReport(isBackgroundLoading: boolean) {
    const { municipalityIds, localityIds } = this.getReportParams();

    this._store.dispatch('layers/analysis/loadReport', {
      municipality_id: municipalityIds,
      locality_id: localityIds,
      isBackgroundLoading,
    });
  }

  getReportParams() {
    const municipalityIds = [];
    const isSomethingSelected = LayersService.instance._localityLayers.selectedLayers.length > 0;
    const isSomethingFiltered = LayersService.instance._localityLayers.filter.ids.length > 0;
    const localityIds = isSomethingSelected ? LayersService.instance._localityLayers.selectedLayers.map((l: any) => l.options.id)
      : isSomethingFiltered ? LayersService.instance._localityLayers.filteredLocalities.map((l: any) => l.id)
        : [];

    if (this._store.getters['municipalities/current'].id) {
      municipalityIds.push(this._store.getters['municipalities/current'].id);
    }
    if (this._store.getters['layers/analysis/crossShow'].length > 0) {
      municipalityIds.push(...this._store.getters['layers/analysis/crossShow'].map((i: any) => i.id));
    }

    return {
      municipalityIds,
      localityIds,
    };
  }

  commit() {
    if (this.last) {
      if (this.lastOptions.type === 'line') {
        const latLngs = this.last.getLatLngs();
        this.last.remove();
        this.last = null;

        const layer = L.polyline(latLngs, {
          color: this.color,
          dashArray: this.type === '0' ? '' : '20',
        });
        this.layers.addLayer(layer);
        this.lastId = this.layers.getLayerId(layer);
        (layer as any).enableEdit(this._map);
      } else if (this.lastOptions.type === 'circle') {
        const latLng = this.last.getLatLng();
        const radius = this.last.getRadius();
        this.last.remove();
        this.last = null;

        const layer = L.circle(latLng, {
          radius,
          color: this.color,
          dashArray: this.type === '0' ? '' : '20',
        });
        this.layers.addLayer(layer);
        this.lastId = this.layers.getLayerId(layer);
        (layer as any).enableEdit(this._map);
      }
    }

    this.unsaved = true;
  }

  drawLine() {
    this._map.editTools.stopDrawing();
    const line = this._map.editTools.startPolyline(undefined, {
      color: this.color,
      dashArray: this.type === '0' ? '' : '20',
      weight: 5,
    });
    this.lastOptions = {
      type: 'line',
    };
    this.last = line;
  }

  drawCircle() {
    this._map.editTools.stopDrawing();
    const circle = this._map.editTools.startCircle(undefined, {
      color: this.color,
      dashArray: this.type === '0' ? '' : '20',
      weight: 5,
    });
    this.lastOptions = {
      type: 'circle',
    };
    this.last = circle;
  }

  stopDrawing() {
    if (this.last) {
      this.last.remove();
      this.last = null;
    }

    if (this.lastId) {
      this.layers.removeLayer(this.lastId);
      this.lastId = null;
    }
  }

  load(analysis: IRouteAnalysis) {
    this.isLoading = true;
    const data: Array<{
      type: string,
      [key: string]: any,
    }> = JSON.parse(analysis.data);

    data.forEach(feature => {
      if (feature.type === 'circle' && feature.latLng) {
        const layer = L.circle(feature.latLng, {
          radius: feature.radius ?? 5,
          color: feature.color,
          dashArray: feature.dashArray,
        });
        this.layers.addLayer(layer);
        (layer as any).enableEdit(this._map);
      } else if (feature.type === 'polyline') {
        const layer = L.polyline(feature.latLngs, {
          color: feature.color,
          dashArray: feature.dashArray,
        } as any);
        this.layers.addLayer(layer);
        (layer as any).enableEdit(this._map);
      } else if (feature.type === 'crossShow') {
        this._store.dispatch('layers/analysis/enableCrossShow', {
          id: feature.id,
          color: feature.color,
          text: feature.text,
        });
      } else if (feature.type === 'position') {
        this._store.dispatch('map/setZoom', feature.zoom);
        this._store.dispatch('map/setLatLng', feature.center);
        this._map.setView(L.latLng(feature.center), feature.zoom);
      } else if (feature.type === 'localities_selected') {
        window.setTimeout(() => {
          LayersService.instance._localityLayers.selectLayers(feature.value);
        }, 2000);
      } else if (feature.type === 'localities') {
        window.setTimeout(() => {
          LayersService.instance._localityLayers.filterLayers(feature.value);
        }, 2000);
      } else if (feature.type === 'colorBuffer') {
        LayersService.instance._routeLayers._colorBuffer = { ...feature.value };
      }
    });

    window.setTimeout(() => {
      const municipality = data.find(feature => feature.type === 'municipality');

      if (municipality) {
        localStorage.setItem('currentMunicipality', municipality.id.toString());
        this._store.dispatch('municipalities/setCurrentMunicipality', municipality.id);
      }
      this.isLoading = false;
      this.prepareReport(true);
    });

    window.setTimeout(() => {
      const layers = data.find(feature => feature.type === 'layers');

      if (layers) {
        this._store.dispatch('layer/updateLayers', layers.value);
        this._store.dispatch('map/setFilters', layers.filter);
        LayersService.instance.render(layers.value);
      } else {
        LayersService.instance.render(LayersService.instance._layersParams);
      }
    });
    this.unsaved = false;
  }

  clear(toggleUnsave: boolean = true) {
    this._map.editTools.stopDrawing();

    window.setTimeout(() => {
      this.layers.eachLayer(l => l.remove());
      this.layers.clearLayers();
      if (toggleUnsave) {
        this.unsaved = true;
      }
    });
  }

  toggleEditable(toggle: boolean) {
    this.layers.eachLayer((l: any) => toggle ? l.enableEdit() : l.disableEdit());
  }

  destroy() {
    if (this.handler) {
      (this._map as any).off('editable:drawing:end', this.handler);
      this.handler = null;
    }

    if (this.ctrlHandler) {
      (this._map as any).off('editable:vertex:ctrlclick editable:vertex:metakeyclick', this.ctrlHandler);
      this.ctrlHandler = null;
    }

    if (this.shiftHandler) {
      (this._map as any).off('editable:vertex:shiftclick', this.shiftHandler);
      this.shiftHandler = null;
    }

    if (this.vertexHandler) {
      (this._map as any).off('editable:vertex:drag', this.vertexHandler);
      this.vertexHandler = null;
    }

    this.color = '#000';
    this.type = '0';
    this.mode = MODES.EMPTY;
    this.currentAnalysis = {
      value: 0,
      text: '',
    };

    this._store.dispatch('layers/analysis/setShowReport', false);
    this._store.dispatch('layers/analysis/clearReport');

    LayersService.instance._routeLayers.toggleRouteNumber(false);
    this._store.dispatch('layers/analysis/setCrossShow', []);
    LayersService.instance._localityLayers.filter = {
      ...LayersService.instance._localityLayers.filter,
      ids: [],
    };
    this.unsaved = false;

    this.clear(false);
  }

  getSavingData() {
    const data: Array<{
      type: string,
      [key: string]: any,
    }> = [];

    data.push({
      type: 'municipality',
      id: this._store.getters['municipalities/current'].id,
    });
    if (this._store.getters['layers/analysis/crossShow'].length > 0) {
      data.push(...this._store.getters['layers/analysis/crossShow'].map(({ id, color, text }: { id: number, color: string, text: string }) => ({
        type: 'crossShow',
        id,
        color,
        text,
      })));
    }
    data.push({
      type: 'position',
      zoom: this._store.getters['map/zoom'],
      center: this._store.getters['map/latlngs'],
    });
    data.push({
      type: 'localities',
      value: LayersService.instance._localityLayers.filteredLocalities.map((l: any) => l.id),
    });
    data.push({
      type: 'localities_selected',
      value: LayersService.instance._localityLayers.selectedLayers.map((l: any) => l.options.id),
    });
    data.push({
      type: 'colorBuffer',
      value: LayersService.instance._routeLayers._colorBuffer,
    });
    data.push({
      type: 'layers',
      value: this._store.getters['map/layerParams'],
      filter: this._store.getters['map/filters'],
    });

    return data;
  };

  get drawingType(): null | 'circle' | 'line' {
    return this.lastOptions ? this.lastOptions.type : null;
  }

  get isDrawing(): boolean {
    return !!this.last;
  }
}
