import axios from 'axios';
import { DailyMtsReport } from '@/services/api/report/daily-mts-report';

export default {
  namespaced: true,
  state: {
    data: {},
    routes: [],
    mtsList: [],
  },
  getters: {
    data: state => state.data,
    routes: state => state.routes,
    mtsList: state => state.mtsList,
  },
  mutations: {
    resetData: (state, payload) => { state.data = {}; },
    setData: (state, payload) => { payload ? state.data = payload.data : state.data = {}; },
    setRoutes: (state, payload) => {
      state.routes = [];
      state.routes = payload.map(route => {
        if (route.mts.route) {
          return route.mts.route;
        }
      }).filter(obj => {
        if (obj) {
          return obj.id in state.routes ? 0 : (state.routes[obj.id] = 1);
        }
      }).sort((a, b) => a.name - b.name);
    },
    setMtsList: (state, payload) => { state.mtsList = payload; },
  },
  actions: {
    loadData(state, { mtsId, date, direction = 1 }) {
      state.data = {};
      return new Promise((resolve, reject) => {
        DailyMtsReport.loadData({ mtsId, date, direction })
          .then((response) => {
            state.commit('setData', response);
            resolve(response);
          })
          .catch(e => reject(e));
      });
    },

    loadRoutes(state, { date }) {
      axios.manualLoading = true;

      return new Promise((resolve, reject) => {
        axios.get(`/mts/route-on-day/?page_size=999&date=${date}`, {
          headers: {
            Authorization: 'Bearer exzIk5c3oCvzuG4UwhvxGffCVKm6OxYH',
          },
        })
          .then((data) => {
            axios.manualLoading = false;
            state.commit('setRoutes', data.results);
            resolve(data);
          })
          .catch(e => reject(e));
      });
    },
    loadMtsList(state, { routeId }) {
      return new Promise((resolve, reject) => {
        axios.get(`/mts/mts/?page_size=500&route__in=${routeId}`, {
          headers: {
            Authorization: 'Bearer exzIk5c3oCvzuG4UwhvxGffCVKm6OxYH',
          },
        })
          .then(({ data }) => {
            state.commit('setMtsList', data.results);
            resolve(data);
          })
          .catch(e => reject(e));
      });
    },
  },
};
