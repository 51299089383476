import L from 'leaflet';
import { ParkingFine } from '../api/parkingFine';
import { BehaviorSubject } from 'rxjs';
import { BaseLayer } from './base-layer';

export class ParkingFineLayer extends BaseLayer {
  _layers = L.layerGroup();
  _outZoomedLayers = L.layerGroup();

  _zoomBreakPoint = 13;
  _isOutZoomed = false;
  _zoomHandler = null;

  _originParkingFine = [];
  _municipalityId = null;

  parkingList = new BehaviorSubject([]);

  constructor({ store, map, type }) {
    super({ store, map, type });

    if (this._map.getZoom() >= this._zoomBreakPoint) {
      this._isOutZoomed = false;
      this._layers.addTo(map);
    } else {
      this._isOutZoomed = true;
      this._outZoomedLayers.addTo(map);
    }

    this._zoomHandler = this.updateLayers.bind(this);
    map.on('zoomend', this._zoomHandler);

    this.parkingList.subscribe(() => this._render());
  }

  updateLayers() {
    const zoom = this._map.getZoom();
    if (zoom >= this._zoomBreakPoint && this._isOutZoomed) {
      this._outZoomedLayers.removeFrom(this._map);
      this._layers.addTo(map);
      this._isOutZoomed = false;
    } else if (zoom < this._zoomBreakPoint && !this._isOutZoomed) {
      this._layers.removeFrom(this._map);
      this._outZoomedLayers.addTo(map);
      this._isOutZoomed = true;
    }

    this.updateCircles();
  }

  showMarker(parking) {
    this._layers.eachLayer(layer => {
      if (layer.options.parking.id === parking.id) {
        this._map.setView([parking.polygon.coordinates[0][0][0][1], parking.polygon.coordinates[0][0][0][0]], 17);
        layer.openPopup();
      }
    });
  }

  async reRender() {
    if (this._originParkingFine.length === 0 || this._municipalityId !== this._store.getters['municipalities/current'].id) {
      if (this._store.getters['municipalities/current'].position >= 10) {
        this.filter = {
          ...this.filter,
          municipalityId: this._store.getters['municipalities/current'].id,
        };
      } else {
        this.filter = {
          ...this.filter,
          municipalityId: null,
        };
      }
      const response = await ParkingFine.getParkingFine({ all: true, municipalityId: this.filter.municipalityId });
      this._originParkingFine = response.impounds;
    }

    this.parkingList.next(this._originParkingFine);
  }

  _render() {
    this._deleteLayer();

    this.parkingList.getValue().forEach(parking => {
      let color;

      if (parking.free_impound_today !== null && +parking.capacity > 0) {
        const percents = (parking.free_impound_today.count / parking.capacity) * 100;

        if (percents > 75) {
          color = 'green';
        } else if (percents > 50) {
          color = 'yellow';
        } else if (percents > 25) {
          color = 'orange';
        } else {
          color = 'red';
        }
      } else {
        if (parking.free_impound_today === null) {
          color = 'red';
        } else {
          color = 'green';
        }
      }

      const popupContent = `<p><strong>Наименование организации:</strong> ${parking.company_name}</p>` +
        `<p><strong>ФИО руководителя:</strong> ${parking.director_name}</p>` +
        `<p><strong>Круглосуточный телефон:</strong> ${parking.phone}</p>` +
        `<p><strong>Адрес местонахождения специализированной стоянки:</strong> ${parking.address}</p>` +
        `<p><strong>Кадастровый номер:</strong> ${parking.cadastral_number}</p>` +
        `<p><strong>Вместимость:</strong> ${parking.capacity || 0}</p>` +
        `<p><strong>Количество свободных мест на сегодня:</strong> ${parking.free_impound_today ? parking.free_impound_today.count : 0}</p>`;

      const polygon = L.geoJSON(parking.polygon, {
        weight: 5,
        opacity: 1,
        color,
        dashArray: '3',
        fillOpacity: 0.3,
        fillColor: color,
        parking,
      })
        .bindPopup(popupContent);

      const circle = L.circle([parking.polygon.coordinates[0][0][0][1], parking.polygon.coordinates[0][0][0][0]], {
        color,
        fillColor: color,
        fillOpacity: 0.3,
        radius: this.radius,
      })
        .bindPopup(popupContent);

      this._layers.addLayer(polygon);
      this._outZoomedLayers.addLayer(circle);
    });
  }

  updateCircles() {
    if (this._isOutZoomed) {
      this._outZoomedLayers.eachLayer(layer => {
        layer.setRadius(this.radius);
      });
    }
  }

  _deleteLayer() {
    this._layers.eachLayer(layer => {
      layer.remove();
    });
    this._outZoomedLayers.eachLayer(layer => {
      layer.remove();
    });
    this._layers.clearLayers();
    this._outZoomedLayers.clearLayers();
  }

  destroy() {
    this._deleteLayer();

    if (this._zoomHandler) {
      this._map.off('zoomend', this._zoomHandler);
    }
  }

  get radius() {
    return 6500 - 500 * this._map.getZoom();
  }
}
