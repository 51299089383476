import { BaseLayer } from './base-layer';
import L, { GeoJSON, GeoJSONOptions } from 'leaflet';
import { MapService } from '@/services/map-service';
import { LayersService } from '@/services/layers-service';
import moment from 'moment';
import router from '../../router';
import axios, { CancelTokenSource } from 'axios';
import { app } from '@/main';
import { API_QUERY_DATETIME_TZ_FORMAT } from '@/constants/Global';
import { ACL } from '@/modules/acl/acl-service';
import { IHistoryTransportLayer, IHistoryTransportResponseSegmentItem, ILineString, ITransport } from '@/types';
import { Store } from 'vuex';
import { Subscription } from 'rxjs';
import { Transport } from '@/services/layers/transport';

export class HistoryTransportLayer extends BaseLayer {
  _layers: Array<IHistoryTransportLayer> = [];
  transports: Array<ITransport> = [];
  readonly dateFormat = API_QUERY_DATETIME_TZ_FORMAT;
  #dateFrom = moment(new Date()).startOf('day').format(this.dateFormat);
  #dateTo: string = moment(new Date()).endOf('day').format(this.dateFormat);
  #filterSubscription: Subscription | null = null;
  #storeSubscription: { () : void } | null = null;
  #layersService: Transport | null = null;
  #axiosSource: CancelTokenSource | null= null;
  #realtimeLoadInterval: number = 30; // Интервал загрузки линий машин в секундах (частота запросов)
  #popupTransportId: null | number = null;
  #transportTrackerTimes: Record<string, moment.Moment> = {};
  #onMessageHanlderBinded: null | ((event: MessageEvent) => void) = null;
  constructor({ store, map, type }: {
    store: Store<any>,
    map: L.Map,
    type: string,
  }) {
    super({ store, map, type });
  }

  highlightRoute(lines: Array<L.Polyline | GeoJSON>) {
    lines.forEach((line: any) => {
      line.bringToFront();
      line.setStyle({
        weight: 10,
        opacity: 1,
        color: '#00a707',
        fillColor: '00a707',
      });
    });
  }

  resetLayers(transports:Array<ITransport> = []) {
    if (transports.length) {
      const ids = transports.map(transport => transport.id);
      this._layers.filter((layer: IHistoryTransportLayer) => {
        return !ids.includes(layer.transportId);
      }).forEach((layer: IHistoryTransportLayer) => {
        layer.line.remove();
      });
      this._layers = this._layers.filter((layer: IHistoryTransportLayer) => {
        return ids.includes(layer.transportId);
      });
      return;
    }
    this.#axiosSource?.cancel();
    this.#axiosSource = axios.CancelToken.source();
    this.deleteLayers();
  }

  deleteLayers() {
    if (this._layers.length) {
      this._layers.forEach((layer: any) => layer.line.remove());
      this._layers = [];
    }
  }

  static isRangeThreeMonths(date: string) {
    const dateToCheck = moment(date);
    const startDate = moment().subtract(3, 'months');
    const endDate = moment();
    return dateToCheck.isSameOrAfter(startDate) && dateToCheck.isSameOrBefore(endDate);
  }

  toDetail({ id, number } : {id : number, number: string}) {
    const routeData = router.resolve({
      name: 'kommun_transportation.history-detail',
      params: {
        number,
      },
      query: {
        date: moment(new Date(this.#dateFrom)).format('YYYY-MM-DD'),
        transportId: id.toString(),
        number,
        fromHistoryTransport: '1',
      } });
    return routeData.href;
  }

  activeLayer(id: number) {
    if (this._layers) {
      const layer = this._layers.find((layer: IHistoryTransportLayer) => layer.transportId === id);
      return !!layer;
    }
    return false;
  }

  removeRoute(id: number) {
    this.setTransports(this.transports.filter(transport => transport.id !== id));
    const layers = this._layers.filter((layer: IHistoryTransportLayer) => layer.transportId === id);
    layers.forEach((layer: any) => {
      layer.line.remove();
    });
    this._layers = this._layers.filter((_layer: IHistoryTransportLayer) => _layer.transportId !== id);

    if (Array.isArray(this._layers) && this._layers.length === 0) {
      this._layers = [];
    }

    app.$emit('route-removed', id);
  }
  setDateFrom(dateFrom: string) {
    this.#dateFrom = dateFrom;
  }
  setDateTo(dateTo: string) {
    this.#dateTo = dateTo;
  }
  bindPopup(transport: ITransport, line: any, color: string) {
    let detailBtnHtml = '';
    if (ACL.can('history.index')) {
      detailBtnHtml = `
        <a
            style="color: #fff;"
            href="${this.toDetail({ id: transport.id, number: transport.number })}" target="_blank">
                <button class="btn btn-primary detail-link">Подробнее</button>
        </a>`;
    }
    let popupContent = `
ГРЗ: ${transport.number} <br>
<button class="btn btn-error detail-remove">Скрыть</button>
${detailBtnHtml}
            `;
    line.bindPopup(popupContent).on('popupopen', () => {
      this.#popupTransportId = transport.id;
      setTimeout(() => {
        document.querySelector('.detail-remove')?.addEventListener('click', () => {
          this.removeRoute(transport.id);
        });
      }, 50);
    });

    line.on('popupclose', () => {
      this.#popupTransportId = null;

      this._layers.filter((layer: IHistoryTransportLayer) => layer.transportId === transport.id).map((l: IHistoryTransportLayer) => l.line).forEach((l) => {
        l.setStyle({
          weight: 5,
          opacity: 1,
          color: `#${color}`,
          fillColor: `#${color}`,
        });
      });
    });
  }

  drawLine(transportList: Array<ITransport>, dateFrom: string, dateTo: string | null) {
    for (let ts of transportList) {
      this._store.dispatch('history/getTransportsLine', {
        transportId: ts.id,
        dateFrom: moment(dateFrom, this.dateFormat).format(this.dateFormat),
        dateTo: dateTo ? moment(dateTo, this.dateFormat).format(this.dateFormat) : null,
        cancelToken: this.#axiosSource?.token,
      })
        .then((response: IHistoryTransportResponseSegmentItem) => {
          response.lines?.forEach((element) => {
            let line = this.createLine(element, response);

            if (!line.getBounds().isValid()) {
              return;
            }

            if (ts.id === this.#popupTransportId) {
              this.highlightRoute([line]);
            }

            this.bindPopup(ts, line, response.color);
            line.addTo(MapService.instance.map);
            line.on('click', () => {
              const lines = this._layers.filter((layer: IHistoryTransportLayer) => layer.transportId === ts.id).map((l: IHistoryTransportLayer) => l.line);
              lines.push(line);
              this.highlightRoute(lines);
            });
            this._layers.push({
              line,
              transportId: ts.id,
            });
          });
        })
        .catch();
    }
  }

  createLine(element: ILineString, response: IHistoryTransportResponseSegmentItem) {
    return L.geoJSON(element, {
      weight: 5,
      opacity: 1,
      color: `#${response.color}`,
      dashArray: '3',
      fillOpacity: 0.3,
      fillColor: `#${response.color}`,
    } as GeoJSONOptions) as any;
  }

  onDestroy() {
    this.stopRealtimeLoadLines();
    this.resetLayers();
    this.#filterSubscription?.unsubscribe();
    this.#filterSubscription = null;

    if (typeof this.#storeSubscription === 'function') {
      this.#storeSubscription();
      this.#storeSubscription = null;
    }

    this.#popupTransportId = null;
    this.#transportTrackerTimes = {};
  }

  updateTransportList() {
    this.setTransports(Object.values(this.#layersService?.activeTransport.getValue() || {}));
  }

  setTransports(transports: Array<ITransport>) {
    this.transports = transports.slice();
  }

  filterSubscriptionHandler() {
    this.updateTransportList();
    this.resetLayers(this.transports);
    this.drawLine(this.transports, this.#dateFrom, String(moment(new Date()).format(this.dateFormat)));
    app.$emit('filter-changed');
  }
  socketMessageData(messageEvent: MessageEvent) {
    const msgEvent = JSON.parse(messageEvent.data);
    let transportFromMessage = [];
    const currentTransportIds = this.transports.map((ts) => ts.id);
    if (msgEvent.type === 'T' && !!msgEvent.payload.length) {
      transportFromMessage = msgEvent.payload.filter(function(socketTransport: ITransport) {
        return currentTransportIds.includes(socketTransport.id);
      });
      transportFromMessage.forEach((ts: ITransport) => {
        const trackerTime = ts?.geo_position.tracker_time;
        const newTrackerTime = trackerTime ? moment(trackerTime).utc() : moment().utc();
        const prevTrackerTime = this.#transportTrackerTimes[ts.number]
          ? moment(this.#transportTrackerTimes[ts.number]).utc()
          : moment().subtract(this.#realtimeLoadInterval, 'seconds').utc();
        this.#transportTrackerTimes[ts.number] = newTrackerTime;

        if (newTrackerTime.diff(prevTrackerTime, 'seconds') > 5 && newTrackerTime.isAfter(prevTrackerTime)) {
          this.drawLine([ts], prevTrackerTime.format(this.dateFormat), newTrackerTime.format(this.dateFormat));
        }
      });
    }
  }
  reRender() {
    this.#layersService = LayersService.instance._transportLayers._ws;

    if (!this.#filterSubscription && this.#layersService) {
      this.#filterSubscription = this.#layersService.filter.subscribe(this.filterSubscriptionHandler.bind(this));
    }

    this.#storeSubscription = this._store.subscribeAction(action => {
      if (action.type === 'transports/brokenLoaded') {
        this.resetLayers();
        this.filterSubscriptionHandler();
      }
    });
    this.updateTransportList();
    this.#axiosSource?.cancel();
    this.#axiosSource = axios.CancelToken.source();

    this.drawLine(
      this.transports,
      this.#dateFrom,
      this.#dateTo,
    );

    this.startRealtimeLoadLines();
  }

  /**
   * Останавливает загрузку линий машин в режиме реального времени
   */
  stopRealtimeLoadLines() {
    if (this.#onMessageHanlderBinded) {
      this.#layersService?._socket.removeEventListener('message', this.#onMessageHanlderBinded);
      this.#onMessageHanlderBinded = null;
    }
  }

  /**
   * Запущена ли загрузка линий машин в режиме реального времени
   * @returns {boolean}
   */
  isRealtimeLoadLines() {
    return this.#onMessageHanlderBinded !== null;
  }

  /**
   * Начинает загрузку линий машин в режиме реального времени
   */
  startRealtimeLoadLines() {
    if (!moment().isSame(moment(new Date(this.#dateTo)), 'day')) {
      return;
    }
    this.#onMessageHanlderBinded = this.socketMessageData.bind(this);
    this.#layersService?._socket.addEventListener('message', this.#onMessageHanlderBinded);
  }

  destroy() {
    this.setDateFrom(moment(new Date()).startOf('day').format(this.dateFormat));
    this.setDateTo(moment(new Date()).endOf('day').format(this.dateFormat));
    this.onDestroy();
  }
}
