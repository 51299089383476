import { ndu } from '@/services/api/ndu';

export default {
  state: {
    ndus: [],
  },
  getters: {
    ndus: state => state.ndus,
  },
  mutations: {
    ndus: (state, payload) => { state.ndus = payload; },
  },
  actions: {
    loadNdus(state, { all }) {
      return new Promise((resolve, reject) => {
        ndu.getNdus({ all })
          .then(response => {
            state.commit('ndus', response.ndus);
            resolve(response);
          })
          .catch(reject);
      });
    },
  },
};
