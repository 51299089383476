import { IAccident, IAccidentConcentration, IAccidentStat } from '@/types';
import axios from '../axios';

export class Accident {
  static getParams(
    all: boolean,
    filter: {
      [key: string]: any,
    },
  ) {
    const params: {
      [key: string]: string,
    } = {};

    params.include = 'types,transportTypes,roadOwner,ndus,dictionaries';

    if (all) {
      params.all = '';
    }

    if (filter.municipalityId) {
      params['filter[municipality_id]'] = filter.municipalityId.toString();
    }
    if (filter.place) {
      params['filter[place]'] = filter.place;
    }
    if (filter.startDate) {
      params['filter[date][start_date]'] = filter.startDate;
    }
    if (filter.endDate) {
      params['filter[date][end_date]'] = filter.endDate;
    }
    Object.keys(filter).forEach((key, index) => {
      const relations = ['types', 'transport_groups', 'roads', 'ndus', 'road_owners', 'road_state', 'road_surface',
        'road_light', 'road_value', 'road_category', 'road_plan', 'road_profile', 'place_status', 'street_category', 'dictionaries'];
      if (relations.includes(key) && filter[key] && filter[key].length > 0) {
        params[`filter[${key}]`] = filter[key].join(',');
      }
    });
    if (filter.seasons && filter.seasons.length > 0) {
      if (filter.seasons.length && filter.startDate && filter.endDate) {
        params[`filter[date][season]`] = filter.seasons;
      }
    }
    if (filter.victims && filter.victims.length > 0) {
      filter.victims.forEach((value: string) => {
        if (value === 'adults' || value === 'children') {
          params[`filter[victims][age]`] = value;
        } else {
          params[`filter[victims][${value}]`] = '1';
        }
      });
    }

    if (filter.days) {
      params['filter[date][day]'] = filter.days;
    }

    if (filter.hours) {
      params['filter[date][hour]'] = filter.hours;
    }

    return params;
  }

  static list({ all, filter = {} }: {
    all: boolean,
    filter: {
      [key: string]: any,
    }
  }): Promise<{
    accidents: IAccident,
    stat: IAccidentStat,
  }> {
    const params = this.getParams(all, filter);
    return axios.get(`/accident`, { params })
      .then(({ data }: {
        data: {
          accidents: IAccident,
          stat: IAccidentStat,
        }
      }) => data)
      .catch(error => error);
  }

  static export({ all, filter = {} }: {
    all: boolean,
    filter: {
      [key: string]: any,
    }
  }): Promise<{ file: Blob }> {
    const params = this.getParams(all, filter);

    return axios
      .get(`/accident/export`, {
        params,
        responseType: 'blob',
      })
      .then(({ data }: { data: Blob }) => ({
        file: data,
      }));
  }

  static getConcentrations(dateFrom: string, dateTo: string, municipalityId: string): Promise<{
    concentrations: Array<IAccidentConcentration>,
  }> {
    const params = new URLSearchParams();
    params.set('municipality_id', municipalityId);
    params.set('start_date', dateFrom);
    params.set('end_date', dateTo);

    return axios.get('/accident/concentrations', { params })
      .then(({ data }) => data)
      .catch(error => error);
  }

  static getTypes() {
    return axios.get('/accident-type?all')
      .then(({ data }) => data)
      .catch(error => error);
  }

  static getDictionary() {
    return axios.get('/accident-dictionary?all')
      .then(({ data }) => data)
      .catch(error => error);
  }

  static getRoads() {
    return axios.get('/accident-road?all')
      .then(({ data }) => data)
      .catch(error => error);
  }
}
