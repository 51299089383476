import axios from '../../axios';

export class DailyRouteReport {
  static loadData({ routeId, date, direction }) {
    return new Promise((resolve, reject) => {
      axios.get(`https://rnis.dorogi73.ru/api/logs/route-schedule-execution-report/${routeId}/${direction}/?date=${date}`, {
        headers: {
          Authorization: 'Bearer exzIk5c3oCvzuG4UwhvxGffCVKm6OxYH',
        },
      })
        .then((data) => resolve(data))
        .catch(error => reject(error));
    });
  }

  static loadDataNew({ routeId, direction, date }) {
    return new Promise((resolve, reject) => {
      axios.get(`https://rnis.dorogi73.ru/api/custom/report/form3/?route_id=${routeId}&direction=${direction}&date=${date}`, {
        headers: {
          Authorization: 'Bearer exzIk5c3oCvzuG4UwhvxGffCVKm6OxYH',
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => reject(error));
    });
  }
}
