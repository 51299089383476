<template>
  <div id="app">
    <app-base></app-base>
    <notifications></notifications>
    <div class="preloader" v-if="loading">
      <img src="./assets/loading.svg">
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppBase from '@/components/app/app-base';
import Notifications from '@/modules/notifications/notifications';
import UserService from '@/services/user-service';
import 'vue2-datepicker/locale/ru';

export default {
  name: 'app',
  components: {
    AppBase,
    Notifications,
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
    }),
  },
  created() {
    UserService.instance.subscribe('logout', () => {
      this.$router.push('login');
    });
  },
};
</script>

<style lang="scss">
  @import '~bootstrap/scss/bootstrap';
  @import '~leaflet/dist/leaflet.css';
  @import '~leaflet.markercluster/dist/MarkerCluster.css';
  @import '~leaflet.markercluster/dist/MarkerCluster.Default.css';
  @import '~vue2-datepicker/scss/index.scss';
  @import '~vue-context/src/sass/vue-context';
  @import './scss/global';
  @import './scss/map';
  @import '~plyr/src/sass/plyr.scss';

  .left-slider {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 400px;
    background: rgba(255, 255, 255, .9);
    box-shadow: var(--base-shadow);
    z-index: 401;
  }

  .btn {
    border-radius: 3px;
    height: 33px;
    color: #2B2E37;
    font-family: "GotemMedium", sans-serif;
    font-size: 14px;
    line-height: 13px;
    text-align: center;
    border: none;
    outline: none;
    margin-bottom: 5px;

    &.btn-primary,
    &.btn-primary:not(:disabled):not(.disabled):active {
      background-color: #00AD89;
      color: #FFFFFF;
    }

    &.btn-warning,
    &.btn-warning:not(:disabled):not(.disabled):active {
      background-color: #FFC12E;
      color: #2B2E37;
    }

    &.btn-error,
    &.btn-error:not(:disabled):not(.disabled):active {
      background-color: #d06b6b;
      color: #fff;
    }
  }

  .page-wrapper {
    height: calc(100vh - 56px);
    overflow: auto;
  }

  .preloader {
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.7);
    display: flex;

    img {
      width: 200px;
      margin: auto;
    }
  }

  div.mx-datepicker {
    width: 100%;

    input {
      font-size: 16px;
    }
  }

  @media print {
    #app {
      a, a:hover {
        text-decoration: none;
      }
    }
  }

  .passenger-transporation .main-map-grouped-wrapper {
    display: none;
  }
</style>
