














import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
});
