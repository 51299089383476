import axios from '../axios';

export class BusStop {
  static getBusStops({ all, municipalityId, type, owner, routeTypeId }: {
    all: boolean,
    municipalityId?: number,
    type?: 0 | 1,
    owner?: string,
    routeTypeId?: string,
  }) {
    const params: {
      [key: string]: string,
    } = {
      include: 'route_types,points.routeGroup,points.routeGroup.route',
    };

    if (all) {
      params.all = '';
    }

    if (municipalityId) {
      params['filter[municipalities]'] = municipalityId.toString();
    }

    if (type) {
      params['filter[type]'] = type.toString();
    }

    if (owner) {
      params['filter[owner]'] = owner;
    }

    if (routeTypeId) {
      params['filter[route_type_id]'] = routeTypeId;
    }

    return axios.get('/bus-stop', {
      params,
    })
      .then(({ data }) => data)
      .catch(e => e);
  }
}
