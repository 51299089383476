import axios from '../axios';

export class History {
  static getTransports({ transporterId, dateFrom, dateTo, transportId = null, routeTypeGroup, cancelToken }) {
    let url = '/history/search';

    const params = {};
    params.date_from = dateFrom;
    params.date_to = dateTo;

    if (transporterId) {
      params.transporter_id = transporterId;
    }
    if (transportId) {
      params.transport_id = transportId;
    }
    if (routeTypeGroup) {
      params.route_type_group = routeTypeGroup;
    }

    return axios.get(url, { params, isBackgroundLoading: true, cancelToken })
      .then(({ data }) => {
        return data;
      })
      .catch(e => e);
  }

  static trackTransports({ transportId, dateFrom, dateTo, cancelToken }) {
    const url = '/history/track';
    const params = {
      transport_id: transportId,
      date_from: dateFrom,
      date_to: dateTo,
    };

    return axios.get(url, { params, isBackgroundLoading: true, cancelToken })
      .then(({ data }) => data)
      .catch(e => e);
  }

  static getHistory({ transportId, dateFrom, dateTo, outfitId = null, routeGroupId = null }) {
    const params = {
      date_from: dateFrom,
      date_to: dateTo,
      transport_id: transportId,
    };
    let url = `/history`;

    if (outfitId) {
      params.outfit_id = outfitId;
    }
    if (routeGroupId) {
      params.route_group_id = routeGroupId;
    }
    return axios.get(url, { params, isBackgroundLoading: true })
      .then(({ data }) => data)
      .catch(e => e);
  }
};
