import L from 'leaflet';
import { PubSub } from '@/services/pub-sub';
import Vue from 'vue';
import MapMainControl from '@/components/map/map-controls/main-control.vue';
import router from '@/router';

import 'leaflet-editable';
import { tileSettings } from '@/constants/Global';

const key = Symbol('key');
const keyEnforcer = Symbol('keyEnforcer');

export class MapService extends PubSub {
  _store = null;
  _map = null;
  _baseMaps = {};
  _tileSet = null;
  _currentLayerName = tileSettings.osm.key;
  _defaultCenter = [54.3133, 48.3783];
  _defaultZoom = 11;
  _maxZoom = 20;
  _saveStateHandler = null;

  constructor(enforcer) {
    super();
    if (enforcer !== keyEnforcer) {
      throw new Error('Instantiation failed: use MapService.instance instead of new.');
    }
  }

  static get instance() {
    if (!this[key]) {
      this[key] = new MapService(keyEnforcer);
    }

    return this[key];
  }

  static set instance(v) {
    throw new Error("Can't change constant property!");
  }

  setStore(store) {
    this._store = store;
    return this;
  }

  get map() {
    return this._map;
  }

  destroy() {
    if (this._map !== null) {
      this._map.eachLayer(function(layer) {
        layer.remove();
      });

      if (this._saveStateHandler) {
        this._map.off('zoomend moveend', this._saveStateHandler);
        this._saveStateHandler = null;
      }

      this._map.remove();
      this._map = null;
    }

    return this;
  }

  saveState() {
    if (!this._store) {
      return;
    }

    const zoom = this._map.getZoom();
    const latlng = this._map.getBounds().getCenter();

    this._store.dispatch('map/setZoom', zoom);
    this._store.dispatch('map/setLatLng', [latlng.lat, latlng.lng]);
  }

  initMap({ mapElement, center = this._defaultCenter, zoom = this._defaultZoom, showLayers = true }) {
    if (this._store === null) {
      throw new Error('Nullable store in MapService. Please, inject application store via setStore method to MapService');
    }

    if (this._map !== null) return;

    this._map = L.map(
      mapElement,
      {
        zoomControl: false,
        editable: true,
        renderer: L.canvas(),
      },
    ).setView(center, zoom);
    window.map = this._map;
    const defaultLayer = L.tileLayer(tileSettings.yandexSchema.url, {
      maxZoom: Math.min(19, this._maxZoom),
      l: 'map',
    });
    defaultLayer.addTo(map);
    this._tileSet = tileSettings.yandexSchema.title;

    const yandexSatelliteLayer = L.tileLayer(tileSettings.yandexSatellite.url, {
      maxZoom: Math.min(19, this._maxZoom),
      l: 'sat',
    });
    const osmLayer = L.tileLayer(tileSettings.osm.url, {
      maxZoom: Math.min(19, this._maxZoom),
    });
    const yandexSatelliteSchemaLayer = L.tileLayer(tileSettings.yandexSchema.url, {
      maxZoom: Math.min(19, this._maxZoom),
      l: 'skl',
    });
    const googleSatelliteLayer = L.tileLayer(tileSettings.google.url, {
      maxZoom: this._maxZoom,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    });

    this._baseMaps = {};
    this._baseMaps[tileSettings.yandexSchema.title] = defaultLayer;
    this._baseMaps[tileSettings.yandexSatellite.title] = yandexSatelliteLayer;
    this._baseMaps[tileSettings.osm.title] = osmLayer;
    this._baseMaps[tileSettings.google.title] = googleSatelliteLayer;
    const controls = new L.Control({ position: 'topright' });
    const controlsElement = new Vue({
      render: h => h(MapMainControl, {
        props: {
          baseMaps: this._baseMaps,
          showLayers: showLayers,
        },
      }),
      router,
      store: this._store,
    }).$mount();
    controls.onAdd = () => controlsElement.$el;
    controls.addTo(this._map);
    L.control.zoom({
      position: 'topright',
    }).addTo(this._map);
    this._map.invalidateSize();

    this._saveStateHandler = this.saveState.bind(this);
    this._map.on('zoomend moveend', this._saveStateHandler);
    const selectedBaseLayer = localStorage.getItem('selectedBaseLayer') || this._tileSet;
    const keys = Object.keys(this._baseMaps);

    this.publish('map-initialized');
    if (selectedBaseLayer != null && keys.indexOf(selectedBaseLayer) !== -1) {
      this.selectedTileSet = selectedBaseLayer;
      this.publish('map-selected-base-layer');
    }
  }
  choseTileSet(newTileSet, oldTileSet) {
    if (oldTileSet) {
      this._map.removeLayer(oldTileSet);
    }
    if (newTileSet) {
      this._map.addLayer(newTileSet);
    }
  }

  onTileSetChange(tileSet) {
    const center = this._map.getCenter();
    const zoom = this._map.getZoom();
    const tileKey = Object.keys(tileSettings).find(
      key => tileSettings[key].title === tileSet,
    );

    localStorage.setItem('selectedBaseLayer', tileSet);
    this._currentLayerName = tileKey;
    this._map.options.crs = tileSettings[tileKey].projection;

    this._map._resetView(center, zoom, true);
    this.publish('map-base-layer-change', tileKey);
  }

  /**
   * Возвращает название выбранного слоя tileSettings
   * @returns {string|null}
   */
  get selectedTileSet() {
    return this._tileSet;
  }

  /**
   * Возвращает объект слоя tileSettings
   * @returns {*}
   */
  get currentTileSettings() {
    return Object.entries(tileSettings).find((keyValPair) => {
      return keyValPair[1].key === this._currentLayerName;
    })?.[1];
  }

  set selectedTileSet(newTileSet) {
    const oldLayer = this._baseMaps[this._tileSet];
    const newLayer = this._baseMaps[newTileSet];

    this.choseTileSet(newLayer, oldLayer);
    this.onTileSetChange(newTileSet);

    this._tileSet = newTileSet;
  }
}
