import axios from '../axios';

export class Transport {
  static getTransports(filter = {}, include = [], isBackgroundLoading = false) {
    return new Promise((resolve, reject) => {
      const params = {};
      let url = '/transport?';

      if (filter.all) {
        params.all = 1;
      }

      if (filter.with_unassigned_trips) {
        params['filter[with_unassigned_trips][date]'] = filter.with_unassigned_trips.date;
        params['filter[with_unassigned_trips][route_id]'] = filter.with_unassigned_trips.route_id;
      }

      if (filter.number) {
        params['filter[number]'] = filter.number;
      }
      if (filter.routeTypeIds && filter.routeTypeIds.length) {
        params['filter[route_type_id]'] = filter.routeTypeIds.join(',');
      }
      if (include.length > 0) {
        params.include = include.join(',');
      }

      axios.get(url, { params, isBackgroundLoading })
        .then(({ data }) => {
          resolve(data.transports);
        })
        .catch(reject);
    });
  }

  static getBrokenTransports({ municipalityId }) {
    let url = '/transport/broken';
    const params = [];

    if (municipalityId) {
      params.push(`municipality_id=${municipalityId}`);
    }

    url += '?' + params.join('&');

    return new Promise((resolve, reject) => {
      axios.get(url, {
        isBackgroundLoading: true,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(reject);
    });
  }

  static getTransport(id) {
    return new Promise((resolve, reject) => {
      axios.get(`/transport?page=1`)
        .then(({ data }) => {
          resolve(data.transport);
        })
        .catch(reject);
    });
  }

  static getOnDay({ date, routeId }) {
    const params = [];

    if (date) {
      params.push(`date=${date}`);
    }

    if (routeId) {
      params.push(`route_id=${routeId}`);
    }

    return new Promise((resolve, reject) => {
      axios.get(`/transport/on-day${params.length > 0 ? `?${params.join('&')}` : ''}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(reject);
    });
  }

  static loadTransportTypes() {
    return new Promise((resolve, reject) => {
      axios.get('/transport-type?all')
        .then(({ data }) => {
          resolve(data);
        })
        .catch(reject);
    });
  }

  static loadTransportGroups() {
    return new Promise((resolve, reject) => {
      axios.get('/transport-group?all')
        .then(({ data }) => {
          resolve(data);
        })
        .catch(reject);
    });
  }
}
