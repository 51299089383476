import L from 'leaflet';
import moment from 'moment';
import { Accident } from '@/services/api/accident';
import { BehaviorSubject } from 'rxjs';
import { BaseLayer } from './base-layer';
import pointInPolygon from 'point-in-polygon';

export class AccidentLayer extends BaseLayer {
  _layers = null;
  _originAccidents = [];
  isDictionaryInited = false;
  accidents = new BehaviorSubject([]);
  accidentConcentrations = new BehaviorSubject([]);
  municipalities = new L.LayerGroup();

  markersGroup = null;
  accidentConcentrationsGroups = null;
  markers = [];
  markersConcentrations = [];
  _clusterBreakPoint = 19;

  constructor({ store, map, type }) {
    super({ store, map, type });
    this.filter = {
      type: {
        transport_groups: [],
        types: [],
        ndus: [],
        weather: [],
        road_owners: [],
        victimsInjures: [],
        victimsAge: [],
        seasons: [],
        roads: [],
        municipality_id: [],
      },
      dictionaries: {
        participant_category: [],
        tech_issues: [],
        road_surface: [],
        road_state: [],
        road_light: [],
        road_value: [],
        road_category: [],
        road_plan: [],
        weather: [],
        place_status: [],
        street_category: [],
        road_profile: [],
        pedestrian_location: [],
        violation_first: [],
        violation_second: [],
        uds_object_place: [],
        uds_object_near: [],
      },
      dates: [
        moment().startOf('year').toDate(),
        moment().endOf('year').toDate(),
      ],
      days: [],
      hourFrom: { value: null },
      hourTo: { value: null },
      municipalityId: null,
      showAccidentConcentrations: false,
      ...this.filter,
    };
    this.createGroups();
    this._map.addLayer(this.municipalities);

    this.accidents.subscribe(() => {
      this._renderMarkers();
    });

    this.accidentConcentrations.subscribe(() => {
      this._renderAccidentConcentrations();
    });

    this._store.subscribe(mutation => {
      if (mutation.type === 'map/setGroupControlDisabled') {
        this.markersGroup?.clearLayers();
        this.markersGroup?.remove();
        this.markersGroup = null;
        this.accidentConcentrationsGroups?.clearLayers();
        this.accidentConcentrationsGroups?.remove();
        this.accidentConcentrationsGroups = null;

        this.createGroups();
      }
    });
  }

  createGroups() {
    this.markersGroup = new L.MarkerClusterGroup({
      disableClusteringAtZoom: !this._store.getters['map/isGroupControlDisabled'] ? this._clusterBreakPoint : 0,
      iconCreateFunction: function(cluster) {
        const markers = cluster.getAllChildMarkers();
        let classNames = 'marker-cluster marker-cluster-';
        if (markers.length < 10) {
          classNames += 'small';
        } else if (markers.length <= 100) {
          classNames += 'medium';
        } else {
          classNames += 'large';
        }

        return L.divIcon({
          html: `<div><span>${markers.length}</span></div>`,
          className: classNames,
          iconSize: L.point(40, 40),
        });
      },
    });
    this.accidentConcentrationsGroups = new L.MarkerClusterGroup({
      disableClusteringAtZoom: !this._store.getters['map/isGroupControlDisabled'] ? this._clusterBreakPoint : 0,
      iconCreateFunction: function(cluster) {
        return L.divIcon({
          html: `<img style="height: 100%" src="../../assets/icons/dtp2.png"></img>`,
          className: 'marker-cluster',
          iconSize: L.point(45, 45),
        });
      },
    });

    this.markersGroup.addLayers(this.markers);
    this.accidentConcentrationsGroups.addLayers(this.markersConcentrations);

    this._map.addLayer(this.markersGroup);
    this._map.addLayer(this.accidentConcentrationsGroups);
  }

  _deleteLayer() {
    if (this._layers !== null) {
      this._layers.forEach(layer => layer.remove());
      this._layers = null;
    }
    this.markersGroup.clearLayers();
    this.markers = [];
  }

  deleteAccidentConcentrationLayers() {
    this.accidentConcentrationsGroups.clearLayers();
    this.markersConcentrations = [];
  }

  deleteMunicipalities() {
    this.municipalities.eachLayer(l => l.remove());
    this.municipalities.clearLayers();
  }

  goToMarker(accident) {
    this._layers.forEach(layer => {
      if (layer.options.id === accident.id) {
        layer.openPopup();
        const latlng = layer.getLatLng();
        this._map.setView([latlng.lat, latlng.lng], 18);
      }
    });
  }

  _filter(filter) {
    if (filter.hours && filter.hours.length > 0) {
      filter.hourFrom = filter.hours[0];
      filter.hourTo = filter.hours[1];
    } else {
      filter.hourFrom = { value: null };
      filter.hourTo = { value: null };
    }

    this.filter = JSON.parse(JSON.stringify(filter));

    this.reRender();
  }

  getParams() {
    const params = {
      all: true,
      filter: {},
    };

    if (this._originAccidents.length === 0 || this.filter._municipalityId !== this._store.getters['municipalities/current'].id) {
      params.filter.municipalityId = this._store.getters['municipalities/current'].id;
    }

    if (Array.isArray(this.filter.dates) && this.filter.dates.length > 0) {
      params.filter.startDate = moment(this.filter.dates[0]).format('YYYY-MM-DD HH:mm:ss');
      params.filter.endDate = moment(this.filter.dates[1]).format('YYYY-MM-DD HH:mm:ss');
    }

    if (this.filter.type) {
      params.filter.types = this.filter.type.types.map(t => t.id);
      params.filter.transport_groups = this.filter.type.transport_groups.map(g => g.id);
      params.filter.roads = this.filter.type.roads.map(r => r.id);
      params.filter.ndus = this.filter.type.ndus.map(n => n.id);
      params.filter.municipality_id = this.filter.type.municipality_id.map(m => m.id);
      params.filter.road_owners = this.filter.type.road_owners.map(o => o.id);
      params.filter.seasons = this.filter.type.seasons;
      params.filter.victims = [
        ...this.filter.type.victimsInjures.map(v => v.id),
        ...this.filter.type.victimsAge.map(v => v.id),
      ];
    }

    if (this.filter.dictionaries) {
      const dictionaries = [];

      Object.entries(this.filter.dictionaries).forEach(([key, arr]) => {
        switch (key) {
          case 'road_state':
          case 'road_surface':
          case 'road_light':
          case 'road_value':
          case 'road_category':
          case 'road_plan':
          case 'road_profile':
          case 'place_status':
          case 'street_category':
            params.filter[key] = arr.map(c => c.id);
            break;
          default:
            arr.forEach(c => {
              dictionaries.push(c.id);
            });
            break;
        }
      });
      params.filter.dictionaries = dictionaries;
    }

    if (this.filter.days) {
      params.filter.days = this.filter.days.map(({ id }) => id).join(',');
    }

    if (this.filter.hourFrom.value !== null && this.filter.hourTo.value !== null) {
      params.filter.hours = `${this.filter.hourFrom.value},${this.filter.hourTo.value}`;
    }

    return params;
  }

  async reRender() {
    const params = this.getParams();

    this._initDictionaries()
      .then(() => {
        return Accident.list(params);
      })
      .then(response => {
        this._originAccidents = response.accidents;
        this.accidents.next(response.accidents);

        if (this.showAccidentConcentrations) {
          Accident.getConcentrations(params.filter.startDate, params.filter.endDate, params.filter.municipalityId)
            .then(data => {
              this.accidentConcentrations.next(data.concentrations);
              this._store.commit('accident/concentrations', data.concentrations);
            });
        } else {
          this._store.commit('accident/concentrations', []);
        }

        this._store.commit('accident/stat', response.stat);
        this._renderAllMunicipalities();
      });
  }

  _renderAccidentConcentrations() {
    this.deleteAccidentConcentrationLayers();
    const boundsLatLngs = this._store.getters['zone-selection/rectangle'];

    this.accidentConcentrations
      .getValue()
      .filter(accidentConcentration => {
        return boundsLatLngs.length === 0 || pointInPolygon([...accidentConcentration.point.coordinates].reverse(), boundsLatLngs);
      })
      .forEach((accidentConcentration) => {
        const color = accidentConcentration.type === 'yellow' ? '#FFB91F' : 'red';
        const marker = L.circle([...accidentConcentration.point.coordinates].reverse(), { radius: accidentConcentration.radius, color });
        marker.bindPopup(`
          <p class="mts"><strong>Количество ДТП: </strong>${accidentConcentration.count}</p>
          <p class="mts"><strong>Макс кол-во дтп одного вида: </strong>${accidentConcentration.countOneType}</p>
          <p class="mts"><strong>Погибло: </strong>${accidentConcentration.death}</p>
          <p class="mts"><strong>Ранено: </strong>${accidentConcentration.injured}</p>
          <p class="mts"><strong>Муниципалитет: </strong>${accidentConcentration.municipalities}</p>
          <p class="mts"><strong>Радиус: </strong>${accidentConcentration.radius} м</p>

        `).openPopup();

        this.accidentConcentrationsGroups.addLayer(marker);
        this.markersConcentrations.push(marker);
        this._layers.push(marker);
      });
  }

  _renderMarkers() {
    this._deleteLayer();
    this._layers = [];
    const accidents = this.accidents.getValue();

    const dictionary = this._store.getters['accident/dictionary'];
    const roads = this._store.getters['accident/roads'];
    const boundsLatLngs = this._store.getters['zone-selection/rectangle'];

    accidents
      .filter(accident => {
        return boundsLatLngs.length === 0 || pointInPolygon([accident.latitude, accident.longitude], boundsLatLngs);
      })
      .forEach((accident) => {
        let iconColor;
        if (accident.death > 0) {
          iconColor = 'red';
        } else if (accident.injured) {
          iconColor = 'yellow';
        } else {
          iconColor = 'green';
        }

        const marker = L.marker([accident.latitude, accident.longitude], {
          icon: L.divIcon({
            html: `<img src="/assets/icons/accident-${iconColor}.svg" style="height: 100%">`,
            iconSize: [35, 35],
            className: '',
          }),
          id: accident.id,
        });

        accident.types = accident.types.map(type => { return type?.name ?? ''; });

        marker.bindPopup(
          `
          <p class="accident-info"><strong>Время ДТП: </strong>${moment(accident.date).format('DD.MM.YYYY HH:mm')}</p>
          <p class="accident-info"><strong>Вид ДТП: </strong>${accident.types.join(', ')}</p>
          <p class="accident-info"><strong>Количество участников ДТП: </strong>${accident.count_participants}</p>
          <p class="accident-info"><strong>Количество пострадавших: </strong>${accident.injured}</p>
          <p class="accident-info"><strong>Количество пострадавших детей: </strong>${accident.children_injured}</p>
          <p class="accident-info"><strong>Количество погибших: </strong>${accident.death}</p>
          <p class="accident-info"><strong>Количество погибших детей: </strong>${accident.children_death}</p>
          <p class="accident-info"><strong>Типы ТС: </strong>${accident.transport_types.map(t => t.name).join(', ')}</p>
          <p class="accident-info"><strong>Место: </strong>${accident.place}</p>
          <p class="accident-info"><strong>Статус населенного пункта: </strong>${this._getNameFromDictionary(dictionary.place_status, accident.place_status)}</p>
          <p class="accident-info"><strong>Дорога: </strong>${this._getNameFromDictionary(roads, accident.road_id)}</p>
          <p class="accident-info"><strong>Владелец автомобильной дороги: </strong>${accident.road_owner ? accident.road_owner.name : '—'}</p>
          <p class="accident-info"><strong>Категория дороги: </strong>${this._getNameFromDictionary(dictionary.road_category, accident.road_category)}</p>
          <p class="accident-info"><strong>Освещение: </strong>${this._getNameFromDictionary(dictionary.road_light, accident.road_light)}</p>
          <p class="accident-info"><strong>Элементы плана дороги: </strong>${this._getNameFromDictionary(dictionary.road_plan, accident.road_plan)}</p>
          <p class="accident-info"><strong>Профиль дороги: </strong>${this._getNameFromDictionary(dictionary.road_profile, accident.road_profile)}</p>
          <p class="accident-info"><strong>Состояние покрытия: </strong>${this._getNameFromDictionary(dictionary.road_state, accident.road_state)}</p>
          <p class="accident-info"><strong>Вид покрытия: </strong>${this._getNameFromDictionary(dictionary.road_surface, accident.road_surface)}</p>
          <p class="accident-info"><strong>Значение дороги: </strong>${this._getNameFromDictionary(dictionary.road_value, accident.road_value)}</p>
          <p class="accident-info"><strong>Категория улицы: </strong>${this._getNameFromDictionary(dictionary.street_category, accident.street_category)}</p>
          <p class="accident-info"><strong>Состояние погоды: </strong>${this._parseDictionary(accident.dictionaries, 'weather')}</p>
          <p class="accident-info"><strong>Объекты УДС на месте ДТП: </strong>${this._parseDictionary(accident.dictionaries, 'uds_object_place')}</p>
          <p class="accident-info"><strong>Объекты УДС вблизи места ДТП: </strong>${this._parseDictionary(accident.dictionaries, 'uds_object_near')}</p>
          <p class="accident-info"><strong>Категории участников: </strong>${this._parseDictionary(accident.dictionaries, 'participant_category')}</p>
          <p class="accident-info"><strong>Непосредственные нарушения ПДД: </strong>${this._parseDictionary(accident.dictionaries, 'violation_first')}</p>
          <p class="accident-info"><strong>Сопутствующие нарушения ПДД: </strong>${this._parseDictionary(accident.dictionaries, 'violation_second')}</p>
          <p class="accident-info"><strong>Технические неисправности: </strong>${this._parseDictionary(accident.dictionaries, 'tech_issues')}</p>
          <p class="accident-info"><strong>НДУ: </strong>${accident.ndus.map(t => t.name).join(', ')}</p>
        `, {
            maxWidth: 500,
            maxHeight: 500,
          }).openPopup();
        this.markers.push(marker);
        this.markersGroup.addLayer(marker);
        this._layers.push(marker);
      });
  }

  appg(a, v) {
    let d = v > 0 ? v : 1;

    return Math.floor(((v - a) / d) * 100).toString();
  };

  getColor(appg) {
    switch (appg) {
      case '0':
        return '#ff9c00';
      case '—':
        return 'black';
      default: {
        if (!this.isSameYear) {
          return 'black';
        }
        if (Number(appg) < 0) {
          return 'green';
        } else {
          return 'red';
        }
      }
    }
  }

  _renderAllMunicipalities() {
    this.deleteMunicipalities();

    if (!this.isSameYear || !this._store.getters['municipalities/current'].super) {
      return;
    }

    const geo = this._store.getters['municipalities/municipalities']
      .filter(m => m.position > 9)
      .map(m => ({
        id: m.id,
        geometry: m.border,
        color: m.color,
        name: m.name,
      }));
    const stat = this._store.getters['accident/statMunicipalities'].reduce((acc, item) => {
      const appgCountCalc = this.appg(Number(item.appgCount), Number(item.count));
      const appgDeathCalc = this.appg(Number(item.appgDeath), Number(item.death));
      const appgInjuredCalc = this.appg(Number(item.appgInjured), Number(item.injured));

      acc[item.municipality_id] = {
        ...item,
        appgCountCalc,
        appgDeathCalc,
        appgInjuredCalc,
        color: this.getColor(appgCountCalc),
      };

      return acc;
    }, {});

    geo
      .filter(g => stat[g.id]).forEach(g => {
        const countColor = this.getColor(stat[g.id].appgCountCalc);
        const deathColor = this.getColor(stat[g.id].appgDeathCalc);
        const injuredColor = this.getColor(stat[g.id].appgInjuredCalc);

        L.polygon(L.GeoJSON.coordsToLatLngs(g.geometry.coordinates, 2), {
          color: stat[g.id] ? stat[g.id].color : g.color,
          fillOpacity: 0.1,
        })
          .bindPopup(`
          <h5 class="text-center">${g.name}</h5>
          <table>
            <thead>
              <tr class="text-center">
                <th></th>
                <th>Абсолютное значение</th>
                <th>Сравнение с АППГ</th>
              </tr>
            </thead>
            <tbody>
              <tr style="border: none" class="text-center">
                <td style="border: none">ДТП</td>
                <td style="color: ${countColor}; border: none;">${stat[g.id].count ?? '—'}</td>
                <td style="color: ${countColor}; border: none;">${stat[g.id].appgCountCalc}%</td>
              </tr>
              <tr style="border: none" class="text-center">
                <td style="border: none">Погибло</td>
                <td style="color: ${deathColor}; border: none;">${stat[g.id].death ?? '—'}</td>
                <td style="color: ${deathColor}; border: none;">${stat[g.id].appgDeathCalc}%</td>
              </tr>
              <tr style="border: none" class="text-center">
                <td style="border: none">Ранено</td>
                <td style="color: ${injuredColor}; border: none;">${stat[g.id].injured ?? '—'}</td>
                <td style="color: ${injuredColor}; border: none;">${stat[g.id].appgInjuredCalc}%</td>
              </tr>
            </tbody>
          </table>
        `)
          .addTo(this.municipalities);
      });

    this.municipalities.eachLayer(l => {
      if (l.options.color === '#ff9c00') {
        l.bringToFront();
      }
    });

    this.municipalities.eachLayer(l => {
      if (l.options.color === 'red') {
        l.bringToFront();
      }
    });
  }

  _initDictionaries() {
    if (this.isDictionaryInited) {
      return Promise.resolve();
    }
    this.isDictionaryInited = true;

    return Promise.all([
      this._store.dispatch('accident/getTypesFromDictionary'),
      this._store.dispatch('accident/getAccidentRoads'),
    ]);
  }

  _parseDictionary(dictionaries, type) {
    const answer = dictionaries.filter(d => d.type === type).map(d => d.name);

    return answer.length === 0 ? '—' : answer.join(', ');
  }

  _getNameFromDictionary(dictionary, id) {
    const value = dictionary.find(s => s.id === id);

    if (!id || !value) {
      return '—';
    }

    return value.name;
  }

  get showAccidentConcentrations() {
    return this.filter.showAccidentConcentrations;
  }

  set showAccidentConcentrations(v) {
    this._store.commit('accident/setShowAccidentConcentrations', v);
    this.filter = {
      ...this.filter,
      showAccidentConcentrations: v,
    };
  }

  destroy() {
    this._deleteLayer();
    this.deleteAccidentConcentrationLayers();
    this.deleteMunicipalities();

    this.markers = [];
    this.markersConcentrations = [];
  }

  get isSameYear() {
    return moment(this.filter.dates[0]).year() === moment(this.filter.dates[1]).year();
  }
}
