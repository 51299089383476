














import Vue from 'vue';
import { defineComponent, ref, Ref } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { ACL } from '../../../../modules/acl/acl-service';

import { isMobile } from 'mobile-device-detect';

const chatHelpers = createNamespacedHelpers('chat');

export default defineComponent({
  setup(props, { root }) {
    const { toggleChat }: {
      toggleChat: () => Promise<void>
    } = chatHelpers.useActions(['toggleChat']) as any;
    const { unreadCounter }: {
      unreadCounter: Ref<number>
    } = chatHelpers.useGetters(['unreadCounter']) as any;

    return {
      unreadCounter,
      openChat() {
        if (!ACL.can('messenger.user-contacts')) {
          return;
        }
        if (!isMobile) {
          toggleChat();
        } else {
          root.$router.push('/chat');
        }
      },
    };
  },
});
