import L from 'leaflet';
import { Weather } from '@/services/api/weather';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';

const weather = {
  1: 'Ясно',
  4: 'Облачно',
  10: 'Дождь',
  12: 'Дождь со снегом',
  13: 'Снег',
  20: 'Град',
};

const prec = {
  0: 'Нет',
  1: 'Дождь',
  2: 'Дождь со снегом',
  3: 'Снег',
  4: 'Град',
};

const condition = {
  'clear': 'ясно',
  'partly-cloudy': 'малооблачно',
  'cloudy': 'облачно с прояснениями',
  'overcast': 'пасмурно',
  'drizzle': 'морось',
  'light-rain': 'небольшой дождь',
  'rain': 'дождь',
  'moderate-rain': 'умеренно сильный дождь',
  'heavy-rain': 'сильный дождь',
  'continuous-heavy-rain': 'длительный сильный дождь',
  'showers': 'ливень',
  'wet-snow': 'дождь со снегом',
  'light-snow': 'небольшой снег',
  'snow': 'снег',
  'snow-showers': 'снегопад',
  'hail': 'град',
  'thunderstorm': 'гроза',
  'thunderstorm-with-rain': 'дождь с грозой',
  'thunderstorm-with-hail': 'гроза с градом',
};

const windDirection = {
  'nw': 'северо-западное',
  'n': 'северное',
  'ne': 'северо-восточное',
  'e': 'восточное',
  'se': 'юго-восточное',
  's': 'южное',
  'sw': 'юго-западное',
  'w': 'западное',
  'с': 'штиль',
};

export const weatherSourceTypes = {
  YANDEX: 1,
  METEO: 2,
  AUTO_METEO: 3,
};

export class WeatherLayer {
  _store = null;
  _layers = null;

  _weather = null;
  _alert = new BehaviorSubject(null);
  _weatherKazan = null;
  _weather1 = null;
  _enabledTypes = [];

  _filter = {
    municipalityId: null,
  };

  constructor({ store, map }) {
    this._store = store;
    this._map = map;
  }

  filter({ municipalityId }) {
    this._filter.municipalityId = municipalityId;

    this.reRender();
  }

  _deleteLayer() {
    if (this._layers !== null) {
      this._layers.forEach(layer => {
        layer.remove();
      });
      this._layers = null;
    }
  }

  _deleteTypeLayers(type) {
    if (this._layers !== null) {
      this._layers.forEach(layer => {
        const layerType = layer.options.type;

        if (layerType === type) {
          layer.remove();
        }
      });
    }
  }

  async reRender(params, isBackgroundLoading = false) {
    if (this.filter.municipalityId !== this._store.getters['municipalities/current'].id) {
      if (this._store.getters['municipalities/current'].position >= 10) {
        this.filter.municipalityId = this._store.getters['municipalities/current'].id;
      } else {
        this.filter.municipalityId = null;
      }
    }

    if (this._layers === null) {
      this._layers = [];
    }

    const types = params && params.types ? params.types : [];
    this._enabledTypes = types
      .filter(type => type.checked)
      .map(type => type.id);

    types.forEach(type => {
      switch (type.id) {
        case weatherSourceTypes.YANDEX:
          if (type.checked) {
            this.yandexWeather(isBackgroundLoading);
          } else {
            this._deleteTypeLayers('yandexWeather');
          }
          break;
        case weatherSourceTypes.METEO:
          if (type.checked) {
            this.samara(isBackgroundLoading);
          } else {
            this._deleteTypeLayers('samara');
          }
          break;
        case weatherSourceTypes.AUTO_METEO:
          if (type.checked) {
            this.load(isBackgroundLoading);
            this.kazan(isBackgroundLoading);
            this.meteoM5(isBackgroundLoading);
          } else {
            this._deleteTypeLayers('list');
            this._deleteTypeLayers('kazan');
            this._deleteTypeLayers('meteoM5');
          }
          break;
      }
    });

    this.ulskAlert(isBackgroundLoading);
  }

  async load(isBackgroundLoading = false) {
    const response = await Weather.list({ isBackgroundLoading: isBackgroundLoading, municipalityId: this.filter.municipalityId });

    if (this._enabledTypes.indexOf(weatherSourceTypes.AUTO_METEO) === -1) {
      return;
    }

    const type = 'list';
    this._weather = response.WEATHER_DATA;
    this._deleteTypeLayers(type);

    if (Array.isArray(this._weather)) {
      this._weather.forEach(elm => {
        let marker = L.marker([elm.latitude, elm.longitude], {
          icon: L.divIcon({
            html: `<div class="marker-icon text-center no-icon"><span>${Math.round(elm.T)}</span></div>`,
            iconSize: [25, 25],
            className: '',
          }),
          id: elm.id,
          type,
        });

        marker.bindPopup(
          `<p class='traffic-light'><strong>Название: </strong>${elm.METEO_NAME}</p>` +
          `<p class='traffic-light'><strong>Время фиксации данных: </strong>${elm.WEATHER_DATE}</p>` +
          `<p class='traffic-light'><strong>Температура: </strong>${elm.T}</p>` +
          `<p class='traffic-light'><strong>Температура полотна дороги: </strong>${elm.t_road}</p>` +
          `<p class='traffic-light'><strong>Давление: </strong>${elm.PO}</p>` +
          `<p class='traffic-light'><strong>Влажность: </strong>${elm.U}%</p>` +
          `<p class='traffic-light'><strong>Направление ветра: </strong>${elm.DD}</p>` +
          `<p class='traffic-light'><strong>Скорость ветра: </strong>${elm.FF}</p>` +
          `<p class='traffic-light'><strong>Погода: </strong>${weather[elm.weather]}</p>` +
          `<p class='traffic-light'><strong>Осадки: </strong>${prec[elm.prec_type]}</p>` +
          `<p class='traffic-light'><strong>Интенсивность осадков: </strong>${elm.prec_intensity}</p>` +
          `<p class='traffic-light'><strong>Общее количество ТС: </strong>${elm.TRAFFIC_DATA.AllT}</p>` +
          `<p class='traffic-light'><img src="/v1/service/weather/asm/screenshot/${elm.METEO_ID}" width="100%"></p>`,
        );

        this._layers.push(marker);
        marker.addTo(this._map);
      });
    }
  }

  samara(isBackgroundLoading = false) {
    Weather.samaraList({ isBackgroundLoading: isBackgroundLoading, municipalityId: this.filter.municipalityId })
      .then((data) => {
        if (this._enabledTypes.indexOf(weatherSourceTypes.METEO) === -1) {
          return;
        }

        const type = 'samara';
        this._deleteTypeLayers(type);

        data.data.forEach((elm, index) => {
          const marker = L.marker([elm[elm.length - 1][0], elm[elm.length - 1][1]], {
            icon: L.divIcon({
              html: `<div class="marker-icon text-center no-icon"><span>${Math.round(elm[9])}</span></div>`,
              iconSize: [25, 25],
              className: '',
            }),
            id: index,
            type,
          });

          let content = '';

          for (let i = 1; i < data.headers.length - 1; i++) {
            content += `<strong>${data.headers[i]}:</strong> ${elm[i]}<br>`;
          }

          marker.bindPopup(content);
          marker.addTo(this._map);

          this._layers.push(marker);
        });
      });
  }

  kazan(isBackgroundLoading = false) {
    Weather.kazanList({
      isBackgroundLoading: isBackgroundLoading,
      municipalityId: this.filter.municipalityId,
    }).then(response => {
      if (this._enabledTypes.indexOf(weatherSourceTypes.AUTO_METEO) === -1) {
        return;
      }

      const type = 'kazan';
      this._deleteTypeLayers(type);
      this._weatherKazan = response;
      this.renderKazan(type);
    });
  }

  renderKazan(type) {
    this._weatherKazan.forEach(el => {
      let marker = L.marker([el.latitude, el.longitude], {
        icon: L.divIcon({
          html: `<div class="marker-icon text-center no-icon" style="border-color: ${el.iconColor}"><span>-</span></div>`,
          iconSize: [25, 25],
          className: '',
        }),
        id: el.station_id,
        type,
      });

      marker.on('click', () => {
        L.popup()
          .setLatLng([el.latitude, el.longitude])
          .setContent(el.html_meteo + el.html_precip + el.html_wind)
          .openOn(this._map);
      });

      marker.addTo(this._map);

      this._layers.push(marker);
    });
  }

  ulskAlert(isBackgroundLoading = false) {
    Weather.alertList({
      isBackgroundLoading: isBackgroundLoading,
    })
      .then((res) => {
        this._alert.next(res[73]);
      });
  }

  yandexWeather(isBackgroundLoading = false) {
    Weather.yandexWeatherList({
      isBackgroundLoading: isBackgroundLoading,
      municipalityId: this.filter.municipalityId,
    }).then(response => {
      if (this._enabledTypes.indexOf(weatherSourceTypes.YANDEX) === -1) {
        return;
      }

      const type = 'yandexWeather';
      this._deleteTypeLayers(type);

      response.forEach(el => {
        let marker = L.marker(el.point.coordinates.reverse(), {
          icon: L.divIcon({
            html: `<div class="marker-icon yandex-icon no-icon"><img src=${el.data.fact.icon} /><span>${el.data.fact.temp}</span></div>`,
            iconSize: [25, 25],
            className: '',
          }),
          id: el.id,
          type,
        });

        marker.on('click', () => {
          L.popup()
            .setLatLng(el.point.coordinates)
            .setContent(`
                <strong>Название: </strong>${el.name}</br>
                <strong>Температура (°C):</strong> ${el.data.fact.temp}</br>
                <strong>Ощущаемая температура (°C):</strong> ${el.data.fact.feels_like}</br>
                <strong>Погодное описание:</strong> ${condition[el.data.fact.condition]}</br>
                <strong>Скорость ветра (в м/с):</strong> ${el.data.fact.wind_speed}</br>
                <strong>Скорость порывов ветра (в м/с):</strong> ${el.data.fact.wind_gust}</br>
                <strong>Направление ветра:</strong> ${windDirection[el.data.fact.wind_dir]}</br>
                <strong>Давление (в мм рт. ст.):</strong> ${el.data.fact.pressure_mm}</br>
                <strong>Давление (в гектопаскалях):</strong> ${el.data.fact.pressure_pa}</br>
                <strong>Влажность воздуха (в процентах):</strong> ${el.data.fact.humidity}</br>
              `)
            .openOn(this._map);
        });

        marker.addTo(this._map);
        this._layers.push(marker);
      });
    })
      .catch(e => console.error(e));
  }

  meteoM5(isBackgroundLoading = false) {
    Weather.meteoM5List({
      isBackgroundLoading: isBackgroundLoading,
      municipalityId: this.filter.municipalityId,
    }).then(response => {
      if (this._enabledTypes.indexOf(weatherSourceTypes.AUTO_METEO) === -1) {
        return;
      }

      const type = 'meteoM5';
      this._deleteTypeLayers(type);

      response.forEach(station => {
        let marker = L.marker([station.pdm_lat, station.pdm_lon], {
          icon: L.divIcon({
            html: `<div class="marker-icon text-center no-icon"><span>${Math.round(station.t)}</span></div>`,
            iconSize: [25, 25],
            className: '',
          }),
          id: station.id,
          type,
        });

        let screenUrl = `${process.env.VUE_APP_API_BASE}/service/weather/m5/screenshot/${station.image_id}`;
        marker.on('click', () => {
          L.popup()
            .setLatLng([station.pdm_lat, station.pdm_lon])
            .setContent(`
                <strong>Станция: </strong> ${station.name}</br>
                <strong>Время получение метеоданных: </strong> ${moment(station.load_time_tmz).format('DD.MM.YYYY HH:mm')}</br>
                <strong>Температура (°C): </strong> ${Math.round(station.t * 10) / 10}</br>
                <strong>Влажность: </strong> ${station.rh}%</br>
                <strong>Температура росы (°C): </strong> ${Math.round(station.td * 10) / 10 || '-'}</br>
                <strong>Скорость ветра (в м/с): </strong> ${Math.round(station.ws * 10) / 10}</br>
                <strong>Направление ветра: </strong> ${windDirection[this.getDirectionWind(+station.wd)]}</br>
                <strong>Данные поста видеоконтроля: </strong> ${moment(station.load_time_image).format('DD.MM.YYYY HH:mm')}</br>
                <div class="m5-img"><img src="${screenUrl}" /></div>
              `)
            .openOn(this._map);
        });

        marker.addTo(this._map);
        this._layers.push(marker);
      });
    })
      .catch(e => console.error(e));
  }

  destroy() {
    this._deleteLayer();
  }

  getDirectionWind(azimut) {
    switch (true) {
      case (azimut >= 0 && azimut <= 22) || (azimut >= 339 && azimut <= 360):
        return 'n';

      case azimut >= 23 && azimut <= 67:
        return 'ne';

      case azimut >= 68 && azimut <= 112:
        return 'e';

      case azimut >= 113 && azimut <= 158:
        return 'se';

      case azimut >= 159 && azimut <= 203:
        return 's';

      case azimut >= 204 && azimut <= 248:
        return 'sw';

      case azimut >= 249 && azimut <= 293:
        return 'w';

      case azimut >= 294 && azimut <= 338:
        return 'nw';
    }
  }
}
