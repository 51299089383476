






































































































import { computed, defineComponent, onMounted, reactive, Ref } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { ILocality, TrashContainerType, TrashAreaFilters } from '@/types';
import { MultiListSelect, BasicSelect } from 'vue-search-select';
import { LayersService } from '@/services/layers-service';
import { TrashAreaCommon } from '@/services/api/report/trash-area/trash-area-common';
import { ExportService } from '@/services/export-service';
import { ACL } from '@/modules/acl/acl-service';

const municipalitiesHelpers = createNamespacedHelpers('municipalities');
const localityHelper = createNamespacedHelpers('locality');
const transporterHelper = createNamespacedHelpers('transporter');
const trashAreaHelper = createNamespacedHelpers('trash-area');
const trashContentTypeHelper = createNamespacedHelpers('trash-container-type');

interface Selected {
  id: number,
  text: string,
};

interface SelectedList {
  id: number,
  name: string,
};

export default defineComponent({
  name: 'TrashAreaControl',
  components: { MultiListSelect, BasicSelect },
  setup() {
    const filter : {
      localityId: Selected,
      address: string,
      transporterId: Selected,
      squareFrom: number | null,
      squareTo: number | null,
      trashContainerTypeId: Array<SelectedList>,
      trashContainerMaterial: Array<SelectedList>,
      countFrom: number | null,
      countTo: number | null,
      fence: Selected,
      houseCountFrom: number | null,
      houseCountTo: number | null,
      longDistance: boolean,
      longDistanceValue: number,
      capacityFrom: number | null,
      capacityTo: number | null,
      coatingTypes: Array<SelectedList>,
    } = reactive({
      localityId: { id: 0, text: '' },
      address: '',
      transporterId: { id: 0, text: '' },
      squareFrom: null,
      squareTo: null,
      trashContainerTypeId: [],
      trashContainerMaterial: [],
      countFrom: null,
      countTo: null,
      fence: { id: 0, text: '' },
      houseCountFrom: null,
      houseCountTo: null,
      longDistance: false,
      longDistanceValue: 100,
      capacityFrom: null,
      capacityTo: null,
      coatingTypes: [],
    });

    const { localities } : { localities: Ref<Array<ILocality>> } = localityHelper.useGetters(['localities']) as any;

    const { loadLocalities } : {
      loadLocalities: (municipalityId: number) => Promise<void>,
    } = localityHelper.useActions(['loadLocalities']) as any;

    const { transporters } : { transporters: Ref<Array<ILocality>> } = transporterHelper.useGetters(['transporters']) as any;

    const { coatingTypes } : {
      coatingTypes: Ref<Array<string>>,
    } = trashAreaHelper.useGetters(['coatingTypes']) as any;

    const { loadCoatingTypes } : {
      loadCoatingTypes: () => Promise<void>,
    } = trashAreaHelper.useActions(['loadCoatingTypes']) as any;

    const { trashContainerTypes, trashContainerMaterials } : {
      trashContainerTypes: Ref<Array<TrashContainerType>>,
      trashContainerMaterials: Ref<Array<string>>,
    } = trashContentTypeHelper.useGetters(['trashContainerTypes', 'trashContainerMaterials']) as any;

    const { loadTrashContainerTypes, loadTrashContainerMaterials } : {
      loadTrashContainerTypes: () => Promise<void>,
      loadTrashContainerMaterials: () => Promise<void>,
    } = trashContentTypeHelper.useActions(['loadTrashContainerTypes', 'loadTrashContainerMaterials']) as any;

    const { current } = municipalitiesHelpers.useGetters(['current']);

    const localitiesList = computed(() => {
      if (localities) {
        return [
          {
            id: 0,
            text: 'Все округа',
          },
          ...localities.value
            .filter(locality => current.value.id === 0 || current.value.id === locality.municipality_id)
            .map(locality => ({
              id: locality.id,
              text: locality.name,
            })),
        ];
      }

      return [];
    });

    const transportersList = computed(() => {
      if (transporters) {
        return [
          {
            id: 0,
            text: 'Все собственники',
          },
          ...transporters.value
            .filter(transporter => current.value.id === 0 || current.value.id === transporter.municipality_id)
            .map(transporter => ({
              id: transporter.id,
              text: transporter.name,
            })),
        ];
      }

      return [];
    });

    const canDownloadReport = computed(() => {
      return ACL.can('report_trash_area_export.form1');
    });

    const coatingTypesList = computed(() => {
      if (coatingTypes) {
        return coatingTypes.value
          .map((coating, index) => ({
            id: index,
            name: coating,
          }));
      }

      return [];
    });

    const trashContainerTypesList = computed(() => {
      if (trashContainerTypes) {
        return trashContainerTypes.value
          .map(type => ({
            id: type.id,
            name: type.name,
          }));
      }

      return [];
    });

    const trashContainerMaterialsList = computed(() => {
      if (trashContainerMaterials) {
        return trashContainerMaterials.value
          .map((material, index) => ({
            id: index,
            name: material,
          }));
      }

      return [];
    });

    const fenceList: Array<Selected> = [
      {
        id: 0,
        text: 'Не важно',
      },
      {
        id: 1,
        text: 'Есть',
      }, {
        id: 2,
        text: 'Нет',
      },
    ];

    const getFilters = () : TrashAreaFilters => {
      return {
        localityId: filter.localityId.id,
        id: 0,
        address: filter.address.trim(),
        transporterId: filter.transporterId.id,
        squareFrom: filter.squareFrom ?? 0,
        squareTo: filter.squareTo ?? 0,
        trashContainerTypeId: filter.trashContainerTypeId.map(m => m.id),
        trashContainerMaterial: filter.trashContainerMaterial.map(m => m.name),
        countFrom: filter.countFrom ?? 0,
        countTo: filter.countTo ?? 0,
        fence: filter.fence.id === 1 ? 1 : (filter.fence.id === 2 ? 0 : -1),
        houseCountFrom: filter.houseCountFrom ?? 0,
        houseCountTo: filter.houseCountTo ?? 0,
        longDistance: filter.longDistance ?? false,
        longDistanceValue: filter.longDistanceValue ?? 0,
        capacityFrom: filter.capacityFrom ?? 0,
        capacityTo: filter.capacityTo ?? 0,
        coatingTypes: filter.coatingTypes.map(m => m.name),
      };
    };

    onMounted(() => {
      loadLocalities(0);
      loadTrashContainerTypes();
      loadTrashContainerMaterials();
      loadCoatingTypes();
    });

    return {
      localitiesList,
      transportersList,
      coatingTypesList,
      trashContainerTypesList,
      trashContainerMaterialsList,
      fenceList,
      filter,
      canDownloadReport,
      refresh() {
        LayersService.instance
          ._trashAreaLayers
          ._filter(getFilters());
      },
      download() {
        TrashAreaCommon.exportReport(current.value.id, getFilters())
          .then((file) => {
            ExportService.downloadFile(file, ExportService.VND_EXCEL_FILE_TYPE, 'Форма 1.xlsx');
          })
          .catch(() => {});
      },
      selectLocality(locality: Selected) {
        filter.localityId = locality;
      },
      selectTransporter(transporter: Selected) {
        filter.transporterId = transporter;
      },
      selectCoatingTypes(type: Array<SelectedList>) {
        filter.coatingTypes = type;
      },
      selectTrashContainerType(type: Array<SelectedList>) {
        filter.trashContainerTypeId = type;
      },
      selectTrashContainerMaterial(type: Array<SelectedList>) {
        filter.trashContainerMaterial = type;
      },
      setFence(fence: Selected) {
        filter.fence = fence;
      },
    };
  },
});

