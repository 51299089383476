<template>
  <div class="layer-controls" @scroll="scrolling">
    <div class="layer" v-if="activeLayers.indexOf('analysisDouble') > -1">
      <div class="header" :class="{open: openLayer === 'analysisDouble'}" @click="toggleLayer('analysisDouble')">Анализ задублированности</div>
      <div class="content" v-if="openLayer === 'analysisDouble'">
        <analysis-double-layer-control></analysis-double-layer-control>
      </div>
    </div>
    <div class="layer" v-if="activeLayers.indexOf('analysis') > -1">
      <div class="header" :class="{open: openLayer === 'analysis'}" @click="toggleLayer('analysis')">Анализ доступности</div>
      <div class="content" v-if="openLayer === 'analysis'">
        <analysis-layer-control></analysis-layer-control>
      </div>
    </div>
    <div class="layer" v-if="activeLayers.indexOf('analysisAverage') > -1">
      <div class="header" :class="{open: openLayer === 'analysisAverage'}" @click="toggleLayer('analysisAverage')">Анализ средней скорости</div>
      <div class="content" v-if="openLayer === 'analysisAverage'">
        <analysis-average-layer-control></analysis-average-layer-control>
      </div>
    </div>
    <div class="layer" v-if="activeLayers.indexOf('transport') > -1">
      <div class="header" :class="{open: openLayer === 'transport'}" @click="toggleLayer('transport')">Транспорт</div>
      <div class="content" v-if="openLayer === 'transport'">
        <transport-layer-control :scroll="currentScroll"></transport-layer-control>
      </div>
    </div>
    <div class="layer" v-if="activeLayers.indexOf('historyTransport') > -1">
      <div class="header" :class="{open: openLayer === 'historyTransport'}" @click="toggleLayer('historyTransport')">История движения ТС</div>
      <div class="content" v-show="openLayer === 'historyTransport'">
        <history-transport-layer-control></history-transport-layer-control>
      </div>
    </div>
    <div class="layer" v-if="activeLayers.indexOf('route') > -1">
      <div class="header" :class="{open: openLayer === 'route'}" @click="toggleLayer('route')">Маршруты</div>
      <div class="content" v-if="openLayer === 'route'">
        <route-layer-control></route-layer-control>
      </div>
    </div>
    <div class="layer" v-if="activeLayers.indexOf('busStop') > -1">
      <div class="header" :class="{open: openLayer === 'busStop'}" @click="toggleLayer('busStop')">Остановки</div>
      <div class="content" v-if="openLayer === 'busStop'">
        <bus-stop-layer-control></bus-stop-layer-control>
      </div>
    </div>
    <div class="layer" v-if="activeLayers.indexOf('road') > -1">
      <div class="header" :class="{open: openLayer === 'road'}" @click="toggleLayer('road')">Дороги</div>
      <div class="content" v-if="openLayer === 'road'">
        <road-layer-control></road-layer-control>
      </div>
    </div>
    <div class="layer" v-if="activeLayers.indexOf('accident') > -1">
      <div class="header" :class="{open: openLayer === 'accident'}" @click="toggleLayer('accident')">Аварийность</div>
      <div class="content" v-if="openLayer === 'accident'">
        <accident-layer-control></accident-layer-control>
      </div>
    </div>
    <div class="layer" v-if="activeLayers.indexOf('detector') > -1">
      <div class="header" :class="{open: openLayer === 'detector'}" @click="toggleLayer('detector')">Фото-видео
        фиксация
      </div>
      <div class="content" v-if="openLayer === 'detector'">
        <detector-layer-control></detector-layer-control>
      </div>
    </div>
    <div class="layer" v-if="activeLayers.indexOf('video') > -1">
      <div class="header" :class="{open: openLayer === 'video'}" @click="toggleLayer('video')">Видеонаблюдение</div>
      <div class="content" v-if="openLayer === 'video'">
        <video-layer-control :scroll="currentScroll"></video-layer-control>
      </div>
    </div>
    <div class="layer" v-if="activeLayers.indexOf('trafficLight') > -1">
      <div class="header" :class="{open: openLayer === 'trafficLight'}" @click="toggleLayer('trafficLight')">Регулируемые перекрестки
      </div>
      <div class="content" v-if="openLayer === 'trafficLight'">
        <traffic-light-layer-control></traffic-light-layer-control>
      </div>
    </div>
    <div class="layer" v-if="activeLayers.indexOf('trafficJam') > -1">
      <div class="header" :class="{open: openLayer === 'trafficJam'}" @click="toggleLayer('trafficJam')">Пробки</div>
      <div class="content" v-if="openLayer === 'trafficJam'">
        <traffic-jam-layer-control></traffic-jam-layer-control>
      </div>
    </div>
    <div class="layer" v-if="activeLayers.indexOf('repairRoad') > -1">
      <div class="header" :class="{open: openLayer === 'repairRoad'}" @click="toggleLayer('repairRoad')">Ремон дорог
      </div>
      <div class="content" v-if="openLayer === 'repairRoad'">
        <repair-road-layer-control></repair-road-layer-control>
      </div>
    </div>
    <div class="layer" v-if="activeLayers.indexOf('parkingFine') > -1">
      <div class="header" :class="{open: openLayer === 'parkingFine'}" @click="toggleLayer('parkingFine')">
        Спецстоянки
      </div>
      <div class="content" v-if="openLayer === 'parkingFine'">
        <parking-fine-layer-control></parking-fine-layer-control>
      </div>
    </div>
    <div class="layer" v-if="activeLayers.indexOf('parking') > -1">
      <div class="header" :class="{open: openLayer === 'parking'}" @click="toggleLayer('parking')">Парковки</div>
      <div class="content" v-if="openLayer === 'parking'">
        <parking-layer-control></parking-layer-control>
      </div>
    </div>
    <div class="layer" v-if="activeLayers.indexOf('locality') > -1">
      <div class="header" :class="{open: openLayer === 'locality'}" @click="toggleLayer('locality')">Населённые пункты</div>
      <div class="content" v-if="openLayer === 'locality'">
        <locality-layer-control></locality-layer-control>
      </div>
    </div>
    <div class="layer" v-if="activeLayers.indexOf('trashArea') > -1">
      <div class="header" :class="{open: openLayer === 'trashArea'}" @click="toggleLayer('trashArea')">Площадки ТКО</div>
      <div class="content" v-if="openLayer === 'trashArea'">
        <trash-area-layer-control></trash-area-layer-control>
      </div>
    </div>
    <div class="layer" v-if="activeLayers.indexOf('house') > -1">
      <div class="header" :class="{open: openLayer === 'house'}" @click="toggleLayer('house')">Дома</div>
      <div class="content" v-if="openLayer === 'house'">
        <house-layer-control></house-layer-control>
      </div>
    </div>
    <!--<div class="layer" v-if="activeLayers.indexOf('weather') > -1">
      <div class="header" :class="{open: openLayer === 'weather'}" @click="toggleLayer('weather')">Погода</div>
      <div class="content" v-if="openLayer === 'weather'">
        <weather-layer-control></weather-layer-control>
      </div>
    </div>-->
  </div>
</template>

<script>
import { LayersService } from '@/services/layers-service';
import TransportLayerControl from '@/components/map/layer-controls/transport-layer-control';
import HistoryTransportLayerControl from '@/components/map/layer-controls/history-transport';
import AccidentLayerControl from '@/components/map/layer-controls/accident-layer-control';
import DetectorLayerControl from '@/components/map/layer-controls/detector-layer-control';
import VideoLayerControl from '@/components/map/layer-controls/video-layer-control';
import BusStopLayerControl from '@/components/map/layer-controls/bus-stop-layer-control';
import TrafficLightLayerControl from '@/components/map/layer-controls/traffic-light-layer-control';
import TrafficJamLayerControl from '@/components/map/layer-controls/traffic-jam-layer-control';
import RepairRoadLayerControl from '@/components/map/layer-controls/repair-road-layer-control';
import ParkingFineLayerControl from '@/components/map/layer-controls/parking-fine-layer-control';
import ParkingLayerControl from '@/components/map/layer-controls/parking-layer-control';
// import WeatherLayerControl from '@/components/map/layer-controls/weather-layer-control';
import RouteLayerControl from '@/components/map/layer-controls/route-layer-control';
import RoadLayerControl from '@/components/map/layer-controls/road-layer-control';
import LocalityLayerControl from '@/components/map/layer-controls/locality-layer-control';
import AnalysisLayerControl from '@/components/map/layer-controls/analysis-layer-control';
import { mapActions, mapGetters } from 'vuex';
import AnalysisDoubleLayerControl from './layer-controls/analysis-double-layer-control';
import AnalysisAverageLayerControl from './layer-controls/analysis-average-layer-control';
import TrashAreaLayerControl from './layer-controls/trash-area-layer-control';
import HouseLayerControl from './layer-controls/house-layer-control';

export default {
  name: 'layers-control',
  components: {
    RouteLayerControl,
    // WeatherLayerControl,
    ParkingLayerControl,
    HistoryTransportLayerControl,
    ParkingFineLayerControl,
    RepairRoadLayerControl,
    TrafficJamLayerControl,
    TrafficLightLayerControl,
    BusStopLayerControl,
    VideoLayerControl,
    DetectorLayerControl,
    AccidentLayerControl,
    TransportLayerControl,
    RoadLayerControl,
    LocalityLayerControl,
    AnalysisLayerControl,
    AnalysisDoubleLayerControl,
    AnalysisAverageLayerControl,
    TrashAreaLayerControl,
    HouseLayerControl,
  },
  data: () => ({
    subscription: null,
    activeLayers: [],
    currentScroll: 0,
  }),
  methods: {
    ...mapActions({
      findTransportOnDetectorLayer: 'transports/findTransportOnDetectorLayer',
      setOpenLayer: 'setOpenLayer',
    }),
    changeLayers(types) {
      this.activeLayers = types;
    },
    toggleLayer(layer) {
      this.setOpenLayer(this.openLayer === layer ? false : layer);
    },
    scrolling(event) {
      this.currentScroll = event.target.scrollTop + event.target.clientHeight;
    },
  },
  computed: {
    ...mapGetters(['openLayer']),
  },
  created() {
    this.subscription = this.changeLayers.bind(this);
    LayersService.instance.subscribe('change-layers', this.subscription);

    this.$store.subscribeAction(action => {
      if (action.type === 'transports/openDetectorLayerSidebar') {
        if (this.openLayer !== 'detector') {
          this.toggleLayer('detector');
        }
        this.$nextTick(() => {
          this.findTransportOnDetectorLayer(action.payload);
        });
      }
    });
  },
  beforeDestroy() {
    LayersService.instance.unsubscribe('change-layers', this.subscription);
  },
};
</script>

<style lang="scss" scoped>
  .layer-controls {
    overflow-y: auto;
    height: calc(100vh - var(--header-height) - 64px);
    .layer {
      .header {
        padding: 10px;
        font-size: 16px;
        transition: .3s;
        cursor: pointer;
        border-bottom: 1px solid var(--border-color);

        &.open,
        &:hover {
          background: var(--main-color);
          color: var(--white);
        }
      }

      .content {
        padding: 0 16px;
      }
    }
  }
</style>
