import L from 'leaflet';
import moment from 'moment';
import { RepairRoad } from '../api/repairRoad';
import { BehaviorSubject } from 'rxjs';
import { BaseLayer } from './base-layer';

export class RepairRoadLayer extends BaseLayer {
  _layers = null;

  _originRepairRoads = [];
  _municipalityId = null;

  repairRoads = new BehaviorSubject([]);

  constructor({ store, map, type }) {
    super({ store, map, type });

    this.repairRoads.subscribe(() => this._render());
  }

  _deleteLayer() {
    if (this._layers !== null) {
      this._layers.forEach(layer => layer.line.remove());
      this._layers = null;
      this._originRepairRoads = [];
    }
  }

  showMarker(repairRoad) {
    this._layers.forEach(layer => {
      if (layer.repairRoad.id === repairRoad.id) {
        layer.line.openPopup();
        this._map.setView([repairRoad.line.coordinates[0][0][1], repairRoad.line.coordinates[0][0][0]], 15);
      }
    });
  }

  async reRender() {
    if (this._originRepairRoads.length === 0 || this._municipalityId !== this._store.getters['municipalities/current'].id) {
      if (this._store.getters['municipalities/current'].position >= 10) {
        this.filter = {
          ...this.filter,
          municipalityId: this._store.getters['municipalities/current'].id,
        };
      } else {
        this.filter = {
          ...this.filter,
          municipalityId: null,
        };
      }
      this._municipalityId = this.filter.municipalityId;
      const response = await RepairRoad.getRepairRoad({ all: true, municipalityId: this._municipalityId, municipality: true });
      this._originRepairRoads = response.repair_roads;
    }

    this.repairRoads.next(this._originRepairRoads);
  }

  _render() {
    this._deleteLayer();
    this._layers = [];

    this.repairRoads.getValue().forEach((repairRoad) => {
      const line = L.geoJSON(repairRoad.line, {
        weight: 5,
        opacity: 1,
        color: '#ff0000',
        dashArray: '3',
        fillOpacity: 0.3,
        fillColor: '#ff0000',
      });

      const endDate = moment(repairRoad.end_date);
      const startDate = moment(repairRoad.start_date);

      line.bindPopup(`<p><strong>Наименование объекта:</strong> ${repairRoad.name}</p>` +
          `<p><strong>Заказчик работ:</strong> ${repairRoad.municipality.name}</p>` +
          `<p><strong>Вид работ:</strong> ${repairRoad.type}</p>` +
          `<p><strong>Начало работ:</strong> ${startDate.format('DD.MM.YYYY')}</p>` +
          `<p><strong>Окончание работ:</strong> ${endDate.format('DD.MM.YYYY')}</p>` +
          `<p><strong>Стоимость работ:</strong> ${repairRoad.cost}</p>` +
          `<p><strong>Процент выполнения работ:</strong> ${repairRoad.percent_completion} %</p>`,
      );

      line.addTo(this._map);

      this._layers.push({
        line,
        repairRoad,
      });
    });
  }

  destroy() {
    this._deleteLayer();
  }
}
