import { IChatGroup, IChatMessage, IFile } from '@/types';
import { AxiosRequestConfig } from 'axios';
import axios from '../axios';

export class Chat {
  static getContacts(): Promise<Array<IChatGroup>> {
    return axios.get('/user/contacts')
      .then(res => {
        return res.data.contact_groups.map((group: IChatGroup, index: number) => ({
          ...group,
          id: index,
          open: true,
        }));
      });
  }

  static getMessages(contactId: number): Promise<Array<IChatMessage>> {
    const params = {
      'filter[contact]': contactId,
      sort: '-id',
      all: 1,
      include: 'files',
    };

    return axios.get('/message', { params })
      .then(res => res.data.messages);
  }

  static sendMessage(contactId: number, body: string, files?: Array<number>) {
    return axios.post('/message', {
      to_user_id: contactId,
      body,
      files: files?.map(id => ({ id })),
    }, {
      isBackgroundLoading: true,
    } as AxiosRequestConfig)
      .then(res => res.data.message);
  }

  static editMessage(id: number | string, body: string) {
    return axios.put(`/message/${id}`, { body })
      .then(res => res.data.message);
  }

  static removeMessage(id: string | number) {
    return axios.delete('/message', {
      data: {
        messages: [{ id }],
      },
    });
  }

  static markRead(ids: Array<number>): Promise<Array<IChatMessage>> {
    if (ids.length === 0) {
      return new Promise(() => []);
    }

    const data = {
      messages: ids.map(id => ({
        id,
      })),
    };

    return axios.put('/message/read', data, {
      isBackgroundLoading: true,
    } as AxiosRequestConfig)
      .then(res => res.data.messages);
  }

  static uploadFile(file: File): Promise<IFile> {
    const formData = new FormData();
    formData.append('file', file);

    return axios.post('/file', formData)
      .then(res => res.data.file);
  }

  static downloadFile(file: IFile) {
    return axios({
      url: `file/${file.key}`,
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        if (file.type.split('/')[0] !== 'image') {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', file.name);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: file.type + ';base64' }));
          window.open(url, '_blank');
        }
      });
  }

  static massSend(to: Array<number>, body: string, files?: Array<number>) {
    return axios.post('/message/mass', {
      to: to.map(id => ({ id })),
      body,
      files: files?.map(id => ({ id })),
    })
      .then(res => res.data);
  }
}
