import axios from '@/services/axios';
import { TrashAreaResponse, TrashAreaCoatingResponse, TrashAreaFilters, TrashAreaItem } from '@/types';
import { AxiosResponse } from 'axios';

export class TrashArea {
  static getTrashAreas(
    municipalityId: number,
    filters: TrashAreaFilters | null,
    background: boolean = true,
    include: Array<string> = ['houses', 'trashContainerTypes', 'files', 'transporter'],
    fields?:{
      [key: string]: string[],
    },
  ) {
    const params = TrashArea.makeRequestFilterParams(municipalityId, filters);

    params.push('all=1');

    if (include.length) {
      params.push(`include=${include.join(',')}`);
    }

    if (fields) {
      for (const [key, value] of Object.entries(fields)) {
        params.push(`fields[${key}]=${value.join(',')}`);
      }
    }

    const query = params.length > 0 ? `?${params.join('&')}` : '';
    return axios.get<TrashAreaResponse>(`/trash-area${query}`, {
      isBackgroundLoading: background,
    } as any);
  }

  static getSingleTrashArea(id: number): Promise<TrashAreaItem|null> {
    return new Promise((resolve, reject) => {
      TrashArea.getTrashAreas(0, { id } as TrashAreaFilters, false, ['houses', 'trashContainerTypes', 'files', 'transporter', 'lastTrips']).then((trashAreas) => {
        if (trashAreas.data.trash_areas[0]) {
          resolve(trashAreas.data.trash_areas[0]);
        } else {
          resolve(null);
        }
      }).catch((e) => {
        reject(e);
      });
    });
  }
  static makeRequestFilterParams(municipalityId: number, filters: TrashAreaFilters | null) {
    const params = [];
    if (filters?.address) {
      params.push(`filter[address]=${filters.address}`);
    }

    if (filters?.id) {
      params.push(`filter[id]=${filters.id}`);
    }

    if (municipalityId) {
      params.push(`filter[municipality_id]=${municipalityId}`);
    }

    if (filters?.localityId) {
      params.push(`filter[locality_id]=${filters.localityId}`);
    }

    if (filters?.transporterId) {
      params.push(`filter[transporter_id]=${filters.transporterId}`);
    }

    if (filters?.squareFrom) {
      params.push(`filter[square][from]=${filters?.squareFrom}`);
    }
    if (filters?.squareTo) {
      params.push(`filter[square][to]=${filters?.squareTo}`);
    }

    if (filters?.trashContainerTypeId && filters?.trashContainerTypeId.length) {
      params.push(`filter[trash_container_type_id]=${filters.trashContainerTypeId.join(',')}`);
    }

    if (filters?.trashContainerMaterial && filters?.trashContainerMaterial.length) {
      params.push(`filter[trash_container_type_material]=${filters.trashContainerMaterial.join(',')}`);
    }

    if (filters?.countFrom) {
      params.push(`filter[trash_container_type_count][from]=${filters?.countFrom}`);
    }
    if (filters?.countTo) {
      params.push(`filter[trash_container_type_count][to]=${filters?.countTo}`);
    }

    if (filters?.capacityFrom) {
      params.push(`filter[trash_container_type_value][from]=${filters?.capacityFrom}`);
    }
    if (filters?.capacityTo) {
      params.push(`filter[trash_container_type_value][to]=${filters?.capacityTo}`);
    }

    if (filters?.houseCountFrom) {
      params.push(`filter[house_count][from]=${filters?.houseCountFrom}`);
    }
    if (filters?.houseCountTo) {
      params.push(`filter[house_count][to]=${filters?.houseCountTo}`);
    }

    if (filters?.longDistance && filters?.longDistance === true && filters?.longDistanceValue) {
      params.push(`filter[has_long_distance_houses]=${filters?.longDistanceValue}`);
    }

    if (filters?.coatingTypes && filters?.coatingTypes.length) {
      params.push(`filter[coating]=${filters.coatingTypes.join(',')}`);
    }

    if (filters?.hasTripsForPeriod) {
      params.push(`filter[has_trips_for_period][date_from]=${filters?.hasTripsForPeriod.date_from}`);
      params.push(`filter[has_trips_for_period][date_to]=${filters?.hasTripsForPeriod.date_to}`);
    }

    params.push(`filter[fence]=${filters?.fence ?? -1}`);

    return params;
  }

  static getCoatingTypes(): Promise<Array<string>> {
    return axios.get('/trash-area/coating')
      .then((res: AxiosResponse<TrashAreaCoatingResponse>) => {
        return res.data.coatings;
      });
  }
}
