
































































































































































































/* eslint-disable camelcase */
import Vue from 'vue';
import { computed, defineComponent, nextTick, reactive, ref, Ref, watch, onBeforeUnmount } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import Popup from '../../../../components/shared/popup.vue';
import ChatMessage from './message.vue';
import ChatMenu from './menu.vue';
import FilePreview from './file-preview.vue';
import MassSendConfirm from './mass-send-confirm.vue';
import { Chat } from '../../../../services/api/chat';
import { IChatGroup, IChatMessage, IChatShowMessage, IChatUser, IFile, MessageTypeEnum } from '../../../../types';
import UserService from '../../../../services/user-service';
import moment from 'moment';
import { ACL } from '../../../../modules/acl/acl-service';
import { isMobile } from 'mobile-device-detect';
import { SocketService } from '../../../../services/socket';
import { NotificationsService } from '../../../../modules/notifications/notifications-service';

const chatHelpers = createNamespacedHelpers('chat');

export default defineComponent({
  components: { Popup, ChatMessage, ChatMenu, FilePreview, MassSendConfirm },
  setup(props, { root }) {
    const { open, unreadCounter }: {
      open: Ref<boolean>,
      unreadCounter: Ref<boolean>,
    } = chatHelpers.useGetters(['open', 'unreadCounter']) as any;
    const { openChat, closeChat, setUnreadCounter, incrementUnreadCounter }: {
      openChat: () => Promise<void>,
      closeChat: () => Promise<void>,
      setUnreadCounter: (payload: number) => Promise<void>,
      incrementUnreadCounter: (payload: number) => Promise<void>,
    } = chatHelpers.useActions(['openChat', 'closeChat', 'setUnreadCounter', 'incrementUnreadCounter']) as any;

    const messages: Ref<Array<IChatShowMessage>> = ref([]);

    const groups: {
      data: Array<IChatGroup>
    } = reactive({
      data: [],
    });
    const unreadMessages: {
      data: Array<number>,
    } = reactive({
      data: [],
    });
    const authorRecUser: {
      data: IChatUser | null,
    } = reactive({
      data: null,
    });
    const message: Ref<string> = ref('');
    const isChatDisabled: Ref<boolean> = ref(!ACL.can('messenger.user-contacts'));
    const isAbleToSendMessage: Ref<boolean> = computed(() => {
      if (!isMassSendMode.value) {
        return message.value.length > 0 || attachedFiles.data.length > 0;
      } else {
        return massSendData.users.length > 0 && (message.value.length > 0 || attachedFiles.data.length > 0);
      }
    });
    const isMobileMode: Ref<boolean> = computed(() => isMobile);
    const isChatOpened: Ref<boolean> = ref(false);
    const isMenuOpen: Ref<boolean> = ref(true);
    const isJumperShown: Ref<boolean> = ref(false);
    const editMessageId: Ref<number | string> = ref(0);
    const isEditMode: Ref<boolean> = computed(() => editMessageId.value !== 0);
    const isEditModeForError: Ref<boolean> = ref(false);
    const buffer: {
      files: Array<IFile>,
      text: string
    } = {
      files: [],
      text: '',
    };
    const receiverId: Ref<number> = ref(0);
    const position: Ref<number> = ref(0);
    const newUnreadMessagesCount: Ref<number> = computed(() => unreadMessages.data.length);
    const upload: Ref<HTMLInputElement | null> = ref(null);
    const textarea: Ref<HTMLInputElement | null> = ref(null);
    const attachedFiles: {
      data: Array<IFile>,
    } = reactive({
      data: [],
    });
    const isDragActive: Ref<boolean> = ref(false);

    const authorMe: Ref<string> = ref(UserService.instance.user.name);

    const newMessageAudio = new Audio('/assets/chat/inbound-message-sound.mp3');
    const playNewMessageSound = () => {
      newMessageAudio.play();
    };

    const readBuffer: Array<number> = [];

    const isMassSendMode = ref(false);
    const isMassSendConfirm = ref(false);
    const massSendData: {
      users: Array<IChatUser>,
    } = reactive({
      users: [],
    });

    const generateMessage = (message: IChatMessage, isInput: boolean) => {
      return {
        id: message.id,
        date: moment.utc(message.created_at).local().format('DD.MM.YYYY HH:mm:ss'),
        text: message.body,
        from: message.from_user_id,
        read: !!message.readed_at,
        readed_at: moment.utc(message.readed_at).local().format('DD.MM.YYYY HH:mm:ss'),
        edited_at: message.edited_at ? moment.utc(message.edited_at).local().format('DD.MM.YYYY HH:mm:ss') : '',
        input: isInput,
        output: !isInput,
        files: message.files,
        error: false,
      };
    };

    const generateGroup = (group: IChatGroup): IChatGroup => {
      return {
        ...group,
        open: false,
        users: group.users.map(u => {
          return {
            ...u,
            last_seen: u.is_online
              ? 'В сети'
              : u.last_seen
                ? 'Был в сети ' + moment.utc(u.last_seen).local().format('DD.MM.YYYY HH:mm:ss')
                : 'Не был в сети',
          };
        }),
      };
    };

    const markRead = (data: Array<IChatMessage>) => {
      data.forEach(message => {
        const shownMessage = messages.value.find(m => m.id === message.id);
        if (shownMessage) {
          shownMessage.read = !!message.readed_at;
          shownMessage.readed_at = moment.utc(message.readed_at).local().format('DD.MM.YYYY HH:mm:ss');
        }
      });

      unreadMessages.data = unreadMessages.data.filter(id => data.find(message => message.id !== id));

      const inputMessages = data.filter(message => message.from_user_id === receiverId.value);

      groups.data.forEach(group => {
        const user = group.users.find(user => Number(user.id) === Number(receiverId.value));

        if (user) {
          user.count_unread_messages -= inputMessages.length;

          if (user.count_unread_messages < 0) {
            user.count_unread_messages = 0;
          }
        }
      });
      incrementUnreadCounter(-data.length);
    };

    const getUnreadCount = (data: Array<IChatGroup>) => {
      let count = 0;
      const users: Array<number> = [];

      data.forEach((group: IChatGroup) => {
        group.users.forEach(user => {
          if (users.findIndex(id => id === user.id) === -1) {
            count += user.count_unread_messages;
            users.push(user.id);
          }
        });
      });

      if (count > 0 && !isChatOpened.value) {
        openChat();
      }

      setUnreadCounter(count);
    };

    const initUnreadMessages = () => {
      if (!UserService.instance.isAuth || isChatDisabled.value) {
        return;
      }

      Chat.getContacts()
        .then(res => {
          groups.data = res.map(generateGroup);
          getUnreadCount(res);
        });
    };
    initUnreadMessages();

    const openChatWithUser = (user: IChatUser) => {
      const id = user.id;
      isMenuOpen.value = !isMobileMode.value;
      isDragActive.value = false;
      attachedFiles.data = [];
      updateInputHeight();
      Chat.getMessages(id)
        .then(res => {
          messages.value = res.map(message => {
            return generateMessage(
              message,
              message.from_user_id === id,
            );
          });

          isChatOpened.value = true;
          receiverId.value = id;
          authorRecUser.data = {
            ...user,
            lastSeenShortFormat: user.last_seen !== 'Не был в сети'
              ? 'Был в сети ' + moment(user.last_seen, 'DD.MM.YYYY HH:mm:ss').local().format('DD.MM.YYYY HH:mm:ss')
              : 'Не был в сети',
          };
        });
    };

    const resetBuffer = () => {
      editMessageId.value = 0;
      isEditModeForError.value = false;

      attachedFiles.data = [...buffer.files];
      message.value = buffer.text;
      updateInputHeight();

      buffer.files = [];
      buffer.text = '';
    };

    const doMassSend = () => {
      isMassSendConfirm.value = true;
      const messages = document.querySelector('.chat-main-messages') as HTMLElement | undefined;
      if (messages) {
        messages.style.height = '95%';
      }
    };

    const sendMessageWrapper = () => {
      if (isMassSendMode.value) {
        doMassSend();
      } else if (!isEditMode.value) {
        sendMessage();
      } else {
        editMessage();
      }
    };

    const editMessage = () => {
      if (!isEditMode.value) {
        return;
      }

      if (!isEditModeForError.value) {
        Chat.editMessage(editMessageId.value, message.value)
          .then(m => {
            messages.value = messages.value.map(message => {
              if (message.id === m.id) {
                return {
                  ...generateMessage(m, false),
                  files: [...attachedFiles.data],
                };
              } else {
                return message;
              }
            });
            resetBuffer();
          });
      } else {
        const messageToEdit = messages.value.find(m => m.id === editMessageId.value);
        messages.value = messages.value.filter(m => m.id !== editMessageId.value);

        if (messageToEdit) {
          sendMessage({
            ...messageToEdit,
            text: message.value,
          });
          resetBuffer();
        }
      }
    };

    const sendMessage = (messageToResend?: IChatShowMessage) => {
      let files = [...attachedFiles.data];
      let id = `temp_${Math.floor(Math.random() * 10000)}`;
      let tempMessage: IChatShowMessage = {
        id,
        date: '',
        text: message.value,
        from: authorRecUser.data?.id ?? 0,
        read: false,
        readed_at: '',
        input: false,
        output: true,
        error: false,
        files,
      };

      if (messageToResend) {
        files = messageToResend.files;
        id = messageToResend.id.toString();
        tempMessage = messageToResend;
      }
      messages.value.unshift(tempMessage);

      Chat.sendMessage(receiverId.value, tempMessage.text, files.map(file => file.id))
        .then(message => {
          const foundTempMessage = messages.value.findIndex(m => m.id === id);

          if (foundTempMessage !== -1) {
            messages.value = messages.value.map(m => {
              if (m.id === id) {
                return generateMessage({ ...message, files }, false);
              };

              return m;
            });
          } else {
            messages.value.unshift(generateMessage({ ...message, files }, false));
          }

          if (!isJumperShown.value) {
            jump();
          }
        })
        .catch(() => {
          tempMessage.error = true;
        });
      attachedFiles.data = [];
      message.value = '';
      updateInputHeight();
    };

    const sendMessageOnEnter = (key: KeyboardEvent) => {
      if (key.key === 'Escape' && isEditMode.value) {
        resetBuffer();
        return;
      }

      if (!key.ctrlKey && !key.shiftKey && (key.key === 'Enter' || (key.keyCode !== undefined && key.keyCode === 13))) {
        key.preventDefault();
      }
      if (isChatOpened.value && isAbleToSendMessage.value && !key.ctrlKey && !key.shiftKey && (key.key === 'Enter' || (key.keyCode !== undefined && key.keyCode === 13))) {
        sendMessageWrapper();
      } else if (key.ctrlKey && (key.key === 'Enter' || (key.keyCode !== undefined && key.keyCode === 13))) {
        message.value += '\r\n';
      }
    };

    const jump = () => {
      const messages: HTMLElement | null = document.querySelector('.chat-main-messages');

      if (messages) {
        messages.scrollTo({ top: 0 });
      }
    };

    const onNewMessage = (newMessages: Array<IChatMessage>) => {
      const openedMessages = newMessages
        .filter(message => message.from_user_id === receiverId.value)
        .map(message => {
          return generateMessage(
            message,
            message.from_user_id === receiverId.value,
          );
        });

      if (openedMessages.length > 0) {
        incrementUnreadCounter(openedMessages.length);
        if (document.hidden) {
          playNewMessageSound();
        }
        messages.value.unshift(...openedMessages);

        if (!isJumperShown.value) {
          jump();
        }

        openedMessages.forEach(message => {
          if (unreadMessages.data.indexOf(message.id) === -1) {
            unreadMessages.data.push(message.id);
          }
        });

        groups.data.forEach(group => {
          const user = group.users.find(user => Number(user.id) === Number(receiverId.value));

          if (user) {
            user.count_unread_messages += openedMessages.length;
          }
        });
      }

      const closedMessages = newMessages
        .filter(message => message.from_user_id !== receiverId.value)
        .map(message => {
          return generateMessage(
            message,
            message.from_user_id === receiverId.value,
          );
        });

      if (closedMessages.length > 0) {
        incrementUnreadCounter(closedMessages.length);
        playNewMessageSound();

        const counters = closedMessages.reduce((prev: {
          [key: string]: number,
        }, current) => {
          if (prev[current.from]) {
            prev[current.from]++;
          } else {
            prev[current.from] = 1;
          }

          return prev;
        }, {});
        const incremented: Array<string> = [];

        Object.keys(counters).forEach(id => {
          groups.data.forEach(group => {
            const user = group.users.find(user => Number(user.id) === Number(id));

            if (user) {
              user.count_unread_messages += counters[id];

              if (incremented.indexOf(id) === -1) {
                incremented.push(id);
              }
            }
          });
        });

        if (Object.keys(counters).length !== incremented.length) {
          initUnreadMessages();
        }
      }
    };

    const setUserStatus = (data: Array<{
      id: number,
      is_online: boolean,
      last_seen: string,
    }>) => {
      data.forEach(u => {
        groups.data.forEach(group => {
          const user = group.users.find(user => Number(user.id) === Number(u.id));

          if (user) {
            user.is_online = u.is_online;
            user.last_seen = u.is_online ? 'В сети' : moment.utc(u.last_seen).local().format('DD.MM.YYYY HH:mm:ss');
          }

          if (user && authorRecUser.data && authorRecUser.data.id === user.id) {
            authorRecUser.data.is_online = u.is_online;
            authorRecUser.data.last_seen = u.is_online ? 'В сети' : moment.utc(u.last_seen).local().format('DD.MM.YYYY HH:mm:ss');
            authorRecUser.data.lastSeenShortFormat = authorRecUser.data.last_seen !== 'Не был в сети'
              ? 'Был в сети ' + moment(authorRecUser.data.last_seen, 'DD.MM.YYYY HH:mm:ss').local().format('DD.MM.YYYY HH:mm:ss')
              : 'Не был в сети';
          }
        });
      });
    };

    const handleMessageRemoving = (data: Array<IChatMessage>) => {
      // Удаляем сообщения из чата
      const ids = data.map(m => m.id);
      messages.value = messages.value.filter(m => ids.indexOf(Number(m.id)) === -1);

      // Ниже логика по изменению счётчиков непрочитанных сообщений
      // Смотрим, какие сообщения из текущего чата и непрочитаны
      const openedMessages = data
        .filter(message => message.from_user_id === receiverId.value && !message.readed_at);

      // Уменьшаем счётчик непрочитанных сообщений, которые касаются текущего контакта
      if (openedMessages.length > 0) {
        incrementUnreadCounter(-openedMessages.length);
        unreadMessages.data = unreadMessages.data.filter(id => data.find(message => message.id !== id));

        groups.data.forEach(group => {
          const user = group.users.find(user => Number(user.id) === Number(receiverId.value));

          if (user) {
            user.count_unread_messages -= openedMessages.length;

            if (user.count_unread_messages < 0) {
              user.count_unread_messages = 0;
            }
          }
        });
      }

      // Уменьшаем счётчик непрочитанных сообщений, которые не касаются текущего контакта
      const closedMessages = data
        .filter(message => message.from_user_id !== receiverId.value && !message.readed_at);

      if (closedMessages.length) {
        incrementUnreadCounter(-closedMessages.length);
        const counters = closedMessages.reduce((prev: {
          [key: string]: number,
        }, current) => {
          if (prev[current.from_user_id]) {
            prev[current.from_user_id]++;
          } else {
            prev[current.from_user_id] = 1;
          }

          return prev;
        }, {});
        const incremented: Array<string> = [];

        Object.keys(counters).forEach(id => {
          groups.data.forEach(group => {
            const user = group.users.find(user => Number(user.id) === Number(id));

            if (user) {
              user.count_unread_messages -= counters[id];

              if (user.count_unread_messages < 0) {
                user.count_unread_messages = 0;
              }

              if (incremented.indexOf(id) === -1) {
                incremented.push(id);
              }
            }
          });
        });
      }
    };

    const handleMessageEditing = (data: Array<IChatMessage>) => {
      const editedMessages = data
        .filter(message => message.from_user_id === receiverId.value);

      if (editedMessages.length > 0) {
        messages.value = messages.value.map(message => {
          const newMessage = editedMessages.find(m => m.id === message.id);

          if (newMessage) {
            return generateMessage(newMessage, true);
          } else {
            return message;
          }
        });
      }
    };

    watch(open, () => {
      if (open.value) {
        isMenuOpen.value = true;
        resetBuffer();

        const input: HTMLElement | null = document.querySelector('.chat-main-input textarea');
        const messages: HTMLElement | null = document.querySelector('.chat-main-messages');
        const files: HTMLElement | null = document.querySelector('.chat-main-input-files');
        // const wrapper: HTMLElement | null = document.querySelector('.chat-main-input');

        if (input && messages && files) {
          input.style.height = '21px';
          messages.style.height = `calc(95% - 85px - ${files.clientHeight}px)`;
          // wrapper.style.height = `calc(85px + ${files.clientHeight}px)`;
        }

        initUnreadMessages();
      }

      if (open.value) {
        document.addEventListener('keyup', sendMessageOnEnter);
      } else {
        document.removeEventListener('keyup', sendMessageOnEnter);
      }
    });

    const updateInputHeight = async() => {
      await nextTick();

      const input: HTMLElement | null = document.querySelector('.chat-main-input textarea');
      const messages: HTMLElement | null = document.querySelector('.chat-main-messages');
      const files: HTMLElement | null = document.querySelector('.chat-main-input-files');
      // const wrapper: HTMLElement | null = document.querySelector('.chat-main-input');

      if (input && messages && files) {
        input.style.height = message.value.length ? `${Math.min(input.scrollHeight, 200)}px` : '21px';
        messages.style.height = message.value.length ? `calc(95% - ${Math.min(Math.max(input.clientHeight, 60), 200) + 25}px - ${files.clientHeight}px)` : `calc(95% - 85px - ${files.clientHeight}px)`;
        // wrapper.style.height = message.value.length ? `calc(${Math.min(Math.max(input.clientHeight, 60), 200) + 25}px + ${files.clientHeight}px)` : `calc(85px + ${files.clientHeight}px)`;
      }
    };

    watch(message, updateInputHeight);

    const websocket = ref(SocketService.run());
    const websocketListener = (event: MessageEvent<string>) => {
      const eventData: {
          type: MessageTypeEnum,
          payload: any,
        } = JSON.parse(event.data);

      switch (eventData.type) {
        case MessageTypeEnum.MESSAGE:
          const { action, data }: {
              action: 'postMessage' | 'readMessages' | 'statusUser' | 'deleteMessages' | 'editMessage',
              data: Array<IChatMessage>,
            } = eventData.payload;

          if (action === 'postMessage') {
            onNewMessage(data);
          } else if (action === 'readMessages') {
            markRead(data);
          } else if (action === 'statusUser') {
            setUserStatus(data as any);
          } else if (action === 'deleteMessages') {
            handleMessageRemoving(data);
          } else if (action === 'editMessage') {
            handleMessageEditing(data);
          }
          break;

        default:
          break;
      }
    };

    const websocketOnOpen = () => {
      if (authorRecUser.data) {
        openChatWithUser(authorRecUser.data);
      }
    };

    const initWebsocket = () => {
      websocket.value?.removeEventListener('open', websocketOnOpen);
      websocket.value?.removeEventListener('message', websocketListener);
      websocket.value = SocketService.run();
      websocket.value?.addEventListener('message', websocketListener);
      websocket.value?.addEventListener('open', websocketOnOpen);
    };

    const onPaste = async(event: ClipboardEvent) => {
      try {
        let blobOutput: Blob | File | null = null;

        if (navigator.clipboard) {
          const clipboardItems = await (navigator.clipboard as any).read();
          blobOutput = await clipboardItems[0].getType('image/png') as Blob;
        } else if (event.clipboardData) {
          const items = event.clipboardData.items;
          for (let index = 0; index < items.length; index++) {
            const item = items[index];

            if (item.type === 'image/png') {
              blobOutput = item.getAsFile();
              break;
            }
          }
        }

        if (!blobOutput) {
          return;
        }

        const file = new File([blobOutput], `Изображение ${moment().local().format('DD.MM.YYYY HH:mm:ss')}.png`, {
          type: 'image/png',
        }); ;
        Chat.uploadFile(file)
          .then(res => {
            attachedFiles.data.push(res);
            updateInputHeight();
          })
          .catch(() => {});
      } catch (error) {}
    };
    document.addEventListener('paste', onPaste);

    const uploadFilesArray = (files: Array<File>) => {
      files.forEach(file => {
        Chat.uploadFile(file)
          .then(res => {
            attachedFiles.data.push(res);
            updateInputHeight();
          })
          .catch(() => {});
      });
    };

    const onBeforeFileDrop = () => {
      if (!isChatOpened.value) {
        return;
      }

      isDragActive.value = true;
    };

    const onAfterFileDrop = async() => {
      await nextTick();
      isDragActive.value = false;
    };

    if (UserService.instance.isAuth) {
      initWebsocket();
    }

    UserService.instance.subscribe('logout', () => {
      closeChat();
      setUnreadCounter(0);
      websocket.value?.removeEventListener('message', websocketListener);
      SocketService.close();
    });

    onBeforeUnmount(() => {
      document.removeEventListener('paste', onPaste);
      SocketService.close();
    });

    UserService.instance.subscribe('login', () => {
      isChatDisabled.value = !ACL.can('messenger.user-contacts');
      authorMe.value = UserService.instance.user.name;
      resetBuffer();
      initWebsocket();
      initUnreadMessages();
    });

    return {
      open,
      message,
      messages,
      groups,
      isChatOpened,
      isChatDisabled,
      isAbleToSendMessage,
      isMenuOpen,
      isMobileMode,
      isJumperShown,
      isEditMode,
      isMassSendMode,
      isMassSendConfirm,
      authorRecUser,
      position,
      newUnreadMessagesCount,
      upload,
      textarea,
      attachedFiles,
      massSendData,
      unreadCounter,
      onClose() {
        if (isMobileMode.value) {
          root.$router.back();
        }
        closeChat();
        message.value = '';
        messages.value = [];
        isChatOpened.value = false;
        isMassSendMode.value = false;
        isMassSendConfirm.value = false;
        receiverId.value = 0;
        authorRecUser.data = null;
      },
      openChat(user: IChatUser) {
        openChatWithUser(user);
      },
      startMassSend() {
        isMassSendMode.value = true;
        attachedFiles.data = [];
        message.value = '';
        updateInputHeight();
      },
      cancelMassSend() {
        isMassSendMode.value = false;
        isMassSendConfirm.value = false;
        attachedFiles.data = [];
        message.value = '';
        updateInputHeight();
      },
      updateMassSend(users: Array<IChatUser>) {
        massSendData.users = users;
      },
      messagesScrolled(e: Event) {
        const messages: HTMLElement = e.target as HTMLElement;
        isJumperShown.value = Math.abs(messages.scrollTop) > Math.floor(messages.clientHeight / 4);
        position.value = Math.abs(messages.scrollTop);
      },
      markMessageRead(id: number) {
        if (readBuffer.indexOf(id) === -1) {
          readBuffer.push(id);
          Chat.markRead([id])
            .then(markRead);
        }
      },
      removeMessage(id: string) {
        messages.value = messages.value.filter(m => m.id !== id);

        if (editMessageId.value && editMessageId.value === id) {
          resetBuffer();
        }
      },
      resendMessage(id: string) {
        const message = messages.value.find(m => m.id === id);
        messages.value = messages.value.filter(m => m.id !== id);

        if (message) {
          sendMessage(message);
        }
      },
      deleteMessage(id: number) {
        if (editMessageId.value && editMessageId.value === id) {
          resetBuffer();
        }

        Chat.removeMessage(id)
          .then(() => {
            messages.value = messages.value.filter(m => m.id !== id);
          })
          .catch(() => {});
      },
      editMessage(id: number | string) {
        if (isEditMode.value) {
          resetBuffer();
        }

        buffer.files = [...attachedFiles.data];
        buffer.text = message.value;
        updateInputHeight();

        const messageToEdit = messages.value.find(m => m.id === id);

        if (messageToEdit) {
          attachedFiles.data = [ ...messageToEdit.files ];
          message.value = messageToEdit.text;
          isEditModeForError.value = messageToEdit.error;
        }
        editMessageId.value = id;

        if (textarea.value) {
          textarea.value.focus();
        }
      },
      uploadFile(e: InputEvent) {
        const files = Array.from((e.target as HTMLInputElement).files as unknown as ArrayLike<File>);

        uploadFilesArray(files);

        (e.target as HTMLInputElement).value = '';
      },
      removeAttachedFile(id: number) {
        attachedFiles.data = attachedFiles.data.filter(file => file.id !== id);
        updateInputHeight();
      },
      chooseFile() {
        if (upload.value) {
          upload.value.click();
        }
      },
      jump,
      sendMessage,
      isDragActive,
      sendMessageWrapper,
      onFileDrop(event: DragEvent) {
        if (!isChatOpened.value) {
          return;
        }
        const files: Array<File> = [];

        if (event.dataTransfer) {
          const items = event.dataTransfer.items;
          for (let index = 0; index < items.length; index++) {
            const item = items[index];
            const file = item.getAsFile();

            if (file && item.kind === 'file') {
              files.push(file);
            }
          }
        }

        uploadFilesArray(files);
        onAfterFileDrop();
      },
      onBeforeFileDrop,
      onAfterFileDrop,
      resetBuffer,
      confirmMassSend() {
        Chat.massSend(massSendData.users.map(u => u.id), message.value, attachedFiles.data.map(file => file.id))
          .then(() => {
            NotificationsService.instance.add({
              type: 'success',
              text: 'Сообщения успешно доставлены',
            });
            attachedFiles.data = [];
            message.value = '';
            updateInputHeight();
            isMassSendMode.value = false;
            isMassSendConfirm.value = false;

            if (authorRecUser.data) {
              openChatWithUser(authorRecUser.data);
            }
          })
          .catch(() => {});
      },
      cancelConfirmMassSend() {
        isMassSendConfirm.value = false;
        updateInputHeight();
      },
    };
  },
});
