
export default {
  namespaced: true,
  state: {
    filter: {
      number: '',
      transporter: {},
      status: [],
      inLineTransport: false,
      notInLineTransport: false,
      isOnRoute: false,
    },
  },
  getters: {
    filter: state => state.filter,
  },
  mutations: {
    setFilter: (state, filter) => {
      state.filter = {
        ...filter,
      };
    },
    updateTransporterFilter: () => {},
  },
  actions: {
    setFilter: ({ commit }, filter) => {
      commit('setFilter', filter);
    },
  },
};
