import axios from '../axios';

export class Road {
  static getRoads({ all, filter = {}, include = '' }: {
    all: boolean,
    filter: {
      [key: string]: any,
    },
    include: string,
  }) {
    const params: {
      [key: string]: string,
    } = {};

    if (all) {
      params.all = '';
    }

    if (include) {
      params.include = include;
    }

    if (filter.municipalityId) {
      params['filter[municipality_id]'] = filter.municipalityId.toString();
    }

    if (filter.type) {
      params['filter[type]'] = filter.type;
    }

    return axios.get('/road', {
      params,
    })
      .then(({ data }) => data)
      .catch(e => e);
  }
}
