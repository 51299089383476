import { Transport } from '@/services/api/transport';
import { Route } from '@/services/api/route';

export default {
  state: {
    transports: [],
    brokenTransports: [],
    transportTypes: [],
    routeFilter: null,
    transportGroups: [],
  },
  getters: {
    transports: state => state.transports,
    brokenTransports: state => state.brokenTransports,
    transportTypes: state => state.transportTypes,
    routeFilter: state => state.routeFilter,
    transportGroups: state => state.transportGroups,
  },
  mutations: {
    transports: (state, payload) => { state.transports = payload; },
    brokenTransports: (state, payload) => { state.brokenTransports = payload; },
    transportTypes: (state, payload) => { state.transportTypes = payload; },
    setRouteFilter: (state, payload) => { state.routeFilter = payload; },
    transportGroups: (state, payload) => { state.transportGroups = payload; },
  },
  actions: {
    loadTransports(state, { filter = [], include = [] }) {
      return new Promise((resolve, reject) => {
        Transport.getTransports({ filter, include })
          .then(response => {
            state.commit('transports', response.transports);
            resolve(response);
          })
          .catch(reject);
      });
    },
    loadBrokenTransports(state) {
      return new Promise((resolve, reject) => {
        Transport.getBrokenTransports()
          .then(response => {
            state.commit('brokenTransports', response.broken);
            resolve(response.broken);
          })
          .catch(reject);
      });
    },
    loadTransportTypes(state) {
      return new Promise((resolve, reject) => {
        Transport.loadTransportTypes()
          .then(response => {
            state.commit('transportTypes', response.transport_types);
            resolve(response);
          })
          .catch(e => reject(e));
      });
    },
    showRoute({ commit }, routeId) {
      return new Promise((resolve, reject) => {
        Route.getRoutes({
          filter: {
            routeId,
          },
          include: ['route_groups'],
        })
          .then(response => {
            const data = response.data;
            if (!data.length) {
              reject(new Error('Route not found'));
              return;
            }

            const route = data[0];

            commit('setRouteFilter', route);
            resolve(route);
          })
          .catch(e => reject(e));
      });
    },
    clearRoute({ commit }) {
      commit('setRouteFilter', null);
    },
    loadTransportGroups(state) {
      return new Promise((resolve, reject) => {
        Transport.loadTransportGroups()
          .then(response => {
            state.commit('transportGroups', response.transport_groups);
            resolve(response);
          })
          .catch(e => reject(e));
      });
    },
    showDetectorLayer(context, transportNumber) { },
    openDetectorLayerSidebar(context, transportNumber) { },
    findTransportOnDetectorLayer(context, transportNumber) { },
    openTransportLayer() { },
    brokenLoaded: () => {
    },
  },
};
