import { NotificationsService } from '@/modules/notifications/notifications-service';

let Notifications = {};

Notifications.install = function(Vue) {
  Vue.prototype.$flash_primary = function(text) {};
  Vue.prototype.$flash_success = function(text) {};
  Vue.prototype.$flash_warning = function(text) {};
  Vue.prototype.$flash_danger = function(text) {};

  NotificationsService.instance.types.forEach(type => {
    Vue.prototype['$flash_' + type] = function(text) {
      NotificationsService.instance.add({
        type: type,
        text,
      });
    };
  });

  Vue.prototype.$flash_api_errors = function(errors) {
    if (Array.isArray(errors)) {
      errors.forEach(error => {
        this.$flash_warning(error);
      });
    } else {
      Object.keys(errors).map(key => {
        errors[key].forEach(error => {
          this.$flash_warning(error);
        });
      });
    }
  };
};

export default Notifications;
