import axios from '../../axios';

export class DailyMtsReport {
  static loadData({ mtsId, date, direction }) {
    return new Promise((resolve, reject) => {
      axios.get(`https://rnis.dorogi73.ru/api/logs/mts-schedule-execution-report/${mtsId}/${direction}/?date=${date}`, {
        headers: {
          Authorization: 'Bearer exzIk5c3oCvzuG4UwhvxGffCVKm6OxYH',
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch(e => reject(e));
    });
  }

  static loadRoutes({ date }) {
    return new Promise((resolve, reject) => {
      axios.get(`/mts/route-on-day/?page_size=999&date=${date}`, {
        headers: {
          Authorization: 'Bearer exzIk5c3oCvzuG4UwhvxGffCVKm6OxYH',
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => reject(e));
    });
  }
}
