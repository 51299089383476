export default [
  {
    path: '/kommun-transportation/schedule',
    name: 'kommun_transportation.schedule',
    component: () => import(/* webpackChunkName: "kommun-transportation.schedule" */ '@/views/kommun-transportation/schedule'),
  },
  {
    path: '/kommun-transportation/history/:number',
    name: 'kommun_transportation.history-detail',
    component: () => import(/* webpackChunkName: "kommun-transportation.history-detail" */ '@/views/kommun-transportation/history/history-detail'),
    props: true,
  },
];
