<template>
  <div>
    <div class="left-menu" :class="{open: openMenu}">
      <div class="menu-item" v-for="(mItem, index) in menu" :key="index" :class="{open: mItem.open}">
        <template v-if="checkPermission(mItem.permission)">
          <div
            class="child-title"
            :class="{ 'child-disabled': !!mItem.disabled }"
            @click="menu[index].open = !menu[index].open && !mItem.disabled"
          >
            <img :src="mItem.iconUrl" :alt="mItem.title" class="child-icon">
            <span>{{ mItem.title }}</span>
            <item-open-close-icon :open="menu[index].open"></item-open-close-icon>
          </div>
          <div class="child" v-if="mItem.open">
            <div
              class="child-item"
              v-for="(child, index) in mItem.children
                .filter(c => (!c.permission || checkPermission(c.permission)) && (!c.children ||
                  c.children.filter(ch => checkPermission(ch.permission)).length > 0))"
              :key="index"
              :class="{ 'child-disabled': !!child.disabled }"
            >
              <router-link
                v-if="child.route"
                :to="child.route"
              >
                  <span>{{ child.title }}</span>
                </router-link>
              <div class="child-item-title" v-else @click="child.open = !child.open && !child.disabled">
                <span>{{ child.title }}</span>
                <item-open-close-icon v-if="child.children" :open="child.open"></item-open-close-icon>
              </div>
              <div class="child" v-if="child.children && child.open">
                <div
                  class="child-item"
                  v-for="(ch, index) in child.children"
                  :key="index"
                  v-show="checkPermission(ch.permission)"
                  :class="{ 'child-disabled': !!ch.disabled }"
                >
                  <router-link v-if="ch.route && !ch.disabled" :to="ch.route">
                    <p :title="ch.subtitle">{{ ch.title }}</p>
                  </router-link>
                  <template v-else>
                    <p :title="ch.subtitle" @click="clickHandler(ch)">{{ ch.title }}</p>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="left-menu left-menu-closed" v-show="!openMenu">
      <div
        class="menu-item"
        v-for="(mItem, index) in enabledMenuItems" :key="index"
        :class="{open: mItem.open}"
        @click="openMenuFromClosed(index)"
      >
        <img :src="mItem.iconUrl" :alt="mItem.title" class="closed-icon">
      </div>
    </div>

      <!-- TODO: Вынести в отдельный компонент sideBars -->
    <app-sidebar
        v-show='sidebar=="layers"&&isNeedSidebar'
        :open="openSidebar"
        @toggle-sidebar="toggleSidebar()"
        :class="[openMenu ? '': 'closed-menu', 'menu-sidebars']" >
      <template slot="header">{{ sidebarTitle }}</template>
      <layers-control></layers-control>
    </app-sidebar>
  </div>

</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import LayersControl from '@/components/map/layers-control';
import AppSidebar from '@/components/app/app-sidebar';
import ItemOpenCloseIcon from '@/components/shared/item-open-close-icon';
import { TrashAreaCommon } from '@/services/api/report/trash-area/trash-area-common';
import { ExportService } from '@/services/export-service';
import { InitializeService } from '@/services/initialize-service';
import { KOMMUN_TYPE_GROUP } from '@/constants/RouteTypeGroups';

export default {
  name: 'side-menu',
  components: { AppSidebar, LayersControl, ItemOpenCloseIcon },
  props: {
    openMenu: {
      type: Boolean,
      required: true,
    },

    checkPermission: {
      type: Function,
      default: () => {},
    },
  },

  data: () => ({
    menu: [
      {
        id: KOMMUN_TYPE_GROUP,
        title: 'Коммунальное хозяйство',
        iconUrl: '/assets/icons/kommun.png',
        open: false,
        route: '',
        permission: null,
        children: [
          {
            title: 'Отчеты',
            children: [
              { title: 'Форма № 1',
                subtitle: 'Реестр контейнерных площадок',
                click: function() {
                  TrashAreaCommon.exportReport(null)
                    .then((file) => {
                      ExportService.downloadFile(file, ExportService.VND_EXCEL_FILE_TYPE, 'Форма 1.xlsx');
                    })
                    .catch(() => {});
                },
                permission: 'report_trash_area_export.form1',
              },
              { title: 'Форма № 2',
                subtitle: 'Рабочий  реестр контейнерных площадок',
                permission: 'report_trash_area_export.form2',
                route: { name: 'trash-area-report.form2' },
              },
              { title: 'Форма № 3',
                subtitle: 'Отчет обеспеченности жителей домой доступными контейнерами площадками',
                permission: 'report_trash_area_export.form3',
                route: { name: 'trash-area-report.form3' },
              },
              { title: 'Форма № 4',
                subtitle: 'История вывоза ТКО',
                permission: 'report_trash_area_export.form4',
                route: { name: 'trash-area-report.form4' },
              },
            ],
            open: true,
          },
        ],
      },
    ],
  }),

  methods: {
    ...mapMutations({
      toggleSidebar: 'toggleSidebar',
      setSidebar: 'setSidebar',
      openMenuMethod: 'openMenu',
    }),
    ...mapMutations({
      closeMenu: 'closeMenu',
    }),
    openMenuFromClosed(index) {
      this.menu.forEach((item, i) => {
        if (i === index) {
          this.menu[i].open = true && !this.menu[i].disabled;
        } else {
          this.menu[i].open = false;
        }
      });
      this.openMenuMethod();
    },
    populateMenu() {
      this.menu.forEach((item, i) => {
        if (this.transportItems[item.id]) {
          item.children.unshift(...Object.entries(this.transportItems[item.id]).map(([key, value]) => {
            return {
              title: key,
              route: {
                name: 'main-map-typed',
                query: { transport: value.join(',') },
                params: { type: value.join(',') },
              },
              permission: 'transport.list',
            };
          }));

          item.children.unshift({
            title: 'Организации',
            route: {
              name: 'report.transporters',
              params: { id: item.id },
            },
            permission: 'transporter.list',
          });
        }
      });
    },
    clickHandler(ch) {
      if (typeof ch.click === 'function' && this.checkPermission(ch.permission)) {
        return ch.click();
      }
    },
  },

  computed: {
    ...mapGetters({
      openSidebar: 'openSidebar',
      currentMunicipality: 'municipalities/current',
      municipalities: 'municipalities/sortedMunicipalities',
      transportItems: 'route-type/menu',
      customLayers: 'layer/layers',
      openLayers: 'map/layers',
    }),

    isNeedSidebar() {
      // костыль для отображения сайдбара только на определенных страницах
      // т.к. раньше он был в разметке карты, сейчас не к чему привязать необхадимость его отображения.
      return this.$route.path.includes('/map');
    },

    sidebar() {
      return 'layers';
    },

    sidebarTitle() {
      return 'Слои';
    },

    filteredMunicipality() {
      return this.municipalities.filter(elm => { return elm.id !== 0 && elm.id !== this.currentMunicipality.id; });
    },
    enabledMenuItems() {
      return this.menu.filter(mItem => this.checkPermission(mItem.permission));
    },
  },

  mounted() {
    if (!InitializeService.instance.initialized) {
      InitializeService.instance.subscribe('initialize', () => this.populateMenu());
    } else {
      this.populateMenu();
    }
    // if (this.enabledMenuItems.length === 0) {
    //   this.closeMenu();
    // }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../scss/app/_header";
.disable-router-link {
  color:#a4a4a4 !important;
  font-weight: 400;
}
.menu-sidebars {
    position: fixed;
    left: var(--menu-width);
    transition: .3s;
    z-index: 300;
    &.closed-menu {
      transition: .3s;
      left: 50px;
    }
}

.left-menu {
  padding: 0px;
  position: fixed;
  top: 50px;
  left: 0;
  bottom: 0;
  width: var(--menu-width);
  z-index: 402;
  transition: .3s;
  transform: translateX(-100%);
  max-height: calc(100vh - var(--header-height));
  overflow: auto;
  background-color: var(--menu-main-color);
  box-shadow: 0 0.5rem 1rem 0 var(--menu-shadow-color);
  color: #fff;
  flex: 1;
  scrollbar-face-color: #101426;
  scrollbar-track-color: #192038;

  &-closed {
    z-index: 350;
    left: 50px;
    width: 50px;

    .menu-item {
      cursor: pointer;
      padding: 10px;

      &:hover {
        background-color: var(--main-color);
      }

      img.closed-icon {
        width: 30px;
        height: 30px;
        margin-right: 0;
      }
    }
  }

  &::-webkit-scrollbar {
    width: .3125rem;
    height: .3125rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #101426;
    cursor: pointer;
    border-radius: .15625rem;
  }

  &::-webkit-scrollbar-track {
    background: #192038;
  }

  &.open {
    transform: translateX(0);
  }

  .menu-item {
    transition: .3s;
    font-size: 13px;

    .child a, .child span {
      color: #fff;

      &.router-link-active span, &.router-link-active, &:hover {
        color: var(--menu-hover-color);
      }
    }

    .active-mo {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin: -10px;
      margin-bottom: 0;
      padding: 10px;
      font-size: 13px;
      font-weight: 700;
      background-color: #003398;
      color: #fff;

      &:hover {
        color: #fff;
      }
    }

    .clear-mo {
      margin-right: -5px;

      &-label {
        font-size: 13px;
        width: 100%;
        text-align: center;
      }

      &-wrapper {
        padding: 10px 0;
      }
    }

    img {
      margin-right: 10px;
      height: 23px;
    }

    &.open {
      & > span {
        margin-bottom: 0;
      }
    }

    span {
      display: block;
      cursor: pointer;
      transition: .3s;
    }

    .child {
      &-title {
        padding: 10px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        background-color: transparent;
        cursor: pointer;

        border-bottom: 1px solid var(--menu-second-color);

        & > span {
          width: 100%;
          flex: 1;
          font-size: 15px;
          font-weight: bold;
          line-height: 30px;
          margin-left: 5px;
        }

        &:hover {
          color: var(--menu-hover-color);
        }

        &.child-disabled {
          filter: brightness(0.5);

          &:hover {
            color: #fff;
          }
        }
      }

      &-icon {
        position: relative;
        margin-right: 10px;
        width: 30px;
        height: 30px;
      }

      &-item {
        padding: .75rem 1rem;
        cursor: pointer;
        transition: .3s;

        border-bottom: 1px solid var(--menu-second-color);

        &:hover {
          color: var(--menu-hover-color)
        }

        &.active {
          background-color: var(--menu-hover-color);
          color: #000;
        }

        &.child-disabled {
          filter: brightness(0.5);

          .child-item-title span:hover {
            color: #fff;
          }
        }

        &-title {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;
          background-color: transparent;
          cursor: pointer;

          & + .child .child-item {
            margin-left: 10px;

            p {
              margin-bottom: 0;
            }

            &:last-child {
              border-bottom: 0;
            }
          }
        }
      }

      .child-item {
        margin-left: 40px;
      }
    }
  }
}
</style>
