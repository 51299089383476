/* eslint-disable camelcase */
import L, { GeoJSON } from 'leaflet';

export interface IRouteType {
  id: number,
  image_url: string,
  parking_image_url: string,
  broken_image_url: string,
  name: string,
  is_scheduled: 'Y' | 'N',
  type_group: number;
  menu_path: string;
}
export interface TileSettingInterface {
  key: string;
  title: string;
  url: string;
  projection: L.CRS;
  order: number;
  maxZoom: number,
}
export interface IRouteTransporters {
  id: number,
  route_id: number,
  transporter_id: number,
  yandex_clid_id: number,
}

export interface IRouteGroup {
  color: string,
  id: number,
  name: string,
  route_id: number,
  route_line: {
    type: string,
    coordinates: Array<[lat: number, lng: number]>,
  },
}

export interface IRoute {
  id: number,
  municipality_id: number,
  name: string,
  route_type_id: number,
  yandex_clid: string,
  route_transporters?: Array<IRouteTransporters>,
  route_groups?: Array<IRouteGroup>,
  route_groups_without_line?: Array<Omit<IRouteGroup, 'route_line'>>,
  number?: string
}

export interface IRoutePathType {
  id: number,
  name: string,
  group_code: number
}

export interface IMunicipalityPayload {
  municipalityId: number;
}

export enum TransportStatusEnum {
  ACTIVE = 'A',
  PARKING = 'P',
  BROKEN = 'B',
}

export enum MessageTypeEnum {
  LOGIN_TYPE = 'L',
  LOGIN_SUCCESS_TYPE = 'LS',
  LOGIN_FAILED_TYPE = 'LF',

  TRANSPORT_MESSAGE_TYPE = 'T',
  TRANSPORT_ON = 'TON',
  TRANSPORT_OFF = 'TOFF',

  MUNICIPALITY_CHANGE_TYPE = 'M',
  MUNICIPALITY_CHANGE_SUCCESS_TYPE = 'MS',
  MUNICIPALITY_CHANGE_FAILED_TYPE = 'MF',

  MESSAGE = 'MSG',
}

export interface IPoint {
  coordinates: L.LatLngTuple,
  type: 'Point',
}

export interface IMultiLineString {
  coordinates: Array<Array<L.LatLngTuple>>,
  type: 'MultiLineString'
}

export interface IHistoryTransportLayer {
  line: L.Polyline | GeoJSON,
  transportId: number,
}

export interface ILineString {
  coordinates: Array<Array<L.LatLngTuple>>,
  type: 'LineString'
}

export interface IHistoryTransportResponseSegmentItem {
  lines: Array<ILineString>,
  color: string,
}

export interface IIdName {
  id: number;
  name: string;
}

export interface IIdNameIndexed {
  [key: string]: IIdName,
}

export interface IAbstractObject {
  [key: string]: any,
}

export interface ITransport {
  geo_position: {
    direction: number,
    id: number,
    in_line: boolean,
    is_valid: 1,
    point: IPoint,
    protocol_type: number,
    server_time: string,
    speed: number,
    tracker_time: string,
    transport_id: number,
    route_id?: number,
  },
  geo_position_id: number,
  id: number,
  municipalities_ids: Array<number>,
  number: string,
  operators_ids: Array<number>,
  parking_status: boolean,
  parking_time: string,
  receiver_number: string,
  route_type: {
    id: number,
    is_scheduled: 'Y' | 'N',
    name: string,
  },
  route_type_id: number,
  routes: Array<IRoute>,
  route?: IRoute,
  status?: TransportStatusEnum,
  // eslint-disable-next-line no-use-before-define
  transporter: ITransporter,
  transporter_id: number,
  model?: {
    name: string,
    id: number,
    mark: {
      id: number,
      name: string,
    },
  },
  subtype?: {
    name: string,
    id: number,
    route_type_id: number,
  },
  type?: {
    id: number,
    is_scheduled: 'Y' | 'N',
    type_group: number,
  },
}

export interface IOutfitPoint {
  diff_in_minutes: number,
  distance: string,
  point_id: number,
  schedule_time: string,
  status_done: boolean,
  tracker_time: string,
  time: string,
}

export interface IOutfit {
  avg_speed: number,
  bad_transport_on_trip: number,
  created_at: string,
  date: string,
  end_time: string,
  execution: 1 | 0,
  id: number,
  line_diff: number,
  points: {
    [key: number]: IOutfitPoint,
  }
  rating: number,
  regularity: number,
  speed_violation: Array<Array<{
    point: IPoint
  }>>,
  speed_violation_count: number,
  start_time: string,
  tracker_crashes: Array<Array<{
    point: IPoint
  }>>,
  tracker_crashes_count: number,
  traffic_violation:Array<Array<{
    point: IPoint
  }>>,
  traffic_violation_count:number,
  transport: ITransport,
  transport_id: number,
  trip_id: number,
  updated_at: string,
}

export interface IRoadOwner {
  id: number,
  municipality?: IAbstractObject,
  municipality_id: number,
  name: string,
}

export interface IRoad {
  id: number,
  length: string|number,
  line: IMultiLineString,
  name: string,
  road_owner?: IRoadOwner,
  road_owner_id: number,
  type: string|number,
  updated_at: string,
}

export interface ILayerFilterType {
  id: number,
  title: string,
  checked: boolean,
}

export interface ILayerFilterGroup {
  name: string,
  types: Array<ILayerFilterType>,
}

export interface ILayer {
  checked: boolean,
  hidden: boolean,
  params: {
    groups?: Array<ILayerFilterGroup>,
    types?: Array<ILayerFilterGroup>,
  },
  permission: string,
  title: string,
  type: string,
}

export interface IMunicipality {
  id: number,
  title: string,
}

export interface ICurrentMunicipality {
  id: number,
  name: string,
}
export interface ITrip {
  id: number,
  name: string,
  schedule_id: number,
  points: Array<IOutfitPoint>
}

export interface IOutfitFormParams {
  tripId: number,
  transportId: number,
  startTime: string,
  endTime: string,
  date: string
}

export interface IOperator {
  id: number,
  name: string,
}

export interface ITransporter {
  address: string,
  director_name: string,
  email: string,
  id: number,
  inn: string,
  license_number: string,
  name: string,
  phone: string,
  operators: Array<IOperator>,
  routes?: any,
  groups?: any,
  rowspan?: number,
  count_ts?: number,
  transports?: Array<ITransport>,
}

export interface ICustomLayer {
  id: number,
  name: string,
  sublayers: Array<'2' | '3' | '4'>,
}

export interface ILayerFilter {
  [key: string]: any,
}

export interface IRouteAnalysis {
  data: string,
  id: number,
  name: string,
}

export interface IAnalysisTableRow {
  name: string,
  number: string,
  routes: string,
  transporter: string,
  distance: number,
  day1?: number,
  day2?: number,
  day3?: number,
  day4?: number,
  day5?: number,
  day6?: number,
  day7?: number,
  sum: string,
  showIndex: number,
  sortIndex: number,
  municipality?: string,
  localityRowSpan: number,
}

export interface IAnalysisAverageForm1TableRow {
  count_ts: number,
  length: string,
  avg_time: string,
  avg_speed: number | null,
  traffic_avg_time: string,
  traffic_avg_speed: number | null,
  min_complete_time: string,
  max_speed: number | null,
}

export interface IAnalysisAverageForm2TableRow {
  count_ts: string,
  length: string,
  avg_time: string,
  avg_speed: string | null,
  traffic_avg_time: string,
  traffic_avg_speed: string | null,
  min_complete_time: string,
  max_speed: string | null,
  max_time: string,
  traffic_road_names: Array<string>,
}

export interface IAnalysisDay {
  day: 1 | 2 | 3 | 4 | 5 | 6 | 7,
  count_trips: number,
}

export interface IAnalysisRoute {
  count_planned_transport: number,
  count_route_transport: Array<{
    class_name: string,
    count: number,
  }>,
  distance: number,
  name: string,
  number: string,
  schedule_days: Array<IAnalysisDay>,
  transporter: {
    id: number,
    name: string,
  },
  id: number,
}

export interface IAnalysisLocality {
  id: number,
  name: string,
  population: number,
  routes: Array<IAnalysisRoute>,
}

export interface IAnalysisTotal {
  count_transports: number,
  count_planned_transport: number,
  population: number,
  routes_percent_by_locality_count: number,
  routes_percent_by_locality_population: number,
  schedule_days: Array<IAnalysisDay>,
}

export interface IAnalysisMunicipality {
  id: number,
  localities: Array<IAnalysisLocality>,
  name: string,
  totals: IAnalysisTotal,
}

export interface IAnalysisReportResponse {
  municipalities: Array<IAnalysisMunicipality>,
  totals: IAnalysisTotal,
}

export interface ITransportType {
  code: string,
  id: number,
  name: string,
}

export interface RootState {

}

export interface ChatState {
  open: boolean;
  unreadCounter: number;
}

export interface MapState {
  isGroupControlDisabled: boolean;
  layers: Array<string>;
  layerParams: Array<ILayer>,
  filters: {
    [key: string]: ILayerFilter | undefined,
  },
  zoom?: number,
  latlngs?: [number, number],
}

export interface LayerState {
  layers: Array<ICustomLayer>,
}

export interface ZoneSelectionState {
  rectangle: Array<[number, number]>,
}

export interface AnalysisState {
  showCrossShowPopup: boolean,
  crossShow: Array<{
    color: string,
    id: number,
    text: string,
  }>,
  analysisList: Array<IRouteAnalysis>,
  routeColorBuffer: {
    [key: number]: string,
  },
  report: IAnalysisReportResponse | null,
  showReport: boolean,
  lastReportFilter: string,
  localityFilter: Array<number>,
}

export interface IChatUser {
  name: string,
  id: number,
  last_seen: null | string,
  lastSeenShortFormat?: string,
  is_online: boolean,
  count_unread_messages: number,
}
export interface IChatGroup {
  name: string,
  id?: number,
  open?: boolean,
  users: Array<IChatUser>
}

export interface IFile {
  id: number,
  key: string,
  name: string,
  size: number,
  type: string,
  updated_at: string,
  user_id: number,
}

export interface IChatMessage {
  id: number,
  body: string,
  chat: string,
  from_user_id: number,
  readed_at: string,
  edited_at: string | null,
  to_user_id: number,
  created_at: string,
  files: Array<IFile>,
}

export interface IChatShowMessage {
  id: number | string,
  date: string,
  input?: boolean,
  output?: boolean,
  from: number,
  text: string,
  read: boolean,
  readed_at: string,
  files: Array<IFile>,
  error: boolean,
}

export interface IForm1Transporter {
  transporterId: number,
  name: string,
  municipality: {
    name: string,
    id: number,
  },
  vehicleNumberRnis: number,
  vehicleNumberOnline: number,
  vehicleNumberFact: number,
  sumLineRun: number,
  sumRun: number,
  timeStart: string | null,
  timeEnd: string | null,
  averageSpeed: number,
  stopsOnTheLineNumber: number,
  speedViolationNumber: number,
  trackerFaultNumber: number,
}

export interface IScheduleExecutionReport {
    tripsCount: number,
    doneTripsCount: number,
    routeName: string,
}

export interface IForm21Report {
  name: string,
  len: number,
  routes: Array<{
    number: number,
    name: string,
    type: string,
    route_groups: Array<{
      length: number,
      bus_stops: Array<string>,
      roads: Array<string>,
      name: string,
    }>,
  }>,
}

export interface IForm22Report {
  id: number,
  name: string,
  transports_count: number,
  mugadn_transports_count: number,
  difference: number,
  only_mugadn_ts_numbers: string[],
  only_rnis_ts_numbers: string[],
  only_mugadn_ts_numbers_string: string | null,
  only_rnis_ts_numbers_string: string | null,
  note: string,
}
export interface ITransportForm23 extends Omit<ITransport, 'geo_position' | 'route_type' | 'municipalities_ids' |'operator_ids' | 'transporter'> {
  last_geo_position_on_day: {
    id: number,
    tracker_time: string,
    transport_id: number,
    geo_position_id: number,
  },
}

export interface IForm23Report extends Omit<ITransporter, 'transports' | 'operators'> {
  transports: Array<ITransportForm23>,
  transports_without_route?: Array<ITransportForm23>,
  transports_with_route?: Array<ITransportForm23>,
}

export interface IForm3Road {
  id: number,
  distance: number,
  endAt: string,
  municipality: {
    name: string,
    id: number,
  },
  road: {
    id: number,
    name: string,
  },
  startAt: string,
  avgSpeed: number,
  speedViolationCount: number,
  trackerCrashesCount: number,
}

export interface IForm2Transport {
  transportId: number,
  model: string,
  number: string,
  municipality: {
    name: string,
    id: number,
  },
  mileage: number,
  mileageOnTheLine: number,
  machineHours: string | number,
  startAt: string,
  endAt: string,
  stopsOnTheLineCount: Array<number> | number,
  trackerCrashes: Array<{
    crashe_time: string,
    end_time: string,
    start_time: string,
  }> | number,
  route: string,
  transportStorageLocation: string,
}

export interface IRouteSubtype {
  id: number,
  name: string,
  route_type_id: number,
}

export interface ITransportRoad {
  avg_speed: number,
  distance: number,
  id: number,
  last_distance: number,
  line: {
    type: 'LineString',
    coordinates: Array<Array<L.LatLngTuple>>,
  },
  municipality_id: number,
  road_id: number,
  speed_violation: Array<Array<{
    point: IPoint
  }>>,
  speed_violation_count: number,
  tracker_crashes_count: number,
  transport_id: number,
  transport: ITransport,
  start_at: string,
  end_at: string,
}

export interface IDistrict {
  id: number,
  name: string,
}

export interface ILocality {
  border: {
    type: 'MultiPolygon',
    coordinates: Array<Array<Array<L.LatLngTuple>>>,
  },
  district_id: number,
  district: IDistrict,
  id: number,
  municipality_id: number,
  name: string,
  population: number,
  type: number,
}

export interface ICusomLayerObjectProperty {
  id: number,
  name: string,
  layer_object_type_id: number,
}

export interface ICusomLayerObjectType {
  body_color: null | string,
  border: null | number,
  circuit: null | string,
  color: null | string,
  editor: string,
  id: number,
  name: string,
  properties: Array<ICusomLayerObjectProperty>,
  icon_url: null | string,
}

export interface ICustomLayerObject {
  body_color: null | string;
  color: null | string;
  geometry: {
    coordinates: Array<any>,
    type: 'Point' | 'MultiLineString' | 'MultiPolygon',
  };
  id: number;
  layer_id: number;
  layer_object_type_id: number;
  municipality_id: number;
  name: string;
  object_property_values: Array<{
    id: number,
    value: string,
    layer_object_type_property_id: number,
    layer_object_id: number,
  }>,
  object_type: ICusomLayerObjectType,
}

export interface IRouteConverge {
  active: boolean,
  color: string,
  id: number,
  lines: {
    cut: {
      type: 'LineString',
      coordinates: Array<L.LatLngTuple>,
    },
    tails: IMultiLineString,
  },
  municipality_id: number,
  name: string,
  route_groups: Array<{
    duplication_percent: number,
    id: number,
    length: number,
    route_id: number,
    transporters: Array<{
      id: number,
      name: string,
      days: Array<{
        day: 1 | 2 | 3 | 4 | 5 | 6 | 7,
        count_trips: number,
      }>,
    }>
  }>,
  route_path_type_id: number,
  route_type_id: number,
}

export interface IRouteConvergeRow {
  id: number,
  route: string,
  transporter: string,
  day?: string,
  day1?: string,
  day2?: string,
  day3?: string,
  day4?: string,
  day5?: string,
  day6?: string,
  day7?: string,
  distance: number,
  percent: number,
  color: string,
  groupId?: number,
}

export interface IAccidentDictionary {
  code: string,
  id: number,
  name: string,
  type: string,
  pivot: {
    accident_dictionary_id: number,
    accident_id: number,
  }
}

export interface IAccident {
  children_death: number,
  children_injured: number,
  concentration_place: null | any,
  count_participants: number,
  date: string,
  death: number,
  dictionaries: Array<IAccidentDictionary>,
  factors: null | any,
  id: number,
  injured: number,
  km: string,
  latitude: string,
  light: null | any,
  longitude: string,
  municipality_id: number,
  ndus: Array<{
    code: string,
    id: number,
    name: string,
    pivot: {
      accident_id: number,
      ndu_id: number,
    },
  }>,
  number: string,
  place: string,
  place_status: number,
  road_category: number,
  road_id: number,
  road_light: number,
  road_owner: null | any,
  road_owner_id: null | any,
  road_plan: number,
  road_profile: number,
  road_state: number,
  road_surface: number,
  road_value: number,
  street_category: null | any,
  transport_types: Array<{
    code: string,
    id: number,
    name: string,
    pivot: {
      accident_id: number,
      count_participants: number,
      transport_type_id: number,
    },
  }>,
  types: Array<{
    code: string,
    id: number,
    name: string,
    pivot: {
      accident_id: number,
      accident_type_id: number,
    },
  }>,
  weather: null | any,
}

export interface IAccidentConcentration {
  count: number,
  countOneType: number,
  death: number,
  injured: number,
  municipalities: string
  point: IPoint,
  radius: number,
  type: 'red' | 'yellow' | 'green',
}

export interface IAccidentStatItem {
  appgCount: number,
  appgDeath: number,
  appgInjured: number,
  count: number,
  death: number,
  injured: number,
  municipality_id: null | string,
}

export interface IAccidentStat {
  municipalities: Array<IAccidentStatItem>,
  total: IAccidentStatItem,
}

export interface IAccidentTableRow {
  municipality: string,
  count: number,
  appgCount: string,
  appgCountColor: string,
  death: number,
  appgDeath: string,
  appgDeathColor: string,
  injured: number,
  appgInjured: string,
  appgInjuredColor: string,
}

export interface ICameraSetting {
  id: number,
  ipaddress: string,
  macaddress: string,
  ptz: string,
  servername: string,
  snapuri: string,
}

export interface ICamera {
  analytics: string,
  analytics_id: number | null,
  archivefolder: string,
  id: number,
  motion: string,
  on_record: string,
  setting: ICameraSetting,
  setting_id: number,
  sound: string,
  status: string,
}

export interface ICameraChannel {
  camera: ICamera,
  camera_id: number,
  channel_id: number | null,
  entity_id: number,
  id: number,
  is_duplicated: boolean,
  name: string,
  status: string,
  stream_url: string,
  sub_stream_url: string,
}

export interface ICameraLocationType {
  color: string,
  icon: string,
  id: number,
  name: string,
  old_id: number | null,
}

export interface ICameraLocation {
  address: string,
  channels: Array<ICameraChannel>,
  count_camera: number,
  id: number,
  latitude: string,
  longitude: string,
  municipality_id: number,
  name: string,
  region_id: number,
  type: ICameraLocationType,
  videocam_type_id: number,
}
export interface IFormAccidentCommon {
  key?: number|string,
  count: number,
  death: number,
  injured: number,
  appgCount: number,
  appgDeath: number,
  appgInjured: number,
  appgCountAbs: number,
  appgDeathAbs: number,
  appgInjuredAbs: number,
}

export interface IForm1Accident {
  stat: {
    total: IFormAccidentCommon,
    byDay: Array<IFormAccidentCommon>,
    byTime: Array<IFormAccidentCommon>,
  }
}

export interface IForm2Accident {
  stat: {
    total: IFormAccidentCommon,
    byPedestrian: IFormAccidentCommon,
    byDay: Array<IFormAccidentCommon>,
  }
}

export interface IForm3Accident {
  stat: {
    total: IFormAccidentCommon,
    byType: Array<IFormAccidentCommon>,
  }
}

export interface IForm4Accident {
  stat: {
    total: IFormAccidentCommon,
    byPlace: Array<IFormAccidentCommon>,
  }
}

export interface IForm5Accident {
  stat: {
    total: IFormAccidentCommon,
    byRoad: Array<IFormAccidentCommon>,
    byRoadType: Array<IFormAccidentCommon>,
  }
}

export interface IInsuranceType {
  code: string,
  id: number,
  name: string,
  short_name: string,
}

export interface IOsgopInsuranceType extends IInsuranceType {
  pivot: {
    insurance_transport_type_id: number,
    transport_insurance_id: number,
  },
}
export interface IOsgopInsurance {
  active: boolean,
  end_at: string,
  fact_end_at: string,
  fact_start_at: string,
  id: number,
  insurance_transport_types: Array<IOsgopInsuranceType>,
  insurer_name: string,
  number: string,
  root_number: string,
  routes: Array<IRoute>,
  start_at: string,
  transport_id: number,
}

export interface IOsgopForm20Insurance {
  active: boolean,
  end_at: string,
  fact_end_at: string,
  fact_start_at: string,
  insurance_transport_types: Array<Omit<IOsgopInsuranceType, 'id' | 'code'>>,
  insurer_name: string,
  number: string,
  root_number: string,
  start_at: string,
}

export interface IForm18Pp {
  number: string,
  transporter: string,
  inn: string,
  routes: Array<string>,
  status: Array<string>,
  insurer: Array<string>,
  insurenceNumber: Array<string>,
  date?: string,
  dateFrom: Array<string>,
  dateTo: Array<string>,
  type: Array<string>,
}

export interface IForm19Pp {
  number: string,
  transporter: string,
  inn: string,
  routes: string,
  status: string,
  insurer: string,
  insurenceNumber: string,
  date?: string,
  dateFrom: string,
  dateTo: string,
  type: string,
  request: '',
  response: '',
}

export interface IForm20Pp {
  number: string,
  transporter: string,
  transportOwner: null | ITransporter,
  inn: string,
  status: Array<string>,
  insurer: Array<string>,
  insurenceNumber: Array<string>,
  date?: string,
  dateFrom: Array<string>,
  dateTo: Array<string>,
  type: Array<string>,
}
export interface IForm18Response {
  geo_position_id: number,
  id: number,
  insurance: Array<IOsgopInsurance>,
  model_id: number,
  number: string,
  receiver_name: null | string,
  receiver_number: null | string,
  receiver_vendor: null | string,
  receiver_year: null | number,
  route_subtype_id: null | number,
  route_transport: Array<any>,
  route_type_id: number,
  transporter: ITransporter,
  transporter_id: number,
  year: number,
}

export interface IForm19Response {
  geo_position_id: number,
  id: number,
  last_insurance: null | IOsgopInsurance,
  model_id: number,
  number: string,
  receiver_name: string,
  receiver_number: string,
  receiver_vendor: null | string,
  receiver_year: null | number,
  route_subtype_id: null | number,
  route_type_id: number,
  routes: Array<IRoute>,
  transporter: ITransporter,
  transporter_id: number,
  year: number,
}

export interface IForm20Response {
  number: string,
  transporter: ITransporter,
  transporter_id: number,
  transport_owner: null | ITransporter,
  insurance: Array<IOsgopForm20Insurance>,
}

export interface IAnalysisAverageFilterTab {
  dates: Array<string | Date>,
  days: Array<{
      id: number,
      text: string,
    }>,
  hours: Array<{
      id: number,
      text: string,
    }>,
}
export interface IAnalysisAverageFilter {
  average: IAnalysisAverageFilterTab & {
    pointStart: any,
    pointFinish: any,
    type: Array<string>,
  },
  general: IAnalysisAverageFilterTab & {
    hourGroup: {
      id: number,
      text: string,
    },
    route: {
      id: number,
      text: string,
    },
    routeGroup: {
      id: number,
      text: string,
    },
  },
}

export interface IAnalysisAverageRoadTrafficSegment {
  azimuth: string,
  id: number,
  line: {
    type: 'LineString',
    coordinates: Array<Array<L.LatLngTuple>>,
  },
}
export interface IAnalysisAverageRouteTypeAvgs {
  count_ts: number,
  segment_id: number,
  speed: number,
}
export interface IAnalysisAverageRouteType {
  count_ts: number,
  avg_speed: number,
  avg_time: string,
  max_speed: number,
  traffic_avg_time: string,
  traffic_avg_speed: number,
  min_complete_time: string,
  avg_time_raw: number,
  traffic_avg_time_raw: number,
  min_complete_time_raw: number,
  route_type_name: string,
  length: number,
  avgs: Array<IAnalysisAverageRouteTypeAvgs>,
}

export interface IAnalysisAverageForm1VisualizeItem {
  count_ts: number;
  road_traffic_segment: IAnalysisAverageRoadTrafficSegment,
  speed: number,
}

export interface IAnalysisAverageForm1ResponseItem {
  hour: string,
  route_types: {
    [key: number]: IAnalysisAverageRouteType,
  },
  isGrouped?: boolean,
}

export interface IAnalysisAverageForm1ResponseSegmentItem {
  id: number,
  line: {
    type: 'LineString',
    coordinates: Array<Array<L.LatLngTuple>>,
  },
}

export interface IAnalysisAverageForm1Response {
  report: Array<IAnalysisAverageForm1ResponseItem>,
  segments: {
    [key: number]: IAnalysisAverageForm1ResponseSegmentItem
  },
  report_grouped: {
    [key: number]: IAnalysisAverageRouteType,
  },
}

export interface IAnalysisAverageForm1VisualizeResponse {
  report: Array<IAnalysisAverageForm1VisualizeItem>;
}

export interface IAnalysisAverageForm2ReponseItemRow {
  hour?: string,
  count_ts: number,
  avg_speed: number,
  avg_time_raw: number,
  avg_time: string,
  avg_traffic_speed: number,
  max_time_raw: number,
  max_time: string,
  min_time_raw: number,
  min_time: string,
  max_speed: number,
  traffic_time_raw: number,
  traffic_time: string,
  traffic_road_names: Array<string>,
  length?: number,
  name?: string,
  avgs: Array<IAnalysisAverageRouteTypeAvgs>,
}

export interface IAnalysisAverageForm2ResponseItem {
  id: number,
  length: number,
  name: string,
  route_line: {
    type: 'LineString',
    coordinates: Array<Array<L.LatLngTuple>>,
  },
  rows: Array<IAnalysisAverageForm2ReponseItemRow>,
  report_grouped: IAnalysisAverageForm2ReponseItemRow,
}

export interface IAnalysisAverageForm2Response {
  report: Array<IAnalysisAverageForm2ResponseItem>,
  segments: Array<IAnalysisAverageRoadTrafficSegment>,
}

export interface AnalysisAverageState {
  transportTypes: Array<ITransportType>,
  routes: Array<IRoute>,
  routeGroups: Array<IRouteGroup>,
  dataForm1: IAnalysisAverageForm1Response,
  selectedForm1: IAnalysisAverageForm1ResponseItem | null,
  selectedForm1Visualize: Array<IAnalysisAverageForm1VisualizeItem>,
  dataForm2: IAnalysisAverageForm2Response,
  selectedForm2: null | string | 0,
}

export interface SitCenterRoute {
  route_type_id: number,
  assigned_count: number,
  actual_count: number,
}

export interface SitCenterDetailValues {
  name: string,
  count: number,
}

export interface SitCenterDetails {
  name: string,
  values: Array<SitCenterDetailValues>,
}

export interface SitCenterTransportsDetails {
  route_type_id: number,
  data: Array<SitCenterDetails>,
}

export interface SitCenterRouteResponse {
  data: Array<SitCenterRoute>,
  details?: Array<SitCenterDetails>,
}

export interface SitCenterOutfit {
  route_type_id: number,
  fact_count: number,
  actual_count: number,
}

export interface SitCenterOutfitsResponse {
  data: Array<SitCenterOutfit>,
}

export interface SitCenterTransport {
  route_type_id: number,
  inline_count: number,
  actual_count: number,
}

export interface SitCenterTransportResponse {
  data: Array<SitCenterTransport>,
  details?: Array<SitCenterTransportsDetails>,
}

export interface ChartsPopupState {
  open: boolean,
  type: string,
  details?: string,
  routeType?: number,
}

export interface SitCenterChartOptions {
  name: string,
  series: Array<number>,
  options: {
    chart: {
      type: string,
      width?: number,
    },
    labels: Array<string>,
    legend?: {
      show?: boolean,
      position?: string,
    },
  },
}

export interface SitCenterState {
  active: boolean,
  routes: Array<SitCenterRoute>,
  routesDetails: Array<SitCenterDetails>,
  transports: Array<SitCenterTransport>,
  transportsDetails: Array<SitCenterTransportsDetails>,
  emptyDetails: boolean,
  popup: ChartsPopupState,
  charts: Array<SitCenterChartOptions>,
  outfits: Array<SitCenterOutfit>,
}

export interface TrashAreaTransporter {
  id: number,
  name: string,
  updated_at: string,
  address: string,
  phone: string,
  inn: string,
  director_name: string,
  email: string,
  license_number: string,
  contracts: string,
  type: number,
}

export interface TrashContainerType {
  id: number,
  name: string,
  material: string,
  value: number,
  pivot?: {
    trash_area_id: number,
    trash_container_type_id: number,
    count: 2,
  },
}
export interface HouseItem {
  id: number,
  border: {
    type: 'MultiPolygon',
    coordinates: Array<Array<Array<L.LatLngTuple>>>,
  },
  point: IPoint,
  street: string,
  address: string,
  number: string,
  flats_count: number,
  type: string,
  square: string,
  year: number,
  postcode: number,
  levels_count: number,
  population: number,
  mingkh_id: number,
  trash_chute: boolean,
  municipality_id: number,
  updated_at: string,
  // eslint-disable-next-line no-use-before-define
  trash_areas: Array<TrashAreaItem>,
}

export interface HousesResponse {
  houses: Array<HouseItem>,
}

export interface TrashAreaItem {
  id: number,
  address: string,
  municipality_id: number
  transporter_id: number,
  point: IPoint,
  border: {
    type: 'MultiPolygon',
    coordinates: Array<Array<Array<L.LatLngTuple>>>,
  }
  coating: string,
  square: string,
  source: string,
  updated_at: string,
  size: string,
  fence: true,
  houses?: Array<HouseItem>,
  transporter?: TrashAreaTransporter,
  trash_container_types?: Array<TrashContainerType>,
  files?: Array<IFile>,
  last_trips: Array<{
    id: number,
    trash_area_id: number,
    transport_id: number,
    transport: {
      id: number,
      number: string,
    },
    datetime: string,
  }>
}

export interface TrashAreaResponse {
  trash_areas: Array<TrashAreaItem>,
}

export interface TrashAreaCoatingResponse {
  coatings: Array<string>,
}

export interface TrashAreaFilters {
  id: number,
  localityId: number,
  address: string,
  transporterId: number,
  squareFrom: number
  squareTo: number,
  trashContainerTypeId: Array<number>,
  trashContainerMaterial: Array<string>,
  countFrom: number,
  countTo: number,
  fence: number,
  houseCountFrom: number,
  houseCountTo: number,
  longDistance: boolean,
  longDistanceValue: number,
  capacityFrom: number,
  capacityTo: number,
  coatingTypes: Array<string>,
  hasTripsForPeriod?: {
    date_from: string,
    date_to: string,
  }
}

export interface TrashAreaTrips {
  id: number,
  trash_area_id: number,
  transport_id: number,
  datetime: string,
  transport: {
    id: number,
    number: string,
    transporter_id: number,
    transporter: {
      id: number,
      name: string,
    }
  },
  trash_area: {
    id: number,
    address: string,
  }
}

export interface ImagesPreviewPopup {
  open: boolean,
  files: Array<IFile>,
  title: string,
}

export interface HouseFilters {
  municipalityId: number,
  address: string,
  street: Array<string>,
  number: string,
  type: Array<string>,
  yearFrom: number
  yearTo: number,
  squareFrom: number,
  squareTo: number,
  populationFrom: number,
  populationTo: number,
  levelsCountFrom: number,
  levelsCountTo: number,
  flatsCountFrom: number,
  flatsCountTo: number,
  trashChute: number,
  hasTrashAreas: number,
}

export interface TrashAreaState {
  coatingTypes: Array<string>,
  highlight: boolean,
  imagesPopup: ImagesPreviewPopup;
}

export interface LocalityState {
  localities: Array<ILocality>;
}

export interface TrashContainerTypeState {
  trashContainerTypes: Array<TrashContainerType>,
  trashContainerMaterials: Array<string>,
}

export interface HouseState {
  streets: Array<string>,
  highlight: boolean,
  types: Array<string>,
}

export interface TrashContainerTypeResponse {
  trash_container_types: Array<TrashContainerType>,
}

export interface TrashContainerMaterialResponse {
  materials: Array<string>,
}
