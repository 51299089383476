import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { TrashContainerTypes } from '@/services/api/trash-container-types';
import { RootState, TrashContainerTypeState, TrashContainerType } from '@/types';

const state: TrashContainerTypeState = {
  trashContainerTypes: [],
  trashContainerMaterials: [],
};

const getters: GetterTree<TrashContainerTypeState, RootState> = {
  trashContainerTypes: state => state.trashContainerTypes,
  trashContainerMaterials: state => state.trashContainerMaterials,
};

const mutations: MutationTree<TrashContainerTypeState> = {
  setTrashContainerTypes: (state, payload: Array<TrashContainerType>) => {
    state.trashContainerTypes = [...payload];
  },
  setTrashContainerMaterial: (state, payload: Array<string>) => {
    state.trashContainerMaterials = [...payload];
  },
};

const actions: ActionTree<TrashContainerTypeState, RootState> = {
  async loadTrashContainerTypes({ commit }) {
    const trashContainerTypes = await TrashContainerTypes.getTrashContainerTypes();
    commit('setTrashContainerTypes', trashContainerTypes);
  },
  async loadTrashContainerMaterials({ commit }) {
    const trashContainerMaterials = await TrashContainerTypes.getTrashContainerMaterials();
    commit('setTrashContainerMaterial', trashContainerMaterials);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
