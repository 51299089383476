import axios from '@/services/axios';
import { HouseFilters, HousesResponse } from '@/types';

export class House {
  static getHouses(filters: Partial<HouseFilters> | null, background: boolean = true) {
    const params = [];

    params.push('all=1');
    params.push('include=municipality,trashAreas');

    if (filters?.number) {
      params.push(`filter[number]=${filters.number}`);
    }

    if (filters?.street?.length) {
      params.push(`filter[street]=${filters.street.join(',')}`);
    }

    if (filters?.type?.length) {
      params.push(`filter[type]=${filters.type.join(',')}`);
    }

    if (filters?.municipalityId) {
      params.push(`filter[municipality_id]=${filters.municipalityId}`);
    }

    if (filters?.squareFrom) {
      params.push(`filter[square][from]=${filters?.squareFrom}`);
    }
    if (filters?.squareTo) {
      params.push(`filter[square][to]=${filters?.squareTo}`);
    }

    if (filters?.populationFrom) {
      params.push(`filter[population][from]=${filters?.populationFrom}`);
    }

    if (filters?.populationTo) {
      params.push(`filter[population][to]=${filters?.populationTo}`);
    }

    if (filters?.yearFrom) {
      params.push(`filter[year][from]=${filters?.yearFrom}`);
    }

    if (filters?.yearTo) {
      params.push(`filter[year][to]=${filters?.yearTo}`);
    }

    if (filters?.levelsCountFrom) {
      params.push(`filter[levels_count][from]=${filters?.levelsCountFrom}`);
    }

    if (filters?.levelsCountTo) {
      params.push(`filter[levels_count][to]=${filters?.levelsCountTo}`);
    }

    if (filters?.flatsCountFrom) {
      params.push(`filter[flats_count][from]=${filters?.flatsCountFrom}`);
    }

    if (filters?.flatsCountTo) {
      params.push(`filter[flats_count][to]=${filters?.flatsCountTo}`);
    }

    if (filters?.trashChute !== undefined) {
      params.push(`filter[trash_chute]=${filters?.trashChute}`);
    }

    if (filters?.hasTrashAreas !== undefined) {
      params.push(`filter[has_trash_areas]=${filters?.hasTrashAreas}`);
    }

    const query = params.length > 0 ? `?${params.join('&')}` : '';
    return axios.get<HousesResponse>(`/house${query}`, {
      isBackgroundLoading: background,
    } as any);
  }

  static getStreets(municipalityId: number, background: boolean = true) {
    return axios.get<{ streets: Array<string> }>('/house/street', {
      params: {
        'municipality_id': municipalityId !== 0 ? municipalityId : '',
      },
      isBackgroundLoading: background,
    } as any);
  }

  static getTypes(background: boolean = true) {
    return axios.get<{ types: Array<string> }>('/house/type', {
      isBackgroundLoading: background,
    } as any);
  }
}
