import { Store } from 'vuex';

export class BaseLayer {
  _store!: Store<any>;
  _map!: L.Map;
  _type = 'base';

  constructor({ store, map, type }: {
    store: Store<any>,
    map: L.Map,
    type: string,
  }) {
    this._store = store;
    this._map = map;
    this._type = type;
  }

  get filter() {
    return this._store.getters['map/filters'][this._type] ?? {};
  }

  set filter(filter) {
    this._store.dispatch('map/setFilter', {
      value: { ...filter },
      type: this._type,
    });
  }
}
