import { ILayer, ILayerFilter, MapState, RootState } from '@/types';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

const state: MapState = {
  isGroupControlDisabled: false,
  layers: ['transport'],
  layerParams: [],
  filters: {},
  zoom: undefined,
  latlngs: undefined,
};
const getters: GetterTree<MapState, RootState> = {
  isGroupControlDisabled: state => state.isGroupControlDisabled,
  layers: state => state.layers,
  layerParams: state => state.layerParams,
  filters: state => state.filters,
  zoom: state => state.zoom,
  latlngs: state => state.latlngs,
};
const mutations: MutationTree<MapState> = {
  setGroupControlDisabled: (state, payload: boolean) => {
    state.isGroupControlDisabled = payload;
  },
  setLayers: (state, paylaod: Array<string>) => {
    state.layers = [ ...paylaod ];
  },
  setLayerParams: (state, payload: Array<ILayer>) => {
    state.layerParams = JSON.parse(JSON.stringify(payload));
  },
  setFilter: (state, payload: {
    value?: ILayerFilter,
    type: string,
  }) => {
    state.filters[payload.type] = JSON.parse(JSON.stringify(payload.value));
  },
  setFilters: (state, payload: {
    [key: string]: ILayerFilter | undefined;
  }) => {
    state.filters = payload;
  },
  setZoom: (state, payload: number) => {
    state.zoom = payload;
  },
  setLatLng: (state, payload: [number, number]) => {
    state.latlngs = [...payload];
  },
};
const actions: ActionTree<MapState, RootState> = {
  setGroupControlDisabled({ commit }, payload: boolean) {
    return commit('setGroupControlDisabled', payload);
  },
  setLayers({ commit }, payload: Array<string>) {
    return commit('setLayers', payload);
  },
  setLayerParams({ commit }, payload: Array<ILayer>) {
    return commit('setLayerParams', payload);
  },
  setFilter({ commit }, payload: {
    value?: ILayerFilter,
    type: string,
  }) {
    return commit('setFilter', payload);
  },
  setFilters({ commit }, payload: {
    [key: string]: ILayerFilter | undefined;
  }) {
    return commit('setFilters', payload);
  },
  setZoom({ commit }, payload: number) {
    return commit('setZoom', payload);
  },
  setLatLng({ commit }, payload: [number, number]) {
    return commit('setLatLng', payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
