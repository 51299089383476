import { ILocality } from '@/types';
import { AxiosResponse } from 'axios';
import axios from '../axios';

export class Locality {
  static getLocality(municipalityId: number): Promise<Array<ILocality>> {
    const params: {
      [key: string]: string | number
    } = {};

    if (municipalityId) {
      params['filter[municipality_id]'] = municipalityId;
    }
    params.include = 'district';

    return axios.get('/locality?all', { params })
      .then((res: AxiosResponse<{
        localities: Array<ILocality>,
      }>) => res.data.localities);
  }

  static getDistricts(municipalityId: number): Promise<Array<ILocality>> {
    const params: {
      [key: string]: string | number
    } = {};

    if (municipalityId) {
      params['filter[municipality_id]'] = municipalityId;
    }
    params['filter[only_district]'] = 1;

    return axios.get('/locality?all', { params })
      .then((res: AxiosResponse<{
        localities: Array<ILocality>,
      }>) => res.data.localities);
  }
}
