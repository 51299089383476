import L from 'leaflet';
import { Detector } from '@/services/api/detector';
import { BehaviorSubject } from 'rxjs';
import { BaseLayer } from './base-layer';
import {} from 'leaflet.markercluster';
import moment from 'moment';

export class DetectorLayer extends BaseLayer {
  _layers = null;

  _transportMarkers = null;

  _originDetectors = [];
  detectors = new BehaviorSubject([]);
  markersGroup = null;
  markers = [];

  _clusterBreakPoint = 16;

  constructor({ store, map, type }) {
    super({ store, map, type });
    this.filter = {
      address: '',
      startDate: null,
      endDate: null,
      number: '',
      municipalityId: null,
      ...this.filter,
    };
    this.createGroup();

    this.detectors.subscribe(() => {
      this._render();
    });

    this._store.subscribe(mutation => {
      if (mutation.type === 'map/setGroupControlDisabled') {
        this.createGroup();
      }
    });
  }

  createGroup() {
    this.markersGroup?.clearLayers();
    this.markersGroup?.remove();
    this.markersGroup = null;
    this.markersGroup = new L.MarkerClusterGroup({
      disableClusteringAtZoom: !this._store.getters['map/isGroupControlDisabled'] ? this._clusterBreakPoint : 0,
      iconCreateFunction: function(cluster) {
        const markers = cluster.getAllChildMarkers();
        return L.divIcon({
          html: `
          <div class="text-center"
            style="
            height: 48px;
            width: 48px;
            padding: 10px;
            position: relative;
            border:none;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url(https://andromeda.fvf73.ru/images/detectors/%D0%90%D0%B2%D1%82%D0%BE%D0%BF%D0%B0%D1%82%D1%80%D1%83%D0%BB%D1%8C%20%D0%A0%D0%B0%D0%B4%D0%B0%D1%80-%D0%A1.png);">
              <div style="
                position: absolute;
                top: 10px;
                left: 6px;
                width: 28px;
                height: 28px;
                line-height: 28px;
                text-align: center;
                font-size: 12px;
                color: #ffffff;
                border-radius: 50%;
                background: rgba(197, 11, 25, 0.7)">
                  ${markers.length}
              </div>
          </div>`,
          className: '',
          iconSize: L.point(50, 50),
        });
      },
    });

    this.markersGroup.addLayers(this.markers);
    this._map.addLayer(this.markersGroup);
  }

  _deleteLayer() {
    this.markersGroup?.clearLayers();
    if (this._layers !== null) {
      this._layers.forEach(layer => layer.remove());
      this._layers = null;
    }
  }

  applyFilter(filter) {
    let filtered = this._originDetectors;

    if (filter.address) {
      filtered = filtered.filter(detector => {
        return detector.dislocation.dislocation_title.indexOf(filter.address) > -1;
      });
    }

    this.filter = { ...filter };

    this.detectors.next(filtered);
  }

  showMarker(detector) {
    if (this._layers === null) return;
    this._layers.forEach(layer => {
      if (layer.options.id === detector.host_id) {
        layer.openPopup();
        const latlng = layer.getLatLng();
        this._map.setView([latlng.lat, latlng.lng], 18);
      }
    });
  }

  async reRender() {
    if (this._originDetectors.length === 0 || this.filter._municipalityId !== this._store.getters['municipalities/current'].id) {
      if (this._store.getters['municipalities/current'].position >= 10) {
        this.filter = {
          ...this.filter,
          municipalityId: this._store.getters['municipalities/current'].id,
        };
      } else {
        this.filter = {
          ...this.filter,
          municipalityId: null,
        };
      }
    }

    const response = await Detector.list({ all: true, municipalityId: this.filter.municipalityId });
    this._originDetectors = response.detectors.filter(detector => !!detector.dislocation);

    this.applyFilter(this.filter);
  }

  _render() {
    this._deleteLayer();
    this._layers = [];
    this.markers = [];
    this.createGroup();
    if (this._transportMarkers !== null) {
      this._transportMarkers.remove();
    }

    this.detectors.getValue().forEach(elm => {
      if (elm.dislocation === null) return;

      let marker = L.marker([elm.dislocation.position_latitude, elm.dislocation.position_longitude], {
        icon: L.divIcon({
          html: `<img src="https://andromeda.fvf73.ru/images/detectors/${elm.detector_model}.png">`,
          iconSize: L.point(40, 40),
          className: '',
        }),
        id: elm.host_id,
      });

      marker.bindTooltip(elm.dislocation.dislocation_title);
      marker.bindPopup(`<p>${elm.detector_model}</p>
        <p>${elm.dislocation.dislocation_title}</p>
        <img class='cars-map' id='detection_image' width='100%'>`,
      );

      let interval;
      marker.on('popupopen', e => {
        Detector.detector({ id: e.target.options.id })
          .then(({ images }) => {
            if (images.length > 0) {
              let index = 1;
              document.getElementById('detection_image').src = images[index];
              interval = setInterval(() => {
                document.getElementById('detection_image').src = images[index];
                index = (index + 1 > images.length - 1) ? 0 : index + 1;
              }, 3000);
            }
          });
      });

      marker.on('popupclose', e => {
        clearInterval(interval);
      });
      this.markers.push(marker);
      this.markersGroup.addLayer(marker);
    });
    this._layers.push(this.markersGroup);
    this._map.addLayer(this.markersGroup);
  }

  destroy() {
    this._deleteLayer();
    this.markers = [];
  }

  searchTransport({ startDate, endDate, number }) {
    this.filter = {
      ...this.filter,
      startDate,
      endDate,
      number,
    };
    Detector.search({ startDate, endDate, number })
      .then(data => {
        this._deleteLayer();
        this.renderTransportPoints(data.detectors);
      })
      .catch(() => {});
  }

  renderTransportPoints(detectors) {
    let transportMarkersGroup = new L.MarkerClusterGroup({});
    if (this._transportMarkers !== null) {
      this._transportMarkers.remove();
    }

    detectors.forEach((elm, index) => {
      let marker = L.marker([elm.position_latitude, elm.position_longitude], {
        icon: L.divIcon({
          html: '<span class="marker-icon" style="border-color: green"><img src="/assets/icons/car.svg" style="height: 100%;"></span>',
          iconSize: [25, 25],
          className: '',
        }),
        id: elm.id,
      });

      marker.bindTooltip(`Автомобиль с номером: ${elm.car_number}<br>
        Время проезда: ${moment(elm.report_datetime).format('DD.MM.YYYY HH:mm:ss')}`);

      marker.bindPopup(`<p>Автомобиль с номером: ${elm.car_number}</p>
        <p>Время проезда: ${moment(elm.report_datetime).format('DD.MM.YYYY HH:mm:ss')}</p>
        <img class='cars-map' width='100%' src="${elm.photo_url}" onclick="openPopup(${index})">`);

      transportMarkersGroup.addLayer(marker);
    });

    this._transportMarkers = transportMarkersGroup;
    this._map.addLayer(this._transportMarkers);

    window.openPopup = (index) => {
      this._store.commit('showDetectorImage', detectors[index]);
    };
  }
}
