<template>
  <div class="left-sidebar-menu" :class="{show: open}">
    <div class="sidebar-content">
      <div class="sidebar-header">
        <div class="sidebar-header__content">
          <slot name="header">
            Заголовок
          </slot>
        </div>
        <div class="sidebar-header__control" v-if="filterable" @click="openFilter = !openFilter">
          <img src="/assets/images/app/filter.svg">
        </div>
      </div>
      <div class="sidebar-filter" v-if="openFilter">
        <slot name="filter"></slot>
      </div>
      <slot></slot>
    </div>

    <div
      class="sidebar-button"
      :class="{ opened: open }"
      @click="toggleSidebar"
    >
      <img src="@/assets/sidebar-arrow.png" alt="sidebar-arrow">
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-sidebar',
  props: {
    filterable: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    openFilter: false,
  }),
  methods: {
    toggleSidebar() {
      this.$emit('toggle-sidebar');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../../scss/app/sidebar';

  .sidebar-button {
    position: absolute;
    cursor: pointer;
    top: 13px;
    left: 0;
    transition: .3s;

    img {
      width: 34px;
    }

    &.opened  {
      left: calc(var(--sidebar-width) - 34px);
      transition: .3s;

      img {
        transform: scaleX(-1);
      }
    }
  }
</style>
