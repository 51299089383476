import { ACL } from '@/modules/acl/acl-service';

const acl = {};

function directive(elm, binding, vnode) {
  if (!ACL.can(binding.value)) {
    vnode.elm.parentElement.removeChild(vnode.elm);
  }
}

acl.install = function(Vue) {
  Vue.prototype.$can = function(permission) {
    return ACL.can(permission);
  };

  Vue.directive('can', {
    inserted: directive,
    update: directive,
  });
};

export default acl;
