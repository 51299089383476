var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"element",staticClass:"item",class:{
    input: _vm.input,
    output: _vm.output,
    error: _vm.error,
  },on:{"contextmenu":_vm.openContextMenu}},[_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s(_vm.prepareBody(_vm.body))}}),_c('div',{staticClass:"files"},_vm._l((_vm.files),function(file){return _c('file-preview',{key:file.id,attrs:{"id":file.id,"mime-type":file.type,"name":file.name,"download-mode":""},on:{"download":_vm.downloadFile}})}),1),(!_vm.error)?_c('div',{staticClass:"date"},[(_vm.editedAt)?_c('div',{staticClass:"edited",attrs:{"title":_vm.editedAt}},[_vm._v(" изменено ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.date)+" "),(_vm.output)?_c('div',{staticClass:"read",class:{
        done: _vm.read,
        'not-done': !_vm.read,
      },attrs:{"title":_vm.read ? _vm.readedAt : 'Не прочитано'}}):_vm._e()]):_c('div',{staticClass:"error-controls"},[_c('div',{staticClass:"error-controls-button",attrs:{"title":"Удалить"},on:{"click":_vm.remove}},[_c('i',{staticClass:"fas fa-trash"})]),_c('div',{staticClass:"error-controls-button",attrs:{"title":"Повторить попытку"},on:{"click":_vm.resend}},[_c('i',{staticClass:"fas fa-retweet"})])]),_c('vue-context',{ref:"menu"},[_c('li',{directives:[{name:"can",rawName:"v-can",value:('messenger.message-edit'),expression:"'messenger.message-edit'"}]},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.editMessage($event)}}},[_vm._v("Редактировать")])]),_c('li',{directives:[{name:"can",rawName:"v-can",value:('messenger.messages-delete'),expression:"'messenger.messages-delete'"}]},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.deleteMessage($event)}}},[_vm._v("Удалить")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }