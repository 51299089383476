import L from 'leaflet';

export class TrafficJamLayer {
  _store = null;
  _layers = null;
  _map = null;
  _oldZoom = 8;

  constructor({ store, map }) {
    this._store = store;
    this._map = map;

    this._map.on('zoomend', () => this.moveLayer());
  }

  _deleteLayer() {
    if (this._layers !== null) {
      this._layers.forEach(layer => layer.remove());
      this._layers = null;
    }
  }

  reRender() {
    this._layers = [];
    const ts = Math.round(new Date().getTime() / 1000.0) * 1000;
    const jamLayer = L.tileLayer(
      'https://core-jams-rdr-cache.maps.yandex.net/1.1/tiles?l=trf&x={x}&y={y}&z={z}&tm={ts}',
      {
        maxZoom: 18,
        keepBuffer: 8,
        ts,
      },
    );
    this._layers.push(jamLayer);
    this._oldZoom = this._map.getZoom();

    jamLayer.addTo(this._map);

    this.moveLayer();
  }

  moveLayer() {
    if (!this._layers || !this._layers.length) {
      return;
    }

    // Слой с яндекс-пробками нужно сдвигать в зависимости от зума, коэффициент был получен опытным путём
    const tiles = this._layers[0].getContainer();
    const zoom = this._map.getZoom();
    const margin = Math.pow(2, zoom) * -0.222168;

    tiles.style.marginTop = `${margin}px`;
    this._oldZoom = zoom;
  }

  destroy() {
    this._deleteLayer();
  }
}
