




















import { computed, defineComponent, Ref, ref, watch } from '@vue/composition-api';

export default defineComponent({
  emits: ['change'],
  props: {
    id: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      default: '#000',
    },
    // Используется для попапа в слое маршрутов
    prefix: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const picker: Ref<null | HTMLInputElement> = ref(null);
    const colorValue: Ref<string> = ref(props.color);
    const style = computed(() => colorValue.value ? `color: ${colorValue.value}` : '');

    watch(() => props.color, () => {
      colorValue.value = props.color;
    });

    return {
      picker,
      colorValue,
      style,
      togglePicker() {
        if (picker.value) {
          picker.value.click();
        }
      },
      updateColor() {
        emit('change', {
          color: colorValue.value,
          id: props.id,
        });
      },
    };
  },
});
