



































import { defineComponent, Ref, reactive, watch } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { ImagesPreviewPopup } from '@/types';
import Popup from '@/components/shared/popup.vue';

const trashAreaHelper = createNamespacedHelpers('trash-area');

export default defineComponent({
  components: { Popup },
  setup() {
    const imageUrl = `${process.env.VUE_APP_API_BASE}/trash-area-file/`;

    const { imagesPopup }: {
      imagesPopup: Ref<ImagesPreviewPopup>,
    } = trashAreaHelper.useGetters([
      'imagesPopup',
    ]) as any;

    const { hideImagesPopup }: {
      hideImagesPopup: () => Promise<void>,
    } = trashAreaHelper.useActions(['hideImagesPopup']) as any;

    const selected : {
      image: string,
    } = reactive({
      image: '',
    });

    watch(imagesPopup, () => {
      if (imagesPopup.value.open) {
        selected.image = imagesPopup.value.files[0]?.key ?? '';
      }
    });

    return {
      imageUrl,
      imagesPopup,
      selected,
      selectImage(key: string) {
        selected.image = key;
      },
      onClose() {
        hideImagesPopup();
      },
    };
  },
});
