import { Store } from 'vuex';
import { LayersService } from '../layers-service';
import { BaseLayer } from './base-layer';
export class AnalysisDoubleLayer extends BaseLayer {
  async reRender() { }

  resetMarkers() {
    const routeLayers = LayersService.instance._routeLayers;
    this._map.editTools.stopDrawing();
    const reset = !!(routeLayers.toMarker || routeLayers.fromMarker);

    if (routeLayers.toMarker) {
      routeLayers.toMarker.remove();
      routeLayers.toMarker = null;
      routeLayers.filter.analysisPoint2 = null;
    }

    if (routeLayers.fromMarker) {
      routeLayers.fromMarker.remove();
      routeLayers.fromMarker = null;
      routeLayers.filter.analysisPoint1 = null;
    }

    if (reset) {
      routeLayers._originAnalysisRoutes = [];
      routeLayers.analysisRoutes.next([]);
      routeLayers.highlightedRoute = null;
      routeLayers._filter({
        name: routeLayers.filter.name,
        selectedRoutes: routeLayers.filter.selectedRoutes,
        clear: false,
      });
      this._store.commit('route/converge', []);
      this._store.commit('route/duplicationRouteGroupId', null);
    }
  };

  destroy() {}
}
