























import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'VideoWatchPopup',
  props: {
    cameras: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
});
