<template>
  <div class="control">
    <div class="filters">
      <div class="filter">
        <input v-model="filter" @input="applyFilter" placeholder="Введите название остановки">
      </div>
    </div>
    <div class="stations">
      <div class="station" v-for="(station, index) in stations" :key="index" @click="showStation(station)">
        <div class="station-address">
          {{ station.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LayersService } from '@/services/layers-service';

export default {
  name: 'bus-stop-layer-control',
  data: () => ({
    stations: [],
    filter: '',
  }),
  methods: {
    showStation(station) {
      LayersService.instance
        ._busStopLayers
        .showMarker(station);
    },
    applyFilter(event) {
      LayersService.instance
        ._busStopLayers
        .applyFilter({ name: event.target.value });
    },
  },
  created() {
    this.filter = LayersService.instance._busStopLayers.filter.name;
    LayersService.instance
      ._busStopLayers
      .busStop
      .subscribe((stations) => { this.stations = stations; });
  },
};
</script>

<style lang="scss" scoped>
  .control {

    .filter {
      input {
        padding: 3px;
        font-size: 15px;
        width: 100%;
        margin: 16px 0;
        display: block;
        border: 1px solid var(--border-color);
        border-radius: 3px;
      }
    }

    .station {
      cursor: pointer;
      margin-bottom: 16px;

      &-address {
        font-size: 16px;
      }
    }
  }
</style>

<style lang="scss">
.popup-bus-route {
  &-wrapper {
    margin-top: -5px;

    max-height: 300px;
    overflow: auto;
  }

  &-item {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
