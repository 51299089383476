
import Vue from 'vue';
import { LayerGroup } from 'leaflet';
import { BaseLayer } from './base-layer';
import { Store } from 'vuex';
import { TrashAreaItem, TrashAreaFilters, ILocality } from '@/types';
import { TrashArea } from '../api/trash-area';
import { Locality } from '../api/locality';
import TrashAreaPopup from '@/components/map/popups/trash-area-popup.vue';
import router from '../../router';
import store from '../../store';

const UNSELECTED_COLOR = 'green';
const UNSELECTED_HOUSE_COLOR = 'red';
const UNSELECTED_LOCALITY_COLOR = 'blue';

export class TrashAreaLayer extends BaseLayer {
  _clusterBreakPoint = 16;
  municipalityId: number;
  localities: Array<ILocality> = [];
  layers: LayerGroup<L.GeoJSON<any>> = L.layerGroup();
  markersGroup: L.MarkerClusterGroup | null = null;
  markers: Array<L.Marker> = [];
  trashAreas: Array<TrashAreaItem> = [];
  isInProcess: number | null = null;
  popups: Record<number, Vue> = {};
  currentArea: number = 0;
  houseLayers: LayerGroup<L.GeoJSON<any>> = L.layerGroup();
  filters: TrashAreaFilters | null = null;
  filtersUpdated: boolean = true;
  localityLayers: LayerGroup<L.GeoJSON<any>> = L.layerGroup();

  constructor({ store, map, type }: {
    store: Store<any>,
    map: L.Map,
    type: string,
  }) {
    super({ store, map, type });

    this.municipalityId = this._store.getters['municipalities/current'].id;
    this.layers.addTo(map);
    this.houseLayers.addTo(map);
    this.localityLayers.addTo(map);

    this.createGroup();

    this._store.subscribe(mutation => {
      if (mutation.type === 'map/setGroupControlDisabled') {
        this.createGroup();
      }

      if (mutation.type === 'trash-area/setHighlight') {
        this.showHouses(this.currentArea);
      }
    });
  }

  createGroup() {
    this.markersGroup?.clearLayers();
    this.markersGroup?.remove();
    this.markersGroup = null;

    this.markersGroup = new L.MarkerClusterGroup({
      disableClusteringAtZoom: !this._store.getters['map/isGroupControlDisabled'] ? this._clusterBreakPoint : 0,
    });

    this.markersGroup.addLayers(this.markers);
    this._map.addLayer(this.markersGroup);
  }

  async reRender() {
    if (this.filtersUpdated || ((this.isInProcess === null || this.isInProcess !== this.municipalityId) && (this.trashAreas.length === 0 || this.municipalityId !== this._store.getters['municipalities/current'].id))) {
      this.municipalityId = this._store.getters['municipalities/current'].id;
      this.isInProcess = this.municipalityId;

      const response = await TrashArea.getTrashAreas(this.municipalityId, this.filters, false, [], {
        'trash_areas': ['id', 'point', 'address'],
      });
      this.trashAreas = response.data?.trash_areas;

      if (this.filters && this.filters.localityId) {
        this.localities = await Locality.getLocality(this.municipalityId);
      }

      this.isInProcess = null;
      this.filtersUpdated = false;
    }

    this._render();
  }

  _filter(filters: TrashAreaFilters) {
    this.filters = filters;
    this.filtersUpdated = true;
    this.reRender();
  }

  _render() {
    this._deleteLayers();
    this.createGroup();

    this.trashAreas.forEach((trashArea) => {
      const polygon = L.geoJSON(trashArea.border, {
        weight: 5,
        opacity: 1,
        color: UNSELECTED_COLOR,
        dashArray: '3',
        fillOpacity: 0.3,
        fillColor: UNSELECTED_COLOR,
        id: trashArea.id,
      } as any).bindTooltip(trashArea.address, {
        permanent: false,
      });

      this.layers.addLayer(polygon);

      if (trashArea.point.coordinates) {
        const marker = L.marker(L.GeoJSON.coordsToLatLng(trashArea.point.coordinates), {
          icon: L.icon({
            iconUrl: '/assets/icons/trash.png',
            iconSize: [32, 46],
            iconAnchor: [14, 46],
          }),
        } as any).bindTooltip(trashArea.address, {
          permanent: false,
        }).on('click', async(event: L.LeafletMouseEvent) => {
          const newTrashArea: any = await TrashArea.getSingleTrashArea(trashArea.id);
          const replaceIndex = this.trashAreas.findIndex((t) => t.id === trashArea.id);
          trashArea = newTrashArea;
          this.trashAreas[replaceIndex] = trashArea;
          const popup = new Vue({
            render: h => h(TrashAreaPopup, {
              props: {
                trashArea: trashArea,
              },
              on: {
                'show-houses': (id: number) => {
                  this._store.dispatch('trash-area/showHighlight');
                  this._store.dispatch('house/hideHighlight');

                  this.showHouses(id);
                  this.gotoHouses(trashArea);
                },
              },
            }),
            router,
            store,
          }).$mount();

          if (this.popups[trashArea.id]) {
            this.popups[trashArea.id].$destroy();
          }
          this.popups[trashArea.id] = popup;

          if (marker.getPopup()) {
            marker.unbindPopup();
          }

          if (polygon.getPopup()) {
            polygon.unbindPopup();
          }

          polygon.bindPopup(popup.$el as HTMLElement, { maxWidth: 500 });
          marker.bindPopup(popup.$el as HTMLElement, { maxWidth: 500 }).openPopup();
          marker.getPopup()?.on('remove', (e) => {
            marker.unbindPopup();
          });

          if (event.originalEvent.ctrlKey) {
            if (!trashArea.houses || trashArea.houses.length === 0) {
              this.showEmptyHouses(marker, popup);
              return;
            }

            this._store.dispatch('trash-area/showHighlight');
            this._store.dispatch('house/hideHighlight');

            this.showHouses(trashArea.id);
            this.gotoHouses(trashArea);

            marker.togglePopup();
            event.originalEvent.preventDefault();

            return false;
          }
        });

        this.markers.push(marker);
      }
    });

    this.markersGroup?.addLayers(this.markers);

    this.showHouses(this.currentArea);

    this.showLocality();

    setTimeout(() => {
      this.layers.eachLayer(layer => (layer as L.GeoJSON<any>).bringToFront());
    });
  }

  gotoHouses(trashArea: TrashAreaItem) {
    if (trashArea.houses && trashArea.houses.length && trashArea.houses[0].point.coordinates) {
      this._map.setView(L.GeoJSON.coordsToLatLng(trashArea.houses[0].point.coordinates), 17);
    }
  }

  showEmptyHouses(layer: any, popup: any) {
    layer.unbindPopup();
    layer.bindPopup('Нет связанных домов');
    layer.openPopup();

    layer.bindPopup(popup.$el as HTMLElement, { maxWidth: 500 });
  }

  showHouses(id: number) {
    this.currentArea = id;
    this._deleteHouseLayers();

    const highlight = this._store.getters['trash-area/highlight'];

    if (highlight && id) {
      const area = [...this.trashAreas.filter(value => value.id === id)].pop();
      if (area && area.houses) {
        area.houses.forEach((house) => {
          const polygon = L.geoJSON(house.border, {
            weight: 2,
            opacity: 1,
            color: UNSELECTED_HOUSE_COLOR,
            fillOpacity: 0.5,
            fillColor: UNSELECTED_HOUSE_COLOR,
            id: house.id,
          } as any).bindTooltip(house.address, {
            permanent: false,
          });

          this.houseLayers.addLayer(polygon);
        });

        setTimeout(() => {
          this.houseLayers.eachLayer(layer => (layer as L.GeoJSON<any>).bringToBack());
        });
      }
    }
  }

  showLocality() {
    this._deleteLoyalityLayers();

    if (this.filters && this.filters.localityId) {
      this.localities.filter(locality => locality.id === this.filters?.localityId).forEach(
        locality => {
          const popupContent = `${locality.name} (${locality.population} чел.)`;

          const polygon = L.geoJSON(locality.border, {
            weight: 5,
            opacity: 1,
            color: UNSELECTED_LOCALITY_COLOR,
            dashArray: '3',
            fillOpacity: 0,
            fillColor: UNSELECTED_LOCALITY_COLOR,
            id: locality.id,
          } as any).bindTooltip(popupContent, {
            permanent: false,
          });

          this.localityLayers.addLayer(polygon);
        });

      setTimeout(() => {
        this.localityLayers.eachLayer(layer => (layer as L.GeoJSON<any>).bringToBack());
      });
    }
  }

  _deleteLayers() {
    this.layers.eachLayer(l => l.remove());
    this.layers.clearLayers();

    this.markersGroup?.clearLayers();
    this.markers = [];

    Object.values(this.popups).forEach((val) => {
      val.$destroy();
    });
    this.popups = {};

    this._deleteHouseLayers();
    this._deleteLoyalityLayers();
  }

  _deleteHouseLayers() {
    this.houseLayers.eachLayer(l => l.remove());
    this.houseLayers.clearLayers();
  }

  _deleteLoyalityLayers() {
    this.localityLayers.eachLayer(l => l.remove());
    this.localityLayers.clearLayers();
  }

  destroy() {
    this._deleteLayers();
  }
}
