<template>
  <div class="control">
    <div class="tab-headers">
      <div
        class="tab-header"
        :class="{ 'tab-header-active': tabs.accidents }"
        @click="tabs.accidents = true; tabs.forms = false;"
      >
        Аварийность
      </div>
      <div
        class="tab-header"
        :class="{ 'tab-header-active': tabs.forms }"
        @click="tabs.forms = true; tabs.accidents = false;"
      >
        Отчёты
      </div>
    </div>
    <div class="tab-content">
      <div v-show="tabs.accidents">
        <div class="filter">
          <date-picker
            class="datepicker-with-scroll"
            lang="ru"
            range
            confirm
            v-model="filter.dates"
            type="datetime"
            format="DD.MM.YYYY HH:mm:ss"
          ></date-picker>
        </div>
        <div class="toggle">
          <label for="toggle_accident_concentrations" :class="[{'active': showAccidentConcentrations}, 'toggle__button']">
            <span class="toggle__label">Показать места концентрации ДТП</span>

            <input type="checkbox" id="toggle_accident_concentrations" v-model="showAccidentConcentrations" @change="toggleAccidentConcentrations">
            <span class="toggle__switch"></span>
          </label>
        </div>
        <div class="filter">
          <h5>По периоду совершения ДТП</h5>
          <ul>
            <li>
              <input type="checkbox" :value="'winter'" v-model="filter.type.seasons" id="checkWinter">
              <label for="checkWinter">Зима</label>
            </li>
            <li>
              <input type="checkbox" :value="'spring'" v-model="filter.type.seasons" id="checkSpring">
              <label for="checkSpring">Весна</label>
            </li>
            <li>
              <input type="checkbox" :value="'summer'" v-model="filter.type.seasons" id="checkSummer">
              <label for="checkSummer">Лето</label>
            </li>
            <li>
              <input type="checkbox" :value="'fall'" v-model="filter.type.seasons" id="checkFall">
              <label for="checkFall">Осень</label>
            </li>
          </ul>
        </div>

        <div class="filter">
          <multi-list-select
            :list="types"
            :selected-items="filter.type.types"
            @select="selectTypes"
            placeholder="По виду ДТП"
            option-value="name"
            option-text="name"
          ></multi-list-select>
        </div>

        <div class="filter">
          <multi-list-select
            :list="dictionary.participant_category"
            :selected-items="filter.dictionaries.participant_category"
            @select="selectParticipantCategories"
            placeholder="Категория участников ДТП"
            option-value="name"
            option-text="name"
          ></multi-list-select>
        </div>

        <div class="filter">
          <multi-list-select
            :list="victimsInjuresList"
            :selected-items="filter.type.victimsInjures"
            @select="selectVictimsInjures"
            placeholder="По тяжести последствий ДТП"
            option-value="name"
            option-text="name"
          ></multi-list-select>
        </div>

        <div class="filter">
          <multi-list-select
            :list="victimsAgeList"
            :selected-items="filter.type.victimsAge"
            @select="selectVictimsAge"
            placeholder="По возрастной группе пострадавших"
            option-value="name"
            option-text="name"
          ></multi-list-select>
        </div>

        <h5>По ТС</h5>

        <div class="filter">
          <multi-list-select
            :list="transportGroups"
            :selected-items="filter.type.transport_groups"
            @select="selectTransportGroups"
            placeholder="По группе автотранспорта"
            option-value="name"
            option-text="name"
          ></multi-list-select>
        </div>

        <div class="filter">
          <multi-list-select
            :list="dictionary.tech_issues"
            :selected-items="filter.dictionaries.tech_issues"
            @select="selectTechIssues"
            placeholder="Технические неисправности"
            option-value="name"
            option-text="name"
          ></multi-list-select>
        </div>

        <h5>По условиям движения</h5>

        <div class="filter">
          <multi-list-select
            :list="dictionary.road_surface"
            :selected-items="filter.dictionaries.road_surface"
            @select="selectRoadSurface"
            placeholder="Вид покрытия"
            option-value="name"
            option-text="name"
          ></multi-list-select>
        </div>

        <div class="filter">
          <multi-list-select
            :list="dictionary.road_state"
            :selected-items="filter.dictionaries.road_state"
            @select="selectRoadState"
            placeholder="Состояние покрытия"
            option-value="name"
            option-text="name"
          ></multi-list-select>
        </div>

        <div class="filter">
          <multi-list-select
            :list="dictionary.road_light"
            :selected-items="filter.dictionaries.road_light"
            @select="selectRoadLight"
            placeholder="Освещение"
            option-value="name"
            option-text="name"
          ></multi-list-select>
        </div>

        <div class="filter">
          <multi-list-select
            :list="dictionary.weather"
            :selected-items="filter.dictionaries.weather"
            @select="selectWeather"
            placeholder="Состояние погоды"
            option-value="name"
            option-text="name"
          ></multi-list-select>
        </div>

        <h5>По месту свершения ДТП</h5>

        <div class="filter">
          <multi-list-select
            :list="roads"
            :selected-items="filter.type.roads"
            @select="selectRoads"
            placeholder="Дорога"
            option-value="name"
            option-text="name"
          ></multi-list-select>
        </div>

        <div class="filter">
          <multi-list-select
            :list="dictionary.road_value"
            :selected-items="filter.dictionaries.road_value"
            @select="selectRoadValue"
            placeholder="Значение дороги"
            option-value="name"
            option-text="name"
          ></multi-list-select>
        </div>

        <div class="filter">
          <multi-list-select
            :list="dictionary.road_category"
            :selected-items="filter.dictionaries.road_category"
            @select="selectRoadCategory"
            placeholder="Категория дороги"
            option-value="name"
            option-text="name"
          ></multi-list-select>
        </div>

        <div class="filter">
          <multi-list-select
            :list="dictionary.place_status"
            :selected-items="filter.dictionaries.place_status"
            @select="selectPlaceStatus"
            placeholder="Статус населенного пункта"
            option-value="name"
            option-text="name"
          ></multi-list-select>
        </div>

        <div class="filter">
          <multi-list-select
            :list="dictionary.street_category"
            :selected-items="filter.dictionaries.street_category"
            @select="selectStreetCategory"
            placeholder="Категория улицы"
            option-value="name"
            option-text="name"
          ></multi-list-select>
        </div>

        <div class="filter">
          <multi-list-select
            :list="dictionary.road_plan"
            :selected-items="filter.dictionaries.road_plan"
            @select="selectRoadPlan"
            placeholder="Элементы плана дороги"
            option-value="name"
            option-text="name"
          ></multi-list-select>
        </div>

        <div class="filter">
          <multi-list-select
            :list="dictionary.road_profile"
            :selected-items="filter.dictionaries.road_profile"
            @select="selectRoadProfile"
            placeholder="Профиль дороги"
            option-value="name"
            option-text="name"
          ></multi-list-select>
        </div>

        <div class="filter">
          <multi-list-select
            :list="ndus"
            :selected-items="filter.type.ndus"
            @select="selectNdus"
            placeholder="По НДУ"
            option-value="name"
            option-text="name"
          ></multi-list-select>
        </div>

        <div class="filter">
          <multi-list-select
            :list="municipalitiesList"
            :selected-items="filter.type.municipality_id"
            @select="selectMunicipality"
            placeholder="ОД на чьей территории было ДТП"
            option-value="name"
            option-text="name"
          ></multi-list-select>
        </div>

        <div class="filter">
          <multi-list-select
            :list="roadOwners"
            :selected-items="filter.type.road_owners"
            @select="selectRoadOwner"
            placeholder="Владелец автомобильной дороги"
            option-value="name"
            option-text="name"
          ></multi-list-select>
        </div>

        <h5>Особые условия</h5>

        <div class="filter">
          <multi-list-select
            :list="dictionary.pedestrian_location"
            :selected-items="filter.dictionaries.pedestrian_location"
            @select="selectPedestrianLocation"
            placeholder="Местоположение пешехода"
            option-value="name"
            option-text="name"
          ></multi-list-select>
        </div>

        <div class="filter">
          <multi-list-select
            :list="dictionary.violation_first"
            :selected-items="filter.dictionaries.violation_first"
            @select="selectViolationFirst"
            placeholder="Непосредственное нарушение ПДД"
            option-value="name"
            option-text="name"
          ></multi-list-select>
        </div>

        <div class="filter">
          <multi-list-select
            :list="dictionary.violation_second"
            :selected-items="filter.dictionaries.violation_second"
            @select="selectViolationSecond"
            placeholder="Сопутствующее нарушение ПДД"
            option-value="name"
            option-text="name"
          ></multi-list-select>
        </div>

        <div class="filter">
          <multi-list-select
            :list="dictionary.uds_object_place"
            :selected-items="filter.dictionaries.uds_object_place"
            @select="selectUdsObjectPlace"
            placeholder="Объекты УДС на месте ДТП"
            option-value="name"
            option-text="name"
          ></multi-list-select>
        </div>

        <div class="filter">
          <multi-list-select
            :list="dictionary.uds_object_near"
            :selected-items="filter.dictionaries.uds_object_near"
            @select="selectUdsObjectNear"
            placeholder="Объекты УДС вблизи места ДТП"
            option-value="name"
            option-text="name"
          ></multi-list-select>
        </div>

        <div class="buttons-block">
          <div class="text-center">
            <button v-if="canExport" class="btn btn-export" @click.prevent="exportTable()">Экспорт списка ДТП</button>
          </div>
          <div class="text-center">
            <button class="btn btn-reset" @click="resetFilter">Сбросить</button>
          </div>
        </div>
      </div>
      <div v-show="tabs.forms" class="accidents-report-wrapper">
        <router-link :to="{ name: 'accidents-report.form1' }">Раздел 1. Аварийность по дням недели и времени суток</router-link>
        <router-link :to="{ name: 'accidents-report.form2' }">Раздел 2. Аварийность с участием пешеходов</router-link>
        <router-link :to="{ name: 'accidents-report.form3' }">Раздел 3. Аварийность по видам ДТП</router-link>
        <router-link :to="{ name: 'accidents-report.form4' }">Раздел 4. Аварийность по местам совершения</router-link>
        <router-link :to="{ name: 'accidents-report.form5' }">Раздел 5. Аварийность на автомобильных дорогах</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { LayersService } from '@/services/layers-service';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import { mapGetters, mapActions } from 'vuex';
import { MultiListSelect } from 'vue-search-select';
import { ACL } from '@/modules/acl/acl-service';
import { Accident } from '@/services/api/accident';

export default {
  name: 'accident-layer-control',
  components: { DatePicker, MultiListSelect },
  data: () => ({
    accidents: [],
    tabs: {
      accidents: true,
      forms: false,
    },
    filter: {
      type: {
        transport_groups: [],
        types: [],
        roads: [],
        ndus: [],
        road_owners: [],
        victimsInjures: [],
        victimsAge: [],
        seasons: [],
        municipality_id: [],
      },
      dictionaries: {
        participant_category: [],
        tech_issues: [],
        road_surface: [],
        road_state: [],
        road_light: [],
        road_value: [],
        road_category: [],
        road_plan: [],
        road_profile: [],
        weather: [],
        place_status: [],
        street_category: [],
        pedestrian_location: [],
        violation_first: [],
        violation_second: [],
        uds_object_place: [],
        uds_object_near: [],
      },
      dates: [
        moment().startOf('year').toDate(),
        moment().endOf('year').toDate(),
      ],
    },
    showAccidentConcentrations: false,
  }),
  methods: {
    ...mapActions({
      loadRoadOwners: 'road-owner/loadRoadOwners',
      loadNdus: 'ndu/loadNdus',
      loadAccidentTypes: 'accident/getAccidentTypes',
      loadTransportGroups: 'transports/loadTransportGroups',
    }),
    selectTypes(types) {
      this.filter.type.types = types;
    },
    selectParticipantCategories(category) {
      this.filter.dictionaries.participant_category = category;
    },
    selectTransportGroups(groups) {
      this.filter.type.transport_groups = groups;
    },
    selectTechIssues(issues) {
      this.filter.dictionaries.tech_issues = issues;
    },
    selectRoadSurface(surfaces) {
      this.filter.dictionaries.road_surface = surfaces;
    },
    selectRoadState(state) {
      this.filter.dictionaries.road_state = state;
    },
    selectRoadLight(light) {
      this.filter.dictionaries.road_light = light;
    },
    selectWeather(weather) {
      this.filter.dictionaries.weather = weather;
    },
    selectRoads(roads) {
      this.filter.type.roads = roads;
    },
    selectRoadValue(value) {
      this.filter.dictionaries.road_value = value;
    },
    selectRoadCategory(category) {
      this.filter.dictionaries.road_category = category;
    },
    selectPlaceStatus(status) {
      this.filter.dictionaries.place_status = status;
    },
    selectStreetCategory(category) {
      this.filter.dictionaries.street_category = category;
    },
    selectRoadPlan(plan) {
      this.filter.dictionaries.road_plan = plan;
    },
    selectRoadProfile(profile) {
      this.filter.dictionaries.road_profile = profile;
    },
    selectNdus(ndus) {
      this.filter.type.ndus = ndus;
    },
    selectMunicipality(municipality) {
      this.filter.type.municipality_id = municipality;
    },
    selectRoadOwner(owners) {
      this.filter.type.road_owners = owners;
    },
    selectPedestrianLocation(location) {
      this.filter.dictionaries.pedestrian_location = location;
    },
    selectViolationFirst(violation) {
      this.filter.dictionaries.violation_first = violation;
    },
    selectViolationSecond(violation) {
      this.filter.dictionaries.violation_second = violation;
    },
    selectUdsObjectPlace(object) {
      this.filter.dictionaries.uds_object_place = object;
    },
    selectUdsObjectNear(object) {
      this.filter.dictionaries.uds_object_near = object;
    },
    selectVictimsInjures(victims) {
      const withoutInjuredBefore = this.filter.type.victimsInjures.find(v => v.id === 'without_injured');
      const withoutInjuredAfter = victims.find(v => v.id === 'without_injured');
      const withInjuredBefore = this.filter.type.victimsInjures.find(v => v.id !== 'without_injured');
      const withInjuredAfter = victims.find(v => v.id !== 'without_injured');

      if (withoutInjuredAfter && !(withInjuredAfter && !withInjuredBefore)) {
        this.filter.type.victimsInjures = [withoutInjuredAfter];
      } else {
        this.filter.type.victimsInjures = victims.filter(v => v.id !== 'without_injured');
      }
    },
    selectVictimsAge(victims) {
      if (this.filter.type.victimsAge.length === 0 || victims === 0) {
        this.filter.type.victimsAge = victims;
      } else {
        this.filter.type.victimsAge = victims.filter(v => v.id !== this.filter.type.victimsAge[0].id);
      }
    },
    applyFilter() {
      let dates = [];

      if (this.filter.dates[0] !== null || this.filter.dates[1] !== null) {
        dates = [
          moment(this.filter.dates[0]).format('YYYY-MM-DD HH:mm:ss'),
          moment(this.filter.dates[1]).format('YYYY-MM-DD HH:mm:ss'),
        ];
      }

      LayersService.instance
        ._accidentLayers
        ._filter({
          dates,
          type: this.filter.type,
          dictionaries: this.filter.dictionaries,
          showAccidentConcentrations: this.showAccidentConcentrations,
        });
    },
    resetFilter() {
      Object.keys(this.filter.type).forEach(key => {
        if (Array.isArray(this.filter.type[key])) {
          this.filter.type[key] = [];
        } else {
          delete this.filter.type[key];
        }
      });

      let dates = [];
      if (this.filter.dates[0] !== null || this.filter.dates[1] !== null) {
        dates = [
          moment(this.filter.dates[0]).format('YYYY-MM-DD HH:mm:ss'),
          moment(this.filter.dates[1]).format('YYYY-MM-DD HH:mm:ss'),
        ];
      }

      this.showAccidentConcentrations = false;
      this.toggleAccidentConcentrations();

      LayersService.instance
        ._accidentLayers
        .filter({
          dates,
          type: this.filter.type,
          dictionaries: this.filter.dictionaries,
        });
    },
    toggleAccidentConcentrations() {
      if (this.showAccidentConcentrations) {
        LayersService.instance._accidentLayers.showAccidentConcentrations = true;
        LayersService.instance
          ._accidentLayers
          .reRender();
      } else {
        LayersService.instance
          ._accidentLayers
          .deleteAccidentConcentrationLayers();
        LayersService.instance._accidentLayers.showAccidentConcentrations = false;
      }
    },
    exportTable() {
      const params = LayersService.instance._accidentLayers.getParams();
      Accident.export(params)
        .then(({ file }) => {
          const url = URL.createObjectURL(new Blob([file], {
            type: 'application/vnd.ms-excel',
          }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Список ДТП.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {});
    },
  },
  created() {
    this.loadTransportGroups();
    this.loadRoadOwners({ all: true });
    this.loadNdus({ all: true });
    this.loadAccidentTypes();
    LayersService.instance
      ._accidentLayers
      .accidents
      .subscribe(accidents => { this.accidents = accidents; });

    this.filter = Object.assign({}, LayersService.instance._accidentLayers.filter);
    this.showAccidentConcentrations = LayersService.instance._accidentLayers.showAccidentConcentrations;

    if (this.filter.dates.length > 0) {
      this.filter.dates = [
        moment(this.filter.dates[0]).toDate(),
        moment(this.filter.dates[1]).toDate(),
      ];
    }
    this.toggleAccidentConcentrations();
  },
  computed: {
    ...mapGetters({
      transportGroups: 'transports/transportGroups',
      types: 'accident/types',
      roads: 'accident/roads',
      dictionary: 'accident/dictionary',
      roadOwners: 'road-owner/roadOwners',
      ndus: 'ndu/ndus',
      municipalities: 'municipalities/menuMunicipalities',
    }),
    municipalitiesList() {
      return this.municipalities
        .filter(m => m.id)
        .map(m => ({
          name: m.title,
          id: m.id,
        }));
    },
    victimsInjuresList() {
      return [
        { name: 'Погибло', id: 'death' },
        { name: 'Ранено', id: 'injured' },
        { name: 'Без пострадавших', id: 'without_injured' },
      ];
    },
    victimsAgeList() {
      return [
        { name: 'Взрослые', id: 'adults' },
        { name: 'Дети', id: 'children' },
      ];
    },
    canExport() {
      return ACL.can('accident.export');
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.applyFilter();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.tab {
  &-headers {
    display: flex;
    justify-content: space-between;
  }

  &-headers {
    margin-left: -16px;
    margin-top: -1px;
    margin-right: -16px;
  }

  &-header {
    width: 50%;
    text-align: center;

    padding-top: 10px;
    padding-bottom: 10px;

    cursor: pointer;

    &:hover {
      color: #fff;
      background-color: var(--main-color);
    }

    &-active, &-active:hover {
      color: #fff;
      background-color: var(--menu-main-color);
    }
  }
}
.accidents-report-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.control {
  max-height: 100%;

  .select {
    margin-top: 16px;
  }
}
.filter {

  ul {
    padding-left: 10px;
    display: flex;
    flex-flow: row wrap;

    li {
      margin-right: 15px;
      list-style: none;
      color: #000;

      input[type='checkbox'] {
        display: none;

        &:disabled {
          +label {
            &:before {
              border-color: black;
            }
          }
        }

        &:checked {
          +label {
            &:before {
              background-color: var(--main-color);
            }
          }
        }
      }

      label {
        cursor: pointer;
        margin-bottom: 0;
        position: relative;
        padding-left: 18px;

        &:before {
          position: absolute;
          top: 4px;
          left: 0;
          content: "";
          width: 10px;
          height: 10px;
          outline: 1px solid #FFFFFF;
          border: 1px solid var(--main-color);
        }
      }
    }
  }
  div {
    margin-bottom: 5px;
  }
}

.buttons-block {
  display: flex;
  justify-content: center;
  .btn {
    margin-top: 10px;
    margin-left: 15px;
    border: 1px solid rgba(0,0,0,.5);

    &-export {
      background-color: var(--main-color);
      color: #fff;
    }
  }
}

.datepicker-with-scroll {
  position: static;
  margin: 10px 0;
}

.toggle {

  &__button {
    vertical-align: middle;
    user-select: none;
    cursor: pointer;

    & input[type="checkbox"] {
      opacity: 0;
      position: absolute;
      width: 1px;
      height: 1px;
    }

    &.active .toggle__switch {
      background: #adedcb;
      box-shadow: inset 0 0 1px #adedcb;
    }
    &.active .toggle__switch::after,
    &.active .toggle__switch::before{
      transform:translateX(40px - 18px);
    }
    &.active .toggle__switch::after {
      left: 0;
      background: #53B883;
      box-shadow: 0 0 1px #53B883;
    }
  }

  &__switch {
    display:inline-block;
    height:12px;
    border-radius:6px;
    width:40px;
    background: #BFCBD9;
    box-shadow: inset 0 0 1px #BFCBD9;
    position:relative;
    margin-left: 10px;
    transition: all .25s;

    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      left: 0;
      top: -3px;
      transform: translateX(0);
      transition: all .25s cubic-bezier(.5, -.6, .5, 1.6);
    }

    &:after {
      background: #4D4D4D;
      box-shadow: 0 0 1px #666;
    }

    &:before {
      background: #4D4D4D;
      box-shadow: 0 0 0 3px rgba(0,0,0,0.1);
      opacity:0;
    }
  }
}
</style>
