import L from 'leaflet';
import { BusStop } from '@/services/api/bus-stop';
import { LayersService } from '@/services/layers-service';
import { BehaviorSubject } from 'rxjs';
import { BaseLayer } from './base-layer';

export class BusStopLayer extends BaseLayer {
  _layers = null;

  _originBusStop = [];
  busStop = new BehaviorSubject([]);
  markersGroup = null;
  markersGroupAv = null;
  markers = [];
  markersAv = [];
  _clusterBreakPoint = 16;
  lastFilter = '';

  constructor({ store, map, type }) {
    super({ store, map, type });

    this.filter = {
      name: '',
      municipalityId: null,
      routeId: null,
      ...this.filter,
    };
    this.createGroups();

    this.busStop.subscribe(() => this._render());

    document.addEventListener('click', this.busStopPopupHandler.bind(this));

    this._store.subscribe(mutation => {
      if (mutation.type === 'map/setGroupControlDisabled') {
        this.markersGroup?.clearLayers();
        this.markersGroup?.remove();
        this.markersGroup = null;
        this.markersGroupAv?.clearLayers();
        this.markersGroupAv?.remove();
        this.markersGroupAv = null;

        this.createGroups();
      } else if (mutation.type === 'transports/setRouteFilter') {
        const routeId = mutation.payload ? mutation.payload.id : null;
        this.applyFilter({
          ...this.filter,
          routeId,
        });
      }
    });
  }

  createGroups() {
    this.markersGroup = new L.MarkerClusterGroup({
      disableClusteringAtZoom: !this._store.getters['map/isGroupControlDisabled'] ? this._clusterBreakPoint : 0,
      iconCreateFunction: function(cluster) {
        const markers = cluster.getAllChildMarkers();
        return L.divIcon({
          html: `
          <div class="text-center"
            style="
            height: 40px;
            width: 40px;
            position: relative;
            padding: 20px 10px;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url(assets/icons/bus-stop.svg);">
              <div style="
              width: 28px;
              height: 28px;
              position: absolute;
              top: 4px;
              left: 4px;
              line-height: 28px;
              text-align: center;
              font-size: 12px;
              color: #ffffff;
              border-radius: 50%;
              background: rgba(0, 74, 42, 0.7)">${markers.length}</div>
          </div>`,
          className: '',
          iconSize: L.point(40, 40),
        });
      },
      spiderfyOnMaxZoom: false,
    });
    this.markersGroupAv = new L.MarkerClusterGroup({
      disableClusteringAtZoom: !this._store.getters['map/isGroupControlDisabled'] ? this._clusterBreakPoint : 0,
      iconCreateFunction: function(cluster) {
        const count = cluster.getChildCount();
        return L.divIcon({
          html: `
          <div class="text-center"
            style="
            height: 56px;
            width: 56px;
            position: relative;
            padding: 20px 10px;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url(assets/icons/bus-stop-group_av.png);">
              <div style="
              width: 28px;
              height: 28px;
              position: absolute;
              top: 15px;
              left: 15px;
              line-height: 28px;
              text-align: center;
              font-size: 12px;
              color: #ffffff;
              border-radius: 50%;
              background: rgba(11, 22, 42, 0.5)">${count}</div>
          </div>`,
          className: '',
          iconSize: L.point(40, 40),
        });
      },
      spiderfyOnMaxZoom: false,
    });

    this.markersGroup.addLayers(this.markers);
    this.markersGroupAv.addLayers(this.markersAv);

    this._map.addLayer(this.markersGroup);
    this._map.addLayer(this.markersGroupAv);
  }

  _deleteLayer() {
    if (this._layers !== null) {
      this._layers.forEach(layer => layer.clearLayers());
      this._layers = null;
    }
  }

  applyFilter(filter) {
    let filtered = this._originBusStop;

    if (filter.name) {
      filtered = filtered.filter((station) => station.name.toLowerCase().indexOf(filter.name.toLowerCase()) > -1);
    }
    if (filter.routeId) {
      filtered = filtered
        .filter((station) => station.points
          .filter(point => point.route_group)
          .map(point => point.route_group)
          .some(routeGroup => routeGroup.route.id === filter.routeId));
    }

    this.filter = { ...filter };

    this.busStop.next(filtered);
  }

  showMarker(station) {
    this._layers.forEach(layer => {
      if (layer.options.id === station.id) {
        layer.openPopup();
        const latlng = layer.getLatLng();
        this._map.setView([latlng.lat, latlng.lng], 18);
      }
    });
  }

  async reRender({ groups }) {
    const selectedGroups = groups.filter(group => group.types.filter(type => type.checked).length > 0);
    const routeTypeId = selectedGroups.find(group => group.name === 'По виду')?.types.filter(type => type.checked).map(type => type.id).join(',');
    const type = selectedGroups.find(group => group.name === 'По типу')?.types.filter(type => type.checked).length > 0 ? 1 : undefined;
    const owner = selectedGroups.find(group => group.name === 'По владельцам')?.types.filter(type => type.checked).map(type => type.title).join(',');

    const filter = JSON.stringify({
      municipalityId: this._store.getters['municipalities/current'].id,
      type,
      owner,
      routeTypeId,
    });

    if (!this.lastFilter || this.lastFilter !== filter) {
      if (this._store.getters['municipalities/current'].id === 0 || this._store.getters['municipalities/current'].super) {
        this.filter = {
          ...this.filter,
          municipalityId: null,
        };
      } else {
        this.filter = {
          ...this.filter,
          municipalityId: this._store.getters['municipalities/current'].id,
        };
      }

      this.lastFilter = filter;

      const response = await BusStop.getBusStops({
        all: true,
        municipalityId: this.filter.municipalityId,
        type,
        owner,
        routeTypeId,
      });
      this._originBusStop = response.bus_stops.slice(0, 6000);
    }

    this.applyFilter(this.filter);
  }

  _render() {
    if (this._store.getters['map/layers'].indexOf('busStop') === -1) {
      return;
    }

    this._deleteLayer();
    let myRenderer = L.canvas({ padding: 0.5 });
    this._layers = [];
    this.markers = [];
    this.markersAv = [];
    this.busStop.getValue().forEach(station => {
      if (station.type === 1) {
        const marker = L.marker([station.point.coordinates[1], station.point.coordinates[0]], {
          icon: L.divIcon({
            html: `<img class="invert" style="height: 100%" src="assets/icons/bus-stop-av.png">`,
            iconSize: [51, 56],
            className: '',
          }),
          renderer: myRenderer,
          id: station.id,
        })
          .bindPopup(this.generatePopupContent(station));
        this.markersGroupAv.addLayer(marker);
        this.markersAv.push(marker);
      } else {
        const marker = L.marker([station.point.coordinates[1], station.point.coordinates[0]], {
          icon: L.divIcon({
            html: `<img class="invert" style="height: 100%" src="assets/icons/bus-stop.svg">`,
            iconSize: [25, 25],
            className: '',
          }),
          renderer: myRenderer,
          id: station.id,
        })
          .bindPopup(this.generatePopupContent(station));
        this.markersGroup.addLayer(marker);
        this.markers.push(marker);
      }
    });
    this._layers.push(this.markersGroup);
    this._layers.push(this.markersGroupAv);
  }

  generatePopupContent(busStop) {
    const routesElement = `
      <div class="popup-bus-route-wrapper">
        ${busStop.points.filter(point => point.route_group).map(point => `<div class="popup-bus-route-item"><a href="#" class="popup-bus-route-name" data-id="${point.route_group.route.id}">${point.route_group.route.name} ${point.route_group.name}</a></div>`).join('')}
      </div>
    `;
    return `
      <p class="mts"><strong>Название:</strong> ${busStop.name}</p>
      <p class="mts"><strong>Вид:</strong> ${busStop.route_types.map(type => type.name).join(', ')}</p>
      <p class="mts"><strong>Принадлежность:</strong> ${busStop.owner ? busStop.owner : 'Не задана'}</p>
      <p class="mts">
        <strong>Маршруты:</strong>
        ${routesElement}
      </p>
    `;
  }

  busStopPopupHandler(e) {
    const target = e.target;
    // Show route
    if (target.classList.contains('popup-bus-route-name')) {
      e.preventDefault();
      e.stopPropagation();
      const routeId = Number(target.getAttribute('data-id'));

      if (!routeId) {
        return;
      }

      this._store.dispatch('transports/openTransportLayer', routeId)
        .then(() => {
          return this._store.dispatch('transports/showRoute', routeId);
        })
        .then((route) => {
          LayersService.instance._transportLayers.drawRoute(route);
        });
    }
  }

  destroy() {
    this._deleteLayer();

    this.markers = [];
    this.markersAv = [];
  }
}
