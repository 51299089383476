





























import { computed, defineComponent, reactive, ref, Ref, watch } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { ExtractGetterTypes } from 'vuex-composition-helpers/dist/types/util';
import { Locality } from '../../../services/api/locality';
import { ILocality, IMunicipality } from '../../../types';
import { LayersService } from '../../../services/layers-service';
import { ModelSelect } from 'vue-search-select';

const municipalitiesHelpers = createNamespacedHelpers('municipalities');

export default defineComponent({
  name: 'locality-layer-control',
  components: {
    ModelSelect,
  },
  setup() {
    const filter = reactive({ ...LayersService.instance._localityLayers.filter });
    const data: {
      districts: Array<ILocality>,
    } = reactive({
      districts: [],
    });
    const { current }: ExtractGetterTypes<{
      current: Ref<IMunicipality>,
    }> = municipalitiesHelpers.useGetters(['current']);

    const districts = computed(() => {
      return [
        {
          value: 0,
          text: 'По округу',
        },
        ...data.districts.map(d => ({
          value: d.id,
          text: d.name,
        })),
      ];
    });

    const filteredLocalities = computed(() => {
      return LayersService.instance
        ._localityLayers
        .filteredLocalities;
    });

    Locality.getDistricts(current.value.id)
      .then(res => {
        data.districts = res;
      });

    watch(current, () => {
      Locality.getDistricts(current.value.id)
        .then(res => {
          data.districts = res;
        });
    });

    const isSelectMode = computed(() => LayersService.instance
      ._localityLayers
      .isSelectMode);

    return {
      filter,
      current,
      data,
      districts,
      filteredLocalities,
      isSelectMode,
      search() {
        LayersService.instance
          ._localityLayers
          ._filter({
            name: filter.name,
            district: filter.district,
            type: filter.type,
          });
      },
      reset() {
        filter.name = '';
        filter.district = 0;
        filter.type = 0;
        LayersService.instance
          ._localityLayers
          ._filter({
            name: '',
            district: 0,
            type: 0,
          });
        LayersService.instance
          ._localityLayers
          .stopSelect();
      },
      jump(locality: ILocality) {
        LayersService.instance
          ._localityLayers
          .jump(locality);
      },
      startSelect() {
        LayersService.instance
          ._localityLayers
          .startSelect();
      },
      stopSelect() {
        LayersService.instance
          ._localityLayers
          .stopSelect();
      },
    };
  },
});
