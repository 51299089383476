

















import { computed, defineComponent, ref, Ref } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';

const mapHelper = createNamespacedHelpers('map');
const zoneSelectionHelper = createNamespacedHelpers('zone-selection');

export default defineComponent({
  name: 'SelectZoneControl',
  setup() {
    const { layers }: {
      layers: Ref<Array<string>>
    } = mapHelper.useGetters(['layers']) as any;
    const { setRectangle, clear, start }: {
      setRectangle: (rectangle: any) => {},
      clear: () => {},
      start: () => {},
    } = zoneSelectionHelper.useActions(['setRectangle', 'clear', 'start']) as any;
    const { enabled }: {
      enabled: Ref<boolean>,
    } = zoneSelectionHelper.useGetters(['rectangle', 'enabled']) as any;

    const isZoneSelected = computed(() => enabled.value);
    const showSelectZone = computed(() => {
      const activeLayers = ['accident'];

      return layers.value.filter(l => activeLayers.indexOf(l) !== -1).length > 0;
    });

    const disable = () => {
      setRectangle([]);
      clear();
    };

    return {
      isZoneSelected,
      showSelectZone,
      selectZone() {
        if (isZoneSelected.value) {
          disable();
        } else {
          start();
        }
      },
    };
  },
});
