/* eslint-disable camelcase */
import { BehaviorSubject, Subscription } from 'rxjs';
import L, { LatLngTuple, LayerGroup, MarkerClusterGroup } from 'leaflet';
import './moving-marker';
import 'leaflet-polylinedecorator';
import { TransportLayer } from './transport-layer';
import moment from 'moment';
import { ACL } from '@/modules/acl/acl-service';
import { IRouteType, ITransport, IMunicipalityPayload, TransportStatusEnum, IRoute, MessageTypeEnum } from '@/types';
import { SocketService } from '../socket';
import Vue from 'vue';
import TransportPopup from '../../components/map/popups/transport-popup.vue';
import router from '../../router';
import { ROUTE_NUMBER_REGEX } from '@/constants/Global';

export class Transport {
  _token: string | null = null;
  _socket!: WebSocket;
  _map!: L.Map;
  _routeTypes: {
    [key: number]: IRouteType,
  } = {};
  _markersZoomed: {
    [key: string]: any,
  } = {};
  _markersClustered: {
    [key: string]: L.Marker,
  } = {};
  _zoomedLayerGroup!: LayerGroup | null;
  _clusterLayer!: MarkerClusterGroup | null;
  _activeLayer!: 'zoomed' | 'cluster';
  activeTransport: BehaviorSubject<{
    [key: string]: ITransport
  }> = new BehaviorSubject({});
  _makerSize = 40 as const;
  _mapMaxZoom = 18 as const;
  _clusterBreakPoint = 13 as const;
  _markerPackageTimeDiff = {
    max: 60000, // 1 минута
    min: 2000, // 2 секунды
  } as const;
  filter: BehaviorSubject<{
    [key: string]: any
  }> = new BehaviorSubject({});
  _filterSubscription!: Subscription;
  _municipality: number | null = null;
  _layer!: TransportLayer;
  _zoomEndHandler: (() => void) | null = null;
  _popupOpenedTransport: string | null = null;
  _onMessageHanlderBinded: null | ((event: MessageEvent<any>) => void) = null;

  constructor({ map, routeTypes, token, municipality, layer }: {
    map: L.Map,
    routeTypes: Array<IRouteType>,
    token: string,
    municipality: number,
    layer: TransportLayer,
  }) {
    this._map = map;
    this._token = token;
    this._routeTypes = routeTypes.reduce((a: {
      [key: number]: IRouteType,
    }, c) => {
      a[c.id] = c;
      return a;
    }, {});
    this._municipality = municipality;
    this._layer = layer;

    this._run();
  }

  _onMessageHandler(event: MessageEvent<any>) {
    this.processMessage(JSON.parse(event.data));
  }

  _run(): void {
    this._zoomedLayerGroup = L.layerGroup();
    this.createClusterLayer();

    this._socket = SocketService.run() as WebSocket;
    this._onMessageHanlderBinded = this._onMessageHandler.bind(this);
    this.initSocket();
    this._socket.addEventListener('message', this._onMessageHanlderBinded);

    if (!this._zoomEndHandler) {
      this._zoomEndHandler = this._zoomEnd.bind(this);
    }

    this._map.on('zoomend', this._zoomEndHandler);
    this._map.on('dragend', this._zoomEndHandler);
    this._map.on('popupclose', () => {
      this._popupOpenedTransport = null;
    });

    if (this._map.getZoom() >= this._clusterBreakPoint) {
      this._zoomedLayerGroup.addTo(this._map);
      this._activeLayer = 'zoomed';
    } else {
      this._clusterLayer?.addTo(this._map);
      this._activeLayer = 'cluster';
    }

    this._filterSubscription = this.filter.subscribe((v) => {
      if (Object.keys(v).length > 0) {
        this._layer._store.dispatch('map/setFilter', {
          value: { ...v },
          type: 'transport',
        });
      }

      this._filterActiveTransport();
    });

    this._layer._store.subscribe((mutation: {
      type: string,
      payload: unknown,
    }) => {
      if (mutation.type === 'map/setGroupControlDisabled') {
        this._clusterLayer?.clearLayers();
        this._clusterLayer?.remove();
        this._clusterLayer = null;

        this.createClusterLayer();
        if (this._map.getZoom() < this._clusterBreakPoint) {
          (this._clusterLayer as any as L.MarkerClusterGroup).addTo(this._map);
        }
        this._filterActiveTransport();
      }
    });
  }

  createClusterLayer() {
    this._clusterLayer = new L.MarkerClusterGroup({
      disableClusteringAtZoom: !this._layer._store.getters['map/isGroupControlDisabled'] ? this._clusterBreakPoint : 0,
      spiderfyOnMaxZoom: false,
      iconCreateFunction: (cluster) => {
        const childCount = cluster.getChildCount();

        return new L.DivIcon({
          html: `<div><span>${childCount}</span></div>`,
          className: 'marker-cluster marker-cluster-custom',
          iconSize: new L.Point(40, 40),
        });
      },
    });
    this._clusterLayer.addLayers(Object.values(this._markersClustered));
  }

  initSocket() {
    if (SocketService.loggedIn) {
      this.changeMunicipality(this._municipality);
      this._socket.send(JSON.stringify({
        type: MessageTypeEnum.TRANSPORT_ON,
        payload: {},
      }));
    }
  }

  processMessage({ type, payload }: {
    type: MessageTypeEnum,
    payload: IMunicipalityPayload | Array<ITransport>
  }): void {
    switch (type) {
      case MessageTypeEnum.TRANSPORT_MESSAGE_TYPE:
        this._transportMessage(payload as Array<ITransport>);
        break;

      case MessageTypeEnum.MUNICIPALITY_CHANGE_SUCCESS_TYPE:
        this._municipality = (payload as IMunicipalityPayload).municipalityId;
        this.filter.next({ ...this.filter.getValue() });
        break;

      case MessageTypeEnum.LOGIN_SUCCESS_TYPE:
        this.changeMunicipality(this._municipality);
        this._socket.send(JSON.stringify({
          type: MessageTypeEnum.TRANSPORT_ON,
          payload: {},
        }));
        break;
    }
  }

  _transportMessage(transports: Array<ITransport>): void {
    const PARKING_CONDITION = 20;
    const BROKEN_CONDITION = 1440;

    const activeTransports = this.activeTransport.getValue();
    const now = moment.utc();
    const filteredTransports = transports
      .map(transport => {
        if (!transport.route && transport.routes && transport.routes.length > 0) {
          transport.route = transport.routes[0];
        }

        return transport;
      })
      .map(transport => {
        const time = moment.utc(transport.geo_position.tracker_time);
        const diff = now.diff(time, 'minutes');

        if (!transport.status && activeTransports[transport.number]) {
          transport.status = activeTransports[transport.number].status;
        }

        if (diff > BROKEN_CONDITION) {
          transport.status = TransportStatusEnum.BROKEN;
        } else if (transport.status !== TransportStatusEnum.BROKEN && diff > PARKING_CONDITION) {
          transport.status = TransportStatusEnum.PARKING;
        } else if (!transport.status) {
          transport.status = TransportStatusEnum.ACTIVE;
        }

        return transport;
      })
      .filter(transport => !!transport);

    this._updateActiveTransport(filteredTransports);
  }

  _updateActiveTransport(transports: Array<ITransport>): void {
    const activeTransports = this.activeTransport.getValue();
    transports.forEach(transport => {
      if (!activeTransports[transport.number]) {
        activeTransports[transport.number] = transport;
      } else {
        const timeNew = moment.utc(transport.geo_position.tracker_time);
        const timeOld = moment.utc(activeTransports[transport.number].geo_position.tracker_time);

        if (timeOld.isSameOrBefore(timeNew)) {
          activeTransports[transport.number] = transport;
        }
      }
    });

    const now = moment.utc();
    Object.keys(activeTransports).forEach(key => {
      const value = activeTransports[key];
      const time = moment.utc(value.geo_position.tracker_time);
      const diff = now.diff(time, 'minutes');

      if (value.status !== TransportStatusEnum.BROKEN && diff > 20) {
        value.status = TransportStatusEnum.PARKING;
      } else if (value.status === TransportStatusEnum.PARKING && diff <= 20) {
        value.status = TransportStatusEnum.ACTIVE;
      }

      this.processTransport(value);
    });

    this.activeTransport.next(activeTransports);

    this._filterActiveTransport();
  }

  _filterActiveTransport(): void {
    const activeTransport = this.activeTransport.getValue();
    const toAdd: Array<L.Marker> = [];

    Object.keys(this._markersZoomed).forEach(key => {
      const markerZoomed = this._markersZoomed[key];
      const element = markerZoomed.getElement();

      if (!this._packageFilter(markerZoomed.options.transport)) {
        if (element) {
          element.style.display = 'none';
        }

        markerZoomed.stop();
        if (this._clusterLayer?.hasLayer(this._markersClustered[key])) {
          this._clusterLayer?.removeLayer(this._markersClustered[key]);
        }
        delete activeTransport[markerZoomed.options.transport.number];
      } else {
        if (element) {
          element.style.display = 'block';
        }

        if (!this._clusterLayer?.hasLayer(this._markersClustered[key])) {
          toAdd.push(this._markersClustered[key]);
        }

        activeTransport[markerZoomed.options.transport.number] = markerZoomed.options.transport;
      }
    });
    this._clusterLayer?.addLayers(toAdd);

    this.activeTransport.next(activeTransport);

    this._layer._store.commit('layers/transports/updateTransporterFilter');
  }

  _packageFilter({ geo_position, route_type_id, transporter_id, number, municipalities_ids, status, route }: ITransport, skipTransporterFilter = false): boolean {
    if (!(Array.isArray(geo_position.point.coordinates) && geo_position.point.coordinates.length === 2)) {
      return false;
    }

    let result = true;
    const filter = this.filter.getValue();

    if (Array.isArray(filter.routeTypes)) {
      result = result && filter.routeTypes.indexOf(route_type_id) > -1;
    }

    if (!skipTransporterFilter && filter.transporter) {
      result = result && filter.transporter === transporter_id;
    }

    if (typeof filter.number === 'string' && filter.number.length > 0) {
      result = result && number.toLowerCase().indexOf(filter.number.toLowerCase()) > -1;
    }

    if (Array.isArray(filter.status) && filter.status.length > 0) {
      result = result && filter.status.indexOf(status) > -1;
    }

    if (this._municipality !== null) {
      result = result && municipalities_ids.indexOf(this._municipality) > -1;
    }

    return result;
  }

  _makeMarker(transport: ITransport, coords: LatLngTuple): void {
    const iconHtml = this._iconUrl(transport);

    this._makeZoomedMarker(transport, coords, iconHtml);
    this._makeClusteredMarker(transport, coords, iconHtml);
  }

  _makeZoomedMarker(transport: ITransport, coords: LatLngTuple, iconHtml: string): void {
    this._markersZoomed[transport.number] = L.movingMarker([coords, coords], 10000, {
      icon: L.divIcon({
        html: iconHtml,
        iconSize: [this._markersSize, this._markersSize],
        className: '',
      }),
      title: transport.route_type.name + ' номер: ' + transport.number,
      rotationAngle: +transport.geo_position.direction,
      rotationOrigin: 'center center',
      transport,
    });

    this._markersZoomed[transport.number].on('click', (event: L.LeafletEvent) => {
      const marker = event.sourceTarget;

      this._generatePopup(marker);
    });

    this._zoomedLayerGroup?.addLayer(this._markersZoomed[transport.number]);

    if (
      (this._map.getBounds().contains(L.latLng(coords)) ||
      this._map.getBounds().contains(this._markersZoomed[transport.number].getLatLng())) &&
      this._map.getZoom() >= this._clusterBreakPoint
    ) {
      this._markersZoomed[transport.number].start();
    }
  }

  _makeClusteredMarker(transport: ITransport, coords: LatLngTuple, iconHtml: string): void {
    this._markersClustered[transport.number] = L.marker(coords, {
      icon: L.divIcon({
        html: iconHtml,
        iconSize: [this._markersSize, this._markersSize],
        className: '',
      }),
      title: transport.route_type.name + ' номер: ' + transport.number,
      rotationAngle: +transport.geo_position.direction,
      rotationOrigin: 'center center',
      transport,
    });

    this._markersClustered[transport.number].on('click', event => {
      const marker = event.sourceTarget;

      this._generatePopup(marker);
    });

    this._clusterLayer?.addLayer(this._markersClustered[transport.number]);
  }

  _updateMarker(transport: ITransport, coords: LatLngTuple): void {
    this._updateZoomedMarker(this._markersZoomed[transport.number], transport, coords);
    this._updateClusteredMarker(this._markersClustered[transport.number], transport, coords);
  }

  _updateZoomedMarker(marker: any, transport: ITransport, coords: LatLngTuple): void {
    const angle = marker.options.rotationAngle;
    if (marker.options.rotationAngle !== transport.geo_position.direction) {
      marker.setRotationAngle(transport.geo_position.direction);
    }
    if (marker.options.transport.status !== transport.status ||
      marker.options.transport.route_type_id !== transport.route_type_id ||
      marker.options.transport.geo_position.in_line !== transport.geo_position.in_line ||
      marker.options.transport.route?.name !== transport.route?.name ||
      (
        marker.options.transport.routes &&
        marker.options.transport.routes.length > 0 &&
        transport.routes &&
        transport.routes.length > 0 &&
        marker.options.transport.routes[0]?.name !== transport.routes[0]?.name
      ) ||
      angle !== transport.geo_position.direction) {
      marker.setIcon(L.divIcon({
        html: this._iconUrl(transport),
        iconSize: [this._markersSize, this._markersSize],
        className: '',
      }));
    }

    if (
      (this._map.getBounds().contains(L.latLng(coords)) ||
      this._map.getBounds().contains(marker.getLatLng())) &&
      this._map.getZoom() >= this._clusterBreakPoint
    ) {
      const duration = moment.utc(transport.geo_position.server_time).diff(moment.utc(marker.options.transport.geo_position.server_time), 'milliseconds');
      const fixedDuration = (duration > this._markerPackageTimeDiff.max || duration < this._markerPackageTimeDiff.min)
        ? this._markerPackageTimeDiff.min
        : duration;

      marker.moveTo(coords, fixedDuration);

      this._markersClustered[transport.number].setLatLng(L.latLng(coords));
    } else {
      marker.stop();
    }
    const time = marker.options.transport.geo_position.server_time;
    marker.options.transport = transport;

    if (marker.getPopup()?.isOpen() && time !== transport.geo_position.server_time) {
      marker.getPopup()?.closePopup();
      marker.bindPopup(this._makePopupContent(marker.options.transport), {
        minWidth: 320,
        maxWidth: 320,
      }).openPopup();
    }
  }

  _updateClusteredMarker(marker: L.Marker, transport: ITransport, coords: LatLngTuple): void {
    const angle = marker.options.rotationAngle;
    if (marker.options.rotationAngle !== transport.geo_position.direction) {
      marker.setRotationAngle(transport.geo_position.direction);
    }
    if (marker.options.transport.status !== transport.status ||
      marker.options.transport.route_type_id !== transport.route_type_id ||
      marker.options.transport.geo_position.in_line !== transport.geo_position.in_line ||
      marker.options.transport.route?.name !== transport.route?.name ||
      (
        marker.options.transport.routes &&
        marker.options.transport.routes.length > 0 &&
        transport.routes &&
        transport.routes.length > 0 &&
        marker.options.transport.routes[0]?.name !== transport.routes[0]?.name
      ) ||
      angle !== transport.geo_position.direction) {
      marker.setIcon(L.divIcon({
        html: this._iconUrl(transport),
        iconSize: [this._markersSize, this._markersSize],
        className: '',
      }));
    }

    const time = marker.options.transport.geo_position.server_time;
    marker.options.transport = transport;

    if (marker.getPopup()?.isOpen() && time !== transport.geo_position.server_time) {
      marker.getPopup()?.closePopup();
      marker.bindPopup(this._makePopupContent(marker.options.transport), {
        minWidth: 320,
        maxWidth: 320,
      }).openPopup();
    }
  }

  _iconUrl({ status, route_type_id }: ITransport): string {
    switch (status) {
      case TransportStatusEnum.ACTIVE:
        return `<img width="100%" src="${this._routeTypes[route_type_id].image_url}">`;
      case TransportStatusEnum.PARKING:
        return `<img width="100%" src="${this._routeTypes[route_type_id].parking_image_url}">`;
      case TransportStatusEnum.BROKEN:
        return `<img width="100%" src="${this._routeTypes[route_type_id].broken_image_url}">`;
      default:
        return '';
    }
  }

  processTransport(transport: ITransport): void {
    const coordinates = [...transport.geo_position.point.coordinates];
    if (!this._markersZoomed[transport.number]) {
      this._makeMarker(transport, coordinates.reverse() as LatLngTuple);
    } else {
      this._updateMarker(transport, coordinates.reverse() as LatLngTuple);
    }
  }

  changeMunicipality(municipalityId: number | null): void {
    this._socket.send(JSON.stringify({
      type: MessageTypeEnum.MUNICIPALITY_CHANGE_TYPE,
      payload: {
        municipalityId,
      },
    }));
  }

  _makePopupContent(transport: ITransport): string {
    const trackPermission = ACL.can('history.index');

    const popup = new Vue({
      render: h => h(TransportPopup, {
        props: {
          transport,
          trackPermission,
        },
        on: {
          'show-detectors': (transportNumber: number) => {
            this._layer._store.dispatch('transports/showDetectorLayer', transportNumber);
          },
          'show-route': (routeId: number) => {
            this._layer._store.dispatch('transports/showRoute', routeId)
              .then((route: IRoute) => {
                this._layer.drawRoute(route);
                this._layer.goToRoute(route, 12);
              });
          },
        },
      }),
      router,
    }).$mount();

    return (popup as any).$el;
  }

  destroy(): void {
    if (this._onMessageHanlderBinded) {
      this._socket.removeEventListener('message', this._onMessageHanlderBinded);
    }
    this._socket.send(JSON.stringify({
      type: MessageTypeEnum.TRANSPORT_OFF,
      payload: {},
    }));
    this._filterSubscription.unsubscribe();

    this._clusterLayer?.clearLayers();
    this._zoomedLayerGroup?.clearLayers();

    this._clusterLayer = null;
    this._zoomedLayerGroup = null;

    Object.keys(this._markersZoomed).forEach(key => this._markersZoomed[key].remove());
    Object.keys(this._markersClustered).forEach(key => this._markersClustered[key].remove());

    this._markersZoomed = {};
    this._markersClustered = {};

    if (this._zoomEndHandler) {
      this._map.off('zoomend', this._zoomEndHandler);
      this._map.off('dragend', this._zoomEndHandler);
    }
    this._layer._store.dispatch('transports/clearRoute');
  }

  _zoomEnd(): void {
    const currentZoom = this._map.getZoom();
    if (currentZoom >= this._clusterBreakPoint) {
      Object.keys(this._markersZoomed).forEach(key => {
        if (this._map.getBounds().contains(this._markersZoomed[key].getLatLng())) {
          this.processTransport(this._markersZoomed[key].options.transport);
          if (!this._markersZoomed[key].isEnded()) {
            this._markersZoomed[key].start();
          }
        } else {
          this._markersZoomed[key].isPaused();
        }

        this._changeMarkerSize(this._markersZoomed[key]);
      });
    } else {
      Object.keys(this._markersZoomed).forEach(key => {
        this._markersZoomed[key].pause();
      });
      Object.keys(this._markersClustered).forEach(key => {
        this._changeMarkerSize(this._markersClustered[key]);
      });
    }

    if (this._activeLayer === 'cluster' && currentZoom >= this._clusterBreakPoint) {
      const transportNumber = this._popupOpenedTransport;
      this._clusterLayer?.removeFrom(this._map);
      this._zoomedLayerGroup?.addTo(this._map);
      this._activeLayer = 'zoomed';
      this._filterActiveTransport();

      if (transportNumber) {
        this._generatePopup(this._markersZoomed[transportNumber]);
      }

      this._zoomEnd();
    } else if (this._activeLayer === 'zoomed' && currentZoom < this._clusterBreakPoint) {
      const transportNumber = this._popupOpenedTransport;
      this._zoomedLayerGroup?.removeFrom(this._map);
      this._clusterLayer?.addTo(this._map);
      this._activeLayer = 'cluster';
      this._filterActiveTransport();

      if (transportNumber) {
        this._generatePopup(this._markersClustered[transportNumber]);
      }

      this._zoomEnd();
    }
  }

  _changeMarkerSize(marker: L.Marker): void {
    const icon = marker.getIcon();
    const size = this._markersSize;
    icon.options.iconSize = [size, size];
    marker.setIcon(icon);
  }

  _generatePopup(marker: L.Marker): void {
    if (marker.getPopup()?.isOpen()) {
      marker.getPopup()?.closePopup();
    } else {
      marker.unbindPopup();
      marker.bindPopup(this._makePopupContent(marker.options.transport), {
        minWidth: 320,
        maxWidth: 320,
      }).openPopup();
      this._popupOpenedTransport = marker.options.transport.number;
    }
  }

  get _markersSize(): number {
    return Math.round((this._makerSize * this._map.getZoom()) / this._mapMaxZoom);
  }

  get isSocketActive(): boolean {
    return this._socket.readyState === WebSocket.OPEN;
  }
}
