<template>
  <div>
    <popup v-model="showPopup" :title="popupTitle" submitText="Закрыть" :show-close="false" @close="closePopup" @save="closePopup">
      <div class="row cameras">
        <player :class="{'col-4': cameras.length > 1, 'col-12': cameras.length === 1}" v-for="(camera, index) in cameras" :src="cameras.length > 2 ? camera.sub_stream_url : camera.stream_url" :index="index" :key="index"></player>
      </div>
    </popup>
    <popup v-model="showDetectorPopup" title="Фиксация транспортного средства" submitText="Закрыть" :show-close="false" @close="closeDetectorPopup" @save="closeDetectorPopup">
      <img :src="detectorImage ? detectorImage.photo_url : ''" width="100%">
    </popup>
    <chat></chat>
    <cross-show-popup></cross-show-popup>
    <trash-area-images-popup />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Popup from '@/components/shared/popup';
import Player from '@/components/player/player';
import Chat from '@/components/app/header/chat/chat';
import CrossShowPopup from '@/components/map/popups/cross-show-popup.vue';
import TrashAreaImagesPopup from '@/components/map/popups/trash-area-images-popup';

export default {
  name: 'popups',
  components: {
    Player,
    Popup,
    Chat,
    CrossShowPopup,
    TrashAreaImagesPopup,
  },
  computed: {
    ...mapGetters({
      showPopup: 'videoPopup',
      currentVideoObject: 'videoObj',
      showDetectorPopup: 'detectorPopup',
      detectorImage: 'detectorObj',
    }),
    cameras() {
      return this.currentVideoObject ? this.currentVideoObject.channels : [];
    },
    popupTitle() {
      return this.currentVideoObject ? this.currentVideoObject.name : '';
    },
  },
  methods: {
    closePopup() {
      this.$store.commit('openVideoPopup', null);
    },
    closeDetectorPopup() {
      this.$store.commit('showDetectorImage', null);
    },
  },
};
</script>
