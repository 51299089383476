<template>
  <div>
    <div class="control" v-can="'detector.car-search'">
      <h5 class="text-center">Поиск ТС по ГРЗ</h5>
      <div class="filter">
        <date-picker
          lang="ru"
          range
          class="datepicker-with-scroll"
          confirm
          v-model="search.date"
          type="datetime"
          format="DD.MM.YYYY HH:mm:ss"
          :minute-step="1"
          :disabled-date="disabledAfter"
          :first-day-of-week="1"
          :editable="false"
        ></date-picker>
      </div>
      <div class="filter">
        <input v-model="search.number" placeholder="Введите номер ТС">
      </div>
      <div class="text-center">
        <button class="btn btn-primary" @click="searchTransport" :disabled="!search.date.length || !search.number">Найти</button>
        <button class="btn btn-reset" @click="resetFilter()" :disabled="!activeClear">Очистить</button>
      </div>
    </div>
    <div class="control">
      <h5 class="text-center">Поиск комплекса ФВФ</h5>
      <div class="filter">
        <input v-model="filter" @input="applyFilter" placeholder="Введите адрес детектора">
      </div>
      <div class="detectors">
        <div class="detector" v-for="(detector, index) in detectors" :key="index" @click="showDetector(detector)">
          <div class="detector-address">
            {{ detector.dislocation.dislocation_title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import { LayersService } from '@/services/layers-service';

export default {
  name: 'detector-layer-control',
  components: {
    DatePicker,
  },
  data: () => ({
    detectors: [],
    filter: '',
    search: {
      date: [],
      number: '',
    },
    activeClear: false,
  }),
  methods: {
    disabledAfter(date) {
      const newDate = moment(date);
      const maxDate = moment(new Date());

      return !newDate.isSameOrBefore(maxDate);
    },
    applyFilter(event) {
      LayersService.instance
        ._detectorLayers
        .applyFilter({ address: event.target.value });
    },
    showDetector(detector) {
      LayersService.instance
        ._detectorLayers
        .showMarker(detector);
    },
    searchTransport() {
      LayersService.instance
        ._detectorLayers
        .searchTransport({
          startDate: moment(this.search.date[0]).format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment(this.search.date[1]).format('YYYY-MM-DD HH:mm:ss'),
          number: this.search.number,
        });

      this.activeClear = true;
    },
    resetFilter() {
      LayersService.instance._detectorLayers.filter = {
        ...LayersService.instance._detectorLayers.filter,
        startDate: null,
        endDate: null,
        number: '',
      };

      LayersService.instance
        ._detectorLayers
        ._render();

      this.activeClear = false;
    },
  },
  created() {
    const filter = LayersService.instance._detectorLayers.filter;

    if (filter.startDate && filter.endDate && filter.number) {
      this.search.date = [
        moment(filter.startDate).toDate(),
        moment(filter.endDate).toDate(),
      ];

      this.search.number = filter.number;

      this.searchTransport();
    }

    this.filter = filter.address;

    LayersService.instance
      ._detectorLayers
      .detectors
      .subscribe(detectors => { this.detectors = detectors; });

    this.$store.subscribeAction(action => {
      if (action.type === 'transports/findTransportOnDetectorLayer') {
        const number = action.payload;
        const dateFrom = moment().startOf('day').toDate();
        const dateTo = moment().endOf('day').toDate();

        if (!number) {
          return;
        }

        this.search.number = number;
        this.search.date = [dateFrom, dateTo];

        this.searchTransport();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
  .control {

    .filter {
      input {
        padding: 3px;
        font-size: 15px;
        width: 100%;
        margin: 16px 0;
        display: block;
        border: 1px solid var(--border-color);
        border-radius: 3px;
      }
    }

    .detector {
      cursor: pointer;
      margin-bottom: 16px;

      &-address {
        font-size: 16px;
      }
    }

    .btn {

      &:disabled {
        background-color: gray;
      }

      &-reset {
        margin-left: 15px;
        background-color: red;
        color: white;
      }
    }

    h5 {
      margin: 5px 0;
    }
  }

  .datepicker-with-scroll {
    position: static;
    margin: 10px 0;
  }
</style>
