/* eslint-disable camelcase */
import { SitCenter } from '@/services/api/sitCenter';
import { RootState, SitCenterRoute, SitCenterState, SitCenterTransport, ChartsPopupState, SitCenterDetails, SitCenterChartOptions, SitCenterTransportsDetails, SitCenterOutfit } from '@/types';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

const state: SitCenterState = {
  active: false,
  routes: [],
  routesDetails: [],
  transports: [],
  transportsDetails: [],
  emptyDetails: false,
  popup: {
    open: false,
    type: '',
    details: '',
    routeType: 0,
  },
  charts: [],
  outfits: [],
};

const getters: GetterTree<SitCenterState, RootState> = {
  active(state) {
    return state.active;
  },
  routes(state) {
    return state.routes;
  },
  routesDetails(state) {
    return state.routesDetails;
  },
  transports(state) {
    return state.transports;
  },
  transportsDetails(state) {
    return state.transportsDetails;
  },
  emptyDetails(state) {
    return state.emptyDetails;
  },
  popupOpen(state) {
    return state.popup.open;
  },
  popupDetails(state) {
    return state.popup.details;
  },
  popupType(state) {
    return state.popup.type;
  },
  popupRouteType(state) {
    return state.popup.routeType;
  },
  charts(state) {
    return state.charts;
  },
  outfits(state) {
    return state.outfits;
  },
};
const mutations: MutationTree<SitCenterState> = {
  setActive(state, payload: boolean) {
    state.active = payload;
  },
  setRoutes(state, payload: Array<SitCenterRoute>) {
    state.routes = [...payload];
  },
  setRoutesDetails(state, payload: Array<SitCenterDetails>) {
    state.routesDetails = [...payload];
  },
  setTransports(state, payload: Array<SitCenterTransport>) {
    state.transports = [...payload];
  },
  setTransportsDetails(state, payload: Array<SitCenterTransportsDetails>) {
    state.transportsDetails = [...payload];
  },
  setEmptyDetails(state, payload: boolean) {
    state.emptyDetails = payload;
  },
  toggleOpen: (state, payload: ChartsPopupState) => {
    state.popup = payload;
  },
  setCharts: (state, payload: Array<SitCenterChartOptions>) => {
    state.charts = payload;
  },
  setRouteType: (state, payload: number) => {
    state.popup.routeType = payload;
  },
  setOutfits: (state, payload: Array<SitCenterOutfit>) => {
    state.outfits = payload;
  },
};

const actions: ActionTree<SitCenterState, RootState> = {
  enable({ commit }) {
    commit('setActive', true);
  },
  disable({ commit }) {
    commit('setActive', false);
  },
  async loadRoutes({ state, commit }, { municipalityId, bg = true }: { municipalityId: number, bg: boolean }) {
    const response = await SitCenter.getRoutes(
      municipalityId,
      state.popup.type === 'routes' && state.popup.open ? state.popup.details : '',
      bg,
    ).catch(() => { });

    if (response?.data?.data) {
      commit('setRoutes', response.data.data);
    }

    if (response?.data?.details) {
      commit('setRoutesDetails', response.data.details);

      if (response.data.details.length === 0) {
        commit('setEmptyDetails', true);
      }
    }
  },
  clearRoutes({ commit }) {
    commit('setRoutes', []);
  },
  clearRoutesDetails({ commit }) {
    commit('setRoutesDetails', []);
    commit('setCharts', []);
    commit('setEmptyDetails', false);
  },
  async loadTransports({ commit }, { municipalityId, bg = true }: { municipalityId: number, bg: boolean }) {
    const response = await SitCenter.getTransports(
      municipalityId,
      state.popup.type === 'transports' && state.popup.open ? state.popup.details : '',
      bg,
    ).catch(() => { });

    if (response?.data?.data) {
      commit('setTransports', response.data.data);
    }

    if (response?.data?.details) {
      commit('setTransportsDetails', response.data.details);

      if (response.data.details.length === 0) {
        commit('setEmptyDetails', true);
      }
    }
  },
  clearTransports({ commit }) {
    commit('setTransports', []);
  },
  clearTransportsDetails({ commit }) {
    commit('setTransportsDetails', []);
    commit('setCharts', []);
    commit('setEmptyDetails', false);
  },
  openChartsPopup({ commit }, { type, details, routeType } : { type: string, details: string, routeType?: number }) {
    return commit('toggleOpen', {
      open: true,
      type,
      details,
      routeType,
    });
  },
  closeChartsPopup({ commit }) {
    return commit('toggleOpen', {
      open: false,
      type: '',
      details: '',
      routeType: 0,
    });
  },
  setRouteType({ commit }, routeType: number) {
    return commit('setRouteType', routeType);
  },
  setCharts({ commit }, charts: Array<SitCenterChartOptions>) {
    return commit('setCharts', charts);
  },
  async loadOutfits({ commit }, municipalityId: number) {
    const response = await SitCenter.getOutfits(municipalityId, true).catch(() => { });

    if (response?.data?.data) {
      commit('setOutfits', response.data.data);
    }
  },
  clearOutfits({ commit }) {
    commit('setOutfits', []);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
