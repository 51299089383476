import axios from '../axios';

export class Transporter {
  static getTransporters({ all, filter = {}, include = [], append = [], sort = '' }) {
    const params = [];

    if (all) {
      params.push('all');
    }

    if (include.length > 0) {
      params.push(`include=${include.join(',')}`);
    }

    if (filter.routes) {
      params.push(`filter[routes]=${filter.routes}`);
    }

    if (filter.date) {
      params.push(`filter[date]=${filter.date}`);
    }

    if (filter.routeTypes && Array.isArray(filter.routeTypes) && filter.routeTypes.length > 0) {
      params.push(`filter[route-types]=${filter.routeTypes.join(',')}`);
    }

    if (filter.typeGroup) {
      params.push(`filter[route-type-groups]=${filter.typeGroup}`);
    }

    if (filter.municipality) {
      params.push(`filter[municipality_id]=${filter.municipality}`);
    }

    if (filter.with_unassigned_transports) {
      params.push(`filter[with_unassigned_transports][date]=${filter.with_unassigned_transports.date}`);
      params.push(`filter[with_unassigned_transports][municipality_id]=${filter.with_unassigned_transports.municipality_id}`);
    }

    if (filter.with_unassigned_transports) {
      params.push(`filter[with_unassigned_transports][date]=${filter.with_unassigned_transports.date}`);
      params.push(`filter[with_unassigned_transports][municipality_id]=${filter.with_unassigned_transports.municipality_id}`);
    }

    if (append.length > 0) {
      params.push(`append=${append.join(',')}`);
    }

    if (sort.length > 0) {
      params.push(`sort=${sort}`);
    }

    const query = params.length > 0 ? `?${params.join('&')}` : '';

    return new Promise((resolve, reject) => {
      axios.get(`/transporter${query}`)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  }

  static getTransportersRoutes({ municipality }) {
    const params = [];

    if (municipality) {
      params.push(`municipality_id=${municipality}`);
    }

    const query = params.length > 0 ? `?${params.join('&')}` : '';

    return new Promise((resolve, reject) => {
      axios.get(`/transporter/routes${query}`)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  }

  static getTransporter(id) {
    return new Promise((resolve, reject) => {
      axios.get(`/transporter/${id}`)
        .then(({ data }) => resolve(data.transporter))
        .catch(reject);
    });
  }
}
