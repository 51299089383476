import axios from '@/services/axios';
import { AxiosResponse } from 'axios';
import { TrashAreaFilters } from '@/types';
import { TrashArea } from '@/services/api/trash-area';

export class TrashAreaCommon {
  static exportReport(municipalityId: number, filters: TrashAreaFilters | null): Promise<Blob> {
    const params = TrashArea.makeRequestFilterParams(municipalityId, filters);
    const query = params.length > 0 ? `?${params.join('&')}` : '';
    return axios.get(`/report-trash-area/export/form1${query}`, { responseType: 'blob' })
      .then((response: AxiosResponse<Blob>) => {
        return response.data;
      });
  }
}
