import L from 'leaflet';
import {} from 'leaflet.markercluster';

import { VideoWatch } from '@/services/api/video-watch';
import { BehaviorSubject } from 'rxjs';
import { BaseLayer } from './base-layer';

import Vue from 'vue';
import VideoWatchPopup from '../../components/map/popups/video-watch-popup.vue';
import router from '../../router';

export class VideoWatchLayer extends BaseLayer {
  _layers = null;

  _originVideo = [];
  video = new BehaviorSubject([]);
  markersGroup = null;
  markers = [];
  _clusterBreakPoint = 16;

  constructor({ store, map, type }) {
    super({ store, map, type });
    this.filter = {
      name: '',
      address: '',
      type: {},
      municipalityId: null,
      ...this.filter,
    };

    this.createGroup();

    this.video.subscribe(() => this._render());

    this._store.subscribe(mutation => {
      if (mutation.type === 'map/setGroupControlDisabled') {
        this.createGroup();
      }
    });
  }

  createGroup() {
    this.markersGroup?.clearLayers();
    this.markersGroup?.remove();
    this.markersGroup = null;

    this.markersGroup = new L.MarkerClusterGroup({
      disableClusteringAtZoom: !this._store.getters['map/isGroupControlDisabled'] ? this._clusterBreakPoint : 0,
      iconCreateFunction: function(cluster) {
        const markers = cluster.getAllChildMarkers();
        return L.divIcon({
          html: `
          <div class="text-center"
            style="
            height: 47px;
            width: 47px;
            padding: 5px;
            border: 2px solid red;
            border-radius: 50%;
            margin: auto;
            background: #ffffff;">
              <img src="/assets/icons/cam.png" style="height: 100%">
              <div style="
              width: 28px;
              height: 28px;
              line-height: 28px;
              text-align: center;
              font-size: 12px;
              position: absolute;
              top: 10px;
              left: 10px;
              color: #ffffff;
              border-radius: 50%;
              background: rgba(25, 41, 139, 0.7)">
                ${markers.length}
              </div>
          </div>`,
          className: '',
          iconSize: L.point(47, 47),
        });
      },
    });

    this.markersGroup.addLayers(this.markers);
    this._map.addLayer(this.markersGroup);
  }

  _deleteLayer() {
    this.markersGroup?.clearLayers();
    if (this._layers !== null) {
      this._layers.forEach(layer => layer.remove());
      this._layers = null;
    }
  }

  _filter(filter) {
    let filtered = this._originVideo;

    if (filter.name) {
      filtered = filtered.filter(elm => elm.name.toLowerCase().indexOf(filter.name.toLowerCase()) > -1);
    }
    if (filter.address) {
      filtered = filtered.filter(elm => elm.address.toLowerCase().indexOf(filter.address.toLowerCase()) > -1);
    }
    if (filter.type && filter.type.id) {
      filtered = filtered.filter(elm => elm.type.id === filter.type.id);
    }

    this.filter = {
      ...this.filter,
      ...filter,
    };

    this.video.next(filtered);
  }

  showMaker(entity) {
    router.push({
      name: 'video-list',
      params: { cameras: entity, id: entity.id },
    });

    this._store.dispatch('map/setZoom', 18);
    this._store.dispatch('map/setLatLng', [Number(entity.latitude), Number(entity.longitude)]);
  }

  async reRender() {
    if (this._originVideo.length === 0 || this.filter._municipalityId !== this._store.getters['municipalities/current'].id) {
      if (this._store.getters['municipalities/current'].position >= 10) {
        this.filter = {
          ...this.filter,
          municipalityId: this._store.getters['municipalities/current'].id,
        };
      } else {
        this.filter = {
          ...this.filter,
          municipalityId: null,
        };
      }
    }
    const response = await VideoWatch.list({ all: true, municipalityId: this.filter.municipalityId });
    this._originVideo = response.videocams;

    this._filter(this.filter);
  }

  createIcon(elm) {
    if (elm.channels && elm.channels.length > 1) {
      return `
        <div class="text-center"
          style="
          height: 47px;
          width: 47px;
          padding: 5px;
          border: 2px solid ${elm.type.color};
          border-radius: 50%;
          margin: auto;
          background: #ffffff;">
            <img src="/assets/icons/cam.png" style="height: 100%">
            <div style="
            width: 24px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            font-size: 12px;
            position: absolute;
            top: 12px;
            left: 12px;
            color: #ffffff;
            border-radius: 50%;
            background-color: red;">
              ${elm.channels.length}
            </div>
            <div style="
            width: 24px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            font-size: 19px;
            position: absolute;
            bottom: -10px;
            right: -12px;
            border-radius: 50%;
            background-color: white;
            border: 1px solid gray;
            ">
              <i class="${elm.type.icon}""></i>
            </div>
        </div>`;
    } else {
      return `<span class="marker-icon text-center" style="border-color: ${elm.type.color};">
        <img src="/assets/icons/cam.png" style="height: 100%">
        <div style="
          width: 24px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          font-size: 19px;
          position: absolute;
          bottom: -6px;
          right: -6px;
          border-radius: 50%;
          background-color: white;
          border: 1px solid gray;
          ">
            <i class="${elm.type.icon}""></i>
          </div>
      </span>`;
    }
  }

  _render() {
    this._deleteLayer();
    this._layers = [];
    this.markers = [];
    this.createGroup();

    this.video.getValue().forEach((elm) => {
      const marker = L.marker([elm.latitude, elm.longitude], {
        icon: L.divIcon({
          html: this.createIcon(elm),
          iconSize: L.point(40, 40),
          className: '',
        }),
        id: elm.id,
      });

      const popup = new Vue({
        render: h => h(VideoWatchPopup, {
          props: {
            cameras: elm,
            id: elm.id,
          },
        }),
        router,
      }).$mount();

      marker.bindPopup(popup.$el);

      this.markersGroup.addLayer(marker);
      this.markers.push(marker);
    });
    this._layers.push(this.markersGroup);
  }

  destroy() {
    this._deleteLayer();
    this.markers = [];
  }
}
