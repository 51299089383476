<template>
  <header class="header">
    <div class="logo-block">
      <router-link :to="{name: 'main-map'}">
        <img src="@/assets/logo.png" />
      </router-link>
    </div>
    <div class="burger">
      <img src="@/assets/menu.png" @click="$emit('toggle-menu')">
    </div>
    <mo-selector></mo-selector>
    <sc-button></sc-button>
    <side-menu :openMenu="openMenu" :checkPermission="checkPermission"></side-menu>
    <div class="right-block">
      <chat-icon>
        <img class="header-icon" :class="{ 'hidden': !checkPermission('messenger.user-contacts') }" src="@/assets/chat.png" alt="chat">
      </chat-icon>
      <template v-if="checkPermission('admin.browse')">
        <router-link
           v-if="user.userable"
          :to="{ name: 'admin' }"
          class="mx-1 control-panel button-link"
        >
          Личный кабинет
        </router-link>
        <a v-else class="mx-1 control-panel button-link" href="/admin/">
          Панель управления
        </a>
      </template>
      <div class="mx-1 header-email">{{ user.email }}</div>
      <div class="logout-wrapper" @click="logout">
        <img class="header-icon logout-icon" src="@/assets/logout.svg" alt="Выйти" />
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACL } from '@/modules/acl/acl-service';
import UserService from '@/services/user-service';

import SideMenu from '@/components/app/menu/side-menu';

import MoSelector from './header/mo-selector';
import ChatIcon from './header/chat/icon';
import ScButton from './header/sc-button';

export default {
  name: 'app-header',
  components: { SideMenu, MoSelector, ChatIcon, ScButton },
  props: {
    openMenu: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    user: UserService.instance.user,
  }),
  methods: {
    logout() {
      UserService.instance.logout();
    },
    gotoIncomingIssues() {
      (this.$route.path !== '/support/incoming-issues') && this.$router.push('/support/incoming-issues');
    },

    checkPermission(name) {
      if (!name) return true;
      return ACL.can(name);
    },
  },
  computed: {
    ...mapGetters({
      routeTypes: 'route-type/routeTypes',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/app/_header";
</style>
