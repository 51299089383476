export class PubSub {
  constructor() {
    this._callbacks = {};
    this._oneTimes = [];
  }

  subscribeOnce(event, callback) {
    this._oneTimes.push({
      event,
      callback,
      finished: false,
    });
  }

  subscribe(ev, callback) {
    (this._callbacks[ev] || (this._callbacks[ev] = [])).push(callback);
    return this;
  }

  unsubscribe(ev, callback) {
    if (
      this._callbacks &&
      Array.isArray(this._callbacks[ev]) &&
      this._callbacks[ev].indexOf(callback) > -1
    ) {
      this._callbacks[ev].splice(this._callbacks[ev].indexOf(callback), 1);
    }

    return this;
  }

  publish() {
    const args = Array.prototype.slice.call(arguments, 0);
    const ev = args.shift();

    let list, i, l;

    this.publishOneTimes();

    if (!this._callbacks) return this;
    if (!(list = this._callbacks[ev])) return this;

    for (i = 0, l = list.length; i < l; i++) {
      list[i].apply(this, args);
    }

    return this;
  }

  publishOneTimes(event) {
    this._oneTimes
      .filter(c => c.event === event)
      .forEach(c => {
        c.callback();
        c.finished = true;
      });

    this._oneTimes = this._oneTimes.filter(c => !c.finished);
  }
}
