import axios from '../axios';

export class Schedule {
  static getSchedules({ all, municipalityId }) {
    let url = '/schedule';
    const params = [];
    params.push('include=routeGroup.route.municipality,transporter');
    params.push('sort=route');

    if (all) {
      params.push('all');
    }

    if (municipalityId) {
      params.push(`filter[municipality_id]=${municipalityId}`);
    }

    url += '?' + params.join('&');
    return new Promise((resolve, reject) => {
      axios.get(url)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  }

  static getSchedule(id) {
    return new Promise((resolve, reject) => {
      axios.get(`/schedule/${id}`)
        .then(({ data }) => resolve(data.schedule))
        .catch(reject);
    });
  }
};
