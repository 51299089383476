import { Accident } from '@/services/api/accident';

export default {
  namespaced: true,
  state: {
    types: [],
    dictionary: {
      participant_category: [],
      tech_issues: [],
      road_surface: [],
      road_state: [],
      road_light: [],
      road_value: [],
      road_category: [],
      road_plan: [],
      weather: [],
      place_status: [],
      street_category: [],
      road_profile: [],
      pedestrian_location: [],
      violation_first: [],
      violation_second: [],
      uds_object_place: [],
      uds_object_near: [],
    },
    roads: [],
    statTotal: [],
    statMunicipalities: [],
    concentrations: [],
    showAccidentConcentrations: false,
  },
  getters: {
    types: state => state.types,
    dictionary: state => state.dictionary,
    roads: state => state.roads,
    statTotal: state => state.statTotal,
    statMunicipalities: state => state.statMunicipalities,
    concentrations: state => state.concentrations,
    showAccidentConcentrations: state => state.showAccidentConcentrations,
  },
  mutations: {
    types: (state, payload) => {
      state.types = payload;
    },
    roads: (state, payload) => {
      state.roads = payload;
    },
    dictionary: (state, payload) => {
      const newDictionary = {};

      payload.forEach(value => {
        if (newDictionary[value.type]) {
          newDictionary[value.type].push(value);
        } else {
          newDictionary[value.type] = [value];
        }
      });

      state.dictionary = newDictionary;
    },
    stat: (state, payload) => {
      state.statTotal = payload.total;
      state.statMunicipalities = payload.municipalities;
    },
    concentrations: (state, payload) => {
      state.concentrations = payload;
    },
    setShowAccidentConcentrations: (state, payload) => {
      state.showAccidentConcentrations = payload;
    },
  },
  actions: {
    getAccidentTypes(state) {
      return new Promise((resolve, reject) => {
        Accident.getTypes()
          .then(response => {
            state.commit('types', response.accident_types);
            resolve(response.accident_types);
          })
          .catch(e => reject(e));
      });
    },
    getTypesFromDictionary(state) {
      return new Promise((resolve, reject) => {
        Accident.getDictionary()
          .then(response => {
            state.commit('dictionary', response.accident_dictionaries);
            resolve(response.accident_dictionaries);
          })
          .catch(e => reject(e));
      });
    },
    getAccidentRoads(state) {
      return new Promise((resolve, reject) => {
        Accident.getRoads()
          .then(response => {
            state.commit('roads', response.accident_roads);
            resolve(response.accident_roads);
          })
          .catch(e => reject(e));
      });
    },
  },
};
