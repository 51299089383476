import { MapService } from '@/services/map-service';
import { BusStopLayer } from '@/services/layers/bus-stop-layer';
import { TransportLayer } from '@/services/layers/transport-layer';
import { HistoryTransportLayer } from '@/services/layers/history-transport-layer';
import { AccidentLayer } from '@/services/layers/accident-layer';
import { WeatherLayer } from '@/services/layers/weather-layer';
import { DetectorLayer } from '@/services/layers/detector-layer';
import { VideoWatchLayer } from '@/services/layers/video-watch-layer';
import { ParkingLayer } from '@/services/layers/parking';
import { TrafficJamLayer } from '@/services/layers/traffic-jam';
import { TrafficLightLayer } from '@/services/layers/traffic-light';
import { ParkingFineLayer } from '@/services/layers/parking-fine-layer';
import { RepairRoadLayer } from '@/services/layers/repair-road-layer';
import { RoadLayer } from '@/services/layers/road-layer';

import { MunicipalityLayer } from '@/services/layers/municipality-layer';
import { PubSub } from '@/services/pub-sub';
import { RouteLayer } from '@/services/layers/route-layer';
import { LocalityLayer } from './layers/locality-layer';
import { AnalysisDoubleLayer } from './layers/analysis-double-layer';
import { CustomLayer } from './layers/custom-layer';
import { AnalysisLayer } from './layers/analysis-layer';
import { AnalysisAverageLayer } from './layers/analysis-average-layer';
import { SitCenterLayer } from './layers/sit-center-layer';
import { HouseLayer } from './layers/house-layer';
import { TrashAreaLayer } from './layers/trash-area-layer';
import { Store } from 'vuex';
import { ILayer } from '@/types';

const key = Symbol('key');
const keyEnforcer = Symbol('keyEnforcer');

export class LayersService extends PubSub {
  _store!: Store<any>;
  _layers = [
    'municipality', 'custom', 'transport', 'busStop', 'accident', 'weather', 'detector', 'video', 'parking', 'trafficJam',
    'trafficLight', 'parkingFine', 'repairRoad', 'route', 'road', 'locality', 'analysis', 'analysisDouble', 'analysisAverage',
    'sitCenter', 'house', 'trashArea', 'historyTransport',
  ];

  currentState: Array<string> = [];

  _enabledLayers: Array<string> = [];

  _layersParams: Array<ILayer> = [];

  constructor(enforcer: typeof keyEnforcer) {
    super();
    if (enforcer !== keyEnforcer) {
      throw new Error('Instantiation failed: use MapService.instance instead of new.');
    }
  }

  static get instance() {
    if (!(this as any)[key]) {
      (this as any)[key] = new LayersService(keyEnforcer);
    }

    return (this as any)[key] as LayersService;
  }

  static set instance(v) {
    throw new Error("Can't change constant property!");
  }

  setStore(store: Store<any>) {
    this._store = store;
  }

  _municipalityLayers!: MunicipalityLayer;
  _transportLayers!: TransportLayer;
  _historyTransportLayers!: HistoryTransportLayer;
  _busStopLayers!: BusStopLayer;
  _accidentLayers!: AccidentLayer;
  _weatherLayers!: WeatherLayer;
  _detectorLayers!: DetectorLayer;
  _videoLayers!: VideoWatchLayer;
  _parkingLayers!: ParkingLayer;
  _trafficJamLayers!: TrafficJamLayer;
  _trafficLightLayers!: TrafficLightLayer;
  _parkingFineLayers!: ParkingFineLayer;
  _repairRoadLayers!: RepairRoadLayer;
  _routeLayers!: RouteLayer;
  _roadLayers!: RoadLayer;
  _localityLayers!: LocalityLayer;
  _analysisLayers!: AnalysisLayer;
  _analysisDoubleLayers!: AnalysisDoubleLayer;
  _analysisAverageLayers!: AnalysisAverageLayer;
  _sitCenterLayers!: SitCenterLayer;
  _houseLayers!: HouseLayer;
  _trashAreaLayers!: TrashAreaLayer;
  _customLayers!: CustomLayer;

  init() {
    this._initMunicipality();
    this._initTransport();
    this._initHistoryTransport();
    this._initBusStop();
    this._initAccident();
    this._initWeather();
    this._initDetector();
    this._initVideo();
    this._initParking();
    this._initTrafficJam();
    this._initTrafficLight();
    this._initParkingFine();
    this._initRepairRoad();
    this._initRoute();
    this._initRoad();
    this._initLocality();
    this._initAnalysis();
    this._initAnalysisDouble();
    this._initAnalysisAverage();
    this._initSitCenter();
    this._initSitCenter();
    this._initHouse();
    this._initTrashArea();
    this._initCustom();

    this.publish('init');
  }

  _initMunicipality() {
    this._municipalityLayers = new MunicipalityLayer({ store: this._store, map: MapService.instance.map });
  }

  _initTransport() {
    this._transportLayers = new TransportLayer({ store: this._store, map: MapService.instance.map, type: 'transport' });
  }

  _initHistoryTransport() {
    this._historyTransportLayers = new HistoryTransportLayer({ store: this._store, map: MapService.instance.map, type: 'historyTransport' });
  }

  _initBusStop() {
    this._busStopLayers = new BusStopLayer({ store: this._store, map: MapService.instance.map, type: 'bus-stop' });
  }

  _initAccident() {
    this._accidentLayers = new AccidentLayer({ store: this._store, map: MapService.instance.map, type: 'accident' });
  }

  _initWeather() {
    this._weatherLayers = new WeatherLayer({ store: this._store, map: MapService.instance.map });
  }

  _initDetector() {
    this._detectorLayers = new DetectorLayer({ store: this._store, map: MapService.instance.map, type: 'detector' });
  }

  _initVideo() {
    this._videoLayers = new VideoWatchLayer({ store: this._store, map: MapService.instance.map, type: 'video' });
  }

  _initParking() {
    this._parkingLayers = new ParkingLayer({ store: this._store, map: MapService.instance.map, type: 'parking' });
  }

  _initTrafficJam() {
    this._trafficJamLayers = new TrafficJamLayer({ store: this._store, map: MapService.instance.map });
  }

  _initTrafficLight() {
    this._trafficLightLayers = new TrafficLightLayer({ store: this._store, map: MapService.instance.map, type: 'traffic-light' });
  }

  _initParkingFine() {
    this._parkingFineLayers = new ParkingFineLayer({ store: this._store, map: MapService.instance.map, type: 'parking-fine' });
  }

  _initRepairRoad() {
    this._repairRoadLayers = new RepairRoadLayer({ store: this._store, map: MapService.instance.map, type: 'repair road' });
  }

  _initRoute() {
    this._routeLayers = new RouteLayer({ store: this._store, map: MapService.instance.map, type: 'route' });
  }

  _initRoad() {
    if (!this._store) {
      return;
    }

    this._roadLayers = new RoadLayer({ store: this._store, map: MapService.instance.map, type: 'road' });
  }

  _initLocality() {
    if (!this._store) {
      return;
    }

    this._localityLayers = new LocalityLayer({ store: this._store, map: MapService.instance.map, type: 'locality' });
  }
  _initAnalysis() {
    if (!this._store) {
      return;
    }

    this._analysisLayers = new AnalysisLayer({ store: this._store, map: MapService.instance.map, type: 'analysis' });
  }
  _initAnalysisDouble() {
    if (!this._store) {
      return;
    }

    this._analysisDoubleLayers = new AnalysisDoubleLayer({ store: this._store, map: MapService.instance.map, type: 'analysisDouble' });
  }
  _initAnalysisAverage() {
    if (!this._store) {
      return;
    }

    this._analysisAverageLayers = new AnalysisAverageLayer({ store: this._store, map: MapService.instance.map, type: 'analysisAverage' });
  }

  _initSitCenter() {
    if (!this._store) {
      return;
    }

    this._sitCenterLayers = new SitCenterLayer({ store: this._store, map: MapService.instance.map, type: 'sitCenter' });
  }

  _initHouse() {
    if (!this._store) {
      return;
    }

    this._houseLayers = new HouseLayer({ store: this._store, map: MapService.instance.map, type: 'house' });
  }

  _initTrashArea() {
    if (!this._store) {
      return;
    }

    this._trashAreaLayers = new TrashAreaLayer({ store: this._store, map: MapService.instance.map, type: 'trashArea' });
  }

  _initCustom() {
    if (!this._store) {
      return;
    }

    this._customLayers = new CustomLayer({ store: this._store, map: MapService.instance.map, type: 'custom' });
  }

  _render({
    type,
    params,
  }: ILayer, isBackgroundLoading = false) {
    if (this._layers.indexOf(type) > -1) {
      if ((this as any)[`_${type}Layers`] && (this as any)[`_${type}Layers`].reRender !== undefined) {
        this._enableLayer(type);
        (this as any)[`_${type}Layers`].reRender(params, isBackgroundLoading);
      } else {
        throw new Error(`Layer '${type}' unsupported`);
      }
    } else {
      throw new Error(`Undefined layer '${type}'`);
    }
  }

  _destroyAll() {
    this._layers
      .filter(layer => (this as any)[`_${layer}Layers`] !== null)
      .forEach(layer => (this as any)[`_${layer}Layers`].destroy());
  }

  _enableLayer(type: string) {
    this._enabledLayers.push(type);
  }

  _disableAllLayers() {
    this._enabledLayers = [];
  }

  render(layersParams: Array<ILayer>) {
    this._layersParams = layersParams;
    const newState = layersParams.map(layer => layer.type);

    if (newState.length !== this.currentState.length) {
      this._layersParams
        .filter(layerParams => newState.includes(layerParams.type))
        .forEach(layerParams => this._render(layerParams, false));

      this.currentState.filter(x => !newState.includes(x))
        .forEach(layer => (this as any)[`_${layer}Layers`].destroy());

      this.currentState = newState;
    } else {
      this._layersParams
        .filter(layerParams => !!layerParams.params || !!layerParams.type)
        .forEach(layerParams => this._render(layerParams, true));
    }

    this._store.dispatch('map/setLayerParams', layersParams);
    this.publish('change-layers', this.currentState);
  }

  renderSingle(layer: ILayer) {
    const oldLayerIndex = this._layersParams.findIndex(p => p.type === layer.type);

    if (oldLayerIndex !== -1) {
      this._layersParams[oldLayerIndex] = layer;
    } else {
      this._layersParams.push(layer);
    }

    this._render(layer, oldLayerIndex !== -1);
    this.currentState = this._layersParams.map(l => l.type);

    this._store.dispatch('map/setLayerParams', this._layersParams);
    this.publish('change-layers', this.currentState);
  }

  destroySingle(layer: ILayer) {
    this._layersParams = this._layersParams.filter(p => p.type !== layer.type);

    (this as any)[`_${layer.type}Layers`].destroy();
    this.currentState = this._layersParams.map(l => l.type);

    this.publish('change-layers', this.currentState);
  }

  destroy() {
    this._layersParams = [];
    this._destroyAll();
  }
}
