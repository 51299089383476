






























import { computed, defineComponent } from '@vue/composition-api';
import moment from 'moment';

export default defineComponent({
  name: 'TransportRoadPopup',
  props: {
    transport: {
      type: Object,
      required: true,
    },
    trackPermission: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const lastReceived = computed(() => {
      return moment.utc(props.transport.geo_position.server_time).local().format('DD.MM.YYYY HH:mm:ss');
    });
    const lastSent = computed(() => {
      return moment.utc(props.transport.geo_position.tracker_time).local().format('DD.MM.YYYY HH:mm:ss');
    });
    const vehicle = computed(() => {
      const arr = [];

      if (props.transport.mark && props.transport.model && props.transport.mark.name !== props.transport.model.name) {
        arr.push(props.transport.mark.name, props.transport.model.name);
      } else if (props.transport.model && props.transport.mark) {
        arr.push(props.transport.model.name);
      } else if (props.transport.model || props.transport.mark) {
        arr.push(props.transport.model ? props.transport.model : props.transport.mark);
      }

      return arr.join(' ');
    });
    const query = computed(() => {
      const query: {
        transporterId?: number,
        date: string,
        transportId?: number,
      } = {
        date: moment().format('YYYY-MM-DD HH:mm:ss'),
        transporterId: props.transport.transporter_id,
        transportId: props.transport.id,
      };

      return query;
    });
    return {
      vehicle,
      lastReceived,
      lastSent,
      query,
      showRoute(id: number) {
        emit('show-route', id);
      },
    };
  },
});
