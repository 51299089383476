<template>
  <div class="detectors">
    <div class="filter transporters-list">
      <model-list-select
        :list="transportersWithDefault"
        :value="selectedTransporter"
        @input="selectTransporter"
        placeholder="Организации"
        option-value="name"
        option-text="name"
      ></model-list-select>
    </div>
    <div class="filter">
      <input type="checkbox" v-model="isActiveFilterEnabled" id="checkActiveTransport">
      <label for="checkActiveTransport">Активные ТС</label>
    </div>
    <div class="filter">
      <input type="checkbox" v-model="isParkingFilterEnabled" id="checkParkingTransport">
      <label for="checkParkingTransport">ТС на паркинге</label>
    </div>
    <div class="filter">
      <input type="checkbox" v-model="isBrokenFilterEnabled" id="checkNotActiveTransport">
      <label for="checkNotActiveTransport">Неактивные ТС</label>
    </div>
    <div class="filter">
      <input v-model.trim="filter.number" placeholder="Введите номер ТС" @change="applyFilter">
    </div>
    <div class="items">
      <table class="transport-table">
        <thead>
          <th class="transport-table-index">№</th>
          <th class="transport-table-type">Тип</th>
          <th class="transport-table-number">Номер ТС</th>
          <th class="transport-table-transporter">Предприятие</th>
        </thead>
        <tbody ref="itemsBody">
          <tr
            v-for="({ number, route_type_id, type, route_type, transporter, image }, index) in transportsListing"
            :key="number"
            @click="showTransport(number)"
          >
            <td>{{ index + 1 }}</td>
            <td class="transport-table-type-value">
              <div style="height: 37px">
                <img
                  v-if="route_type_id || type"
                  :src="image"
                  :alt="route_type.name"
                  class="transport-table-vehicle-type"
                >
              </div>
            </td>
            <td class="transport-table-number-value">{{ number }}</td>
            <td class="transport-table-transporter-value">{{ transporter ? transporter.name : '' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { LayersService } from '@/services/layers-service';
import { mapGetters, mapActions } from 'vuex';
import { ModelListSelect } from 'vue-search-select';
import { TransportStatusEnum } from '@/types';

export default {
  name: 'transport-layer-control',
  components: {
    ModelListSelect,
  },
  props: {
    scroll: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    filter: {
      number: '',
      transporter: '',
      status: [],
    },
    transports: [],
    transporters: [],
    filterSubscription: null,
    updateTransporterListSubscription: null,
    filterRouteTypes: [], // список выбранных на данный момент типов маршрутов
    routeTypes: [],
    selectedTransporter: {},
    maxShow: 100,
  }),
  watch: {
    filter: {
      deep: true,
      handler(value) {
        this.setFilter(value);
      },
    },
    scroll() {
      if (this.$refs.itemsBody && this.$refs.itemsBody.firstChild) {
        const start = 400;
        const item = this.$refs.itemsBody.firstChild?.clientHeight;

        if (this.scroll <= start) {
          this.maxShow = 100;
        } else {
          this.maxShow = Math.max(this.maxShow, this.roundUp((this.scroll - start + 10 * item) / item));
        }
      } else {
        this.maxShow = 100;
      }
    },
  },
  methods: {
    ...mapActions({
      startLoading: 'startLoading',
      stopLoading: 'stopLoading',
      setFilter: 'layers/transports/setFilter',
    }),
    roundUp(value) {
      return (~~((value + 99) / 100) * 100);
    },
    // eslint-disable-next-line camelcase
    applyFilter() {
      this.maxShow = 100;
      const currentFilter = LayersService.instance
        ._transportLayers
        ._ws
        .filter
        .getValue();

      currentFilter.transporter = this.filter.transporter.id;
      currentFilter.number = this.filter.number;

      currentFilter.status = [...this.filter.status];

      LayersService.instance
        ._transportLayers
        ._ws
        .filter
        .next({ ...currentFilter });
    },
    // eslint-disable-next-line camelcase
    getImage({ status, type, route_type_id }) {
      let imageType = 'image_url';

      switch (status) {
        case TransportStatusEnum.ACTIVE:
          imageType = 'image_url';
          break;
        case TransportStatusEnum.PARKING:
          imageType = 'parking_image_url';
          break;
        case TransportStatusEnum.BROKEN:
          imageType = 'broken_image_url';
          break;
      }

      if (type) {
        return type[imageType];
      }

      return this.routeTypes[route_type_id][imageType];
    },
    showTransport(number) {
      LayersService.instance
        ._transportLayers
        .goToMarker({ number });
    },
    selectTransporter(transporter) {
      this.selectedTransporter = transporter;
      this.filter.transporter = transporter;

      this.applyFilter();
    },
    checkActiveTransport(value) {
      if (!value) {
        this.filter.status = this.filter.status.filter(status => status !== TransportStatusEnum.ACTIVE);
      } else {
        this.filter.status = [
          ...this.filter.status.filter(status => status !== TransportStatusEnum.BROKEN),
          TransportStatusEnum.ACTIVE,
        ];
      }

      this.applyFilter();
    },
    checkNotActiveTransport(value) {
      if (!value) {
        this.filter.status = this.filter.status.filter(status => status !== TransportStatusEnum.BROKEN);
      } else {
        this.filter.status = [
          ...this.filter.status.filter(status => status !== TransportStatusEnum.ACTIVE),
          TransportStatusEnum.BROKEN,
        ];
      }

      this.applyFilter();
    },
    checkParkingTransport(value) {
      if (!value) {
        this.filter.status = this.filter.status.filter(status => status !== TransportStatusEnum.PARKING);
      } else {
        this.filter.status = [
          ...this.filter.status,
          TransportStatusEnum.PARKING,
        ];
      }

      this.applyFilter();
    },
    updateTransporterList() {
      const ws = LayersService.instance._transportLayers._ws;

      this.transporters = Object.values(ws._markersZoomed).reduce((acc, current) => {
        const transport = current.options.transport;
        if (!acc.find(t => t.id === transport.transporter.id) && ws._packageFilter(transport, true)) {
          acc.push(transport.transporter);
        }

        return acc;
      }, []);
    },
    updateTransportList() {
      const ws = LayersService.instance._transportLayers._ws;

      this.transports = Object.values(ws.activeTransport.getValue()).map(t => ({
        number: t.number,
        route_type_id: t.route_type_id,
        type: t.type,
        route_type: t.route_type,
        transporter: t.transporter,
        image: this.getImage(t),
      }));
    },
  },
  computed: {
    ...mapGetters({
      routeTypesGetter: 'route-type/routeTypes',
      currentMunicipality: 'municipalities/current',
      storedFilter: 'layers/transports/filter',
      routeTypeGroups: 'route-type/groups',
    }),
    transportsListing() {
      return this.transports.slice(0, this.maxShow);
    },
    transportersWithDefault() {
      return [
        {
          id: '',
          name: 'Организации',
        },
        ...this.transporters,
      ];
    },
    isActiveFilterEnabled: {
      get: function() {
        return this.filter.status.indexOf(TransportStatusEnum.ACTIVE) !== -1;
      },
      set: function(value) {
        this.checkActiveTransport(value);
      },
    },
    isParkingFilterEnabled: {
      get: function() {
        return this.filter.status.indexOf(TransportStatusEnum.PARKING) !== -1;
      },
      set: function(value) {
        this.checkParkingTransport(value);
      },
    },
    isBrokenFilterEnabled: {
      get: function() {
        return this.filter.status.indexOf(TransportStatusEnum.BROKEN) !== -1;
      },
      set: function(value) {
        this.checkNotActiveTransport(value);
      },
    },
  },
  created() {
    this.routeTypes = this.routeTypesGetter.reduce((a, c) => {
      a[c.id] = c;
      return a;
    }, {});

    this.filterSubscription = LayersService.instance
      ._transportLayers
      ._ws
      .filter
      .subscribe(({ routeTypes }) => {
        if (this.filterRouteTypes !== routeTypes) {
          this.filterRouteTypes = routeTypes;
        }
      });

    this.updateTransporterListSubscription = this.$store.subscribe((mutation) => {
      if (mutation.type === 'layers/transports/updateTransporterFilter') {
        this.$nextTick()
          .then(() => {
            this.updateTransporterList();
            this.updateTransportList();
          });
      }
    });
    this.updateTransporterList();
    this.updateTransportList();

    this.filter = this.storedFilter;
    this.selectedTransporter = this.filter.transporter;
  },
  beforeDestroy() {
    this.filterSubscription.unsubscribe();
    this.updateTransporterListSubscription();
  },
};
</script>

<style lang="scss" scoped>
  @import '~vue-search-select/dist/VueSearchSelect.css';

  .detectors {
    margin-top: 15px;
    min-height: 450px;

    .filter {
      input {
        padding: 3px;
        font-size: 15px;
        width: 100%;
        margin: 16px 0;
        display: block;
        border: 1px solid var(--border-color);
        border-radius: 3px;
      }

      select {
        margin-top: 16px;
      }

      &.transporters-list {
        margin-bottom: 16px;
      }
    }

    input[type='checkbox'] {
      display: none;

      &:checked {
        +label {
          &:before {
            background: #00AD89;
          }
        }
      }
    }

    label {
      cursor: pointer;
      margin-bottom: 0;
      position: relative;
      padding-left: 18px;
      font-size: 16px;
      user-select: none;

      &:before {
        position: absolute;
        top: 5px;
        left: 0;
        content: "";
        width: 15px;
        height: 15px;
        outline: 1px solid #FFFFFF;
        border: 1px solid var(--main-color);
      }
    }

    .items {

      .detector {
        cursor: pointer;
        margin-bottom: 16px;

        &-address {
          font-size: 16px;
        }
      }
    }

    .transport {
      &-icon {
        height: 20px;
      }
      &-table {
        width: 100%;
        border: none;
        text-align: left;

        tr {
          cursor: pointer;

          &:hover {
            background-color: rgb(247, 244, 244);
          }
        }
        &-index {
          width: 5%;
        }
        &-type {
          width: 5%;
        }
        &-number {
          width: 30%;

          &-value {
            max-width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        &-transporter {
          width: 45%;

          &-value {
            max-width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        &-status {
          width: 15%;
          text-align: right;

          &-value {
            text-align: right;
          }
        }
        &-vehicle-type {
          width: 30px;

          &-text {
            position: relative;
            top: -25px;
            left: 5px;
            color: #ffffff;
            background-color: rgba(0, 11, 109, 0.72);
            border-radius: 50%;
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
          }
        }
      }
    }

    .route-filter-button .btn {
      height: 50px;
      margin-bottom: 15px;
      background-color: var(--main-color);
      color: #FFFFFF;
    }
  }
</style>
