import axios from '../axios';

export class roadOwner {
  static getRoadOwners({ all = false } = {}) {
    return new Promise((resolve, reject) => {
      axios.get(`/road-owner?${all ? 'all' : ''}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(reject);
    });
  }
}
