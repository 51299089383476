/* eslint-disable camelcase */
import { ICustomLayer, IRouteAnalysis, LayerState, RootState } from '@/types';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { Layer } from '@/services/api/layer';

const state: LayerState = {
  layers: [],
};
const getters: GetterTree<LayerState, RootState> = {
  layers: state => state.layers,
};
const mutations: MutationTree<LayerState> = {
  setLayers: (state, payload: Array<ICustomLayer>) => {
    state.layers = [...payload];
  },
};
const actions: ActionTree<LayerState, RootState> = {
  setLayers({ commit }, payload: Array<ICustomLayer>) {
    return commit('setLayers', payload);
  },
  getLayers({ dispatch }) {
    Layer.getLayers()
      .then(res => {
        dispatch('setLayers', res);
      });
  },
  openLayers() { },
  disableLayers() { },
  openLocality() { },
  updateLayers() { },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
