import { Schedule } from '@/services/api/schedule';

export default {
  state: {
    schedules: [],
  },
  getters: {
    schedules: state => state.schedules,
  },
  mutations: {
    schedules: (state, payload) => { state.schedules = payload; },
  },
  actions: {
    loadSchedules(state, { all, municipalityId }) {
      return new Promise((resolve, reject) => {
        Schedule.getSchedules({ all, municipalityId })
          .then(response => {
            state.commit('schedules', response.schedules);
            resolve(response);
          })
          .catch(reject);
      });
    },
  },
};
