

























































import { computed, defineComponent, Ref, WritableComputedRef } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { LayersService } from '../../../services/layers-service';
import { MODES } from '../../../services/layers/analysis-layer';
import { IRouteAnalysis } from '../../../types';
import { BasicSelect } from 'vue-search-select';
import { ExportService } from '../../../services/export-service';

const layerHelper = createNamespacedHelpers('layer');
const analysisHelper = createNamespacedHelpers('layers/analysis');

export default defineComponent({
  name: 'analysis-layer-control',
  components: {
    BasicSelect,
  },
  setup(props, { root }) {
    const {
      openLocality,
    } = layerHelper.useActions([
      'openLocality',
    ]);
    const { setCrossShowPopup, saveAnalysis, setCrossShow,
      getList, removeAnalysis, exportAnalysis, exportAnalysis2, setShowReport, clearReport } = analysisHelper.useActions([
      'setCrossShowPopup', 'saveAnalysis', 'getList', 'setCrossShow',
      'removeAnalysis', 'exportAnalysis', 'exportAnalysis2', 'setShowReport', 'clearReport',
    ]);
    const { crossShow, analysisList, showReport }: {
      crossShow: Ref<{
        color: string,
        id: number,
        text: string,
      } | null>,
      analysisList: Ref<Array<IRouteAnalysis>>,
      showReport: Ref<boolean>,
    } = analysisHelper.useGetters(['crossShow', 'analysisList', 'showReport']) as any;

    const unsaved: WritableComputedRef<boolean> = computed({
      get: () => {
        return LayersService.instance._analysisLayers ? LayersService.instance._analysisLayers.unsaved : false;
      },
      set: (v: boolean) => {
        LayersService.instance._analysisLayers.unsaved = v;
      },
    });
    const mode: WritableComputedRef<MODES> = computed({
      get: () => {
        return LayersService.instance._analysisLayers.mode;
      },
      set: (v: MODES) => {
        LayersService.instance._analysisLayers.mode = v;
      },
    });
    const color: WritableComputedRef<string> = computed({
      get: () => {
        return LayersService.instance._analysisLayers.color;
      },
      set: (v: string) => {
        LayersService.instance._analysisLayers.color = v;
      },
    });
    const type: WritableComputedRef<string> = computed({
      get: () => {
        return LayersService.instance._analysisLayers.type;
      },
      set: (v: '0' | '1') => {
        LayersService.instance._analysisLayers.type = v;
      },
    });
    const isEmptyMode = computed(() => mode.value === MODES.EMPTY);
    const isExistingMode = computed(() => mode.value === MODES.LOADED);

    const clear = (toggleUnsave: boolean = true) => {
      LayersService.instance._analysisLayers.clear(toggleUnsave);
    };

    const resetLocalities = () => {
      LayersService.instance._localityLayers.filter = {
        ...LayersService.instance._localityLayers.filter,
        ids: [],
      };
    };

    const getReportParams = () => {
      return LayersService.instance._analysisLayers.getReportParams();
    };

    const optionsAnalysis: Ref<Array<{
      value: number,
      text: string,
    }>> = computed(() => {
      return analysisList.value.map(analysis => ({
        value: analysis.id,
        text: analysis.name,
      }));
    });
    const currentAnalysis: WritableComputedRef<{
      value: number,
      text: string,
      }> = computed({
        get: () => {
          return LayersService.instance._analysisLayers.currentAnalysis;
        },
        set: (v: any) => {
          LayersService.instance._analysisLayers.currentAnalysis = v;
        },
      });

    const prepareReport = (isBackgroundLoading: boolean) => {
      LayersService.instance._analysisLayers.prepareReport(isBackgroundLoading);
    };

    const newAnalysis = () => {
      if (unsaved.value && !window.confirm('У вас имеются несохранённые изменения, вы уверены, что хотите продолжить?')) {
        return;
      }
      mode.value = MODES.NEW;

      color.value = '#000';
      type.value = '0';

      setCrossShow([]);
      clear(false);
      resetLocalities();
      LayersService.instance._routeLayers.toggleRouteNumber(true, false);
      LayersService.instance._routeLayers.resetColorBuffer(false);

      currentAnalysis.value = {
        value: 0,
        text: '',
      };

      openLocality();
      prepareReport(true);
      unsaved.value = false;
    };

    getList();
    newAnalysis();

    return {
      mode,
      isEmptyMode,
      isExistingMode,
      crossShow,
      color,
      type,
      optionsAnalysis,
      currentAnalysis,
      showReport,
      newAnalysis,
      crossShowPopup() {
        setCrossShowPopup(true);
      },
      async save() {
        if (mode.value === MODES.NEW) {
          const name: string | null = window.prompt('Введите название анализа');
          if (name && name.length > 0) {
            const analysis: IRouteAnalysis = await saveAnalysis({
              name,
            });

            currentAnalysis.value = {
              value: analysis.id,
              text: analysis.name,
            };
            unsaved.value = false;
            mode.value = MODES.LOADED;
          }
        } else if (mode.value === MODES.LOADED) {
          await saveAnalysis({
            name: currentAnalysis.value.text,
            id: currentAnalysis.value.value,
          });

          unsaved.value = false;
        }
      },
      async saveAs(rename: boolean = false) {
        let name: string | null = window.prompt('Введите название анализа');

        if (!name) {
          return;
        }

        await saveAnalysis({
          name,
        });

        currentAnalysis.value = {
          text: name,
          value: currentAnalysis.value.value,
        };
        unsaved.value = false;
      },
      async removeAnalysis() {
        if (!window.confirm('Вы уверены, что хотите удалить анализ?')) {
          return;
        }

        await removeAnalysis({ id: currentAnalysis.value.value });

        mode.value = MODES.EMPTY;

        color.value = '#000';
        type.value = '0';

        setShowReport(false);
        clearReport();
        setCrossShow([]);
        clear(false);
        LayersService.instance._routeLayers.resetColorBuffer(false);
        LayersService.instance._routeLayers.toggleRouteNumber(false);
        currentAnalysis.value = {
          value: 0,
          text: '',
        };

        getList();
        unsaved.value = false;
      },
      selectAnalysis(data: {
        value: number,
        text: string,
      }) {
        if (unsaved.value && !window.confirm('У вас имеются несохранённые изменения, вы уверены, что хотите продолжить?')) {
          return;
        }
        currentAnalysis.value = data;

        color.value = '#000';
        type.value = '0';

        root.$store.dispatch('startLoading');

        setCrossShow([]);
        clear(false);
        resetLocalities();
        LayersService.instance._routeLayers.resetColorBuffer(false);
        LayersService.instance._routeLayers.toggleRouteNumber(true, false);

        if (data.value !== 0) {
          mode.value = MODES.LOADED;

          const analysis = analysisList.value.find(a => a.id === data.value);
          if (analysis) {
            window.setTimeout(() => {
              LayersService.instance._analysisLayers.load(analysis);
              root.$store.dispatch('stopLoading');
            }, 500);
          } else {
            root.$store.dispatch('stopLoading');
          }

          prepareReport(true);
        } else {
          mode.value = MODES.EMPTY;
          root.$store.dispatch('stopLoading');
        }
        unsaved.value = false;
      },
      async exportReport() {
        const { municipalityIds, localityIds } = getReportParams();

        try {
          const { file } = await exportAnalysis({
            municipality_id: municipalityIds,
            locality_id: localityIds,
          });

          ExportService.downloadFile(file, ExportService.VND_EXCEL_FILE_TYPE, 'Анализ маршрутной сети (форма 1).xlsx');
        } catch (err: any) {}
      },
      async exportReport2() {
        const { municipalityIds, localityIds } = getReportParams();

        try {
          const { file } = await exportAnalysis2({
            municipality_id: municipalityIds,
            locality_id: localityIds,
          });

          ExportService.downloadFile(file, ExportService.VND_EXCEL_FILE_TYPE, 'Анализ маршрутной сети (форма 2).xlsx');
        } catch (err: any) {}
      },
      loadReport() {
        setShowReport(true);
        prepareReport(false);
      },
      hideReport() {
        setShowReport(false);
      },
    };
  },
});
