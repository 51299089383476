

























import { defineComponent } from '@vue/composition-api';
import ChatMessage from './message.vue';
import moment from 'moment';

export default defineComponent({
  props: {
    text: {
      type: String,
      default: '',
    },
    files: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['confirm', 'cancel'],
  components: { ChatMessage },
  setup(props, { emit }) {
    const date = moment.utc().local().format('DD.MM.YYYY HH:mm:ss');
    return {
      date,
      cancel() {
        emit('cancel');
      },
      confirm() {
        emit('confirm');
      },
    };
  },
});
