import axios from '@/services/axios';
import { IOutfit, IOutfitFormParams } from '@/types';
import { AxiosResponse } from 'axios';

export class Outfit {
  static saveOutfit(outfit: IOutfit): Promise<AxiosResponse<{ outfit: IOutfit }>> {
    const points: {
      [key: number]: {
          // eslint-disable-next-line camelcase
          status_done: boolean,
      };
    } = {};

    Object.keys(outfit.points).forEach(pointId => {
      const index = Number(pointId);

      points[index] = {
        status_done: outfit.points[index].status_done,
      };
    });

    return axios.put(`/outfit/${outfit.id}`, {
      trip_id: outfit.trip_id,
      start_time: outfit.start_time,
      end_time: outfit.end_time,
      bad_transport_on_trip: outfit.bad_transport_on_trip,
      execution: outfit.execution,
      points,
    });
  }

  static create(form: IOutfitFormParams): Promise<AxiosResponse<{ outfit: IOutfit}>> {
    return axios.post('/outfit', {
      trip_id: form.tripId,
      transport_id: form.transportId,
      start_time: form.startTime,
      end_time: form.endTime,
      date: form.date,
    });
  }
}
