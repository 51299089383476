export default [
  {
    path: '/report/trash-area',
    name: 'trash-area-report',
    component: () => import(/* webpackChunkName: "main-report" */ '@/views/report/main-report.vue'),
    children: [
      {
        path: 'form2',
        name: 'trash-area-report.form2',
        component: () => import(/* webpackChunkName: "trash-area-report.form2" */ '@/views/report/trash-area/form2.vue'),
      },
      {
        path: 'form3',
        name: 'trash-area-report.form3',
        component: () => import(/* webpackChunkName: "trash-area-report.form3" */ '@/views/report/trash-area/form3.vue'),
      },
      {
        path: 'form4',
        name: 'trash-area-report.form4',
        component: () => import(/* webpackChunkName: "trash-area-report.form4" */ '@/views/report/trash-area/form4.vue'),
      },
    ],
  },
];
