<template>
  <div class="content" :class="{'open-menu': openMenu}">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app-content',
  props: {
    openMenu: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .content {
    transition: var(--animation-time);
    margin-left: 50px;

    &.open-menu {
      margin-left: var(--menu-width);
    }
  }
</style>
