import { MessageTypeEnum } from '@/types';
import UserService from './user-service';
import ReconnectingWebSocket from 'reconnecting-websocket';

export class SocketService {
  static url: string = process.env.VUE_APP_SOCKET.startsWith('ws')
    ? process.env.VUE_APP_SOCKET
    : `${location.protocol === 'https:' ? 'wss://' : 'ws://'}${location.hostname}${process.env.VUE_APP_SOCKET}`;

  static instance: ReconnectingWebSocket | null = null;
  static readyToInit: boolean = true;
  static loggedIn: boolean = false;

  static init() {
    if (process.env.VUE_APP_SOCKET && !SocketService.instance) {
      SocketService.instance = new ReconnectingWebSocket(SocketService.url, undefined, {
        maxRetries: 1000,
        maxReconnectionDelay: 2000,
        minReconnectionDelay: 1000,
      });
      SocketService.instance.addEventListener('message', SocketService.onMessage);
    }
  }

  static run(): WebSocket | undefined {
    if (!UserService.instance.isAuth && SocketService.readyToInit) {
      UserService.instance.subscribeOnce('login', () => SocketService.init());
      SocketService.readyToInit = false;
      return;
    }

    SocketService.init();

    return SocketService.instance as WebSocket;
  }

  static onMessage(event: MessageEvent<string>) {
    const eventData: {
        type: MessageTypeEnum,
        payload: any,
      } = JSON.parse(event.data);

    switch (eventData.type) {
      case MessageTypeEnum.LOGIN_TYPE:
        SocketService.sendMessage({
          type: MessageTypeEnum.LOGIN_TYPE,
          payload: {
            token: UserService.instance.token,
          },
        });
        break;
      case MessageTypeEnum.LOGIN_FAILED_TYPE:
        SocketService.close();
        UserService.instance.logout();
        break;
      case MessageTypeEnum.LOGIN_SUCCESS_TYPE:
        SocketService.loggedIn = true;
        break;

      default:
        break;
    }
  }

  static sendMessage({ type, payload }: {
    type: MessageTypeEnum,
    payload: any,
  }) {
    SocketService.instance?.send(JSON.stringify({ type, payload }));
  }

  static close(): void {
    SocketService.instance?.removeEventListener('message', SocketService.onMessage);
    SocketService.instance?.close();
    SocketService.instance = null;
    SocketService.readyToInit = true;
    SocketService.loggedIn = false;
  }
}
