<template>
  <div class="control">
    <div class="filter">
      <basic-select
        class="autocomplete"
        @select="select"
        :selected-option="filter.type"
        :options="types"
        placeholder="Тип объекта"
      ></basic-select>
      <input v-model="filter.name" placeholder="Введите название объекта">
      <input v-model="filter.address" placeholder="Введите адрес объекта">
      <div class="filter-buttons">
        <button class="btn btn-main" @click="applyFilter">Найти</button>
        <button class="btn btn-reset" @click="resetFilter">Сбросить</button>
      </div>
    </div>
    <div class="detectors" ref="itemsBody">
      <div class="detector" v-for="detector in detectors" :key="detector.id" @click="showDetector(detector)">
        <div class="detector-icon" :style="{ 'color': detector.type.color }">
          <i :class="detector.type.icon"></i>
        </div>
        <div class="detector-wrapper">
          <div class="detector-name">
            {{ detector.name }} ({{ detector.count_cameras }})
          </div>
          <div class="detector-address">
            {{ detector.address }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LayersService } from '@/services/layers-service';
import { BasicSelect } from 'vue-search-select';

export default {
  name: 'video-layer-control',
  components: { BasicSelect },
  props: {
    scroll: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    filter: {
      name: '',
      address: '',
      type: {},
    },
    maxShow: 100,
  }),
  methods: {
    showDetector(entity) {
      LayersService.instance
        ._videoLayers
        .showMaker(entity);
    },
    applyFilter() {
      this.maxShow = 100;
      LayersService.instance
        ._videoLayers
        ._filter(this.filter);
    },
    resetFilter() {
      this.filter.name = '';
      this.filter.address = '';
      this.filter.type = {};

      this.applyFilter();
    },
    select(option) {
      if (!option.id) {
        this.filter.type = {};
      } else {
        this.filter.type = option;
      }
    },
    roundUp(value) {
      return (~~((value + 99) / 100) * 100);
    },
  },
  created() {
    this.filter.name = LayersService.instance._videoLayers.filter.name;
    this.filter.address = LayersService.instance._videoLayers.filter.address;
    this.filter.type = LayersService.instance._videoLayers.filter.type;
  },
  computed: {
    detectors() {
      return LayersService.instance
        ._videoLayers
        .video
        .getValue()
        .slice(0, this.maxShow);
    },
    types() {
      return [
        {
          text: 'Тип объекта',
        },
        ...Object.values(LayersService.instance
          ._videoLayers
          ._originVideo
          .reduce((acc, current) => {
            if (!acc[current.type.id]) {
              acc[current.type.id] = {
                id: current.type.id,
                text: current.type.name,
              };
            }

            return acc;
          }, {})),
      ];
    },
  },
  watch: {
    scroll() {
      if (this.$refs.itemsBody && this.$refs.itemsBody.firstChild) {
        const start = 450;
        const item = this.$refs.itemsBody.firstChild?.clientHeight + 16;

        if (this.scroll <= start) {
          this.maxShow = 100;
        } else {
          this.maxShow = Math.max(this.maxShow, this.roundUp((this.scroll - start + 10 * item) / item));
        }
      } else {
        this.maxShow = 100;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .control {

    .filter {
      margin-top: 16px;

      &-buttons {
        display: flex;
        justify-content: center;
        margin-bottom: 16px;
      }

      input {
        padding: 3px;
        font-size: 15px;
        width: 100%;
        margin: 16px 0;
        display: block;
        border: 1px solid var(--border-color);
        border-radius: 3px;
      }
    }

    .detector {
      cursor: pointer;
      margin-bottom: 16px;
      display: flex;
      align-content: center;

      &:hover {
        background-color: rgb(223, 223, 223);
      }

      &-name {
        font-size: 14px;
      }

      &-address {
        font-size: 12px;
      }

      &-icon {
        font-size: 15px;
        margin-right: 5px;
        display: flex;
        align-items: center;
      }
    }
  }

  .btn-main {
    color: #fff;
    background-color: var(--main-color);

    &:hover {
      color: #fff;
    }
  }

  .btn-reset {
    border: 1px solid rgba(0,0,0,.5);
    margin-left: 5px;
  }
</style>
