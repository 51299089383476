import { roadOwner } from '@/services/api/road-owner';

export default {
  state: {
    roadOwners: [],
  },
  getters: {
    roadOwners: state => state.roadOwners,
  },
  mutations: {
    roadOwners: (state, payload) => { state.roadOwners = payload; },
  },
  actions: {
    loadRoadOwners(state, { all }) {
      return new Promise((resolve, reject) => {
        roadOwner.getRoadOwners({ all })
          .then(response => {
            state.commit('roadOwners', response.road_owners);
            resolve(response);
          })
          .catch(reject);
      });
    },
  },
};
