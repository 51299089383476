import axios from '../axios';

export class RouteGroup {
  static getRoutes(idRoute) {
    return new Promise((resolve, reject) => {
      axios.get(`/route/${idRoute}/route-group`)
        .then(({ data }) => resolve(data.route_groups))
        .catch(error => reject(error));
    });
  }

  static getRoute(idRoute, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/route/${idRoute}/route-group/${id}`)
        .then(({ data }) => resolve(data.route_group))
        .catch(error => reject(error));
    });
  }

  static getRouteGroup(idRoute, all = false) {
    return new Promise((resolve, reject) => {
      axios.get(`/route-group/?filter[route_id]=${idRoute}${all ? '&all' : ''}`)
        .then(({ data }) => {
          resolve(data.route_groups);
        })
        .catch(error => reject(error));
    });
  }

  static getRouteGroupForHistory(routeId, transporterId) {
    return new Promise((resolve, reject) => {
      axios.get(`/route-group/?fields[route_groups]=id,name&filter[route_id]=${routeId}&filter[transporter_id]=${transporterId}&sort=name`)
        .then(({ data }) => {
          resolve(data.route_groups);
        })
        .catch(error => reject(error));
    });
  }
};
