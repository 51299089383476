<template>
  <div class="detectors">
    <div class="detector" v-for="(detector, index) in trafficLights" :key="index" @click="showDetector(detector)">
      <div class="detector-address">
        {{ detector.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { LayersService } from '@/services/layers-service';

export default {
  name: 'traffic-light-layer-control',
  data: () => ({
    trafficLights: [],
  }),
  methods: {
    showDetector(trafficLight) {
      LayersService.instance
        ._trafficLightLayers
        .showMarker(trafficLight);
    },
  },
  created() {
    LayersService.instance
      ._trafficLightLayers
      .markers
      .subscribe(trafficLights => { this.trafficLights = trafficLights; });
  },
};
</script>

<style lang="scss" scoped>
  .detectors {
    .detector {
      cursor: pointer;
      margin-bottom: 16px;

      &-address {
        font-size: 16px;
      }
    }
  }
</style>
