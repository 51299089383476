import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { Locality } from '@/services/api/locality';
import { RootState, LocalityState, ILocality } from '@/types';

const state: LocalityState = {
  localities: [],
};

const getters: GetterTree<LocalityState, RootState> = {
  localities: state => state.localities,
};

const mutations: MutationTree<LocalityState> = {
  setLocalities: (state, payload: Array<ILocality>) => {
    state.localities = [...payload];
  },
};

const actions: ActionTree<LocalityState, RootState> = {
  async loadLocalities({ commit }, municipalityId: number) {
    const localities = await Locality.getLocality(municipalityId);
    commit('setLocalities', localities);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
