<template>
  <div class="video">
    <video :id="'player_' + index" controls></video>
  </div>
</template>

<script>
import shaka from 'shaka-player';

export default {
  name: 'player',
  props: ['src', 'index'],
  data: () => ({
    shaka: null,
  }),
  methods: {
    initPlayer() {
      let player = document.getElementById('player_' + this.index);

      this.shaka = new shaka.Player(player);

      this.shaka.addEventListener('error', onErrorEvent);
      this.shaka.load(this.src).then(function() {
        player.play();
      }).catch(onError);

      function onErrorEvent(event) {
        onError(event.detail);
      }

      function onError(error) {
        return error;
      }
    },
    initShaka() {
      if (!window.shaka_init) {
        shaka.polyfill.installAll();
        if (shaka.Player.isBrowserSupported()) {
          window.shaka_init = true;
          this.initPlayer();
        } else {
          window.shaka_init = false;
          alert('Ваш браузер не поддерживает воспроизведение потокового видео. Пожалуйста, обновите браузер');
        }
      } else {
        this.initPlayer();
      }
    },
  },
  mounted() {
    this.initShaka();

    this.$store.subscribe(mutation => {
      if (mutation.type === 'openVideoPopup' && mutation.payload === null) {
        this.shaka.unload();
      }
    });
  },
};
</script>

<style scoped>
  video {
    width: 100%;
    margin-bottom: 30px;
  }
</style>
