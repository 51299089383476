import { GENERAL_MARKER_BASE_64 } from '@/constants/Global';
import { LAYERS_MAP } from '@/constants/LayerTypes';
import { ICustomLayer, ICustomLayerObject, ILayerFilterType } from '@/types';
import { Store } from 'vuex';
import { Layer } from '../api/layer';
import { BaseLayer } from './base-layer';

export class CustomLayer extends BaseLayer {
  layers: L.LayerGroup = L.layerGroup();
  items: Array<ICustomLayerObject> = [];
  openedLayers: Array<string> = [];

  constructor({ store, map, type }: {
    store: Store<any>,
    map: L.Map,
    type: string,
  }) {
    super({ store, map, type });

    this.layers.addTo(map);
  }

  async reRender({ types }: { types: Array<ILayerFilterType> }) {
    const enabledCustomLayers = types.filter(t => t.checked).map(t => t.id);
    const layers: Array<ICustomLayer> = this._store.getters['layer/layers'];
    const layersToEnable: {
      [key: string]: 1,
    } = {};

    enabledCustomLayers.forEach(id => {
      const layer = layers.find(l => l.id === Number(id));

      if (layer) {
        layer.sublayers
          .filter(sublayer => sublayer)
          .forEach(sublayer => {
            const type = LAYERS_MAP[sublayer];

            if (type) {
              layersToEnable[type] = 1;
            }
          });
      }
    });

    const toOpen = Object.keys(layersToEnable);
    if (JSON.stringify(toOpen) !== JSON.stringify(this.openedLayers)) {
      this._store.dispatch('layer/disableLayers', { layers: this.openedLayers, toggle: false });
      this.openedLayers = toOpen;
      this._store.dispatch('layer/openLayers', this.openedLayers);
    }

    Layer.getLayerObject(enabledCustomLayers.join(','))
      .then(res => {
        this.items = [...res];
        this._render();
      });
  }

  _render() {
    this._deleteLayers();

    this.items
      .filter(i => !this.municipalityId || i.municipality_id === this.municipalityId)
      .forEach(item => {
        const color = item.color ?? item.object_type.color ?? 'black';
        const bodyColor = item.body_color ?? item.object_type.body_color ?? 'black';
        const weight = item.object_type.border ?? 5;
        const dashArray = item.object_type.circuit === '2' ? `${weight + 1} ${weight + 1}` : '1';
        const props = [
          {
            name: 'Название',
            value: item.name,
          },
        ];

        item.object_property_values.forEach(prop => {
          const fullProp = item.object_type.properties.find(p => prop.layer_object_type_property_id === p.id);

          if (fullProp) {
            props.push({
              name: fullProp.name,
              value: prop.value,
            });
          }
        });

        const popupContent = props.map(p => (`<p class="mts"><strong>${p.name}: </strong>${p.value}</p>`)).join('');

        switch (item.geometry.type) {
          case 'Point':
            const coords = item.geometry.coordinates as [number, number];
            const layer = L.marker(L.GeoJSON.coordsToLatLng(coords), {
              icon: L.icon({
                iconUrl: item.object_type.icon_url ?? GENERAL_MARKER_BASE_64,
              }),
            } as any);

            layer.bindPopup(popupContent);
            this.layers.addLayer(layer);

            const icon = layer.getIcon() as L.Icon<L.IconOptions>;
            const htmlIcon = icon.createIcon() as HTMLImageElement;

            htmlIcon.onload = () => {
              const anchor = [htmlIcon.width / 2, htmlIcon.height] as [number, number];
              icon.options.iconAnchor = anchor;
              layer.setIcon(icon);
            };
            break;
          case 'MultiLineString':
            const line = L.polyline(
              L.GeoJSON.coordsToLatLngs(item.geometry.coordinates, 1),
              {
                weight,
                opacity: 1,
                color,
                dashArray,
                fillOpacity: 0.3,
                fillColor: color,
              } as any,
            );
            line.bindPopup(popupContent);
            this.layers.addLayer(line);

            break;
          case 'MultiPolygon':
            const polygon = L.polygon(
              L.GeoJSON.coordsToLatLngs(item.geometry.coordinates, 2),
              {
                weight,
                opacity: 1,
                color,
                dashArray,
                fillOpacity: 0.3,
                fillColor: bodyColor,
              },
            );
            polygon.bindPopup(popupContent);
            this.layers.addLayer(polygon);

            break;
          default:
            break;
        }
      });
  }

  _deleteLayers() {
    this.layers.eachLayer(l => l.remove());
    this.layers.clearLayers();
  }

  destroy() {
    this.items = [];
    this._deleteLayers();
    const toClose = [...this.openedLayers];
    this.openedLayers = [];

    this._store.dispatch('layer/disableLayers', { layers: toClose, toggle: true });
  }

  get municipalityId(): number {
    return this._store.getters['municipalities/current'].id;
  }
}
