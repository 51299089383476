import axios from '../axios';

export default {
  getMunicipalities({ all = false, sort = [] } = {}) {
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams();

      if (Array.isArray(sort) && sort.length > 0) {
        params.set('sort', sort.join(','));
      }

      if (all) {
        params.set('all', '1');
      }

      axios.get('/municipality', { params })
        .then(({ data }) => resolve(data.municipalities))
        .catch(error => reject(error));
    });
  },
  getMunicipality(id) {
    return new Promise((resolve, reject) => {
      axios.get(`/municipality/${id}`)
        .then(({ data }) => resolve(data.municipality))
        .catch(error => reject(error));
    });
  },
};
