

















































import { defineComponent, ref, onMounted, Ref, computed, ComputedRef, reactive, watch } from '@vue/composition-api';
import { LayersService } from '../../../services/layers-service';
import DatePicker from 'vue2-datepicker';
import { MultiListSelect } from 'vue-search-select';
import { IRouteSubtype, ITransporter, IRoad } from '../../../types';
import { RouteSubtype } from '../../../services/api/route-subtype';
import { Transporter } from '../../../services/api/transporter';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import moment from 'moment';
import { ROAD_FACILITIES_TYPE_GROUP } from '../../../constants/RouteTypeGroups';

const municipalitiesHelpers = createNamespacedHelpers('municipalities');

export default defineComponent({
  components: {
    DatePicker,
    MultiListSelect,
  },
  setup() {
    const { current } = municipalitiesHelpers.useGetters(['current', 'isCurrentGeneral']);
    const filter: {
      date: [Date, Date],
      transporter: Array<ITransporter>,
      routeSubtype: Array<IRouteSubtype>,
    } = reactive({
      date: [new Date(LayersService.instance
        ._roadLayers
        .filter.date[0]), new Date(LayersService.instance
        ._roadLayers
        .filter.date[1])],
      transporter: [...LayersService.instance
        ._roadLayers
        .filter.transporter],
      routeSubtype: [...LayersService.instance
        ._roadLayers
        .filter.routeSubtype],
    });
    const roads: Ref<Array<IRoad>> = ref([]);
    const isSubLayerEnabled: ComputedRef<boolean> = computed(() => LayersService.instance._roadLayers.isSubLayerEnabled);
    const subscribeRoads = () => {
      LayersService.instance
        ._roadLayers
        .roads
        .subscribe((roadsResult: Array<IRoad>) => { roads.value = roadsResult; });
    };
    const data: {
      routeSubtypes: Array<IRouteSubtype>,
      transporters: Array<ITransporter>,
    } = reactive({
      routeSubtypes: [],
      transporters: [],
    });
    const transporterList = computed(() => (
      data.transporters.map(r => ({
        value: r.id,
        text: r.name,
      }))));
    const routeSubtypeList = computed(() => (
      data.routeSubtypes.map(r => ({
        value: r.id,
        text: r.name,
      }))));

    const init = () => {
      if (isSubLayerEnabled.value) {
        RouteSubtype.getAll()
          .then((routeSubtypes: Array<IRouteSubtype>) => {
            data.routeSubtypes = [...routeSubtypes];
          });

        Transporter.getTransporters({
          all: 1,
          filter: {
            municipality: current.value.id !== 0 ? current.value.id : undefined,
            typeGroup: ROAD_FACILITIES_TYPE_GROUP,
          },
        })
          .then((response: {
        transporters: Array<ITransporter>
      }) => {
            data.transporters = [...response.transporters];
          });
      }
    };
    init();

    const showRoad = (road: IRoad) => {
      LayersService.instance
        ._roadLayers
        .showMarker(road);
    };

    onMounted(() => {
      subscribeRoads();
    });

    watch(isSubLayerEnabled, () => init());
    watch(filter, () => {
      LayersService.instance
        ._roadLayers
        .filter.date = filter.date;
      LayersService.instance
        ._roadLayers
        .filter.transporter = filter.transporter;
      LayersService.instance
        ._roadLayers
        .filter.routeSubtype = filter.routeSubtype;

      LayersService.instance
        ._roadLayers
        .renderHistory();
    });

    return {
      roads,
      subscribeRoads,
      showRoad,
      isSubLayerEnabled,
      filter,
      transporterList,
      routeSubtypeList,
      selectTransporter(transporter: Array<ITransporter>) {
        filter.transporter = transporter;
      },
      selectRouteSubtype(subtype: Array<IRouteSubtype>) {
        filter.routeSubtype = subtype;
      },
      refresh() {
        LayersService.instance
          ._roadLayers
          .renderHistory();
      },
      disabledAfter(date1: Date, dates: Array<Date>) {
        const newDate = moment(date1);
        const maxDate = moment(new Date());
        let answer = false;

        if (dates.length > 1) {
          const date1 = moment(dates[1]).add(4, 'days');
          const date2 = moment(dates[0]).add(-4, 'days');
          answer = answer || !newDate.isSameOrBefore(date1) || !newDate.isSameOrAfter(date2);
        } else if (dates.length === 1) {
          const date1 = moment(dates[0]).add(4, 'days');
          const date2 = moment(dates[0]).add(-4, 'days');
          answer = answer || !newDate.isSameOrBefore(date1) || !newDate.isSameOrAfter(date2);
        }

        return !newDate.isSameOrBefore(maxDate) || answer;
      },
    };
  },
});
