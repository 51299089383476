import { PubSub } from '@/services/pub-sub';
import UserService from '@/services/user-service';
import { MapService } from '@/services/map-service';
import { LayersService } from '@/services/layers-service';
import { ACL } from '@/modules/acl/acl-service';

const key = Symbol('key');
const keyEnforcer = Symbol('keyEnforcer');

export class InitializeService extends PubSub {
  _store = null;
  initialized = false;

  constructor(enforcer) {
    super();
    if (enforcer !== keyEnforcer) {
      throw new Error('Instantiation failed: use InitializeService.instance instead of new.');
    }
  }

  static get instance() {
    if (!this[key]) {
      this[key] = new InitializeService(keyEnforcer);
    }

    return this[key];
  }

  static set instance(v) {
    throw new Error("Can't change constant property!");
  }

  setStore(store) {
    this._store = store;
    return this;
  }

  initialize() {
    if (this._store !== null) {
      return new Promise((resolve, reject) => {
        if (!UserService.instance.isAuth) {
          resolve();
          return;
        }

        MapService.instance.setStore(this._store);
        LayersService.instance.setStore(this._store);

        const requests = [
          this._store.dispatch('municipalities/loadMunicipalities', { all: true }),
          this._store.dispatch('route-type/loadRouteTypes', { all: true }),
          this._store.dispatch('transporter/loadTransporters', { all: true }),
        ];
        if (ACL.can('layer.list')) {
          requests.push(this._store.dispatch('layer/getLayers'));
        }

        Promise.all(requests)
          .then(() => {
            this.initialized = true;
            this.publish('initialize');
            resolve();
          })
          .catch((e) => {
            reject(e);
            UserService.instance.logout();
          });
      });
    } else {
      throw new Error('Service initialization is not available without application storage!');
    }
  }
}
