import axios from '../axios';

export class Route {
  static getRoutes({ filter, include }) {
    let url = `/route?`;

    if (Array.isArray(include) && include.length > 0) {
      url += `include=${include.join(',')}`;
    }

    const params = [];

    if (filter.all) {
      params.push('all');
    }

    if (filter.routeId) {
      params.push(`filter[id]=${filter.routeId}`);
    }

    if (filter.municipalityId) {
      params.push(`filter[municipality_id]=${filter.municipalityId}`);
    }

    if (filter.date) {
      params.push(`filter[date]=${filter.date}`);
    }

    if (filter.dateUnassignedTransport) {
      params.push(`filter[dateUnassignedTransport]=${filter.dateUnassignedTransport}`);
    }

    if (filter.routePathTypes) {
      params.push(`filter[route_path_types]=${filter.routePathTypes}`);
    }

    if (filter.transporterId) {
      params.push(`filter[transporters]=${filter.transporterId}`);
    }

    if (filter.onlyWithTransporter) {
      params.push(`filter[onlyWithTransporter]=${filter.onlyWithTransporter}`);
    }

    if (filter.types) {
      params.push(`filter[types]=${filter.types}`);
    }

    if (filter.routePathTypesByGroup) {
      params.push(`filter[routePathTypesByGroup]=${filter.routePathTypesByGroup}`);
    }

    if (filter.routeTypes && Array.isArray(filter.routeTypes) && filter.routeTypes.length > 0) {
      params.push(`filter[types]=${filter.routeTypes.join(',')}`);
    }

    url += `&${params.join('&')}`;

    return new Promise((resolve, reject) => {
      axios.get(url)
        .then(({ data }) => resolve(data.routes))
        .catch(reject);
    });
  }

  static getRoute(id) {
    return new Promise((resolve, reject) => {
      axios.get(`/route/${id}`)
        .then(({ data }) => resolve(data.route))
        .catch(reject);
    });
  }

  static converge(point1, point2, filter) {
    const params = {
      'point1[lat]': point1.lat,
      'point1[lng]': point1.lng,
      'point2[lat]': point2.lat,
      'point2[lng]': point2.lng,
    };

    if (filter.municipalityId) {
      params['filter[municipality_id]'] = filter.municipalityId;
    }

    if (filter.routePathTypesByGroup) {
      params['filter[routePathTypesByGroup]'] = filter.routePathTypesByGroup;
    }

    if (filter.onlyWithTransporter) {
      params['filter[onlyWithTransporter]'] = filter.onlyWithTransporter;
    }

    if (filter.types) {
      params['filter[types]'] = filter.types;
    }

    if (filter.duplicationRouteGroupId) {
      params.duplication_route_group_id = filter.duplicationRouteGroupId;
    }

    return axios.get('/route/converge', {
      params,
    }).then(response => response.data);
  }
}
