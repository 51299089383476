















































































import { computed, defineComponent, onMounted, reactive, Ref, watch } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { HouseFilters } from '@/types';
import { BasicSelect, MultiListSelect } from 'vue-search-select';
import { LayersService } from '@/services/layers-service';
import { IdNameInterface } from '../../../../../admin/src/app/_interfaces/models/id-name-interface';

const layerHelper = createNamespacedHelpers('layer');
const municipalitiesHelpers = createNamespacedHelpers('municipalities');
const houseHelper = createNamespacedHelpers('house');

interface Selected {
  id: number,
  text: string,
}

export default defineComponent({
  name: 'HouseLayerControl',
  components: { BasicSelect, MultiListSelect },
  setup() {
    const filter : {
      municipalityId: Selected,
      address: string,
      streets: Array<IdNameInterface>,
      number: string,
      types: Array<IdNameInterface>,
      yearFrom: number | null,
      yearTo: number | null,
      squareFrom: number | null,
      squareTo: number | null,
      populationFrom: number | null,
      populationTo: number | null,
      levelsCountFrom: number | null,
      levelsCountTo: number | null,
      flatsCountFrom: number | null,
      flatsCountTo: number | null,
      trashChute: Selected,
      hasTrashAreas: Selected,
    } = reactive({
      municipalityId: { id: 0, text: '' },
      address: '',
      streets: [],
      number: '',
      types: [],
      yearFrom: null,
      yearTo: null,
      squareFrom: null,
      squareTo: null,
      populationFrom: null,
      populationTo: null,
      levelsCountFrom: null,
      levelsCountTo: null,
      flatsCountFrom: null,
      flatsCountTo: null,
      trashChute: { id: -1, text: 'Мусоропровод' },
      hasTrashAreas: { id: -1, text: 'Есть связанные ТКО' },
    });

    const { types, streets } : {
      types: Ref<Array<string>>,
      streets: Ref<Array<string>>,
    } = houseHelper.useGetters(['types', 'streets']) as any;

    const { loadTypes, loadStreets } : {
      loadTypes: () => Promise<void>,
      loadStreets: (municipalityId: number) => Promise<void>,
    } = houseHelper.useActions(['loadTypes', 'loadStreets']) as any;

    const { current } = municipalitiesHelpers.useGetters(['current']);
    const typesList = computed(() => {
      if (types) {
        return types.value
          .map((typeName, index) => ({
            id: index,
            name: typeName,
          }));
      }

      return [];
    });

    const streetsList = computed(() => {
      if (streets) {
        return streets.value
          .map((name, index) => ({
            id: index,
            name: name,
          }));
      }

      return [];
    });

    const optionsList: Array<Selected> = [
      {
        id: -1,
        text: 'Не важно',
      },
      {
        id: 1,
        text: 'Есть',
      }, {
        id: 0,
        text: 'Нет',
      },
    ];

    const getFilters = () : HouseFilters => {
      return {
        municipalityId: current ? current.value.id : 0,
        address: filter.address.trim(),
        street: filter.streets.map((s) => s.name),
        number: filter.number.trim(),
        type: filter.types.map((t) => t.name),
        yearFrom: filter.yearFrom ?? 0,
        yearTo: filter.yearTo ?? 0,
        populationFrom: filter.populationFrom ?? 0,
        populationTo: filter.populationTo ?? 0,
        squareFrom: filter.squareFrom ?? 0,
        squareTo: filter.squareTo ?? 0,
        levelsCountFrom: filter.levelsCountFrom ?? 0,
        levelsCountTo: filter.levelsCountTo ?? 0,
        flatsCountFrom: filter.flatsCountFrom ?? 0,
        flatsCountTo: filter.flatsCountTo ?? 0,
        trashChute: filter.trashChute.id,
        hasTrashAreas: filter.hasTrashAreas.id,
      };
    };

    onMounted(() => {
      loadStreets(current?.value?.id ?? 0);
      loadTypes();
    });

    watch(current, () => loadStreets(current?.value?.id ?? 0));

    return {
      typesList,
      streetsList,
      optionsList,
      filter,
      refresh() {
        LayersService.instance
          ._houseLayers
          ._filter(getFilters());
      },
      setTrashChute(attr: Selected) {
        filter.trashChute = attr;
      },
      setHasTrashAreas(attr: Selected) {
        filter.hasTrashAreas = attr;
      },
      selectTypes(types: Array<IdNameInterface>) {
        filter.types = types;
      },
      selectStreets(streets: Array<IdNameInterface>) {
        filter.streets = streets;
      },
    };
  },
});

