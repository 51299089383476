<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="100%" height="100%" viewBox="0 0 24 24" v-if="!open">
      <g data-name="Layer 2">
        <g data-name="chevron-left">
          <rect width="24" height="24" transform="rotate(90 12 12)" opacity="0"></rect>
          <path d="M13.36 17a1 1 0 0 1-.72-.31l-3.86-4a1 1 0 0 1 0-1.4l4-4a1 1 0 1 1 1.42 1.42L10.9 12l3.18 3.3a1 1 0 0 1 0 1.41 1 1 0 0 1-.72.29z"></path>
        </g>
      </g>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="100%" height="100%" viewBox="0 0 24 24" v-else>
      <g data-name="Layer 2">
        <g data-name="chevron-down">
          <rect width="24" height="24" opacity="0"></rect>
          <path d="M12 15.5a1 1 0 0 1-.71-.29l-4-4a1 1 0 1 1 1.42-1.42L12 13.1l3.3-3.18a1 1 0 1 1 1.38 1.44l-4 3.86a1 1 0 0 1-.68.28z"></path>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'item-open-close-icon',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  width: 20px;
  color: #8f9bb3;
}
</style>
