import { Route } from '@/services/api/route';
import { Trip } from '@/services/api/trip';
import { Outfit } from '@/services/api/outfit';

export default {
  state: {
    routes: [],
    trips: [],
    converge: [],
    duplicationRouteGroupId: null,
  },
  getters: {
    routes: state => state.routes,
    trips: state => state.trips,
    converge: state => state.converge,
    duplicationRouteGroupId: state => state.duplicationRouteGroupId,
  },
  mutations: {
    routes: (state, payload) => { state.routes = payload; },
    trips: (state, payload) => { state.trips = payload; },
    converge: (state, payload) => { state.converge = payload; },
    duplicationRouteGroupId: (state, payload) => { state.duplicationRouteGroupId = payload; },
  },
  actions: {
    loadRoutes(state, { filter, include }) {
      return new Promise((resolve, reject) => {
        Route.getRoutes({ filter, include })
          .then(response => {
            state.commit('routes', response);
            resolve(response.routes);
          })
          .catch(reject);
      });
    },
    loadTrips(state, { routeGroup, scheduleDate }) {
      return Trip.getList(routeGroup, scheduleDate)
        .then(response => {
          state.commit('trips', response.data.trips);
        });
    },
    createOutfit(state, form) {
      return Outfit.create(form);
    },
    updateConverge(state, payload) {
      state.commit('converge',
        state.getters.converge.map(c => c.id === payload.id ? { ...payload } : c),
      );
    },
  },
};
