import { PubSub } from '@/services/pub-sub';

const key = Symbol('key');
const keyEnforcer = Symbol('keyEnforcer');

export class NotificationsService extends PubSub {
  _notifications = [];
  types = ['success', 'primary', 'default', 'warning', 'danger'];

  constructor(enforcer) {
    super();
    if (enforcer !== keyEnforcer) {
      throw new Error('Instantiation failed: use NotificationsService.instance instead of new.');
    }
  }

  static get instance() {
    if (!this[key]) {
      this[key] = new NotificationsService(keyEnforcer);
    }

    return this[key];
  }

  static set instance(v) {
    throw new Error("Can't change constant property!");
  }

  get notifications() {
    return [...this._notifications];
  }

  add(notification) {
    if (
      notification.type !== undefined &&
      this.types.indexOf(notification.type) > -1 &&
      notification.text !== undefined
    ) {
      let ttl = new Date();
      ttl.setSeconds(ttl.getSeconds() + 7);
      notification.ttl = ttl;
      this._notifications.push(Object.freeze(notification));
      this.publish('update');
    } else {
      throw new Error('Not supported notification forma!\r\n' + JSON.stringify(notification));
    }
  }

  remove(index) {
    if (this._notifications[index] !== undefined) {
      this._notifications.splice(index, 1);
      this.publish('update');
    }
  }

  clear() {
    this._notifications = [];
    this.publish('update');
  }

  error(error) {
    let message = error.message || error.response?.data?.message;

    if (error.response?.data?.errors) {
      Object.values(error.response?.data?.errors)
        .forEach((errorArray) => {
          message += ` ${errorArray.join(', ')}`;
        });
    }
    NotificationsService.instance.add({
      type: 'danger',
      text: message,
    });
  }
}
