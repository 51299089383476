import axios from '../axios';

export class Detector {
  static list({ all, municipalityId }) {
    let url = '/detector';
    const params = [];

    if (all) {
      params.push('all');
    }

    if (municipalityId) {
      params.push(`filter[municipality_id]=${municipalityId}`);
    }

    url += '?' + params.join('&');
    return axios.get(url)
      .then(({ data }) => data)
      .catch(error => error);
  }

  static detector({ id }) {
    return axios.get('/detector/' + id + '/cars/')
      .then(({ data }) => data)
      .catch(error => error);
  }

  static search({ startDate, endDate, number }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/detector/search?number=${number}&start_date=${startDate}&end_date=${endDate}`)
        .then(({ data }) => resolve(data))
        .catch(error => reject(error));
    });
  }
}
