






















import { computed, defineComponent } from '@vue/composition-api';
import moment from 'moment';

export default defineComponent({
  name: 'TransportRoadPopup',
  props: {
    road: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const start = computed(() => moment.utc(props.road.start_at).local().format('DD.MM.YYYY HH:mm:ss'));
    const end = computed(() => moment.utc(props.road.end_at).local().format('DD.MM.YYYY HH:mm:ss'));
    const time = computed(() => {
      return `${start.value} по ${end.value}`;
    });
    const vehicle = computed(() => {
      const arr = [ props.road.transport.number ];

      if (props.road.transport.model && props.road.transport.model.mark) {
        arr.push(props.road.transport.model.mark.name, props.road.transport.model.name);
      } else if (props.road.transport.model) {
        arr.push(props.road.transport.model.name);
      }

      if (props.road.transport && props.road.transport.subtype) {
        arr.push(`(${props.road.transport.subtype.name})`);
      }

      return arr.join(' ');
    });
    const query = computed(() => {
      const query: {
        transporterId?: number,
        date: string,
        transportId?: number,
        transportRoadId: number,
      } = {
        date: moment.utc(props.road.start_at).format('YYYY-MM-DD'),
        transportRoadId: props.road.id,
      };

      if (props.road.transport) {
        query.transportId = props.road.transport.id;

        if (props.road.transport.transporter) {
          query.transporterId = props.road.transport.transporter.id;
        }
      }

      return query;
    });
    return {
      time,
      vehicle,
      query,
    };
  },
});
