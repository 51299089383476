import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState, HouseState } from '@/types';
import { House } from '@/services/api/house';

const state: HouseState = {
  streets: [],
  highlight: false,
  types: [],
};

const getters: GetterTree<HouseState, RootState> = {
  streets: state => state.streets,
  highlight: state => state.highlight,
  types: state => state.types,
};

const mutations: MutationTree<HouseState> = {
  setStreets: (state, payload: Array<string>) => {
    state.streets = [...payload];
  },
  setHighlight: (state, payload: boolean) => {
    state.highlight = payload;
  },
  setTypes: (state, payload: Array<string>) => {
    state.types = [...payload];
  },
};

const actions: ActionTree<HouseState, RootState> = {
  async loadStreets({ commit }, municipalityId) {
    const response = await House.getStreets(municipalityId);
    commit('setStreets', response.data.streets);
  },
  async loadTypes({ commit }) {
    const response = await House.getTypes();
    commit('setTypes', response.data.types);
  },
  showHighlight({ commit }) {
    commit('setHighlight', true);
  },
  hideHighlight({ commit }) {
    commit('setHighlight', false);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
