
export class ExportService {
  public static readonly VND_EXCEL_FILE_TYPE: string = 'application/vnd.ms-excel';

  static downloadFile(file: Blob, type: string, fileName: string): void {
    const url = URL.createObjectURL(new Blob([file], {
      type: type,
    }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }
}
