export default {
  1: {
    key: 1,
    value: 1,
    text: 'Автобус',
    class: 'green',
    icon: 'bus',
    color: '#5BC059',
  },
  3: {
    key: 3,
    value: 3,
    text: 'Троллейбус',
    class: 'blue',
    icon: 'trolleybus',
    color: '#70BCFD',
  },
  4: {
    key: 4,
    value: 4,
    text: 'Трамвай',
    class: 'purple',
    icon: 'trum',
    color: '#B9A3FF',
  },
  5: {
    key: 5,
    value: 5,
    text: 'Скорая помощь',
    class: 'red',
    icon: 'ambulance',
    color: '#FF4F4F',
  },
  6: {
    key: 6,
    value: 6,
    text: 'Медицина катастроф',
    class: 'red',
    icon: 'emergency_medicine',
    color: '#FF0000',
  },
  7: {
    key: 7,
    value: 7,
    text: 'Школьный автобус',
    class: 'yellow',
    icon: 'school_bus',
    color: '#FBFF00',
  },
};
