import axios from '../axios';

export class RepairRoad {
  static getRepairRoad({ all, municipalityId, municipality }) {
    let url = '/repair-road';
    const params = [];

    if (all) {
      params.push('all');
    }

    if (municipalityId) {
      params.push(`filter[municipality_id]=${municipalityId}`);
    }

    if (municipality) {
      params.push(`include=municipality`);
    }

    url += '?' + params.join('&');

    return new Promise((resolve, reject) => {
      axios.get(url)
        .then(({ data }) => resolve(data))
        .catch(error => reject(error));
    });
  }
}
