import L from 'leaflet';
import { BehaviorSubject } from 'rxjs';
import { BaseLayer } from '@/services/layers/base-layer';
import { Route } from '@/services/api/route';
import Vue from 'vue';
import ColorPicker from '@/components/shared/color-picker';
import '../offset';
import { ROUTE_NUMBER_REGEX } from '@/constants/Global';

export class RouteLayer extends BaseLayer {
  _layers = null;
  _analysisLayers = null;
  _crossShowLayers = null;

  _originRoutes = [];
  routes = new BehaviorSubject([]);
  _originAnalysisRoutes = [];
  analysisRoutes = new BehaviorSubject([]);
  highlightedRoute = null;
  crossShowRoutes = [];
  crossShowIds = [];
  crossShowColors = {};
  numbersGroup = null;
  numberLayers = [];
  numberCrossShowLayers = [];
  _clusterBreakPoint = 19;

  _colorAnalysisBuffer = {};
  _colorBuffer = {};

  toMarker = null;
  fromMarker = null;

  showRouteNumbers = false;

  unsubcribe = null;

  lastFilter = '';

  constructor({ store, map, type }) {
    super({ store, map, type });
    this.filter = {
      name: '',
      selectedRoutes: [],
      routePathTypes: [],
      municipalityId: null,
      analysisPoint1: null,
      analysisPoint2: null,
      analysisPathTypes: '',
      analysisTypes: '',
      ...this.filter,
    };

    this.createGroups();

    this.routes.subscribe(() => {
      this._renderMarkers();
    });

    this.analysisRoutes.subscribe(() => {
      this.renderAnalysisRoutes();
    });

    this._store.subscribeAction(action => {
      if (action.type === 'layers/analysis/enableCrossShow') {
        this.crossShowColors[action.payload.id] = action.payload.color;
        this.crossShow([...this.crossShowIds, action.payload.id]);
      } else if (action.type === 'layers/analysis/setCrossShow') {
        this.crossShowColors = action.payload.reduce((acc, curr) => {
          acc[curr.id] = curr.color;

          return acc;
        }, {});
        this.crossShow(action.payload.map(el => el.id));
      }
    });

    this._store.subscribe(mutation => {
      if (mutation.type === 'municipalities/current') {
        if (this.crossShowIds.indexOf(mutation.payload) > -1) {
          this._store.dispatch('layers/analysis/setCrossShow', this._store.getters['layers/analysis/crossShow'].filter(i => i.id !== mutation.payload));
        }
      } else if (mutation.type === 'map/setGroupControlDisabled') {
        this.numbersGroup?.clearLayers();
        this.numbersGroup?.remove();
        this.numbersGroup = null;

        this.createGroups();
      }
    });

    this._colorBuffer = { ...this._store.getters['layers/analysis/routeColorBuffer'] };
  }

  createGroups() {
    this.numbersGroup = new L.MarkerClusterGroup({
      disableClusteringAtZoom: !this._store.getters['map/isGroupControlDisabled'] ? this._clusterBreakPoint : 0,
      spiderfyOnMaxZoom: true,
      showCoverageOnHover: false,
      maxClusterRadius: 25,
    });

    const layers = [];
    const layersCrossShow = [];
    if (this._layers) {
      this._layers.forEach(l => {
        if (l.markerFirst && l.markerLast) {
          layers.push(l.markerFirst);
          layers.push(l.markerLast);
        }
      });
      this.numbersGroup.addLayers(layers);
      this.numberLayers = layers;
    }
    if (this._crossShowLayers) {
      this._crossShowLayers.forEach(l => {
        if (l.markerFirst && l.markerLast) {
          layersCrossShow.push(l.markerFirst);
          layersCrossShow.push(l.markerLast);
        }
      });
      this.numbersGroup.addLayers(layersCrossShow);
      this.numberCrossShowLayers = layersCrossShow;
    }

    this._map.addLayer(this.numbersGroup);
  }

  _deleteAnalysisLayer() {
    if (this._analysisLayers !== null) {
      this._analysisLayers.forEach(layer => {
        layer.lineTails.remove();
        layer.lineCut.remove();
        if (layer.markerFirst) {
          layer.markerFirst.remove();
        }
        if (layer.markerLast) {
          layer.markerLast.remove();
        }
        layer.popup.$destroy();
      });
      this._analysisLayers = null;
    }
  }

  _deleteCrossShowLayer() {
    this.numbersGroup.clearLayers();
    this.numbersGroup.addLayers(this.numberLayers);
    this.numberCrossShowLayers = [];

    if (this._crossShowLayers !== null) {
      this._crossShowLayers.forEach(layer => {
        layer.line.remove();
        if (layer.popup) {
          layer.popup.$destroy();
        }
        if (layer.markerFirst) {
          layer.markerFirst.remove();
        }
        if (layer.markerLast) {
          layer.markerLast.remove();
        }
      });
      this._crossShowLayers = null;
    }
  }

  _deleteLayer() {
    this.numbersGroup.clearLayers();
    this.numbersGroup.addLayers(this.numberCrossShowLayers);
    this.numberLayers = [];

    if (this._layers !== null) {
      this._layers.forEach(layer => {
        layer.line.remove();
        if (layer.popup) {
          layer.popup.$destroy();
        }
        if (layer.markerFirst) {
          layer.markerFirst.remove();
        }
        if (layer.markerLast) {
          layer.markerLast.remove();
        }
      });
      this._layers = null;
    }
  }

  goToMarker(route, zoom = 15) {
    this._layers.forEach(layer => {
      if (layer.route.id === route.id) {
        this._map.setView([route.route_groups[0].route_line.coordinates[0][1], route.route_groups[0].route_line.coordinates[0][0]], zoom);
        layer.line.openPopup();
      }
    });
  }

  _filter({ name, selectedRoutes, clear }) {
    if (clear || (this.filter.analysisPoint1 && this.filter.analysisPoint2)) {
      this.routes.next([]);
      return;
    }

    let routes = this._originRoutes;

    this.filter = {
      ...this.filter,
      name,
      selectedRoutes,
    };

    if (selectedRoutes.length > 0) {
      routes = routes.filter(route => selectedRoutes.indexOf(route.id) > -1);
    }

    routes = routes.filter(route => route.route_groups.filter(routeGroup => routeGroup.route_line !== null).length > 0);

    this.routes.next(routes);
    this._store.commit('route/routes', routes);
  }

  async reRender({ groups }) {
    const selectedGroups = groups.filter(group => group.types.filter(type => type.checked).length > 0);
    const types = selectedGroups.find(group => group.name === 'Тип ТС')?.types.filter(type => type.checked).map(type => type.id).join(',');
    const pathTypes = selectedGroups.filter(group => group.name !== 'Тип ТС').map(group => group.types.filter(type => type.checked)
      .map(type => type.id).join(',')).join(',');

    const filter = JSON.stringify({
      municipalityId: this._store.getters['municipalities/current'].id,
      onlyWithTransporter: 1,
      types: types,
      routePathTypesByGroup: pathTypes,
    });

    if (!this.lastFilter || this.lastFilter !== filter) {
      if (this._store.getters['municipalities/current'].id === 0) {
        this.filter = {
          ...this.filter,
          municipalityId: null,
        };
      } else {
        this.filter = {
          ...this.filter,
          municipalityId: this._store.getters['municipalities/current'].id,
        };
      }
      this.lastFilter = filter;

      this._originRoutes = await Route.getRoutes({
        filter: {
          all: true,
          municipalityId: this.filter.municipalityId,
          onlyWithTransporter: 1,
          types: types,
          routePathTypesByGroup: pathTypes,
        },
        include: ['routeGroups'],
      });

      this.filter = {
        ...this.filter,
        analysisTypes: types,
        analysisPathTypes: pathTypes,
      };

      if (this.filter.analysisPoint1 && this.filter.analysisPoint2) {
        this.converge();
      }
    }

    window.setTimeout(() => {
      this._filter(this.filter);
    });
  }

  async crossShow(municipalityIds) {
    this.crossShowIds = [...municipalityIds];
    if (municipalityIds.length === 0) {
      this.crossShowRoutes = [];
      this._deleteCrossShowLayer();

      return;
    }

    const result = await Promise.all(municipalityIds.map(municipalityId => (Route.getRoutes({
      filter: {
        all: true,
        municipalityId,
        onlyWithTransporter: 1,
        types: this.filter.analysisTypes,
        routePathTypesByGroup: this.filter.analysisPathTypes,
      },
      include: ['routeGroups'],
    }))));

    this.crossShowRoutes = municipalityIds.map((municipalityId, index) => {
      return {
        municipalityId,
        routes: result[index],
      };
    });

    this._renderCrossShow();
  }

  converge(duplicationRouteGroupId) {
    Route.converge(this.filter.analysisPoint1, this.filter.analysisPoint2, {
      municipalityId: this.filter.municipalityId,
      onlyWithTransporter: 1,
      types: this.filter.analysisTypes,
      routePathTypesByGroup: this.filter.analysisPathTypes,
      duplicationRouteGroupId,
    }).then(data => {
      const analysisRoutes = [
        ...data.routes,
      ].map(route => ({
        ...route,
        color: this._colorAnalysisBuffer[route.id] ? this._colorAnalysisBuffer[route.id] : `#${route.color}`,
        active: true,
      }));

      this._store.commit('route/converge', [...data.routes.map(r => ({
        ...r,
        active: true,
        color: this._colorAnalysisBuffer[r.id] ? this._colorAnalysisBuffer[r.id] : `#${r.color}`,
      }))]);
      this._store.commit('route/duplicationRouteGroupId', data.duplication_route_group_id);
      this._originAnalysisRoutes = analysisRoutes;
      this.analysisRoutes.next(analysisRoutes);
      this._filter({ clear: true });
    });
  }

  _renderCrossShow() {
    this._deleteCrossShowLayer();
    this._crossShowLayers = [];

    [...this.crossShowRoutes].reverse().forEach(({ routes, municipalityId }) => {
      routes.forEach(route => {
        let isNumberRendered = false;
        route.route_groups.forEach(routeGroup => {
          const colorLine = this._colorBuffer[route.id] ? this._colorBuffer[route.id] : this.crossShowColors[municipalityId];
          const coords = [];
          const line = L.geoJSON(routeGroup.route_line, {
            weight: 5,
            opacity: 1,
            color: colorLine,
            dashArray: '3',
            fillOpacity: 0.3,
            fillColor: colorLine,
            onEachFeature: (feature) => {
              if (feature.coordinates) {
                coords.push(L.GeoJSON.coordsToLatLngs(feature.coordinates));
              } else if (feature.geometry && feature.geometry.coordinates) {
                coords.push(L.GeoJSON.coordsToLatLngs(feature.geometry.coordinates));
              }
            },
          });

          const popup = new Vue({
            render: h => h(ColorPicker, {
              props: {
                id: route.id,
                color: colorLine,
                prefix: `Маршрут ${route.name} направление ${routeGroup.name}`,
              },
              on: {
                change: ({ id, color }) => {
                  this.changeCrossShowRouteGroupsColor(id, color);
                },
              },
            }),
          }).$mount();

          let markerFirst, markerLast;
          const numberContent = `${ROUTE_NUMBER_REGEX.exec(route.name)}`;

          if (this.showRouteNumbers && coords[0] && coords[coords.length - 1] && !isNumberRendered) {
            markerFirst = L.marker(coords[0][0], {
              icon: L.divIcon({
                html: numberContent,
                className: 'route-number-tooltip',
              }),
            }).bindPopup(() => popup.$el);
            markerLast = L.marker(coords[coords.length - 1][coords[coords.length - 1].length - 1], {
              icon: L.divIcon({
                html: numberContent,
                className: 'route-number-tooltip',
              }),
            }).bindPopup(() => popup.$el);

            markerFirst.on('popupopen', () => {
              line.bringToFront();
            });
            markerLast.on('popupopen', () => {
              line.bringToFront();
            });

            markerFirst.addTo(this.numbersGroup);
            markerLast.addTo(this.numbersGroup);
            this.numberCrossShowLayers.push(markerFirst);
            this.numberCrossShowLayers.push(markerLast);

            isNumberRendered = true;
          }

          line.bindPopup(() => popup.$el);
          line.addTo(this._map);

          this._crossShowLayers.push({
            line,
            route,
            popup,
            routeGroupId: routeGroup.id,
            markerFirst,
            markerLast,
          });
        });
      });
    });
  }

  _renderMarkers() {
    this._deleteLayer();
    this._layers = [];

    this.routes.getValue().forEach((route) => {
      let isNumberRendered = false;
      route.route_groups.forEach(routeGroup => {
        let colorLine = this._colorBuffer[route.id] ? this._colorBuffer[route.id]
          : routeGroup.color ? routeGroup.color : 'black';
        const coords = [];
        const line = L.geoJSON(routeGroup.route_line, {
          weight: 5,
          opacity: 1,
          color: colorLine,
          dashArray: '3',
          fillOpacity: 0.3,
          fillColor: colorLine,
          onEachFeature: (feature) => {
            if (feature.coordinates) {
              coords.push(L.GeoJSON.coordsToLatLngs(feature.coordinates));
            } else if (feature.geometry && feature.geometry.coordinates) {
              coords.push(L.GeoJSON.coordsToLatLngs(feature.geometry.coordinates));
            }
          },
        });

        const popup = new Vue({
          render: h => h(ColorPicker, {
            props: {
              id: route.id,
              color: colorLine,
              prefix: `Маршрут ${route.name} направление ${routeGroup.name}`,
            },
            on: {
              change: ({ id, color }) => {
                this.changeRouteGroupsColor(id, color);
              },
            },
          }),
        }).$mount();

        let markerFirst, markerLast;
        const numberContent = `${ROUTE_NUMBER_REGEX.exec(route.name)}`;

        if (this.showRouteNumbers && coords[0] && coords[coords.length - 1] && !isNumberRendered) {
          markerFirst = L.marker(coords[0][0], {
            icon: L.divIcon({
              html: numberContent,
              className: 'route-number-tooltip',
            }),
          }).bindPopup(() => popup.$el);
          markerLast = L.marker(coords[coords.length - 1][coords[coords.length - 1].length - 1], {
            icon: L.divIcon({
              html: numberContent,
              className: 'route-number-tooltip',
            }),
          }).bindPopup(() => popup.$el);

          markerFirst.on('popupopen', () => {
            line.bringToFront();
          });
          markerLast.on('popupopen', () => {
            line.bringToFront();
          });

          markerFirst.addTo(this.numbersGroup);
          markerLast.addTo(this.numbersGroup);
          this.numberLayers.push(markerFirst);
          this.numberLayers.push(markerLast);

          isNumberRendered = true;
        }

        line.bindPopup(() => popup.$el);
        line.addTo(this._map);

        line.on('popupopen', () => {
          line.bringToFront();
        });

        this._layers.push({
          line,
          route,
          routeGroupId: routeGroup.id,
          popup,
          markerFirst,
          markerLast,
        });
      });
    });
  }

  changeRouteGroupsColorFromAnalysis(routeId, color) {
    if (this._layers.find(l => l.route.id === routeId)) {
      this.changeRouteGroupsColor(routeId, color);
    } else if (this._crossShowLayers.find(l => l.route.id === routeId)) {
      this.changeCrossShowRouteGroupsColor(routeId, color);
    }
  }

  changeRouteGroupsColor(routeId, color) {
    this._colorBuffer[routeId] = color;
    this._store.commit('layers/analysis/setRouteColorBuffer', this._colorBuffer);

    window.setTimeout(() => {
      const layer = this._layers.find(l => l.route.id === routeId);

      if (layer) {
        layer.line.bringToFront();
      }
    }, 300);

    this.routes.next(
      this.routes.getValue().map(route => {
        return {
          ...route,
          route_groups: route.route_groups.map(group => {
            if (route.id === routeId) {
              return {
                ...group,
                color,
              };
            } else {
              return group;
            }
          }),
        };
      }),
    );
  }

  changeCrossShowRouteGroupsColor(routeId, color) {
    this._colorBuffer[routeId] = color;
    this._store.commit('layers/analysis/setRouteColorBuffer', this._colorBuffer);

    window.setTimeout(() => {
      const layer = this._crossShowLayers.find(l => l.route.id === routeId);

      if (layer) {
        layer.line.bringToFront();
      }
    }, 300);

    this._renderCrossShow();
  }

  changeAnalysisRouteColor(routeId, color) {
    this._colorAnalysisBuffer[routeId] = color;
    this._originAnalysisRoutes = this._originAnalysisRoutes.map(route => {
      if (route.id === routeId) {
        return {
          ...route,
          color,
        };
      } else {
        return route;
      }
    });

    this.analysisRoutes.next(
      this.analysisRoutes.getValue().map(route => {
        if (route.id === routeId) {
          return {
            ...route,
            color,
          };
        } else {
          return route;
        }
      }),
    );

    const route = this.analysisRoutes.getValue().find(r => r.id === routeId);
    if (route) {
      this._store.dispatch('route/updateConverge', route);
    }
  }

  renderAnalysisRoutes(openPopup = false, latlng = undefined) {
    this._deleteAnalysisLayer();
    this._analysisLayers = [];

    let offset = 0;

    this.analysisRoutes.getValue()
      .filter(r => r.active)
      .forEach((route, index) => {
        const weight = 8;
        const lineTails = L.geoJSON(route.lines.tails, {
          weight: route.id === this.highlightedRoute ? 10 : 5,
          opacity: 1,
          color: route.color,
          dashArray: '3',
          fillOpacity: 0.3,
          fillColor: route.color,
        });

        const lineCut = L.polyline(
          L.GeoJSON.coordsToLatLngs(route.lines.cut.coordinates, 0),
          {
            weight: route.id === this.highlightedRoute ? 10 : weight,
            opacity: 1,
            color: route.color,
            dashArray: '3',
            fillOpacity: 0.3,
            fillColor: route.color,
            offset,
          },
        );

        const popup = new Vue({
          render: h => h(ColorPicker, {
            props: {
              id: route.id,
              color: route.color,
              prefix: `Маршрут ${route.name}`,
            },
            on: {
              change: ({ id, color }) => {
                this.changeAnalysisRouteColor(id, color);
              },
            },
          }),
        }).$mount();

        lineTails.bindPopup(() => popup.$el);
        lineTails.addTo(this._map);

        lineCut.bindPopup(() => popup.$el);
        lineCut.addTo(this._map);

        if (route.id === this.highlightedRoute) {
          if (openPopup === 'tails') {
            lineTails.openPopup(latlng);
          } else if (openPopup === 'cut') {
            lineCut.openPopup(latlng);
          }
        }

        lineCut.on('popupopen', (e) => {
          this.highlightAnalysisRoute(route.id, 'cut', e.popup.getLatLng());
        });

        lineTails.on('popupopen', (e) => {
          this.highlightAnalysisRoute(route.id, 'tails', e.popup.getLatLng());
        });

        if (offset <= 0) {
          offset -= (weight + 4);
        }

        offset *= -1;

        this._analysisLayers.push({
          lineTails,
          lineCut,
          route,
          popup,
        });
      });

    const layer = this._analysisLayers.find(l => l.route.id === this.highlightedRoute);

    if (layer) {
      layer.lineTails.bringToFront();
      layer.lineCut.bringToFront();
    }
  }

  highlightAnalysisRoute(id, openPopup = false, latlng = undefined) {
    this.highlightedRoute = id;
    this.renderAnalysisRoutes(openPopup, latlng);
  }

  toggleRouteNumber(toggle, render = true) {
    this.showRouteNumbers = toggle;

    if (render) {
      this._renderMarkers();
      this.renderAnalysisRoutes();
    }
  }

  resetColorBuffer(render = true) {
    this._colorBuffer = {};
    this._store.commit('layers/analysis/setRouteColorBuffer', {});
    if (render) {
      this._renderMarkers();
    }
  }

  destroy() {
    this._deleteLayer();
    this._deleteCrossShowLayer();
    this._deleteAnalysisLayer();
    this._store.commit('route/converge', []);
    this._store.commit('route/duplicationRouteGroupId', null);

    if (this.fromMarker) {
      this.fromMarker.remove();
    }
    if (this.toMarker) {
      this.toMarker.remove();
    }
    this.filter = {
      ...this.filter,
      analysisPoint1: null,
      analysisPoint2: null,
    };

    this.analysisRoutes.next([]);
    this._originAnalysisRoutes = [];
  }
}
