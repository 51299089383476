import L from 'leaflet';
import { LayersService } from '@/services/layers-service';
import { MapService } from '../map-service';

export class MunicipalityLayer {
  _store = null;
  _municipalitiesLayer = null;
  _subscription = null;
  _municipalityRendered = -1;

  constructor({ store, map }) {
    this._store = store;
    this._map = map;
  }

  _deleteMunicipalities() {
    if (this._municipalitiesLayer !== null) {
      this._municipalitiesLayer.remove();
      this._municipalitiesLayer = null;
    }
  }

  _reInit() {
    if (this._subscription !== null) {
      this._subscription();
    }

    this._subscription = this._store.subscribe((mutation) => {
      if (mutation.type === 'municipalities/current') {
        this.reRender();
      }
    });
  }

  reRender() {
    if (!MapService.instance.map) {
      return;
    }

    this._deleteMunicipalities();
    this._reInit();

    const municipality = this._store.getters['municipalities/current'];
    if (municipality.position > 9) {
      this.renderCurrentMunicipality({
        center: this._store.getters['map/latlngs'],
        zoom: this._store.getters['map/zoom'],
      });
    } else {
      this.renderAllMunicipalities();
    }
  }

  renderAllMunicipalities() {
    const vm = this;
    this._municipalitiesLayer = L.geoJSON(this._store.getters['municipalities/municipalities']
      .filter(m => m.position > 9)
      .map((municipality, index) => ({
        type: 'Feature',
        geometry: municipality.border,
        props: {
          municipality,
          index,
        },
      })), {
      style: feature => {
        return {
          opacity: 1,
          fillOpacity: 0,
        };
      },
      onEachFeature(feature, layer) {
        layer.bindTooltip(feature.props.municipality.name, {
          sticky: true,
        });

        layer.on('click', event => {
          if (event.originalEvent.shiftKey) {
            vm._store.commit('municipalities/current', event.target.feature.props.municipality.id);
            LayersService.instance.render(LayersService.instance._layersParams);
            vm.reRender();
          }
        });
      },
    }).addTo(this._map);

    this._municipalityRendered = null;
  }

  renderCurrentMunicipality({ center = null, zoom = 9 } = {}) {
    const currentMunicipality = this._store.getters['municipalities/current'];

    if (center === null && this._municipalityRendered !== currentMunicipality.id) {
      if (currentMunicipality.center !== null) {
        center = [
          currentMunicipality.center.coordinates[1],
          currentMunicipality.center.coordinates[0],
        ];
      }
    }

    if (center !== null) {
      this._map.setView(center, zoom);
    }

    this._municipalitiesLayer = L.geoJSON({
      type: 'Feature',
      geometry: currentMunicipality.border,
      props: {
        municipality: currentMunicipality,
      },
    }, {
      style: (feature) => {
        return {
          opacity: 1,
          fillOpacity: 0,
        };
      },
    }).addTo(this._map);

    this._municipalitiesLayer.bringToBack();
    this._municipalityRendered = currentMunicipality.id;
  }

  destroy() {
    this._deleteMunicipalities();
    if (this._subscription !== null) {
      this._subscription();
    }
  }
}
