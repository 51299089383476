import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';
import router from '../router';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
  modules,
  state: {
    loading: 0,
    error: null,
    videoPopup: false,
    videoObj: null,
    detectorPopup: false,
    detectorObj: null,
    openSidebar: false,
    openMenu: true,
    openLayer: false,
  },
  getters: {
    loading: state => state.loading > 0,
    videoPopup: state => state.videoPopup,
    videoObj: state => state.videoObj,
    detectorPopup: state => state.detectorPopup,
    detectorObj: state => state.detectorObj,
    openSidebar: ({ openSidebar }) => openSidebar,
    openMenu: state => state.openMenu,
    openLayer: state => state.openLayer,
  },
  mutations: {
    loading: (state, payload) => (payload === true ? state.loading++ : state.loading--),
    showDetectorImage: (state, payload) => {
      state.detectorPopup = payload !== null;
      state.detectorObj = payload;
    },
    toggleSidebar: (state) => {
      state.openSidebar = !state.openSidebar;
    },
    setSidebar: (state, payload) => {
      state.openSidebar = payload;
    },
    toggleMenu: (state) => {
      state.openMenu = !state.openMenu;
    },
    closeMenu: (state) => {
      state.openMenu = false;
    },
    openMenu: (state) => {
      state.openMenu = true;
    },
    setOpenLayer: (state, payload) => {
      state.openLayer = payload;
    },
  },
  actions: {
    startLoading: ({ commit }) => commit('loading', true),
    stopLoading: ({ commit }) => commit('loading', false),
    closeMenu: ({ commit }) => commit('closeMenu'),
    openMenu: ({ commit }) => commit('openMenu'),
    setSidebar: ({ commit }, payload) => commit('setSidebar', payload),
    setOpenLayer: ({ commit }, payload) => commit('setOpenLayer', payload),
  },
  strict: debug,
});

export default store;
