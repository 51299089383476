import { render, staticRenderFns } from "./transport-popup.vue?vue&type=template&id=639e7ae3&scoped=true&"
import script from "./transport-popup.vue?vue&type=script&lang=ts&"
export * from "./transport-popup.vue?vue&type=script&lang=ts&"
import style0 from "./transport-popup.vue?vue&type=style&index=0&id=639e7ae3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "639e7ae3",
  null
  
)

export default component.exports