<template>
  <transition name="fade">
    <div class="modal" v-if="value" style="display: block" @click.self.prevent="outclick">
      <div class="modal-dialog" :style="{'max-width': maxWidth}">
        <div class="modal-content">
          <div class="modal-header" v-if="showHeader">
            <button class="close" @click="close" type="button" data-dismiss="modal" :aria-label="closeText"><span
              aria-hidden="true">×</span></button>
            <h3 class="modal-title">{{ title }}</h3>
          </div>
          <div class="modal-body" :class="{ 'modal-body-full': fullBody }">
            <slot></slot>
          </div>
          <div class="modal-footer" v-if="showFooter">
            <button class="btn btn-default btn-sm pull-left" v-if="showClose" @click="close" type="button" data-dismiss="modal">{{ closeText }}
            </button>
            <slot name="custom-buttons" ></slot>
            <button class="btn btn-primary btn-sm" @click="save" type="button">{{ submitText }}</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Popup',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    closeText: {
      type: String,
      default: 'Отмена',
      required: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    submitText: {
      type: String,
      default: 'Сохранить',
      required: false,
    },
    maxWidth: {
      type: String,
      default: '80%',
    },
    fullBody: {
      type: Boolean,
      default: false,
    },
    hideOnEsc: {
      type: Boolean,
      default: true,
    },
    hideOnOutclick: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({}),
  methods: {
    outclick() {
      if (this.hideOnOutclick) {
        this.close();
      }
    },
    close() {
      this.$emit('close');
    },
    save() {
      this.$emit('save');
    },
    closeOnEscape(key) {
      if (key.key === 'Escape' || (key.keyCode !== undefined && key.keyCode === 27)) {
        if (this.value && this.hideOnEsc) {
          this.close();
        }
      }
    },
  },
  watch: {
    value(val) {
      if (val) {
        document.addEventListener('keyup', this.closeOnEscape);
      } else {
        document.removeEventListener('keyup', this.closeOnEscape);
      }
    },
  },
};
</script>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .modal {
    overflow-y: auto;
  }

  .modal .modal-content {
    border-radius: 5px;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .modal {
    background: rgba(0,0,0,0.7);
    z-index: 5000;
  }

  .modal-dialog .modal-header .close {
    position: absolute;
    right: 25px;
  }

  .modal-body-full {
    padding: 0;
  }
</style>
