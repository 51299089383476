import { ReportByRoute } from '@/services/api/report/report-by-route';

export default {
  namespaced: true,
  state: {
    routes: [],
    plannedData: {
      planned_mts: 0,
      count: 0,
      routes: 0,
    },
  },
  getters: {
    routes: state => state.routes,
    plannedData: state => {
      return {
        planned_mts: state.plannedData.planned_mts,
        count: state.plannedData.count,
        routes: state.plannedData.routes,
        diff_mts: state.plannedData.count - state.plannedData.planned_mts,
      };
    },
  },
  mutations: {
    setRoutes: (state, payload) => {
      state.routes = payload;
    },
    setPlannedData: (state, payload) => {
      state.plannedData = {
        planned_mts: payload.planned_mts || 0,
        count: payload.count || 0,
        routes: payload.routes || 0,
      };
    },
  },
  actions: {
    loadRoutes(state, { dateFrom, dateTo }) {
      return new Promise((resolve, reject) => {
        ReportByRoute.loadRoutesNew({ dateFrom, dateTo })
          .then(response => {
            state.commit('setRoutes', response);
            resolve(response);
          })
          .catch(e => reject(e));
      });
    },

    loadPlannedData(state) {
      return new Promise((resolve, reject) => {
        ReportByRoute.loadPlannedData()
          .then(response => {
            state.commit('setPlannedData', response);
            resolve(response);
          })
          .catch(e => reject(e));
      });
    },

    loadRoutesNew(state, { date = '2020-02-20' }) {
      return new Promise((resolve, reject) => {
        ReportByRoute.loadRoutesNew({ date })
          .then(response => {
            state.commit('setRoutes', response.reports);
            resolve(response.reports);
          })
          .catch(e => reject(e));
      });
    },
  },
};
