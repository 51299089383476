/* eslint-disable camelcase */
import { AnalysisAverageState, IAnalysisAverageForm1Response, IAnalysisAverageForm1ResponseItem, IAnalysisAverageForm1VisualizeItem, IAnalysisAverageForm2Response, IRoute, IRouteGroup, ITransportType, RootState } from '@/types';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { Transport } from '@/services/api/transport';
import { Route } from '@/services/api/route';
import { RouteGroup } from '@/services/api/route-group';
import { AnalysisAverage } from '@/services/api/analysisAverage';
import { LayersService } from '@/services/layers-service';

const state: AnalysisAverageState = {
  transportTypes: [],
  routes: [],
  routeGroups: [],
  dataForm1: {
    report: [],
    segments: [],
    report_grouped: [],
  },
  dataForm2: {
    report: [],
    segments: [],
  },
  selectedForm1: null,
  selectedForm1Visualize: [],
  selectedForm2: null,
};
const getters: GetterTree<AnalysisAverageState, RootState> = {
  transportTypes(state) {
    return state.transportTypes;
  },
  routes(state) {
    return state.routes;
  },
  routeGroups(state) {
    return state.routeGroups;
  },
  reportsForm1(state) {
    return state.dataForm1.report;
  },
  segmentsForm1(state) {
    return state.dataForm1.segments;
  },
  groupedForm1(state) {
    return state.dataForm1.report_grouped;
  },
  selectedForm1(state) {
    return state.selectedForm1;
  },
  selectedForm1Visualize(state) {
    return state.selectedForm1Visualize;
  },
  reportsForm2(state) {
    return state.dataForm2.report;
  },
  segmentsForm2(state) {
    return state.dataForm2.segments;
  },
  selectedForm2Hour(state) {
    return state.selectedForm2;
  },
  selectedForm2(state) {
    if (state.selectedForm2 === null) {
      return null;
    }

    if (Number(state.selectedForm2) === 0) {
      return state.dataForm2.report.map(r => ({
        ...r.report_grouped,
        length: r.length,
        name: r.name,
      }));
    }

    return state.dataForm2.report.map(r => {
      return r.rows.filter(row => row.hour === state.selectedForm2).map(row => ({
        ...row,
        length: r.length,
        name: r.name,
      }));
    }).flat();
  },
};
const mutations: MutationTree<AnalysisAverageState> = {
  setTransportTypes(state, payload: Array<ITransportType>) {
    state.transportTypes = [...payload];
  },
  setRoutes(state, payload: Array<IRoute>) {
    state.routes = [...payload];
  },
  setRouteGroups(state, payload: Array<IRouteGroup>) {
    state.routeGroups = [...payload];
  },
  setDataForm1(state, payload: IAnalysisAverageForm1Response) {
    state.dataForm1 = { ...payload };
  },
  setSelectedForm1(state, payload: null | IAnalysisAverageForm1ResponseItem) {
    state.selectedForm1 = payload;
  },
  setSelectedForm1Visualize(state, payload: Array<IAnalysisAverageForm1VisualizeItem>) {
    state.selectedForm1Visualize = payload;
  },
  setDataForm2(state, payload: IAnalysisAverageForm2Response) {
    state.dataForm2 = { ...payload };
  },
  setSelectedForm2(state, payload: null | string) {
    state.selectedForm2 = payload;
  },
};

const actions: ActionTree<AnalysisAverageState, RootState> = {
  async loadTransportTypes({ commit }) {
    const data = await Transport.loadTransportTypes().catch(() => { });
    commit('setTransportTypes', data.transport_types);
  },
  async loadRoutes({ commit }, { municipalityId, routeTypes }: {
    municipalityId: number,
    routeTypes: Array<string>,
  }) {
    const data = await Route.getRoutes({
      filter: {
        municipalityId,
        routeTypes,
        all: true,
      },
      include: [],
    }).catch(() => { });
    commit('setRoutes', data);
  },
  async loadRouteGroups({ commit }, payload: number) {
    if (payload === 0) {
      commit('setRouteGroups', []);
    } else {
      const data = await RouteGroup.getRouteGroup(payload, true).catch(() => { });
      commit('setRouteGroups', data ?? []);
    }
  },
  async loadForm1({ commit }, payload: {
    point1: {
      lat: number, lng: number,
    },
    point2: {
      lat: number, lng: number,
    },
    timeFrom: string,
    timeTo: string,
    days: Array<string>,
    hours: Array<string>,
    routeTypeIds: Array<string>,
  }) {
    const data = await AnalysisAverage.getForm1(
      payload.point1,
      payload.point2,
      payload.timeFrom,
      payload.timeTo,
      payload.days,
      payload.hours,
      payload.routeTypeIds,
    ).catch(() => { });

    commit('setDataForm1', data ?? { report: [], segments: [], report_grouped: [] });
  },
  async visualizeForm1({ commit }, payload: {
    point1: {
      lat: number, lng: number,
    },
    point2: {
      lat: number, lng: number,
    },
    timeFrom: string,
    timeTo: string,
    routeTypeIds: Array<string>,
  }) {
    const data = await AnalysisAverage.visualizeForm1(payload.point1, payload.point2, payload.timeFrom, payload.timeTo, payload.routeTypeIds).catch(() => { });

    if (data) {
      commit('setSelectedForm1Visualize', data);

      LayersService.instance._analysisAverageLayers.visualizeForm1();
    }
  },
  clearForm1({ commit }) {
    commit('setDataForm1', { report: [], segments: [], report_grouped: [] });
  },
  selectForm1({ commit }, payload: IAnalysisAverageForm1ResponseItem) {
    commit('setSelectedForm1', payload);

    LayersService.instance._analysisAverageLayers.visualizeForm1();
  },
  clearSelectForm1({ commit }) {
    LayersService.instance._analysisAverageLayers.destroyForm1();
    commit('setSelectedForm1', null);
  },
  async loadForm2({ commit }, payload: {
    timeFrom: string,
    timeTo: string,
    days: Array<string>,
    hours: Array<string>,
    hourGroup: number,
    routeId?: number,
    routeGroupId?: number,
  }) {
    const data = await AnalysisAverage.getForm2(payload.timeFrom, payload.timeTo, payload.days, payload.hours, payload.hourGroup, payload.routeId, payload.routeGroupId)
      .catch(() => { });

    commit('setDataForm2', data ?? { report: [], segments: [] });
  },
  selectForm2({ commit }, payload: string) {
    commit('setSelectedForm2', payload);

    LayersService.instance._analysisAverageLayers.visualizeForm2();
  },
  clearForm2({ commit }) {
    commit('setDataForm2', { report: [], segments: [] });
  },
  clearSelectForm2({ commit }) {
    LayersService.instance._analysisAverageLayers.destroyForm2();
    commit('setSelectedForm2', null);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
