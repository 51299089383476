import { RoutePathType } from '@/services/api/route-path-type';

export default {
  namespaced: true,
  state: {
    routePathTypes: [],
    selectedRoutePathTypes: [],
  },
  getters: {
    routePathTypes: state => state.routePathTypes,
    selectedRoutePathTypes: state => state.selectedRoutePathTypes,
  },
  mutations: {
    routePathTypes: (state, payload) => { state.routePathTypes = payload; },
    selectedRoutePathTypes: (state, payload) => { state.selectedRoutePathTypes = payload; },
  },
  actions: {
    loadRoutePathTypes(state, { all = false }) {
      return new Promise((resolve, reject) => {
        RoutePathType.loadRoutePathTypes({ all })
          .then(collection => {
            state.commit('routePathTypes', collection);
            state.commit('selectedRoutePathTypes', collection);
            resolve(collection);
          })
          .catch(e => reject(e));
      });
    },
  },
};
