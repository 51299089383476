/* eslint-disable camelcase */
import { ITransportRoad } from '@/types';
import { AxiosResponse } from 'axios';
import axios from '../axios';

export class TransportRoad {
  static getAll(filter: {
    municipality_id: number,
    date: string,
    date_from: string,
    transporter_id?: string,
    route_subtype_id?: string,
    road_type?: string,
    [key: string]: string | number | undefined,
  }): Promise<Array<ITransportRoad>> {
    const params: {
      [key: string]: string | number | undefined,
    } = {};

    Object.keys(filter).forEach((key) => {
      if (filter[key]) {
        params[`filter[${key}]`] = filter[key];
      }
    });

    params.include = 'transport.transporter,transport,transport.model,transport.model.mark,transport.subtype';
    params.sort = '-avg_speed';

    return axios.get('/transport-road?all', { params })
      .then((
        result: AxiosResponse<{
          transport_roads: Array<ITransportRoad>,
        }>,
      ) => result.data.transport_roads);
  }
}
