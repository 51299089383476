export const PASSENGER_TYPE_GROUP = 1;
export const ROAD_FACILITIES_TYPE_GROUP = 2;
export const KOMMUN_TYPE_GROUP = 3;
export const ROUTE_TAXI_TYPE_GROUP = 4;
export const FIRST_AID_TYPE_GROUP = 5;
export const TAXI_TYPE_GROUP = 6;
export const MCHS_TYPE_GROUP = 7;

export const MENU_ITEMS = [
  PASSENGER_TYPE_GROUP,
  ROAD_FACILITIES_TYPE_GROUP,
  KOMMUN_TYPE_GROUP,
  ROUTE_TAXI_TYPE_GROUP,
  FIRST_AID_TYPE_GROUP,
  TAXI_TYPE_GROUP,
];
