import { TrashContainerType, TrashContainerTypeResponse, TrashContainerMaterialResponse } from '@/types';
import { AxiosResponse } from 'axios';
import axios from '../axios';

export class TrashContainerTypes {
  static getTrashContainerTypes(): Promise<Array<TrashContainerType>> {
    return axios.get('/trash-container-type?all')
      .then((res: AxiosResponse<TrashContainerTypeResponse>) => {
        return res.data.trash_container_types;
      });
  }

  static getTrashContainerMaterials(): Promise<Array<string>> {
    return axios.get('/trash-container-type/material?all')
      .then((res: AxiosResponse<TrashContainerMaterialResponse>) => {
        return res.data.materials;
      });
  }
}
