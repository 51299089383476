import { BaseLayer } from './base-layer';
export class SitCenterLayer extends BaseLayer {
  async reRender() {
    this._store.dispatch('layers/sitCenter/enable');
  }

  destroy() {
    this._store.dispatch('layers/sitCenter/disable');
  }
}
